import {
  faCalendarCheck,
  faCalendarDays,
  faEllipsisH,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { Link } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import calendario from "../../assets/icons/CALENDARIO.svg";
import check from "../../assets/icons/CHECK.svg";
import messaggi from "../../assets/icons/MESSAGGI.svg";
import persone from "../../assets/icons/PERSONE.svg";
import xmark from "../../assets/icons/XMARK.svg";
import BaseComponent from "../BaseComponent";
import ModalBookingDetail from "../ModalBookingDetail";
import PopUp from "../PopUp";

export default function Bookings({ experiences }) {
  const experiencesApproved = experiences.filter(
    (experience) => experience.approved_at
  );
  const [value, onChange] = useState(new Date());
  const [experiencesOfTheDay, setExperiencesOfTheDay] = useState([]);
  const [bookingsForTheDay, setBookingsForTheDay] = useState(null);
  const [bookingsForTheMonth, setBookingsForTheMonth] = useState(null);
  const [selectedExperience, setSelectedexperience] = useState(experiences[0]);
  const animatedComponents = makeAnimated();
  const [loading, setLoading] = useState(true);
  const [action, setAction] = useState(null);
  const [bookingToOpen, setBookingToOpen] = useState(null);
  const [bookingToEdit, setBookingToEdit] = useState(null);
  const [openDetailBooking, setOpenDetailBooking] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  const getBookings = async () => {
    const token = localStorage.getItem("token");
    const data = {
      date: new Date(value),
      experiences: [selectedExperience._id],
    };
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/experiencesBookingsOnDate`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (res) => {
        setBookingsForTheDay(res.data.filter((booking) => booking.user_id));
        const data2 = {
          month: value.getMonth() + 1,
          year: value.getFullYear(),
          experience_id: selectedExperience._id,
        };
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/experiencesBookingsOnMonth`,
            data2,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setBookingsForTheMonth(res.data);
            setTimeout(() => {
              setLoading(false);
            }, 200);
          });
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  function getExperiencesOfTheDay(date) {
    const dateFormatted =
      date.getFullYear() +
      "-" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(date.getDate()).padStart(2, "0");
    const experiences = experiencesApproved.filter((experience) => {
      if (experience.is_recurrent) {
        if (experience.data_info.recurrency_type === "week") {
          const dayOfWeek = date.getDay();
          const experienceDayOfWeek = experience.data_info.week_days;
          const experienceDayOfWeekByNumber = experienceDayOfWeek.map((day) => {
            switch (day) {
              case "lunedi":
                return 1;
              case "martedi":
                return 2;
              case "mercoledi":
                return 3;
              case "giovedi":
                return 4;
              case "venerdi":
                return 5;
              case "sabato":
                return 6;
              default:
                return 0;
            }
          });
          return experienceDayOfWeekByNumber.includes(dayOfWeek);
        } else {
          return experience;
        }
      } else {
        const dateFormatted =
          date.getFullYear() +
          "-" +
          String(date.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(date.getDate()).padStart(2, "0");

        const currentDate = new Date(experience.data_info.start_date);
        const inputDate = new Date(dateFormatted);

        return inputDate.getTime() === currentDate.getTime();
      }
    });
    setExperiencesOfTheDay(experiences);
  }

  const updateBooking = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      if (action === "confirm" || action === "delete") {
        await axios
          .put(
            `${process.env.REACT_APP_SERVER_URL}/api/bookings/edit/${bookingToEdit._id}`,
            {
              status: action === "confirm" ? "confirmed" : "rejected",
            }
          )
          .then((res) => {
            console.log(res.data);
            if (res.data.status === "confirmed") {
              setBookingsForTheDay(
                bookingsForTheDay.map((booking) =>
                  booking._id === bookingToEdit._id
                    ? { ...booking, status: "confirmed" }
                    : booking
                )
              );
            } else {
              setBookingsForTheDay(
                bookingsForTheDay.filter(
                  (booking) => booking._id !== bookingToEdit._id
                )
              );
            }
            setAction(null);
            setBookingToEdit(null);
            setIsLoadingUpdate(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (action === "propose") {
        setAction(null);
        setBookingToEdit(null);
        setIsLoadingUpdate(false);
      }
    }
  };

  useEffect(() => {
    if (bookingToEdit) {
      setIsLoadingUpdate(true);
      updateBooking();
    }
  }, [bookingToEdit]);

  useEffect(() => {
    if (selectedExperience) {
      getBookings();
    }
  }, [selectedExperience, value]);

  useEffect(() => {
    getExperiencesOfTheDay(value);
  }, []);

  const renderBookingDetails = (booking) => (
    <>
      <div className="flex items-center py-4 border-r gap-x-2 border-primary">
        <img
          src={
            booking.user_id.profile_pic ||
            "https://www.gravatar.com/avatar/?d=mp&s=200"
          }
          alt="user"
          className="object-cover w-8 h-8 rounded-full"
        />
        <div>{`${booking.user_id.name} ${booking.user_id.surname}`}</div>
      </div>
      <div className="grid w-full grid-cols-2 px-2 md:px-4 gap-x-4">
        {booking.guests_number.is_group ? (
          <div className="flex items-center text-xs font-normal md:text-base gap-x-4">
            <div className="flex items-center gap-x-2">
              <img src={persone} alt="persone" className="w-5 h-5" />
              <div>Gruppo privato</div>
            </div>
          </div>
        ) : (
          <div className="flex items-center text-xs font-normal md:text-base gap-x-4">
            <div className="flex items-center gap-x-2">
              <img src={persone} alt="persone" className="w-5 h-5" />
              <div>{`${booking.guests_number.adults} adulti`}</div>
            </div>
            {booking.guests_number.children > 0 && (
              <>
                <div>-</div>
                <div>{`${booking.guests_number.children} bambini`}</div>
              </>
            )}
          </div>
        )}
        <div className="flex items-center justify-between w-full gap-x-2">
          <div className="flex items-center gap-x-2">
            <div
              className={`w-3 h-3 rounded-full ${
                booking.status === "pending"
                  ? "bg-yellow-500"
                  : booking.status === "confirmed"
                  ? "bg-green-500"
                  : "bg-red-500"
              }`}
            ></div>
            <div
              className={`text-xs md:text-xl font-bold ${
                booking.status === "pending"
                  ? "text-yellow-500"
                  : booking.status === "confirmed"
                  ? "text-green-500"
                  : "text-red-500"
              }`}
            >
              {booking.status === "pending"
                ? "In attesa"
                : booking.status === "confirmed"
                ? "Confermata"
                : "Rifiutata"}
            </div>
          </div>
          <PopUp
            icon={
              <FontAwesomeIcon
                icon={faEllipsisH}
                className="text-xs text-white"
              />
            }
            orientation="horizontal"
            length={3}
            items={
              <>
                {booking.status !== "pending" ? (
                  <button
                    className="flex items-center w-full h-8 px-6 py-6 text-sm rounded-t-[30px] cursor-pointer text-primary hover:bg-gray-50"
                    onClick={() => {
                      setOpenDetailBooking(true);
                      setBookingToOpen(booking);
                    }}
                  >
                    <img
                      src={calendario}
                      alt="modifica"
                      className="w-6 h-6 pr-2"
                    />
                    <div>Dettagli prenotazione</div>
                  </button>
                ) : (
                  <>
                    <button
                      className="flex gap-x-4 items-center w-full h-8 px-6 py-6 text-sm rounded-t-[30px] cursor-pointer text-primary hover:bg-gray-50"
                      onClick={() => {
                        setAction("confirm");
                        setBookingToOpen(booking);
                      }}
                    >
                      <img
                        src={check}
                        alt="modifica"
                        className="w-6 h-6 pr-2"
                      />
                      <div className="text-start">Conferma Prenotazione</div>
                    </button>
                    <div className="mx-4 border-b" />
                    <button
                      className="flex items-center w-full h-8 px-6 py-6 text-sm cursor-pointer gap-x-4 text-primary hover:bg-gray-50"
                      onClick={() => {
                        setAction("propose");
                        setBookingToOpen(booking);
                      }}
                    >
                      <img
                        src={calendario}
                        alt="modifica"
                        className="w-6 h-6 pr-2"
                      />
                      <div className="text-start">Proponi data alternativa</div>
                    </button>
                    <div className="mx-4 border-b" />
                    <button
                      className="flex items-center w-full h-8 px-6 py-6 text-sm cursor-pointer gap-x-4 text-primary hover:bg-gray-50"
                      onClick={() => {
                        setAction("delete");
                        setBookingToOpen(booking);
                      }}
                    >
                      <img src={xmark} alt="cancel" className="w-6 h-6 pr-2" />
                      <div className="text-start">Rifiuta prenotazione</div>
                    </button>
                  </>
                )}
                <div className="mx-4 border-b" />
                <Link
                  className="flex gap-x-4 items-center w-full h-8 px-6 py-6 text-sm rounded-b-[30px] cursor-pointer text-primary hover:bg-gray-50"
                  to={`#`}
                >
                  <img src={messaggi} alt="modifica" className="w-6 h-6 pr-2" />
                  <div className="text-start">Scrivi messaggio all'utente</div>
                </Link>
              </>
            }
          />
          {openDetailBooking && bookingToOpen._id === booking._id && (
            <ModalBookingDetail
              openModal={openDetailBooking}
              setOpenModal={setOpenDetailBooking}
              booking={booking}
              entity={selectedExperience}
              kind={"experience"}
              viewer={"creator"}
            />
          )}
          <ModalTransition>
            {action !== null && bookingToOpen._id === booking._id && (
              <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
                <Modal
                  onClose={() => setAction(null)}
                  className="mt-0 md:mt-5"
                  width="medium"
                >
                  <ModalHeader>
                    <div className="flex gap-x-2">
                      <FontAwesomeIcon
                        icon={
                          action === "delete"
                            ? faTriangleExclamation
                            : action === "confirm"
                            ? faCalendarCheck
                            : faCalendarDays
                        }
                        className={`text-2xl ${
                          action === "delete"
                            ? "text-yellow-500"
                            : "text-primary"
                        }`}
                      />
                      <h3 className="text-xl">
                        {action === "confirm"
                          ? "Conferma Prenotazione"
                          : action === "propose"
                          ? "Proponi data alternativa"
                          : "Rifiuta prenotazione"}
                      </h3>
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setAction(null);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </ModalHeader>
                  <ModalBody>
                    {action === "confirm" ? (
                      <p className="text-gray-600">
                        Sei sicuro di voler confermare la prenotazione?
                      </p>
                    ) : action === "propose" ? (
                      <p className="text-gray-600">
                        Proponi una data alternativa per la prenotazione
                      </p>
                    ) : (
                      <p className="text-gray-600">
                        Sei sicuro di voler rifiutare la prenotazione?
                      </p>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <div className="flex items-center gap-x-4">
                      <button
                        className="px-6 py-2 bg-gray-100 rounded-md"
                        onClick={() => {
                          setAction(null);
                          setBookingToEdit(null);
                        }}
                      >
                        Annulla
                      </button>
                      <button
                        className="px-6 py-2 text-white rounded-md bg-primary"
                        onClick={() => {
                          if (isLoadingUpdate) return;
                          setBookingToEdit(booking);
                        }}
                      >
                        {isLoadingUpdate ? (
                          <UiduSpinner size="small" invertColor />
                        ) : action === "confirm" || action === "delete" ? (
                          "Conferma"
                        ) : (
                          "Proponi"
                        )}
                      </button>
                    </div>
                  </ModalFooter>
                </Modal>
              </div>
            )}
          </ModalTransition>
        </div>
      </div>
    </>
  );

  const renderExperience = (experience, value, bookingsForTheDay) => (
    <div className="flex flex-col w-full gap-y-2">
      {experience.is_recurrent ? (
        experience.data_info.week_days_start_time
          .filter(
            (day) =>
              day.day ===
              value
                .toLocaleDateString("it", { weekday: "long" })
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
          )
          .map((day) =>
            day.time.map((time, index) => {
              const formattedTime = time.padStart(5, "0");
              return (
                <div className="mb-4" key={index}>
                  <div className="flex justify-between w-full text-primary">
                    <h3 className="mb-4 text-xl font-semibold">
                      {experience.name}
                    </h3>
                    <p>
                      Orario inizio: {formattedTime} -{" "}
                      {value.toLocaleDateString("it", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </p>
                  </div>
                  {bookingsForTheDay.filter(
                    (booking) => booking.start_time === time
                  ).length > 0 ? (
                    <div className="grid items-center w-full grid-cols-2 py-2 pl-6 pr-4 border rounded-[15px] border-primary">
                      <div className="font-bold border-r border-primary">
                        Prenotazioni
                      </div>
                      <div className="grid w-full grid-cols-2 px-2 md:px-4 gap-x-4">
                        <div className="font-bold">Ospiti: </div>
                        <div className="pr-4 font-bold md:pr-12">stato:</div>
                      </div>
                      {bookingsForTheDay
                        .filter((booking) => booking.start_time === time)
                        .map(renderBookingDetails)}
                    </div>
                  ) : (
                    <p className="py-2 text-gray-600">
                      Nessuna prenotazione per questo orario
                    </p>
                  )}
                </div>
              );
            })
          )
      ) : (
        <>
          <div className="flex justify-between w-full text-primary">
            <h3 className="mb-4 text-xl font-semibold">{experience.name}</h3>
            <p>
              {value.toLocaleDateString("it", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </p>
          </div>
          {bookingsForTheDay.length > 0 ? (
            <div className="grid items-center w-full grid-cols-2 py-2 pl-6 pr-4 border rounded-[15px] border-primary">
              <div className="font-bold border-r border-primary">
                Prenotazioni
              </div>
              <div className="grid w-full grid-cols-2 px-2 md:px-4 gap-x-4">
                <div className="font-bold">Ospiti: </div>
                <div className="pr-4 font-bold md:pr-12">stato:</div>
              </div>
              {bookingsForTheDay.map(renderBookingDetails)}
            </div>
          ) : (
            <p className="py-2 text-gray-600">
              Nessuna prenotazione per questa data
            </p>
          )}
        </>
      )}
    </div>
  );

  return (
    <BaseComponent title="">
      <h1 className="text-2xl text-center md:text-left text-primary">
        Le tue prenotazioni
      </h1>
      <div className="flex flex-col px-4 pt-4 mt-4 mb-12 overflow-y-auto rounded-md gap-y-6">
        <p className="text-gray-600">
          Verifica le prenotazioni per il giorno dell'esperienza selezionata
        </p>
        {experiences.length > 1 && (
          <div className="flex items-center justify-between">
            <p className="text-lg font-semibold">Esperienza</p>
            <div className="w-2/3">
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                // menuPortalTarget={document.body}
                // menuPosition="absolute"
                defaultValue={
                  selectedExperience && {
                    value: selectedExperience?._id,
                    label: selectedExperience?.name,
                  }
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "2px solid rgb(229 231 235)",
                    height: "35px",
                    width: "100%",
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: "#246155",
                    color: "white",
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: "white",
                  }),
                  multiValueRemove: (provided, state) => ({
                    ...provided,
                    color: "white",
                    ":hover": {
                      backgroundColor: "#328575",
                    },
                  }),
                }}
                options={experiences.map((experience) => ({
                  value: experience._id,
                  label: experience.name,
                }))}
                onChange={(value) => {
                  setSelectedexperience(
                    experiences.find(
                      (experience) => experience._id === value.value
                    )
                  );
                }}
              />
            </div>
          </div>
        )}
        <div className="w-full border rounded-md shadow-md md:py-4 md:px-6">
          <Calendar
            onChange={(date) => {
              onChange(date);
              setLoading(true);
              getExperiencesOfTheDay(date);
            }}
            value={value}
            minDate={new Date()}
            prev2Label={null}
            next2Label={null}
            tileContent={({ date, view }) => {
              if (view === "month") {
                const dateFormatted = dayjs(date).format("YYYY-MM-DD");
                const experience = [selectedExperience].map((experience) => {
                  if (experience.is_recurrent) {
                    if (experience.data_info.recurrency_type === "week") {
                      const dayOfWeek = date.getDay();
                      const experienceDayOfWeek =
                        experience.data_info.week_days;
                      const experienceDayOfWeekByNumber =
                        experienceDayOfWeek.map((day) => {
                          switch (day) {
                            case "lunedi":
                              return 1;
                            case "martedi":
                              return 2;
                            case "mercoledi":
                              return 3;
                            case "giovedi":
                              return 4;
                            case "venerdi":
                              return 5;
                            case "sabato":
                              return 6;
                            default:
                              return 0;
                          }
                        });
                      if (experienceDayOfWeekByNumber.includes(dayOfWeek)) {
                        return experienceDayOfWeekByNumber.includes(dayOfWeek);
                      } else {
                        return false;
                      }
                    } else {
                      return true;
                    }
                  } else {
                    const currentDate = new Date(
                      experience.data_info.start_date
                    );
                    currentDate.setDate(currentDate.getDate() - 1);

                    const formattedDate = currentDate.toLocaleDateString("it", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    });
                    return (
                      new Date(dateFormatted).toLocaleString("it", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }) === formattedDate
                    );
                  }
                });

                const today = new Date();
                const todayFormatted = today.toISOString().slice(0, 10);

                if (
                  experience.includes(true) &&
                  dateFormatted >= todayFormatted
                ) {
                  return (
                    <div className="flex items-center gap-x-1">
                      <div className="w-1 h-1 -mt-2 bg-green-500 rounded-full" />
                      {bookingsForTheMonth &&
                        Object.keys(bookingsForTheMonth).length > 0 &&
                        bookingsForTheMonth[dateFormatted] && (
                          <div className="w-1 h-1 -mt-2 bg-yellow-500 rounded-full" />
                        )}
                    </div>
                  );
                }
              }
            }}
          />
        </div>
        <div className="flex flex-col w-full mt-6 gap-y-4">
          {loading ? (
            <div className="flex items-center justify-center w-full h-32">
              <UiduSpinner size="medium" className="text-primary" />
            </div>
          ) : (
            <>
              {experiencesOfTheDay.length > 0 && bookingsForTheDay ? (
                <>
                  {experiencesOfTheDay.some(
                    (experience) => selectedExperience._id === experience._id
                  ) ? (
                    experiencesOfTheDay
                      .filter(
                        (experience) =>
                          selectedExperience._id === experience._id
                      )
                      .map((experience) =>
                        renderExperience(experience, value, bookingsForTheDay)
                      )
                  ) : (
                    <p className="py-2 text-lg font-bold text-gray-600">
                      L'esperienza selezionata non è disponibile per questa data
                    </p>
                  )}
                </>
              ) : (
                <p className="py-2 text-lg font-bold text-gray-600">
                  Nessuna esperienza disponibile per questa data
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </BaseComponent>
  );
}
