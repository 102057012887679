import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { faArrowRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProposeStructureContext } from "../../pages/ProposeStructure";
import BaseComponent from "../BaseComponent";

export default function ManagePayment() {
  const { id } = useParams();
  const [checkingStatus, setCheckingStatus] = useState(true);
  const [accountToComplete, setAccountToComplete] = useState(null);
  const [accountCompleted, setAccountCompleted] = useState(null);
  const [loading, setLoading] = useState(false);

  const { userStructure, setErrors, setUserStructure } = useContext(
    ProposeStructureContext
  );

  const checkStripeAccount = async () => {
    const token = localStorage.getItem("token");

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/checkStripeAccountStatus/${userStructure._id}`,
        {
          url: `proposeStructure/${id}/managePayments`,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (res) => {
        if (res.data.stripe_status === "inactive") {
          setAccountToComplete(res.data.accountLink);
          setErrors([
            {
              error:
                "Devi completare la registrazione del tuo account Stripe per continuare",
            },
          ]);
        }
        if (res.data.stripe_status === "active") {
          setAccountCompleted(res.data.dashboardLink);
          setErrors([{ error: null }]);
          setUserStructure(res.data.user);
        }
        setCheckingStatus(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createStripeAccount = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/createStripeAccount/${userStructure._id}`,
        {
          url: `proposeStructure/${id}/managePayments`,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (res) => {
        const { url } = res.data.accountLink;
        console.log(url);
        window.location.href = url;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    checkStripeAccount();
  }, []);

  return (
    <BaseComponent title="Gestione Pagamenti">
      <div className="flex flex-col justify-between min-h-[500px] overflow-y-auto bg-gray-100 rounded-md px-4 py-4 my-4">
        {userStructure && !checkingStatus ? (
          <div className="flex flex-col gap-y-12">
            <div className="flex p-4 text-white rounded-md md:p-6 bg-primary gap-x-4">
              <div className="flex flex-col justify-between w-full text-sm gap-y-4">
                <div className="flex items-center text-lg gap-x-3 md:gap-x-4">
                  <FontAwesomeIcon icon={faCircleQuestion} />
                  <p className="font-semibold">
                    Come gestisce i pagamenti Esco? &nbsp; 🤔
                  </p>
                </div>
                <p>
                  <p>
                    Esco.zone utilizza Stripe, una delle piattaforme di
                    pagamento online più sicure e affidabili, per gestire le
                    transazioni in modo semplice e trasparente.
                    <br className="mb-1" />
                    💳 &nbsp; Come funziona il pagamento?
                    <br className="mb-1" />
                    1️⃣ &nbsp; Quando un cliente prenota un soggiorno presso la
                    tua struttura, l’importo viene temporaneamente bloccato
                    sulla sua carta di credito.
                    <br className="mb-1" />
                    2️⃣ &nbsp; Se la prenotazione viene confermata, il pagamento
                    viene trasferito a Esco.zone.
                    <br className="mb-1" />
                    3️⃣ &nbsp; Esco.zone ti accrediterà l’importo direttamente
                    sul tuo conto bancario all’inizio del mese successivo al
                    soggiorno.
                    <br className="mb-1" />❌ &nbsp; Se la prenotazione viene
                    rifiutata, l’importo bloccato sulla carta del cliente viene
                    sbloccato immediatamente.
                  </p>
                </p>
              </div>
            </div>
            <>
              {!userStructure.stripe_id ? (
                <div className="flex flex-col items-center justify-between w-full p-4 bg-white border rounded-md md:flex-row gap-y-6">
                  <p className="w-3/4 text-lg font-semibold">
                    Completa ora la registrazione su Stripe per iniziare a
                    ricevere pagamenti in modo semplice e sicuro!
                  </p>
                  <button
                    className="flex items-center w-full px-4 py-2 font-semibold text-white rounded-md md:w-fit gap-x-4 bg-primary"
                    onClick={() => {
                      createStripeAccount();
                    }}
                  >
                    {!loading ? (
                      <>
                        <p>Collega Stripe</p>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </>
                    ) : (
                      <>
                        <p>Caricamento..</p>
                        <UiduSpinner size="small" invertColor />
                      </>
                    )}
                  </button>
                </div>
              ) : accountToComplete ? (
                <div className="flex flex-col items-center justify-between w-full p-4 bg-white border rounded-md md:flex-row gap-y-6">
                  <p className="text-lg font-semibold">
                    Completa ora la registrazione su Stripe per iniziare a
                    ricevere pagamenti in modo semplice e sicuro!
                  </p>
                  <a
                    href={accountToComplete}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center w-full px-4 py-2 font-semibold text-white rounded-md md:w-fit gap-x-4 bg-primary"
                  >
                    <p>Completa la registrazione</p>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </a>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-between w-full p-4 bg-white border rounded-md md:flex-row gap-y-6">
                  <p className="text-lg font-semibold">
                    Iscrizione a Stripe completata con successo!
                  </p>
                  <div className="flex items-center w-full px-3 py-2 font-semibold text-white bg-green-500 rounded-md md:w-fit gap-x-4">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                </div>
              )}
            </>
          </div>
        ) : (
          <div className="flex items-center justify-center w-full min-h-[468px]">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
