import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ProposeStructureContext } from "../../pages/ProposeStructure";
import BaseComponent from "../BaseComponent";
import { useGeneralServices } from "../structureComponents/StructureServices";

export default function Services() {
  const animatedComponents = makeAnimated();
  const {
    generalServices,
    hospitalityServices,
    comfortServices,
    securityServices,
  } = useGeneralServices();
  const { id } = useParams();

  const { structure, setStructure } = useContext(ProposeStructureContext);
  const [selectedGeneralServices, setSelectedGeneralServices] = useState(
    structure.services.length > 0
      ? structure?.services?.find((service) => service.kind === "general")
          .services
      : null
  );
  const [selectedHospitalityServices, setSelectedHospitalityServices] =
    useState(
      structure.services.length > 0
        ? structure?.services?.find((service) => service.kind === "hospitality")
            .services
        : null
    );
  const [selectedComfortServices, setSelectedComfortServices] = useState(
    structure.services.length > 0
      ? structure?.services?.find((service) => service.kind === "comfort")
          .services
      : null
  );
  const [selectedSecurityServices, setSelectedSecurityServices] = useState(
    structure.services.length > 0
      ? structure?.services?.find((service) => service.kind === "security")
          .services
      : null
  );

  const updateStructure = async () => {
    const token = localStorage.getItem("token");
    const data = {
      services: [
        {
          kind: "general",
          services: selectedGeneralServices,
        },
        {
          kind: "hospitality",
          services: selectedHospitalityServices,
        },
        {
          kind: "comfort",
          services: selectedComfortServices,
        },
        {
          kind: "security",
          services: selectedSecurityServices,
        },
      ],
    };

    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/structure/edit/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setStructure(res.data.structure);
      })
      .catch(() => {
        alert("Errore durante la modifica della struttura");
      });
  };

  useEffect(() => {
    if (structure) updateStructure();
  }, [
    selectedGeneralServices,
    selectedHospitalityServices,
    selectedComfortServices,
    selectedSecurityServices,
  ]);

  return (
    <BaseComponent title="Servizi struttura">
      <div className="flex flex-col p-4 my-4 overflow-y-auto bg-gray-100 rounded-md md:py-8 md:px-10 gap-y-4 md:gap-y-6">
        {structure ? (
          <>
            <div className="flex flex-col pb-6 border-b-2 border-gray-200 md:flex-row gap-y-2">
              <p className="w-full font-semibold md:w-1/3">Servizi generali</p>
              <div className="w-full md:w-2/3">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  menuPortalTarget={document.body}
                  menuPosition="absolute"
                  defaultValue={
                    selectedGeneralServices &&
                    selectedGeneralServices.map((service) => {
                      return {
                        value: service,
                        label: generalServices.find(
                          (generalService) => generalService.id === service
                        )?.name,
                      };
                    })
                  }
                  onChange={(value) => {
                    setSelectedGeneralServices(
                      value.map((service) => service.value)
                    );
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid rgb(229 231 235)",
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: "#246155",
                      color: "white",
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: "white",
                    }),
                    multiValueRemove: (provided, state) => ({
                      ...provided,
                      color: "white",
                      ":hover": {
                        backgroundColor: "#328575",
                      },
                    }),
                  }}
                  options={generalServices.map((service) => {
                    return {
                      value: service.id,
                      label: service.name,
                    };
                  })}
                />
              </div>
            </div>
            <div className="flex flex-col pb-6 border-b-2 border-gray-200 md:flex-row gap-y-2">
              <p className="w-full font-semibold md:w-1/3">Accoglienza</p>
              <div className="w-full md:w-2/3">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  menuPortalTarget={document.body}
                  menuPosition="absolute"
                  defaultValue={
                    selectedHospitalityServices &&
                    selectedHospitalityServices.map((service) => {
                      return {
                        value: service,
                        label: hospitalityServices.find(
                          (hospitalityService) =>
                            hospitalityService.id === service
                        )?.name,
                      };
                    })
                  }
                  onChange={(value) =>
                    setSelectedHospitalityServices(
                      value.map((service) => service.value)
                    )
                  }
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid rgb(229 231 235)",
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: "#246155",
                      color: "white",
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: "white",
                    }),
                    multiValueRemove: (provided, state) => ({
                      ...provided,
                      color: "white",
                      ":hover": {
                        backgroundColor: "#328575",
                      },
                    }),
                  }}
                  options={hospitalityServices.map((service) => {
                    return {
                      value: service.id,
                      label: service.name,
                    };
                  })}
                />
              </div>
            </div>
            <div className="flex flex-col pb-6 border-b-2 border-gray-200 md:flex-row gap-y-2">
              <p className="w-full font-semibold md:w-1/3">Comfort</p>
              <div className="w-full md:w-2/3">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  menuPortalTarget={document.body}
                  menuPosition="absolute"
                  defaultValue={
                    selectedComfortServices &&
                    selectedComfortServices.map((service) => {
                      return {
                        value: service,
                        label: comfortServices.find(
                          (comfortService) => comfortService.id === service
                        )?.name,
                      };
                    })
                  }
                  onChange={(value) =>
                    setSelectedComfortServices(
                      value.map((service) => service.value)
                    )
                  }
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid rgb(229 231 235)",
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: "#246155",
                      color: "white",
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: "white",
                    }),
                    multiValueRemove: (provided, state) => ({
                      ...provided,
                      color: "white",
                      ":hover": {
                        backgroundColor: "#328575",
                      },
                    }),
                  }}
                  options={comfortServices.map((service) => {
                    return {
                      value: service.id,
                      label: service.name,
                    };
                  })}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-y-2">
              <p className="w-full font-semibold md:w-1/3">Sicurezza</p>
              <div className="w-full md:w-2/3">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  menuPortalTarget={document.body}
                  menuPosition="absolute"
                  defaultValue={
                    selectedSecurityServices &&
                    selectedSecurityServices.map((service) => {
                      return {
                        value: service,
                        label: securityServices.find(
                          (securityService) => securityService.id === service
                        )?.name,
                      };
                    })
                  }
                  onChange={(value) =>
                    setSelectedSecurityServices(
                      value.map((service) => service.value)
                    )
                  }
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid rgb(229 231 235)",
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: "#246155",
                      color: "white",
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: "white",
                    }),
                    multiValueRemove: (provided, state) => ({
                      ...provided,
                      color: "white",
                      ":hover": {
                        backgroundColor: "#328575",
                      },
                    }),
                  }}
                  options={securityServices.map((service) => {
                    return {
                      value: service.id,
                      label: service.name,
                    };
                  })}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
