import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

export default function EditPassword({ setOpenEditPassword }) {
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const intl = useIntl();
  const { id } = useParams();

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    const oldPassword = document.getElementById("oldPassword").value;
    const password = document.getElementById("password").value;
    const confirmPassword = document.getElementById("confirmPassword").value;
    if (oldPassword === "" || password === "" || confirmPassword === "") {
      setError(
        intl.formatMessage({
          defaultMessage: "Compilare tutti i campi",
          id: "iQeu2m",
        })
      );
      return;
    }
    if (password !== confirmPassword) {
      setError(
        intl.formatMessage({
          defaultMessage: "Le password non corrispondono",
          id: "go4IKA",
        })
      );
      return;
    }
    if (password.length < 8) {
      setError(
        intl.formatMessage({
          defaultMessage: "La password deve essere di almeno 8 caratteri",
          id: "tVqRmM",
        })
      );
      return;
    }
    if (password === oldPassword) {
      setError(
        intl.formatMessage({
          defaultMessage:
            "La nuova password non può essere uguale a quella vecchia",
          id: "HKZV6T",
        })
      );
      return;
    }
    setError(null);
    const data = {
      oldPassword,
      password,
    };
    const token = localStorage.getItem("token");
    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/user/editPassword/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "Password updated") {
          setOpenEditPassword(false);
        } else {
          console.log(res.data);
          setError(
            intl.formatMessage({
              defaultMessage: "Errore durante l'aggiornamento della password",
              id: "YYTH8U",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setError(
          intl.formatMessage({
            defaultMessage: "Errore durante l'aggiornamento della password",
            id: "YYTH8U",
          })
        );
      });
  };

  return (
    <div className="flex flex-col justify-between w-full gap-y-6">
      <div className="flex flex-col w-full gap-y-4">
        <div className="relative flex flex-col w-full gap-y-2">
          <p className="font-semibold">
            {intl.formatMessage({
              defaultMessage: "Vecchia password",
              id: "QYf31u",
            })}
          </p>
          <div className="relative w-full">
            <input
              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none md:mt-2 focus:outline-none focus:shadow-outline"
              type={showOldPassword ? "text" : "password"}
              id="oldPassword"
              name="oldPassword"
              placeholder="Password"
              autoComplete="new-password"
            />
            <span
              className="absolute right-0 mt-1.5 mr-3 text-gray-300 cursor-pointer top-2"
              onClick={() => {
                setShowOldPassword(!showOldPassword);
              }}
            >
              {showOldPassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </span>
          </div>
        </div>
        <div className="relative flex flex-col w-full gap-y-2">
          <p className="font-semibold">
            {intl.formatMessage({
              defaultMessage: "Nuova password",
              id: "0G+kvh",
            })}
          </p>
          <div className="relative w-full">
            <input
              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none md:mt-2 focus:outline-none focus:shadow-outline"
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              placeholder="Password"
              autoComplete="new-password"
            />
            <span
              className="absolute right-0 mt-1.5 mr-3 text-gray-300 cursor-pointer top-2"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </span>
          </div>
        </div>
        <div className="relative flex flex-col w-full gap-y-2">
          <p className="font-semibold">
            {intl.formatMessage({
              defaultMessage: "Conferma password",
              id: "ewOF9L",
            })}
          </p>
          <div className="relative w-full">
            <input
              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none md:mt-2 focus:outline-none focus:shadow-outline"
              type={showConfirmPassword ? "text" : "password"}
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Password"
            />
            <span
              className="absolute right-0 mt-1.5 mr-3 text-gray-300 cursor-pointer top-2"
              onClick={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
            >
              {showConfirmPassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </span>
          </div>
        </div>
      </div>
      {error && (
        <div className="flex items-center justify-center w-full h-8 text-sm text-white bg-red-500 rounded">
          {error}
        </div>
      )}
      <div className="flex justify-end w-full mt-16 mb-8 gap-x-8">
        <button
          className="flex items-center justify-center px-4 py-2 text-white bg-gray-400 rounded-md"
          type="button"
          onClick={() => setOpenEditPassword(false)}
        >
          {intl.formatMessage({
            defaultMessage: "Annulla",
            id: "tfqu9h",
          })}
        </button>
        <button
          className="flex items-center justify-center px-4 py-2 text-white rounded-md bg-primary"
          type="button"
          onClick={(e) => handleUpdatePassword(e)}
        >
          {intl.formatMessage({
            defaultMessage: "Salva",
            id: "eRxNJP",
          })}
        </button>
      </div>
    </div>
  );
}
