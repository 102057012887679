import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CarouselArticles from "../components/CarouselArticles";
import CarouselEscoBox from "../components/CarouselEscoBox";
import CarouselExperiences from "../components/CarouselExperiences";
import CarouselStructures from "../components/CarouselStructures";
import Cover from "../components/Cover";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageBody from "../components/PageBody";

export default function Home() {
  const [shareArticles, setShareArticles] = useState([]);
  const [selectedTab, setSelectedTab] = useState(
    localStorage.getItem("selectedExperienceTab") || "wine"
  );
  const intl = useIntl();

  useEffect(() => {
    document.title = "Esco";
    // setShareArticles(generateFakeData({ kind: "escoShare", amount: 2 }));
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("selectedExperienceTab") === null ||
      localStorage.getItem("selectedExperienceTab") !== selectedTab
    ) {
      localStorage.setItem("selectedExperienceTab", selectedTab);
    }
  }, [selectedTab]);

  return (
    <div>
      <header>
        <Header />
      </header>
      <main>
        <div>
          <Cover
            key={selectedTab}
            url={
              selectedTab === "wine"
                ? "https://escopublic.s3.eu-north-1.amazonaws.com/2_senior-couple-at-wine-tasting-2022-08-04-17-49-16-utc.mov"
                : selectedTab === "art"
                ? "https://escopublic.s3.eu-north-1.amazonaws.com/2_historical-columns-2022-01-18-23-34-52-utc.mov"
                : selectedTab === "outdoor"
                ? "https://escopublic.s3.eu-north-1.amazonaws.com/GX014697.mp4"
                : "https://escopublic.s3.eu-north-1.amazonaws.com/2_chef-and-students-smashing-garlic-at-cooking-maste-2022-08-05-01-52-56-utc.mov"
            }
            description={intl.formatMessage({
              defaultMessage: "ESPLORA, SCOPRI, GUSTA VIVI.",
              id: "hSKLVn",
            })}
            isVideo={true}
            isHomePage={true}
            withSearch={true}
          />
        </div>
        <div className="relative z-20">
          <PageBody
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            isHomePage={true}
          >
            <h1 className="px-4 pt-4 mt-16 mb-10 text-xl font-bold text-center md:my-16 md:text-4xl text-secondary md:p-0">
              {intl.formatMessage({ defaultMessage: "i box", id: "sdh927" })}{" "}
              <strong
                className={
                  selectedTab === "wine"
                    ? "text-wine"
                    : selectedTab === "art"
                    ? "text-art"
                    : selectedTab === "outdoor"
                    ? "text-outdoor"
                    : "text-authentic"
                }
              >
                {selectedTab === "wine"
                  ? intl.formatMessage({
                      defaultMessage: "Food & Wine",
                      id: "dlw6zi",
                    })
                  : selectedTab === "art"
                  ? intl.formatMessage({
                      defaultMessage: "Arte e Cultura",
                      id: "BheYmi",
                    })
                  : selectedTab === "outdoor"
                  ? intl.formatMessage({
                      defaultMessage: "Outdoor",
                      id: "fsWxsR",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Authentic",
                      id: "MzJPQ5",
                    })}
              </strong>{" "}
              {intl.formatMessage({
                defaultMessage: "pensati per te",
                id: "k0M1z7",
              })}
            </h1>
            <div>
              <CarouselEscoBox selectedTab={selectedTab} />
            </div>
            <h1 className="px-4 mt-0 text-xl font-bold text-center mb-14 md:mt-8 md:mt-16 md:mb-20 md:text-4xl text-secondary md:px-0">
              {intl.formatMessage({
                defaultMessage: "Le migliori esperienze",
                id: "yg5eI9",
              })}{" "}
              <strong
                className={
                  selectedTab === "wine"
                    ? "text-wine"
                    : selectedTab === "art"
                    ? "text-art"
                    : selectedTab === "outdoor"
                    ? "text-outdoor"
                    : "text-authentic"
                }
              >
                {selectedTab === "wine"
                  ? intl.formatMessage({
                      defaultMessage: "Food & Wine",
                      id: "dlw6zi",
                    })
                  : selectedTab === "art"
                  ? intl.formatMessage({
                      defaultMessage: "Arte e Cultura",
                      id: "BheYmi",
                    })
                  : selectedTab === "outdoor"
                  ? intl.formatMessage({
                      defaultMessage: "Outdoor",
                      id: "fsWxsR",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Authentic",
                      id: "MzJPQ5",
                    })}
              </strong>
            </h1>
            <div>
              <CarouselExperiences selectedTab={selectedTab} />
            </div>
            <h1 className="px-4 mt-2 mb-12 text-xl font-bold text-center md:mt-16 md:mb-20 md:text-4xl text-secondary md:px-0">
              {intl.formatMessage({
                defaultMessage: "Gli alloggi più apprezzati",
                id: "chmqqV",
              })}
            </h1>
            <div>
              <CarouselStructures />
            </div>
            <h1 className="px-4 mt-2 mb-12 text-xl font-bold text-center md:mt-16 md:mb-20 md:text-4xl text-secondary md:px-0">
              {intl.formatMessage({
                defaultMessage: "Scopri il territorio Bresciano",
                id: "FazPSb",
              })}
            </h1>
            <div>
              <CarouselArticles />
            </div>
            {/* <h1 className="mt-8 mb-12 text-3xl text-center md:mt-16 md:mb-20 md:text-4xl text-secondary">
              Segui le orme di altri esploratori
            </h1>
            <div className="grid grid-cols-2 gap-x-8">
              {shareArticles.map((item) => (
                <SingleShareArticle item={item} key={item.id} />
              ))}
            </div> */}
          </PageBody>
        </div>
      </main>
      <Footer />
    </div>
  );
}
