import { faCheckCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { FeeContext } from "../App";
import StructureIcon from "./StructureIcon";
import { useGeneralServices } from "./structureComponents/StructureServices";

export default function RoomModal({
  openModal,
  setOpenModal,
  structure,
  room,
  value,
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const intl = useIntl();
  const { generalServices } = useGeneralServices();
  const { fees } = useContext(FeeContext);

  const structureFee =
    structure.fee_id === "associations"
      ? fees.associations.fee
      : structure.fee_id
      ? fees.custom.find((f) => f._id === structure.fee_id).fee
      : fees.structures.fee;

  const currentPrice =
    room.prices && room.prices.length > 0
      ? room.prices.find(
          (p) =>
            new Date(p.from_date) < Date.now() &&
            new Date(p.to_date) > Date.now()
        )?.price || room.price
      : room.price;

  const currentPriceWithValue =
    room.prices && room.prices.length > 0 && value && value.length > 1
      ? (
          room.prices.find(
            (p) =>
              new Date(p.from_date) < new Date(value[0]) &&
              new Date(p.to_date) > new Date(value[1])
          ) || {}
        ).price || room.price
      : currentPrice;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ModalTransition>
      {openModal && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
          <Modal
            onClose={() => setOpenModal(false)}
            className="mt-0 md:mt-10 "
            width="x-large"
          >
            <ModalHeader className="-mt-2 md:mt-0 !px-4 md:!px-8 !items-start md:!items-center">
              <div className="flex items-start w-10/12 md:items-center gap-x-2 md:gap-x-4">
                <div className="mt-1 md:mt-0">
                  <StructureIcon
                    kind={structure.kind}
                    color="black"
                    dimension={6}
                  />
                </div>
                <h3 className="text-2xl">{room.name}</h3>
              </div>
              <button
                className="mt-1 md:mt-0"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenModal(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </ModalHeader>
            <ModalBody className="h-[calc(100vh-14rem)] !px-4 md:!px-8">
              <Swiper
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
                loop={true}
              >
                {room.images.map((image) => (
                  <SwiperSlide key={image}>
                    <div
                      className="relative max-w-full"
                      style={{ paddingBottom: "56.25%" }}
                    >
                      <img
                        src={image}
                        alt=""
                        className="absolute top-0 left-0 object-contain w-full max-h-full rounded-lg"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="flex flex-col mt-6 gap-y-6">
                <div className="h-auto">{room.description}</div>
                <div className="w-full border-b border-primary" />
                <div className="flex flex-col justify-between w-full pb-4 md:ml-4 gap-y-6">
                  <div className="flex flex-wrap gap-4 text-sm">
                    <div className="font-semibold">
                      {intl.formatMessage({
                        defaultMessage: "Servizi:",
                        id: "ULAuaJ",
                      })}
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {room.services.slice(0, 4).map((service) => (
                        <div
                          key={service}
                          className="flex items-center justify-center px-2 border rounded-md shadow-md text-primary border-primary"
                        >
                          {!isNaN(service)
                            ? generalServices.find((s) => s.id === service)
                                ?.name
                            : service}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="grid items-start grid-cols-2 gap-x-4">
                    <div className="flex text-sm gap-x-2">
                      <div className="font-semibold">
                        {intl.formatMessage({
                          defaultMessage: "Letti:",
                          id: "r2OGhC",
                        })}
                      </div>
                      <div className="flex flex-wrap gap-2 text-gray-500">
                        {room.double_bed > 0 && (
                          <div className="flex items-center px-2 border rounded-md gap-x-1">
                            {`${room.double_bed} `}{" "}
                            {intl.formatMessage({
                              defaultMessage: "matrimoniali",
                              id: "c7YzvK",
                            })}
                          </div>
                        )}
                        {room.single_bed > 0 && (
                          <div className="flex items-center px-2 border rounded-md gap-x-1">
                            {`${room.single_bed} `}{" "}
                            {intl.formatMessage({
                              defaultMessage: "singoli",
                              id: "/nLt3I",
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center text-sm gap-x-2">
                      <div className="font-semibold">
                        {intl.formatMessage({
                          defaultMessage: "Superficie:",
                          id: "RolgT3",
                        })}
                      </div>
                      <div className="px-2 text-gray-500 border rounded-md">
                        {room.size} m<sup>2</sup>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center w-full text-sm gap-x-2 md:hidden">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-primary"
                    />
                    <div className="text-gray-400">
                      {intl.formatMessage({
                        defaultMessage: "Cancellazione",
                        id: "6Mrr/R",
                      })}{" "}
                      {structure.cancellation_policy === "flessibile"
                        ? intl.formatMessage({
                            defaultMessage: "gratuita fino a 1 settimana prima",
                            id: "JJSwPI",
                          })
                        : structure.cancellation_policy === "moderata"
                        ? intl.formatMessage({
                            defaultMessage: "gratuita fino a 15 giorni prima",
                            id: "Ypc1P9",
                          })
                        : intl.formatMessage({
                            defaultMessage: "gratuita fino a 1 mese prima",
                            id: "rUDGoN",
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="px-0 mx-4 border-t border-primary md:mx-8">
              <div className="flex flex-col w-full md:flex-row md:items-center md:justify-between gap-y-4 md:gap-y-0">
                <div className="items-center hidden w-full -ml-4 text-sm gap-x-2 md:flex">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-primary"
                  />
                  <div className="text-gray-400">
                    {intl.formatMessage({
                      defaultMessage: "Cancellazione",
                      id: "6Mrr/R",
                    })}{" "}
                    {structure.cancellation_policy === "flessibile"
                      ? intl.formatMessage({
                          defaultMessage: "gratuita fino a 1 settimana prima",
                          id: "JJSwPI",
                        })
                      : structure.cancellation_policy === "moderata"
                      ? intl.formatMessage({
                          defaultMessage: "gratuita fino a 15 giorni prima",
                          id: "Ypc1P9",
                        })
                      : intl.formatMessage({
                          defaultMessage: "gratuita fino a 1 mese prima",
                          id: "rUDGoN",
                        })}
                  </div>
                </div>
                <div className="flex items-center justify-center w-full md:pt-0 md:pl-11">
                  <div className="flex items-center justify-between w-full gap-x-4">
                    {(!value || (value && value.length > 1)) && (
                      <div className="font-semibold">
                        {intl.formatMessage({
                          defaultMessage: "Prezzo a notte:",
                          id: "HKjb2E",
                        })}
                      </div>
                    )}
                    <div>
                      {value && value.length !== 2 ? (
                        <div className="text-sm font-normal text-gray-500">
                          <FontAwesomeIcon icon={faInfoCircle} /> &nbsp;
                          {intl.formatMessage({
                            defaultMessage:
                              "Seleziona un range di date per vedere il prezzo",
                            id: "iZ0mlU",
                          })}
                        </div>
                      ) : (
                        <>
                          {value && value.length > 1 ? (
                            <div className="text-xl font-bold">
                              {Math.ceil(
                                currentPriceWithValue / 100 +
                                  (structureFee * currentPriceWithValue) / 10000
                              ).toFixed(0)}{" "}
                              €
                            </div>
                          ) : (
                            <div className="text-xl font-bold">
                              {Math.ceil(
                                currentPrice / 100 +
                                  (structureFee * currentPrice) / 10000
                              ).toFixed(0)}{" "}
                              €
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </ModalTransition>
  );
}
