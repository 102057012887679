import dayjs from "dayjs";
import React from "react";
import { useIntl } from "react-intl";
import condizioni from "../assets/icons/CONDIZIONI.svg";

const BookingDetails = ({
  booking,
  refunds,
  paymentDetails,
  paymentIntent,
}) => {
  const intl = useIntl();

  const refund =
    refunds &&
    refunds?.data.find((refund) => refund.metadata.booking_id === booking._id);

  return booking.status === "refunded" && refunds ? (
    <div className="flex flex-col mb-6 gap-y-6">
      <div className="flex items-center gap-x-4">
        <img src={condizioni} alt="condizioni" className="w-6 h-6" />
        <div className="font-semibold">
          {intl.formatMessage({
            defaultMessage: "Dettagli rimborso",
            id: "oxKTXY",
          })}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="flex items-center gap-x-4">
          <div className="font-semibold">
            {intl.formatMessage({
              defaultMessage: "Quota rimborsata:",
              id: "gAUsW+",
            })}
          </div>
          <div className="text-primary">{refund.amount / 100} €</div>
        </div>
        <div className="flex items-center gap-x-4">
          <div className="font-semibold">
            {intl.formatMessage({
              defaultMessage: "Rimborsato su:",
              id: "OERgzI",
            })}
          </div>
          <div className="text-primary">
            {paymentDetails && paymentDetails.type}
          </div>
        </div>
        {paymentDetails && paymentDetails.type === "card" ? (
          <>
            <div className="flex items-center gap-x-4">
              <div className="font-semibold">
                {intl.formatMessage({
                  defaultMessage: "Numero carta:",
                  id: "Hp7Qsl",
                })}
              </div>
              <div className="text-primary">
                {paymentDetails.brand} **** **** **** {paymentDetails.last4}
              </div>
            </div>
            <div className="flex items-center gap-x-4">
              <div className="font-semibold">
                {intl.formatMessage({
                  defaultMessage: "Scadenza:",
                  id: "sqgT3H",
                })}
              </div>
              <div className="text-primary">
                {paymentDetails.exp_month < 10 ? "0" : ""}
                {paymentDetails.exp_month}/{paymentDetails.exp_year}
              </div>
            </div>
          </>
        ) : paymentDetails && paymentDetails.type === "paypal" ? (
          <div className="flex items-center gap-x-4">
            <div className="font-semibold">
              {intl.formatMessage({
                defaultMessage: "Email:",
                id: "xpTPb3",
              })}
            </div>
            <div className="text-primary">{paymentDetails.email}</div>
          </div>
        ) : null}
        <div className="flex items-center gap-x-4">
          <div className="font-semibold">
            {intl.formatMessage({
              defaultMessage: "Stato del rimborso:",
              id: "npuCPi",
            })}
          </div>
          <div className="text-primary">
            {refund && refund.status === "succeeded" ? (
              <div className="text-primary">
                {intl.formatMessage({
                  defaultMessage: "Rimborso completato",
                  id: "Jmlvqf",
                })}
              </div>
            ) : (
              <div className="text-yellow-500">
                {intl.formatMessage({
                  defaultMessage: "Rimborso in elaborazione",
                  id: "JVIVv0",
                })}
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center gap-x-4">
          <div className="font-semibold">
            {intl.formatMessage({
              defaultMessage: "Data rimborso:",
              id: "c3EcMM",
            })}
          </div>
          <div className="text-primary">
            {refund && dayjs(refund.created * 1000).format("DD/MM/YYYY")}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col mb-6 gap-y-6">
      <div className="flex items-center gap-x-4">
        <img src={condizioni} alt="condizioni" className="w-6 h-6" />
        <div className="font-semibold">
          {intl.formatMessage({
            defaultMessage: "Dettagli di pagamento",
            id: "wp7b9N",
          })}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="flex items-center gap-x-4">
          <div className="font-semibold">
            {intl.formatMessage({
              defaultMessage: "Totale:",
              id: "ilfY6d",
            })}
          </div>
          <div className="text-primary">{booking.price} €</div>
        </div>
        <div className="flex items-center gap-x-4">
          <div className="font-semibold">
            {intl.formatMessage({
              defaultMessage: "Metodo di pagamento:",
              id: "2zLW0l",
            })}
          </div>
          <div className="text-primary">
            {paymentDetails && paymentDetails.type}
          </div>
        </div>
        {paymentDetails && paymentDetails.type === "card" ? (
          <>
            <div className="flex items-center gap-x-4">
              <div className="font-semibold">
                {intl.formatMessage({
                  defaultMessage: "Numero carta:",
                  id: "Hp7Qsl",
                })}
              </div>
              <div className="text-primary">
                {paymentDetails.brand} **** **** **** {paymentDetails.last4}
              </div>
            </div>
            <div className="flex items-center gap-x-4">
              <div className="font-semibold">
                {intl.formatMessage({
                  defaultMessage: "Scadenza:",
                  id: "sqgT3H",
                })}
              </div>
              <div className="text-primary">
                {paymentDetails.exp_month < 10 ? "0" : ""}
                {paymentDetails.exp_month}/{paymentDetails.exp_year}
              </div>
            </div>
          </>
        ) : paymentDetails && paymentDetails.type === "paypal" ? (
          <div className="flex items-center gap-x-4">
            <div className="font-semibold">
              {intl.formatMessage({
                defaultMessage: "Email:",
                id: "xpTPb3",
              })}
            </div>
            <div className="text-primary">{paymentDetails.email}</div>
          </div>
        ) : null}
        <div className="flex items-center gap-x-4">
          <div className="font-semibold">
            {intl.formatMessage({
              defaultMessage: "Stato del pagamento:",
              id: "t/rqMl",
            })}
          </div>
          <div className="text-primary">
            {paymentIntent && paymentIntent.status === "succeeded" ? (
              <div className="text-primary">
                {intl.formatMessage({
                  defaultMessage: "Pagamento completato",
                  id: "+2Qyj/",
                })}
              </div>
            ) : (
              <div className="text-yellow-500">
                {intl.formatMessage({
                  defaultMessage: "In attesa di pagamento",
                  id: "eq7Zep",
                })}
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center gap-x-4">
          <div className="font-semibold">
            {intl.formatMessage({
              defaultMessage: "Data di pagamento:",
              id: "+ih3ev",
            })}
          </div>
          <div className="text-primary">
            {paymentIntent &&
              dayjs(paymentIntent.created * 1000).format("DD/MM/YYYY")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
