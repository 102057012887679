import {
  faBars,
  faChevronDown,
  faChevronLeft,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useHover from "@react-hook/hover";
import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import {
  ElementsCartContext,
  FavouriteContext,
  LanguageContext,
  UserContext,
  UserLoggedContext,
} from "../App";
import logo from "../assets/LOGO.svg";
import carrello_white from "../assets/icons/CARRELLOWHITE.svg";
import logout from "../assets/icons/LOGOUT.svg";
import { NavbarSwitch } from "./NavbarCreator";
import PopUp from "./PopUp";

export default function MobileNavbar() {
  const currentPath = window.location.pathname.split("/").pop();
  const { userLogged, setUserLogged } = useContext(UserLoggedContext);
  const target = useRef(null);
  const intl = useIntl();
  const isHovering = useHover(target, { enterDelay: 0, leaveDelay: 0 });
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const { language, setLanguage } = useContext(LanguageContext);
  const [isOpenLanguage, setIsOpenLanguage] = useState(false);

  useEffect(() => {
    const body = document.querySelector("body");
    if (isOverlayVisible) {
      // Disabilita lo scorrimento per il body quando il componente superiore è visibile
      body.style.overflow = "hidden";
    } else {
      // Riabilita lo scorrimento quando il componente superiore viene nascosto
      body.style.overflow = "auto";
    }

    // Pulizia: riabilita lo scorrimento quando il componente viene smontato
    return () => {
      body.style.overflow = "auto";
    };
  }, [isOverlayVisible]);

  const { elements, setElements } = useContext(ElementsCartContext);
  const { setFavourite } = useContext(FavouriteContext);
  const { user, setUser } = useContext(UserContext);
  const user_id = localStorage.getItem("user");

  const logOutUser = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate(`/`, { replace: true });
    setUserLogged(false);
    setFavourite(null);
    setElements([]);
    setUser({});
  };

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 z-50 flex items-center justify-between w-full text-white bg-secondary">
        <div className="relative w-2/5 h-full py-6 pl-4 pr-8 rounded-r-full bg-primary max-h-[120px]">
          <Link to="/" className="relative flex items-center h-full gap-x-2">
            <img src={logo} alt="logo" className="h-full max-h-[70px]" />
          </Link>
        </div>
        <div className="flex items-center justify-end w-3/5 pr-4 gap-x-2">
          <Link
            className={`relative py-2 px-[11px] rounded-full border border-white ${
              currentPath === "cart" ? "bg-primary" : "hover:border-primary"
            }`}
            to="/cart"
          >
            <img
              src={carrello_white}
              alt="carrello"
              className="relative -left-[1px] w-6 h-6"
            />
            {elements.length === 0 ? null : (
              <span className="absolute px-2 text-xs text-white rounded-full left-8 -top-2 bg-primary">
                {elements.length}
              </span>
            )}
          </Link>
          {userLogged ? (
            <>
              {user.has_structures || user.has_experiences ? (
                <PopUp
                  setIsOpen={setIsOpen}
                  iconStyle="noBackground"
                  goUnderneath={true}
                  orientation="vertical"
                  withoutEvent={true}
                  isOpen={isOpen}
                  icon={
                    <div
                      className="flex items-center border rounded-full"
                      ref={target}
                      style={{
                        borderColor: isHovering || isOpen ? "#246155" : "white",
                      }}
                    >
                      <div
                        className="flex items-center rounded-full"
                        onClick={() => navigate(`/profile/${user_id}`)}
                        style={{
                          backgroundColor:
                            isHovering || isOpen ? "#246155" : "white",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faUser}
                          className={`py-2.5 px-4`}
                          style={{
                            color: isHovering || isOpen ? "white" : "#246155",
                          }}
                        />
                      </div>
                      <div
                        onClick={() => setIsOpen((prev) => !prev)}
                        className="flex items-center h-full"
                      >
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={`py-2 px-2 text-white text-sm`}
                        />
                      </div>
                    </div>
                  }
                  items={
                    <>
                      <NavbarSwitch />
                      {/* <Link
                        className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-t-[30px] cursor-pointer text-black hover:bg-gray-50"
                        to={`/profile/${user_id}`}
                      >
                        <img
                          src={backToUser}
                          alt="torna al profilo"
                          className="w-6 h-6 pr-2"
                        />
                        <div>Profilo</div>
                      </Link>
                      {user.has_structures && user.has_experiences && (
                        <>
                          <div className="mx-4 border-b" />
                          <Link
                            className="flex items-center w-full h-8 px-6 py-5 text-sm text-black cursor-pointer hover:bg-gray-50"
                            to={`/partnerZone/${user_id}`}
                          >
                            <img
                              src={backToUser}
                              alt="torna al profilo"
                              className="w-6 h-6 pr-2"
                            />
                            <div>Partner zone</div>
                          </Link>
                        </>
                      )}
                      {user.has_structures && !user.has_experiences && (
                        <>
                          <div className="mx-4 border-b" />
                          <Link
                            className="flex items-center w-full h-8 px-6 py-5 text-sm text-black cursor-pointer hover:bg-gray-50"
                            to={`/partner/${user_id}`}
                          >
                            <img
                              src={backToUser}
                              alt="torna al profilo"
                              className="w-6 h-6 pr-2"
                            />
                            <div>Partner zone</div>
                          </Link>
                        </>
                      )}
                      {user.has_experiences && !user.has_structures && (
                        <>
                          <div className="mx-4 border-b" />
                          <Link
                            className="flex items-center w-full h-8 px-6 rounded-b-[30px] py-5 text-sm text-black cursor-pointer hover:bg-gray-50"
                            to={`/creatorZone/${user_id}`}
                          >
                            <img
                              src={backToUser}
                              alt="torna al profilo"
                              className="w-6 h-6 pr-2"
                            />
                            <div>Partner zone</div>
                          </Link>
                        </>
                      )} */}
                      <div className="mx-4 border-b" />
                      <button
                        className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-b-[30px] cursor-pointer text-black hover:bg-gray-50"
                        type="button"
                        onClick={logOutUser}
                      >
                        <img
                          src={logout}
                          alt="torna al profilo"
                          className="w-6 h-6 pr-2 mx-3"
                        />
                        <div>
                          {intl.formatMessage({
                            defaultMessage: "Logout",
                            id: "C81/uG",
                          })}
                        </div>
                      </button>
                    </>
                  }
                />
              ) : (
                <Link
                  className={`py-2 px-4 rounded-full border border-white ${
                    currentPath === "login"
                      ? "bg-primary"
                      : "hover:border-primary"
                  }`}
                  to={`/profile/${localStorage.getItem("user")}`}
                >
                  <FontAwesomeIcon icon={faUser} />
                </Link>
              )}
            </>
          ) : (
            <Link
              className={`py-2 px-4 rounded-full border border-white ${
                currentPath === "login" ? "bg-primary" : "hover:border-primary"
              }`}
              to="/login"
            >
              <FontAwesomeIcon icon={faUser} />
            </Link>
          )}
          <button
            onClick={() => {
              setOpenMenu(true);
              setIsOverlayVisible(true);
            }}
            className="flex items-center px-4 py-3 border rounded-full"
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
      </nav>
      <AnimatePresence mode="wait">
        {openMenu && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-screen h-screen overflow-y-auto bg-black bg-opacity-50">
            <motion.div
              className="w-full h-full"
              initial={{ y: -200, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -200, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <nav className="sticky top-0 left-0 right-0 z-10 flex items-center justify-between w-full text-white bg-primary">
                <div className="relative w-2/5 h-full py-6 pl-4 pr-8">
                  <img src={logo} alt="logo" className="w-full" />
                </div>
                <div className="flex items-center justify-end w-3/5 pr-4 gap-x-2">
                  <button
                    onClick={() => {
                      setOpenMenu(false);
                      setIsOverlayVisible(false);
                      setIsOpenLanguage(false);
                    }}
                    className="flex items-center px-4 py-3 border rounded-full"
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </nav>
              {isOpenLanguage ? (
                <div className="w-full relative bg-white p-8 rounded-b-[37px] shadow-lg text-primary text-xl font-bold flex flex-col gap-y-10">
                  <button
                    onClick={() => {
                      setIsOpenLanguage((prev) => !prev);
                    }}
                    className="absolute px-2 py-1 text-sm top-3 left-4"
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  <button
                    onClick={() => {
                      setLanguage("it");
                      setIsOpenLanguage((prev) => !prev);
                    }}
                    className="flex items-center justify-center h-full"
                  >
                    Italiano
                  </button>
                  <button
                    onClick={() => {
                      setLanguage("en");
                      setIsOpenLanguage((prev) => !prev);
                    }}
                    className="flex items-center justify-center h-full"
                  >
                    English
                  </button>
                </div>
              ) : (
                <div className="w-full bg-white p-8 rounded-b-[37px] shadow-lg text-primary text-xl font-bold flex flex-col gap-y-10">
                  <Link
                    className="flex items-center justify-center h-full"
                    to="/experiences"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Esperienze",
                      id: "ipp3co",
                    })}
                  </Link>
                  <Link
                    className="flex items-center justify-center h-full"
                    to="/structures"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Alloggi",
                      id: "TAoh5a",
                    })}
                  </Link>
                  <Link
                    className="flex items-center justify-center h-full"
                    to="/escoboxes"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Esco box",
                      id: "1kkz+W",
                    })}
                  </Link>
                  {/* <Link
                    className="flex items-center justify-center h-full"
                    to="/community"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Community",
                      id: "4CrCbD",
                    })}
                  </Link> */}
                  <div className="border border-primary" />
                  <Link
                    className="flex items-center justify-center h-full"
                    to="/becomePartner"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Diventa partner",
                      id: "tVcuIm",
                    })}
                  </Link>
                  <button
                    onClick={() => {
                      setIsOpenLanguage((prev) => !prev);
                    }}
                    className="flex items-center justify-center h-full"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Lingua",
                      id: "6k9RO+",
                    })}
                  </button>
                </div>
              )}
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
}
