import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import parse from "html-react-parser";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FavouriteContext } from "../App";
import art from "../assets/art.svg";
import authentic from "../assets/auth.svg";
import heart from "../assets/heart.svg";
import heartFull from "../assets/heartFull.svg";
import geotag from "../assets/icons/GEOTAG.svg";
import stella from "../assets/icons/STELLA.svg";
import outdoor from "../assets/out.svg";
import food from "../assets/wine.svg";
import { options } from "../pages/ExperiencePreview";

export default function ExtendedSingleExperience({ item }) {
  const navigate = useNavigate();
  const intl = useIntl();
  const { favourite, setFavourite } = useContext(FavouriteContext);
  const [message, setMessage] = useState("");
  const notify = (msg) =>
    toast.success(msg, {
      toastId: Math.random().toString(),
      position: "bottom-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: (
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="text-2xl text-primary"
        />
      ),
      containerId: `ExtendedSingleExperience${item._id}`,
    });

  const avarageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  const addToFavourites = async () => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    if (!user) {
      navigate("/login");
      return;
    } else {
      if (
        favourite &&
        favourite.length > 0 &&
        favourite[0].experiences.find(
          (experience) => experience.experience_id === item._id
        )
      ) {
        const newExperiences = favourite[0].experiences.filter(
          (experience) => experience.experience_id !== item._id
        );
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              experiences: newExperiences,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            setMessage(
              intl.formatMessage({
                defaultMessage:
                  "Esperienza rimossa dai preferiti con successo.",
                id: "u6pkrj",
              })
            );
          });
      } else {
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              experiences: [
                ...(favourite && favourite.length > 0
                  ? favourite[0].experiences
                  : []),
                { experience_id: item._id },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            setMessage(
              intl.formatMessage({
                defaultMessage:
                  "Esperienza aggiunta ai preferiti con successo.",
                id: "dmjaR8",
              })
            );
          });
      }
    }
  };

  useEffect(() => {
    if (message !== "") {
      notify(message);
      setMessage("");
    }
  }, [message]);

  return (
    <div
      key={item._id}
      className="relative flex flex-col mb-2 border rounded-[30px] shadow-lg cursor-pointer hover:shadow-xl"
    >
      <div
        style={{
          backgroundImage: `url(${
            encodeURI(item.images[0]) ||
            "https://www.thrillophilia.com/blog/wp-content/uploads/2017/05/shutterstock_327692783.jpg"
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        alt={item.name}
        className="relative w-full h-56 rounded-[30px] shadow-[inset_0px_-60px_40px_0px_rgba(0,0,0,0.6)]"
      />
      <div className="absolute flex flex-row items-center justify-between w-full p-2 top-2">
        <div className="flex items-center">
          <div className="relative z-10 flex items-center justify-center rounded-full bg-slate-600 w-9 h-9">
            {item.category === "wine" ? (
              <img src={food} alt="wine" className="w-6 h-6" />
            ) : item.category === "art" ? (
              <img src={art} alt="art" className="w-6 h-6" />
            ) : item.category === "outdoor" ? (
              <img src={outdoor} alt="outdoor" className="w-6 h-6" />
            ) : item.category === "authentic" ? (
              <img src={authentic} alt="authentic" className="w-6 h-6" />
            ) : null}
          </div>
        </div>
        <button
          className="flex items-center justify-center bg-white rounded-full w-9 h-9"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            addToFavourites();
          }}
        >
          {/* <img src={heart} alt="heart" className="w-5" /> */}
          {favourite &&
          favourite.length > 0 &&
          favourite[0].experiences.find(
            (experience) => experience.experience_id === item._id
          ) ? (
            <img src={heartFull} alt="heartFull" className="w-5" />
          ) : (
            <img src={heart} alt="heart" className="w-5" />
          )}
        </button>
      </div>
      <div className="relative ">
        <div className="absolute w-full px-2 font-bold text-center text-white -mt-14">
          {item.name}
        </div>
      </div>
      <div className="flex flex-col flex-1 p-2">
        <div className="flex flex-row justify-around text-sm">
          <div className="flex items-center gap-x-2">
            <img src={geotag} alt="geotag" className="w-4" />
            <div className="text-center">
              {item.address
                ? item.address.municipality
                  ? item.address.municipality
                  : item.address.village
                : "Pertica Bassa"}
            </div>
          </div>
          <div className="flex items-center gap-x-2">
            <img src={stella} alt="stella" className="w-4" />
            <div>{item.reviews ? avarageReviews(item.reviews) : 0}/5</div>
          </div>
        </div>
      </div>
      <div className="flex flex-col pb-4">
        <div className="p-3 pt-0 overflow-hidden text-sm rounded-b-lg ">
          <div className="line-clamp-2">{parse(item.description, options)}</div>
        </div>
        <div className="relative flex justify-center">
          <button className="absolute flex items-center justify-center px-6 py-1 text-white bg-gray-400 rounded-full w-fit">
            <div className="font-semibold">
              {intl.formatMessage({
                defaultMessage: "Scopri di più",
                id: "onwiq0",
              })}
            </div>
          </button>
        </div>
      </div>
      <ToastContainer
        containerId={`ExtendedSingleExperience${item._id}`}
        position="bottom-left"
        autoClose={2000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
        transition={Bounce}
        stacked
      />
    </div>
  );
}
