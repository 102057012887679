import {
  faChevronLeft,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { Bounce, toast, ToastContainer } from "react-toastify";
import {
  AreaContext,
  ElementsCartContext,
  FavouriteContext,
  UserContext,
  UserLoggedContext,
} from "../App";
import escobot from "../assets/icons/ESCOBOT.svg";
import escobotwhite from "../assets/icons/ESCOBOTWHITE.svg";
import heart from "../assets/icons/HEART.svg";
import heartwhite from "../assets/icons/HEARTWHITE.svg";
import logout from "../assets/icons/LOGOUT.svg";
import logoutwhite from "../assets/icons/LOGOUTWHITE.svg";
import manage from "../assets/icons/MANAGE.svg";
import managewhite from "../assets/icons/MANAGEWHITE.svg";
import messaggi from "../assets/icons/MESSAGGI.svg";
import messaggiwhite from "../assets/icons/MESSAGGIWHITE.svg";
import prenotazioni from "../assets/icons/PRENOTAZIONI.svg";
import prenotazioniwhite from "../assets/icons/PRENOTAZIONIWHITE.svg";
import propose from "../assets/icons/PROPOSE.svg";
import proposewhite from "../assets/icons/PROPOSEWHITE.svg";
import voucher from "../assets/icons/VOUCHER.svg";
import voucherwhite from "../assets/icons/VOUCHERWHITE.svg";
import BaseComponent from "../components/BaseComponent";
import LoadingSpinnerPage from "../components/LoadingSpinnerPage";
import MobileNavbar from "../components/MobileNavbar";
import NavbarPersonalArea from "../components/NavbarPersonalArea";
import UserProfileCard from "../components/UserProfileCard";
import Account from "../components/profilePages/Account";
import Bookings from "../components/profilePages/Bookings";
import Favourites from "../components/profilePages/Favourites";
import Faq from "./Faq";

function SingleLink({ item, baseUrl, windowWidth, setSelectedTab }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setFavourite } = useContext(FavouriteContext);
  const { setElements } = useContext(ElementsCartContext);
  const { setUser } = useContext(UserContext);
  const { setUserLogged } = useContext(UserLoggedContext);
  const { setArea } = useContext(AreaContext);
  const isSelected =
    windowWidth < 768
      ? false
      : item.label === "Prenotazioni"
      ? window.location.pathname.split("/").pop() === id
      : `/${window.location.pathname.split("/").pop()}` === item.link;

  const logOutUser = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate(`/`, { replace: true });
    setFavourite(null);
    setElements([]);
    setUser({});
    setUserLogged(false);
  };

  if (item.label === "Logout") {
    return (
      <button
        onClick={logOutUser}
        className="flex items-center w-full h-10 px-4 py-2 gap-x-4 hover:bg-gray-200 rounded-[30px] border border-gray-100"
        style={{
          borderColor: isSelected ? "#246155" : "",
        }}
      >
        <img src={item.iconDark} alt={item.label} className="w-5 h-5 mr-4" />
        <p className="font-semibold">{item.label}</p>
      </button>
    );
  }

  return (
    <button
      onClick={() => {
        setSelectedTab(item.link);
        if (
          item.link === "/creatorZone" ||
          item.link === "/partner" ||
          item.link === "/partnerZone" ||
          item.link === "/adminArea"
        ) {
          setArea("partner");
        }
        const url =
          item.link === "/propose"
            ? "/proposeExperience"
            : item.link === "/creatorZone"
            ? `/creatorZone/${id}`
            : item.link === "/partner"
            ? `/partner/${id}`
            : item.link === "/adminArea"
            ? `/adminArea/${id}`
            : item.link === "/partnerZone"
            ? `/partnerZone/${id}`
            : `${baseUrl}${item.link}`;
        navigate(url, {
          target:
            item.link === "/partner" ||
            item.link === "/creatorZone" ||
            item.link === "/adminArea" ||
            item.link === "/partnerZone"
              ? "blank"
              : "",
        });
      }}
      className="flex items-center w-full h-10 px-4 py-2 gap-x-4 hover:bg-gray-200 rounded-[30px] border border-gray-100"
      style={{
        borderColor: isSelected ? "#246155" : "",
      }}
    >
      <img src={item.iconDark} alt={item.label} className="w-5 h-5 mr-4" />
      <p className="font-semibold">{item.label}</p>
    </button>
  );
}

export default function Profile() {
  const { id, tab } = useParams();
  const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [selectedTab, setSelectedTab] = useState(null);
  const [isResending, setIsResending] = useState(false);
  const intl = useIntl();

  const navigate = useNavigate();
  const [userLogged, setUserLogged] = useState(null);
  const [user, setUser] = useState({});

  const notify = (msg) =>
    toast.success(msg, {
      toastId: Math.random().toString(),
      position: "bottom-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: (
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="text-2xl text-primary"
        />
      ),
      containerId: `EmailVerificationContainer`,
    });

  async function Start() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/logged`)
        .then((res) => {
          setUserLogged(true);
        })
        .catch(() => {
          setUserLogged(false);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        });
    } else {
      setUserLogged(false);
      setLoading(false);
    }
  }

  async function GetUser() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/user/${id}`)
        .then((res) => {
          if (res.data.message === "Unauthorized") {
            navigate(`/`, { replace: true });
          } else {
            setUser(res.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  useEffect(() => {
    if (userLogged) {
      GetUser();
    }
  }, [userLogged]);

  useEffect(() => {
    if (Cookies.get("token") && Cookies.get("user")) {
      localStorage.setItem("token", Cookies.get("token"));
      localStorage.setItem("user", Cookies.get("user"));
      // Cookies.remove("token");
      // Cookies.remove("user");
    }
    Start();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  if (!userLogged) {
    navigate(`/login`, { replace: true });
  }

  const schema = [
    {
      label: intl.formatMessage({
        defaultMessage: "Prenotazioni",
        id: "GnJKAV",
      }),
      iconDark: prenotazioni,
      iconWhite: prenotazioniwhite,
      link: "",
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Preferiti",
        id: "deAI9r",
      }),
      iconDark: heart,
      iconWhite: heartwhite,
      link: "/favorites",
      component: <Favourites />,
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Messaggi",
        id: "WJ2XTN",
      }),
      iconDark: messaggi,
      iconWhite: messaggiwhite,
      link: "/messages",
      component: (
        <BaseComponent
          title={intl.formatMessage({
            defaultMessage: "Messaggi",
            id: "WJ2XTN",
          })}
        />
      ),
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Gestione account",
        id: "DIsHJN",
      }),
      iconDark: manage,
      iconWhite: managewhite,
      link: "/account",
      component: <Account />,
    },
    // {
    //   label: "My esco share",
    //   iconDark: share,
    //   iconWhite: sharewhite,
    //   link: "/share",
    // },
    ...(user.has_structures && !user.has_experiences
      ? [
          {
            label: intl.formatMessage({
              defaultMessage: "Partner zone",
              id: "GtEgso",
            }),
            iconDark: manage,
            iconWhite: managewhite,
            link: "/partner",
          },
        ]
      : []),
    {
      label: user.has_experiences
        ? intl.formatMessage({
            defaultMessage: "Partner zone",
            id: "GtEgso",
          })
        : intl.formatMessage({
            defaultMessage: "Proponi un esperienza",
            id: "SMUSmS",
          }),
      iconDark: propose,
      iconWhite: proposewhite,
      link:
        user.has_experiences && !user.has_structures
          ? "/creatorZone"
          : user.has_experiences && user.has_structures
          ? "/partnerZone"
          : "/propose",
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Voucher & Gift Card",
        id: "QBeuTK",
      }),
      iconDark: voucher,
      iconWhite: voucherwhite,
      link: "/voucher",
      component: (
        <BaseComponent
          title={intl.formatMessage({
            defaultMessage: "Voucher & Gift Card",
            id: "QBeuTK",
          })}
        />
      ),
    },
    ...(user.is_admin
      ? [
          {
            label: intl.formatMessage({
              defaultMessage: "Admin area",
              id: "zj30+k",
            }),
            iconDark: manage,
            iconWhite: managewhite,
            link: "/adminArea",
          },
        ]
      : []),
    {
      label: intl.formatMessage({
        defaultMessage: "Assistenza",
        id: "Qz3BtF",
      }),
      iconDark: escobot,
      iconWhite: escobotwhite,
      link: "/help",
      component: <Faq withoutHeader />,
    },
    {
      label: "Logout",
      iconDark: logout,
      iconWhite: logoutwhite,
      link: "/logout",
    },
  ];

  if (!loading && userLogged) {
    document.title = `Esco | Account`;

    return (
      <div>
        <header>
          <div className="hidden md:block">
            <NavbarPersonalArea />
          </div>
          <div className="block md:hidden h-[81px]">
            <MobileNavbar />
          </div>
        </header>
        <main>
          <div className="mx-0 2xl:mx-8">
            <div className="flex flex-col mx-8 mt-12 md:flex-row md:mt-32 max-w-screen-2xl 2xl:mx-auto">
              <div className="flex flex-col w-full md:w-1/4 gap-y-4 ">
                <UserProfileCard user={user} setUser={setUser} />
                <div className="flex flex-col items-center justify-center w-full bg-gray-100 rounded-[20px] gap-y-0.5 ">
                  {schema.map((item, index) => (
                    <SingleLink
                      key={index}
                      item={item}
                      baseUrl={`/profile/${id}`}
                      windowWidth={windowWidth}
                      setSelectedTab={setSelectedTab}
                    />
                  ))}
                </div>
              </div>
              <div className="flex-col hidden md:flex md:w-3/4 ">
                <div className="mb-24">
                  {tab &&
                  schema.find((item) => item.link === `/${tab}`)?.component ? (
                    schema.find((item) => item.link === `/${tab}`).component
                  ) : (
                    <Bookings />
                  )}
                </div>
              </div>

              <div className="flex flex-col w-full md:hidden">
                <AnimatePresence mode="wait">
                  {selectedTab !== null && (
                    <div className="fixed left-0 z-50 w-full top-[84px] h-[calc(100%-84px)]">
                      <motion.div
                        initial={{ x: 300 }}
                        animate={{ x: 0 }}
                        exit={{ x: 300 }}
                        transition={{ duration: 0.2 }}
                        className="absolute top-0 left-0 w-screen h-full px-4 bg-white"
                      >
                        {/* we need to create an arrow at the top and than show the component as above with the same logic as tab but using the selectedTab */}
                        <div className="relative flex w-full h-10 my-6 bg-gray-100 rounded-full">
                          <button
                            onClick={() => setSelectedTab(null)}
                            className="absolute border border-primary rounded-[50%] w-10 h-10 flex items-center justify-center"
                          >
                            <FontAwesomeIcon
                              icon={faChevronLeft}
                              className="w-6 h-6"
                            />
                          </button>
                          <h1 className="w-full pt-2 text-xl font-semibold text-center">
                            {
                              schema.find((item) => item.link === selectedTab)
                                .label
                            }
                          </h1>
                        </div>
                        <div className="bg-gray-100 h-[calc(90%-4rem)] rounded-[20px] overflow-y-auto py-4 gap-y-4">
                          {schema.find((item) => item.link === selectedTab)
                            .component ? (
                            schema.find((item) => item.link === selectedTab)
                              .component
                          ) : (
                            <Bookings />
                          )}
                        </div>
                      </motion.div>
                    </div>
                  )}
                </AnimatePresence>
              </div>
            </div>
            {!user.email_verified && (
              <div className="fixed bottom-0 z-50 w-full">
                <div className="h-full pt-4 mx-4 md:mx-8">
                  <div className="relative flex max-w-screen-2xl 2xl:mx-auto">
                    <div className="hidden w-1/4 mx-2 md:flex" />
                    <div className="relative w-full md:w-3/4">
                      <div className="flex flex-col items-center justify-center w-full p-2 mb-4 bg-yellow-100 rounded-md gap-y-2">
                        <p className="text-sm text-yellow-800">
                          {intl.formatMessage({
                            defaultMessage:
                              "Verifica l'email per accedere a tutte le funzionalità",
                            id: "eNv/wJ",
                          })}
                        </p>
                        {isResending && (
                          <div className="flex items-center justify-center w-full">
                            <div className="w-6 h-6">
                              <UiduSpinner size="small" />
                            </div>
                          </div>
                        )}
                        {(!user.email_verification_expires ||
                          dayjs(user.email_verification_expires).isBefore(
                            dayjs()
                          )) &&
                          !isResending && (
                            <button
                              onClick={() => {
                                setIsResending(true);
                                axios
                                  .post(
                                    `${process.env.REACT_APP_SERVER_URL}/api/resendEmailVerification/${id}`
                                  )
                                  .then((res) => {
                                    console.log(res);
                                    if (res.data.user) {
                                      console.log("user", res.data.user);
                                      setUser(res.data.user);
                                      setIsResending(false);
                                      notify("Email inviata con successo");
                                    } else {
                                      console.log(res.data);
                                    }
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              }}
                              className="text-sm text-yellow-800 underline"
                            >
                              {intl.formatMessage({
                                defaultMessage: "Reinvia email di verifica",
                                id: "ny67ZB",
                              })}
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="fixed z-50">
            <ToastContainer
              containerId={`EmailVerificationContainer`}
              position="bottom-left"
              autoClose={2000}
              hideProgressBar
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover={false}
              theme="light"
              transition={Bounce}
              stacked
            />
          </div>
        </main>
      </div>
    );
  }
}
