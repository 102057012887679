import { Editor } from "@tinymce/tinymce-react";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ProposeContext } from "../../pages/Propose";
import BaseComponent from "../BaseComponent";
import TagsInputWithButton from "../utils/TagsInputWithButton";

export default function Details() {
  const animatedComponents = makeAnimated();
  const { id } = useParams();
  const editorRef = useRef(null);

  const { experience, setExperience, setErrors, errors } =
    useContext(ProposeContext);
  const [editorContent, setEditorContent] = useState(
    experience?.description
      ? experience.description
      : "<p>Descrivi la tua esperienza</p>"
  );
  const handleEditorChange = (content) => {
    setEditorContent(content);
  };
  const [selectedLenguages, setSelectedLenguages] = useState(
    experience?.languages
  );
  const [selectedAccessibility, setSelectedAccessibility] = useState(
    experience?.accessibility
  );
  const [extraAccessibility, setExtraAccessibility] = useState(
    experience?.extra_accessibility
  );
  const [isWritingExtraAccessibility, setIsWritingExtraAccessibility] =
    useState(false);

  const updateExperience = async () => {
    const token = localStorage.getItem("token");
    const currentErrors = [
      { id: "duration", error: null },
      { id: "languages", error: null },
      { id: "description", error: null },
      { id: "name", error: null },
      { id: "extraAccessibility", error: null },
    ];
    let data = null;

    if (document.getElementById("name").value.length < 5) {
      currentErrors[3].error = "Il nome deve essere lungo almeno 5 caratteri";
    } else {
      data = {
        name: document.getElementById("name").value,
      };
    }

    const editorTextContent =
      editorRef?.current?.getContent({
        format: "text",
      }) || "";

    if (
      (!editorTextContent || editorTextContent.length < 100) &&
      editorRef.current
    ) {
      currentErrors[2].error =
        "La descrizione deve essere lunga almeno 100 caratteri";
    } else {
      data = {
        ...data,
        description: editorContent,
      };
    }

    if (
      document.getElementById("duration").value <= 0 ||
      !document.getElementById("duration").value ||
      document.getElementById("duration").value === null ||
      isNaN(
        +document
          .getElementById("duration")
          .value.split(" h")[0]
          .replace(":", ".")
      )
    ) {
      currentErrors[0].error = "Devi inserire una durata";
    } else {
      let durationValue = document
        .getElementById("duration")
        .value.split(" h")[0];
      let minutes = durationValue.split(":")[1];
      if (minutes && minutes > 59) {
        currentErrors[0].error = "Formato durata non valido";
      } else {
        data = {
          ...data,
          duration: +document
            .getElementById("duration")
            .value.split(" h")[0]
            .replace(":", "."),
        };
      }
    }

    if (
      !selectedLenguages ||
      selectedLenguages === null ||
      selectedLenguages.length === 0
    ) {
      currentErrors[1].error = "Devi selezionare almeno una lingua";
    } else {
      data = {
        ...data,
        languages: selectedLenguages,
      };
    }

    if (
      selectedAccessibility.includes("altro") &&
      (!extraAccessibility || extraAccessibility.length === 0)
    ) {
      currentErrors[4].error =
        "Devi specificare le accessibilità indicate come 'Altro'";
    } else {
      data = {
        ...data,
        extra_accessibility: extraAccessibility,
      };
    }

    setErrors(currentErrors);

    if (!data) return;

    if (selectedAccessibility && selectedAccessibility.length > 0) {
      data = {
        ...data,
        accessibility: selectedAccessibility,
      };
    }

    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/experience/edit/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setExperience(res.data.experience);
      })
      .catch(() => {
        alert("Errore durante la modifica dell'esperienza");
      });
  };

  useEffect(() => {
    updateExperience();
  }, []);

  useEffect(() => {
    if (
      selectedLenguages &&
      JSON.stringify(selectedLenguages) !== JSON.stringify(experience.languages)
    ) {
      updateExperience();
    }
    if (
      selectedAccessibility &&
      JSON.stringify(selectedAccessibility) !==
        JSON.stringify(experience.accessibility)
    ) {
      updateExperience();
    }
    if (
      extraAccessibility &&
      JSON.stringify(extraAccessibility) !==
        JSON.stringify(experience.extra_accessibility)
    ) {
      updateExperience();
    }
    if (editorContent && editorContent !== experience.description) {
      updateExperience();
    }
  }, [
    selectedLenguages,
    selectedAccessibility,
    extraAccessibility,
    editorContent,
    editorRef,
  ]);

  return (
    <BaseComponent title="Andiamo nel dettaglio">
      <div className="flex  min-h-[400px] flex-col px-4 md:px-10 py-4 my-4 overflow-y-auto bg-gray-100 rounded-md gap-y-6">
        {experience ? (
          <>
            <div className="grid items-center w-full grid-cols-1 gap-4 md:grid-cols-4">
              <p className="font-semibold">Nome esperienza</p>
              <div className="relative flex flex-col w-full col-span-3 gap-y-4">
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="name"
                  defaultValue={experience.name}
                  placeholder={`nome esperienza`}
                  onChange={() => updateExperience()}
                ></input>
                {/* {
                  <p className="-mt-2 text-sm text-red-400">
                    {errors.find((e) => e.id === "name")?.error}
                  </p>
                } */}
              </div>
            </div>
            <p className="font-semibold">Descrizione completa</p>
            <div className="flex flex-col gap-y-1">
              <Editor
                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                onInit={(evt, editor) => (editorRef.current = editor)}
                init={{
                  plugins: [
                    // Core editing features
                    "anchor",
                    "autolink",
                    "charmap",
                    "codesample",
                    "emoticons",
                    "image",
                    "link",
                    "lists",
                    "media",
                    "searchreplace",
                    "table",
                    "visualblocks",
                    "wordcount",
                    "textcolor",
                  ],
                  toolbar:
                    "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | forecolor backcolor | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                  tinycomments_mode: "embedded",
                  tinycomments_author: "Author name",
                  mergetags_list: [
                    { value: "First.Name", title: "First Name" },
                    { value: "Email", title: "Email" },
                  ],
                }}
                value={editorContent}
                onEditorChange={handleEditorChange}
              />
              {/* <p className="text-sm text-red-400">
                {errors.find((e) => e.id === "description")?.error}
              </p> */}
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div className="flex flex-col gap-y-4">
                <p className="font-semibold">Seleziona lingue</p>
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  menuPortalTarget={document.body}
                  menuPosition="absolute"
                  id="languages"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid rgb(229 231 235)",
                      minHeight: "35px",
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: "#246155",
                      color: "white",
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: "white",
                    }),
                    multiValueRemove: (provided, state) => ({
                      ...provided,
                      color: "white",
                      ":hover": {
                        backgroundColor: "#328575",
                      },
                    }),
                  }}
                  defaultValue={experience?.languages.map((l) => ({
                    value: l,
                    label: l.charAt(0).toUpperCase() + l.slice(1),
                  }))}
                  options={[
                    {
                      value: "italiano",
                      label: "Italiano",
                    },
                    {
                      value: "inglese",
                      label: "Inglese",
                    },
                    {
                      value: "francese",
                      label: "Francese",
                    },
                    {
                      value: "spagnolo",
                      label: "Spagnolo",
                    },
                    {
                      value: "tedesco",
                      label: "Tedesco",
                    },
                    {
                      value: "altro",
                      label: "Altro",
                    },
                  ]}
                  onChange={(value) => {
                    setSelectedLenguages(value.map((v) => v.value));
                  }}
                />
                {/* <p className="-mt-2 text-sm text-red-400">
                  {errors.find((e) => e.id === "languages")?.error}
                </p> */}
              </div>
              <div className="flex flex-col gap-y-4">
                <p className="font-semibold">Durata</p>
                <CurrencyInput
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                  placeholder="2 h"
                  id="duration"
                  min={0}
                  suffix=" h"
                  decimalsLimit={2}
                  decimalScale={2}
                  decimalSeparator=":"
                  allowNegativeValue={false}
                  defaultValue={experience?.duration}
                  onChange={() => updateExperience()}
                />
                {/* <p className="-mt-2 text-sm text-red-400">
                  {errors.find((e) => e.id === "duration")?.error}
                </p> */}
              </div>
            </div>
            <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-4">
              <p className="font-semibold">Accessibilità</p>
              <div className="w-full col-span-3">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  menuPortalTarget={document.body}
                  menuPosition="absolute"
                  defaultValue={experience?.accessibility.map((l) => ({
                    value: l,
                    label:
                      l === "Lis"
                        ? "Disponibile in lingua dei segni"
                        : l === "sedia"
                        ? "Accessibile in sedia a rotelle"
                        : "Altro",
                  }))}
                  id="accessibility"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid rgb(229 231 235)",
                      height: "35px",
                      width: "100%",
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: "#246155",
                      color: "white",
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: "white",
                    }),
                    multiValueRemove: (provided, state) => ({
                      ...provided,
                      color: "white",
                      ":hover": {
                        backgroundColor: "#328575",
                      },
                    }),
                  }}
                  options={[
                    {
                      value: "Lis",
                      label: "Disponibile in lingua dei segni",
                    },
                    {
                      value: "sedia",
                      label: "Accessibile in sedia a rotelle",
                    },
                    {
                      value: "altro",
                      label: "Altro",
                    },
                  ]}
                  onChange={(value) => {
                    setSelectedAccessibility(value.map((v) => v.value));
                  }}
                />
              </div>
            </div>
            {selectedAccessibility.includes("altro") && (
              <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-4">
                <p className="font-semibold">
                  Specifica le accessibilità della tua esperienza
                </p>
                <div className="w-full md:col-span-3">
                  <TagsInputWithButton
                    value={extraAccessibility}
                    onChange={(tags) => {
                      setExtraAccessibility(tags);
                    }}
                    placeholder="Specifica le accessibilità"
                    id="extraAccessibility"
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
