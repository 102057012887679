import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faPencilSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import AWS from "aws-sdk";
import axios from "axios";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import ImageUploading from "react-images-uploading";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import TagsInput from "react-tagsinput";
import { FeeContext } from "../../App";
import plus from "../../assets/icons/PLUS.svg";
import xmark from "../../assets/icons/XMARK.svg";
import BaseComponent from "../BaseComponent";
import ExperienceIcon from "../ExperienceIcon";
import { LocationValleys } from "../utils/LocationValleys";

export default function Boxes() {
  const animatedComponents = makeAnimated();
  const [boxes, setBoxes] = useState(null);
  const { fees } = useContext(FeeContext);
  const [loading, setLoading] = useState(true);
  const [createNew, setCreateNew] = useState(false);
  const [selectedBox, setSelectedBox] = useState(null);
  const [images, setImages] = useState([]);
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  const [uploadedImages, setUploadedImages] = useState([]);
  const cityList = LocationValleys.flatMap((valley) => valley.name);
  const [isUploading, setIsUploading] = useState(false);
  const [tags, setTags] = useState([]);
  const [structures, setStructures] = useState([]);
  const [selectedStructures, setSelectedStructures] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [experiences, setExperiences] = useState([]);
  const [selectedExperiences, setSelectedExperiences] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [experiencesFiltered, setExperiencesFiltered] = useState([]);
  const [errors, setErrors] = useState([
    { id: "title", error: null },
    { id: "tags", error: null },
    { id: "structures", error: null },
    { id: "experiences", error: null },
    { id: "cover", error: null },
    { id: "body", error: null },
    { id: "category", error: null },
    { id: "city", error: null },
    { id: "rooms", error: null },
    { id: "guestNumber", error: null },
    { id: "duration", error: null },
    { id: "price", error: null },
  ]);

  const boxesDetail = async () => {
    const token = localStorage.getItem("token");
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/escoBoxes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setBoxes(res.data);
        console.log(res.data, "boxes loaded");
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const getOptions = async () => {
    const token = localStorage.getItem("token");
    boxesDetail();
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/structuresApproved`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        console.log(res.data, "structures");
        if (res.data.msg === "No structures approved") {
          setStructures([]);
        } else {
          setStructures(
            res.data.filter((s) => s.user_id.stripe_status === "active")
          );
        }
        await axios
          .get(`${process.env.REACT_APP_SERVER_URL}/api/experiencesApproved`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(async (res) => {
            console.log(res.data, "experiences");
            setExperiences(
              res.data.filter((e) => e.user_id.stripe_status === "active")
            );
            setLoading(false);
          })
          .catch((err) => {
            console.log(err, "err");
          });
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const uploadFile = async () => {
    const S3_BUCKET = "escopublic";
    const REGION = "eu-north-1";

    AWS.config.update({
      accessKeyId: `${process.env.REACT_APP_AWS_ACCES_KEY_ID}`,
      secretAccessKey: `${process.env.REACT_APP_AWS_sECRET_ACCESS_KEY}`,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const tempImages = [];
    setIsUploading(true);

    images.forEach((image, index) => {
      if (
        !uploadedImages.includes(
          `https://escopublic.s3.eu-north-1.amazonaws.com/${image.file.name}`
        )
      ) {
        const params = {
          Bucket: S3_BUCKET,
          Key: image.file.name,
          Body: image.file,
        };

        var upload = s3
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            console.log(
              "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
            );
          })
          .promise();

        upload.then((err, data) => {
          tempImages.push(
            `https://escopublic.s3.eu-north-1.amazonaws.com/${image.file.name}`
          );
          if (index === images.length - 1) {
            setImages([]);
            setIsUploading(false);
            setUploadedImages((prev) => {
              return [...prev, ...tempImages];
            });
          }
        });
      } else {
        if (index === images.length - 1) {
          setImages([]);
          setIsUploading(false);
          setUploadedImages((prev) => {
            return [...prev, ...tempImages];
          });
        }
      }
    });
  };

  const createBox = async () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("user");
    const title = document.getElementById("title").value;
    const body = document.getElementById("body").value;
    const user_id = id;
    const structures = selectedStructures;
    const experiences = selectedExperiences;
    const rooms = selectedRooms;
    const price = document.getElementById("price").value;
    const guestNumber = document.getElementById("guestNumber").value;
    const duration = document.getElementById("duration").value;

    const currentErrors = [
      { id: "title", error: null },
      { id: "tags", error: null },
      { id: "structures", error: null },
      { id: "experiences", error: null },
      { id: "cover", error: null },
      { id: "body", error: null },
      { id: "category", error: null },
      { id: "city", error: null },
      { id: "rooms", error: null },
      { id: "guestNumber", error: null },
      { id: "duration", error: null },
      { id: "price", error: null },
    ];

    if (title === "") {
      currentErrors.find((e) => e.id === "title").error = "Inserisci un titolo";
    }
    if (tags.length === 0) {
      currentErrors.find((e) => e.id === "tags").error =
        "Inserisci almeno un tag";
    }
    if (structures === null) {
      currentErrors.find((e) => e.id === "structures").error =
        "Seleziona almeno una struttura";
    }
    if (experiences === null) {
      currentErrors.find((e) => e.id === "experiences").error =
        "Seleziona almeno un'esperienza";
    }
    if (uploadedImages.length === 0) {
      currentErrors.find((e) => e.id === "cover").error = "Inserisci una cover";
    }
    if (body === "") {
      currentErrors.find((e) => e.id === "body").error =
        "Inserisci la descrizione del box";
    }
    if (selectedCategory === null) {
      currentErrors.find((e) => e.id === "category").error =
        "Seleziona una categoria";
    }
    if (selectedCity === "" || selectedCity === null) {
      currentErrors.find((e) => e.id === "city").error = "Inserisci la città";
    }
    if (rooms.length === 0 || rooms.length !== selectedStructures.length) {
      currentErrors.find((e) => e.id === "rooms").error =
        "Seleziona una camera per ogni struttura";
    }
    if (guestNumber === "" || +guestNumber <= 0) {
      currentErrors.find((e) => e.id === "guestNumber").error =
        "Inserisci il numero di ospiti";
    }
    if (duration === "" || +duration <= 0) {
      currentErrors.find((e) => e.id === "duration").error =
        "Inserisci la durata in notti del box";
    }
    if (price === "" || +price.split("€")[1] <= 0) {
      currentErrors.find((e) => e.id === "price").error =
        "Inserisci un prezzo maggiore di 0";
    }

    setErrors(currentErrors);

    if (currentErrors.find((e) => e.error !== null) === undefined) {
      const data = {
        name: title,
        description: body,
        tags,
        cover: uploadedImages[0],
        structures: structures.map((s, index) => ({
          structure_id: s,
          room_id: rooms[index],
        })),
        experiences,
        user_id,
        category: selectedCategory,
        city: selectedCity,
        guest_number: guestNumber,
        duration: +duration,
        price: parseFloat(+price.substring(1).replace(",", ".") * 100),
      };
      await axios
        .post(`${process.env.REACT_APP_SERVER_URL}/api/escoBoxCreate`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data, "box created");
          setCreateNew(false);
          boxesDetail();
          setSelectedBox(null);
          setSelectedStructures(null);
          setSelectedExperiences(null);
          setUploadedImages([]);
          setTags([]);
          setSelectedRooms([]);
          setSelectedCategory(null);
          setSelectedCity(null);
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };

  const updateBox = async () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("user");
    const title = document.getElementById("title").value;
    const body = document.getElementById("body").value;
    const user_id = id;
    const structures = selectedStructures;
    const experiences = selectedExperiences;
    const rooms = selectedRooms;
    const price = document.getElementById("price").value;
    const guestNumber = document.getElementById("guestNumber").value;
    const duration = document.getElementById("duration").value;

    const currentErrors = [
      { id: "title", error: null },
      { id: "tags", error: null },
      { id: "structures", error: null },
      { id: "experiences", error: null },
      { id: "cover", error: null },
      { id: "body", error: null },
      { id: "category", error: null },
      { id: "city", error: null },
      { id: "rooms", error: null },
      { id: "guestNumber", error: null },
      { id: "duration", error: null },
      { id: "price", error: null },
    ];

    if (title === "") {
      currentErrors.find((e) => e.id === "title").error = "Inserisci un titolo";
    }
    if (tags.length === 0) {
      currentErrors.find((e) => e.id === "tags").error =
        "Inserisci almeno un tag";
    }
    if (structures === null) {
      currentErrors.find((e) => e.id === "structures").error =
        "Seleziona almeno una struttura";
    }
    if (experiences === null) {
      currentErrors.find((e) => e.id === "experiences").error =
        "Seleziona almeno un'esperienza";
    }
    if (uploadedImages.length === 0) {
      currentErrors.find((e) => e.id === "cover").error = "Inserisci una cover";
    }
    if (body === "") {
      currentErrors.find((e) => e.id === "body").error =
        "Inserisci la descrizione del box";
    }
    if (selectedCategory === null) {
      currentErrors.find((e) => e.id === "category").error =
        "Seleziona una categoria";
    }
    if (selectedCity === "" || selectedCity === null) {
      currentErrors.find((e) => e.id === "city").error = "Inserisci la città";
    }
    if (rooms.length === 0 || rooms.length !== selectedStructures.length) {
      currentErrors.find((e) => e.id === "rooms").error =
        "Seleziona una camera per ogni struttura";
    }
    if (guestNumber === "" || +guestNumber <= 0) {
      currentErrors.find((e) => e.id === "guestNumber").error =
        "Inserisci il numero di ospiti";
    }
    if (duration === "" || +duration <= 0) {
      currentErrors.find((e) => e.id === "duration").error =
        "Inserisci la durata in notti del box";
    }
    if (price === "" || +price.split("€")[1] <= 0) {
      currentErrors.find((e) => e.id === "price").error =
        "Inserisci un prezzo maggiore di 0";
    }

    setErrors(currentErrors);

    if (currentErrors.find((e) => e.error !== null) === undefined) {
      const data = {
        name: title,
        description: body,
        tags,
        cover: uploadedImages[0],
        structures: structures.map((s, index) => ({
          structure_id: s,
          room_id: rooms[index],
        })),
        experiences,
        user_id,
        category: selectedCategory,
        city: selectedCity,
        guest_number: guestNumber,
        duration: +duration,
        price: parseFloat(+price.substring(1).replace(",", ".") * 100),
      };
      await axios
        .put(
          `${process.env.REACT_APP_SERVER_URL}/api/escoBox/edit/${selectedBox._id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data, "box updated");
          setCreateNew(false);
          boxesDetail();
          setSelectedBox(null);
          setSelectedStructures(null);
          setSelectedExperiences(null);
          setUploadedImages([]);
          setTags([]);
          setSelectedRooms([]);
          setSelectedCategory(null);
          setSelectedCity(null);
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    if (images.length > 0) {
      uploadFile();
    }
  }, [images]);

  useEffect(() => {
    if (selectedBox) {
      setTags(selectedBox.tags);
      setUploadedImages([selectedBox.cover]);
      // setSelectedStructures(selectedBox.structures.map((s) => s.structure_id));
      // setSelectedRooms(selectedBox.structures.map((s) => s.room_id));
      // we need an array of ids for these [id1, id2, id3]
      setSelectedStructures(selectedBox.structures.map((s) => s.structure_id));
      setSelectedRooms(selectedBox.structures.map((s) => s.room_id));
      setSelectedExperiences(selectedBox.experiences);
    }
  }, [selectedBox]);

  console.log("selecta", selectedStructures);

  const categories = ["wine", "art", "outdoor", "authentic"];

  const [minPrice, setMinPrice] = useState(
    selectedExperiences === null ||
      selectedRooms.length === 0 ||
      selectedStructures === null
      ? 0
      : selectedExperiences?.reduce(
          (acc, cur) =>
            acc +
            experiences.find((e) => e._id === cur).price.adult / 100 +
            ((experiences.find((e) => e._id === cur).fee_id === "associations"
              ? fees.associations.fee
              : experiences.find((e) => e._id === cur).fee_id
              ? fees.custom.find(
                  (f) => f._id === experiences.find((e) => e._id === cur).fee_id
                ).fee
              : fees.experiences.fee) *
              experiences.find((e) => e._id === cur).price.adult) /
              10000,
          0
        ) +
          (selectedStructures?.length === selectedRooms.length
            ? Math.min(
                ...selectedStructures?.map((s) => {
                  const structureSelected = structures.find(
                    (st) => st._id === s
                  );
                  const roomSelected = structureSelected.rooms.find((r) =>
                    selectedRooms.find((sr) => sr === r._id)
                  );
                  const structureFee =
                    structureSelected.fee_id === "associations"
                      ? fees.associations.fee
                      : structureSelected.fee_id
                      ? fees.custom.find(
                          (f) => f._id === structureSelected.fee_id
                        ).fee
                      : fees.structures.fee;
                  return (
                    roomSelected.price / 100 +
                    (structureFee * roomSelected.price) / 10000
                  );
                })
              )
            : 0)
  );

  const [maxPrice, setMaxPrice] = useState(
    selectedExperiences === null ||
      selectedStructures === null ||
      selectedRooms.length === 0
      ? 0
      : selectedExperiences?.reduce(
          (acc, cur) =>
            acc +
            experiences.find((e) => e._id === cur).price.adult / 100 +
            ((experiences.find((e) => e._id === cur).fee_id === "associations"
              ? fees.associations.fee
              : experiences.find((e) => e._id === cur).fee_id
              ? fees.custom.find(
                  (f) => f._id === experiences.find((e) => e._id === cur).fee_id
                ).fee
              : fees.experiences.fee) *
              experiences.find((e) => e._id === cur).price.adult) /
              10000,
          0
        ) +
          (selectedStructures?.length === selectedRooms.length
            ? Math.max(
                ...selectedStructures?.map((s) => {
                  const structureSelected = structures.find(
                    (st) => st._id === s
                  );
                  const roomSelected = structureSelected.rooms.find((r) =>
                    selectedRooms.find((sr) => sr === r._id)
                  );
                  const structureFee =
                    structureSelected.fee_id === "associations"
                      ? fees.associations.fee
                      : structureSelected.fee_id
                      ? fees.custom.find(
                          (f) => f._id === structureSelected.fee_id
                        ).fee
                      : fees.structures.fee;
                  return (
                    roomSelected.price / 100 +
                    (structureFee * roomSelected.price) / 10000
                  );
                })
              )
            : 0)
  );

  useEffect(() => {
    console.log("selectedRoom changed", selectedRooms);
    setMinPrice(
      selectedExperiences === null ||
        selectedRooms.length === 0 ||
        selectedStructures === null
        ? 0
        : selectedExperiences?.reduce(
            (acc, cur) =>
              acc +
              experiences.find((e) => e._id === cur).price.adult / 100 +
              ((experiences.find((e) => e._id === cur).fee_id === "associations"
                ? fees.associations.fee
                : experiences.find((e) => e._id === cur).fee_id
                ? fees.custom.find(
                    (f) =>
                      f._id === experiences.find((e) => e._id === cur).fee_id
                  ).fee
                : fees.experiences.fee) *
                experiences.find((e) => e._id === cur).price.adult) /
                10000,
            0
          ) +
            (selectedStructures?.length === selectedRooms.length
              ? Math.min(
                  ...selectedStructures?.map((s) => {
                    const structureSelected = structures.find(
                      (st) => st._id === s
                    );
                    const roomSelected = structureSelected.rooms.find((r) =>
                      selectedRooms.find((sr) => sr === r._id)
                    );
                    const structureFee =
                      structureSelected.fee_id === "associations"
                        ? fees.associations.fee
                        : structureSelected.fee_id
                        ? fees.custom.find(
                            (f) => f._id === structureSelected.fee_id
                          ).fee
                        : fees.structures.fee;
                    return (
                      roomSelected.price / 100 +
                      (structureFee * roomSelected.price) / 10000
                    );
                  })
                )
              : 0)
    );
    setMaxPrice(
      selectedExperiences === null ||
        selectedRooms.length === 0 ||
        selectedStructures === null
        ? 0
        : selectedExperiences?.reduce(
            (acc, cur) =>
              acc +
              experiences.find((e) => e._id === cur).price.adult / 100 +
              ((experiences.find((e) => e._id === cur).fee_id === "associations"
                ? fees.associations.fee
                : experiences.find((e) => e._id === cur).fee_id
                ? fees.custom.find(
                    (f) =>
                      f._id === experiences.find((e) => e._id === cur).fee_id
                  ).fee
                : fees.experiences.fee) *
                experiences.find((e) => e._id === cur).price.adult) /
                10000,
            0
          ) +
            (selectedStructures?.length === selectedRooms.length
              ? Math.max(
                  ...selectedStructures?.map((s) => {
                    const structureSelected = structures.find(
                      (st) => st._id === s
                    );
                    const roomSelected = structureSelected.rooms.find((r) =>
                      selectedRooms.find((sr) => sr === r._id)
                    );
                    const structureFee =
                      structureSelected.fee_id === "associations"
                        ? fees.associations.fee
                        : structureSelected.fee_id
                        ? fees.custom.find(
                            (f) => f._id === structureSelected.fee_id
                          ).fee
                        : fees.structures.fee;
                    return (
                      roomSelected.price / 100 +
                      (structureFee * roomSelected.price) / 10000
                    );
                  })
                )
              : 0)
    );
  }, [selectedExperiences, selectedRooms]);

  useEffect(() => {
    if (selectedCategory) {
      setExperiencesFiltered(
        experiences.filter((e) => selectedCategory.includes(e.category))
      );
    } else {
      setExperiencesFiltered([]);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedBox) {
      setSelectedCity(selectedBox.city);
      setSelectedCategory(selectedBox.category);
    }
  }, [selectedBox]);

  return (
    <>
      <BaseComponent
        title={
          createNew && !selectedBox
            ? "Nuovo box"
            : selectedBox && createNew
            ? "Modifica box"
            : "Boxes"
        }
        sidebar={
          createNew ? null : (
            <button
              onClick={() => setCreateNew(true)}
              className="flex items-center justify-between px-4 text-white rounded-md bg-primary"
            >
              Nuovo box
            </button>
          )
        }
      >
        {createNew ? (
          <div className="flex flex-col px-10 py-10 my-4 mb-20 overflow-y-auto bg-gray-100 rounded-md gap-y-4">
            <div className="grid items-center w-full grid-cols-5 gap-x-4">
              <p className="font-semibold">Titolo</p>
              <div className="relative flex flex-col w-full col-span-4 gap-y-4">
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="title"
                  defaultValue={selectedBox?.name}
                  placeholder={`tiolo Escobox`}
                  //   onChange={() => updateExperience()}
                ></input>
                <p className="-mt-2 text-sm text-red-400">
                  {errors.find((e) => e.id === "title")?.error}
                </p>
              </div>
            </div>
            <div className="grid items-center w-full grid-cols-5 gap-x-4">
              <p className="font-semibold">Categorie</p>
              <div className="relative flex flex-col w-full col-span-4 gap-y-4">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  menuPortalTarget={document.body}
                  menuPosition="absolute"
                  defaultValue={
                    selectedBox && selectedBox.category !== null
                      ? selectedBox.category.map((c) => ({
                          value: c,
                          label: c,
                        }))
                      : null
                  }
                  id="category"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid rgb(229 231 235)",
                      width: "100%",
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: "#246155",
                      color: "white",
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: "white",
                    }),
                    multiValueRemove: (provided, state) => ({
                      ...provided,
                      color: "white",
                      ":hover": {
                        backgroundColor: "#328575",
                      },
                    }),
                  }}
                  options={categories.map((c) => ({
                    value: c,
                    label: c,
                  }))}
                  onChange={(value) => {
                    setSelectedCategory(value.map((v) => v.value));
                  }}
                />
                <p className="-mt-2 text-sm text-red-400">
                  {errors.find((e) => e.id === "title")?.error}
                </p>
              </div>
            </div>
            <div className="grid items-center w-full grid-cols-5 mb-2 gap-x-4">
              <p className="font-semibold">Numero guest</p>
              <div className="relative flex flex-col w-full col-span-4 gap-y-4">
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="number"
                  placeholder="2 persone"
                  id="guestNumber"
                  min={1}
                  defaultValue={
                    selectedBox && selectedBox.guest_number
                      ? selectedBox.guest_number
                      : ""
                  }
                />
              </div>
              <p className="text-sm text-red-400">
                {errors.find((e) => e.id === "guestNumber")?.error}
              </p>
            </div>
            <div className="grid items-center w-full grid-cols-5 mb-2 gap-x-4">
              <p className="font-semibold">Numero notti</p>
              <div className="relative flex flex-col w-full col-span-4 gap-y-4">
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="number"
                  placeholder="2 notti"
                  id="duration"
                  min={1}
                  defaultValue={
                    selectedBox && selectedBox.duration
                      ? selectedBox.duration
                      : ""
                  }
                />
              </div>
              <p className="text-sm text-red-400">
                {errors.find((e) => e.id === "duration")?.error}
              </p>
            </div>
            <div className="flex flex-col w-full -mt-2 gap-y-2">
              <p className="font-semibold">Città</p>
              <div className="relative flex flex-col w-fullì gap-y-2">
                {/* <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="city"
                  defaultValue={selectedBox?.city}
                  placeholder={`Paese e provincia es: Vestone (BS)`}
                  //   onChange={() => updateExperience()}
                ></input> */}
                <Select
                  isSearchable
                  isMulti={false}
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  menuPortalTarget={document.body}
                  menuPosition="absolute"
                  id="city"
                  options={cityList.map((c) => ({
                    value: c,
                    label: c,
                  }))}
                  defaultValue={
                    selectedBox && selectedBox.city !== null
                      ? {
                          value: selectedBox.city,
                          label: selectedBox.city,
                        }
                      : null
                  }
                  onChange={(value) => {
                    console.log(value.value);
                    setSelectedCity(value.value);
                  }}
                />
                <p className="-mt-2 text-sm text-red-400">
                  {errors.find((e) => e.id === "city")?.error}
                </p>
              </div>
            </div>
            <div className="flex flex-col w-full gap-y-2 ">
              <p className="w-full font-semibold">Tags</p>
              <div className="w-full">
                <TagsInput
                  value={tags}
                  onChange={(tags) => {
                    setTags(tags);
                  }}
                  inputProps={{
                    placeholder: "Aggiungi un tag",
                  }}
                  id="tags"
                />
              </div>
              <p className="text-sm text-red-400">
                {errors.find((e) => e.id === "tags")?.error}
              </p>
            </div>
            <div className="grid items-center w-full grid-cols-5 gap-x-4">
              <p className="mt-1 font-semibold">Strutture</p>
              <div className="w-full col-span-4">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  menuPortalTarget={document.body}
                  menuPosition="absolute"
                  defaultValue={selectedBox?.structures.map((s) => {
                    const structureSelected = structures.find(
                      (st) => st._id === s.structure_id
                    );
                    return {
                      value: structureSelected._id,
                      label: structureSelected.name,
                    };
                  })}
                  id="structures"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid rgb(229 231 235)",
                      width: "100%",
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: "#246155",
                      color: "white",
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: "white",
                    }),
                    multiValueRemove: (provided, state) => ({
                      ...provided,
                      color: "white",
                      ":hover": {
                        backgroundColor: "#328575",
                      },
                    }),
                  }}
                  options={structures.map((s) => ({
                    value: s._id,
                    label: s.name,
                  }))}
                  onChange={(value) => {
                    setSelectedStructures(value.map((v) => v.value));
                  }}
                />
                <p className="text-sm text-red-400">
                  {errors.find((e) => e.id === "structures")?.error}
                </p>
              </div>
            </div>
            {selectedStructures &&
              selectedStructures.map((currentStructure, index) => (
                <div className="grid items-center w-full grid-cols-5 gap-x-4">
                  <p className="mt-1 font-semibold">
                    Camera Struttura {index + 1}
                  </p>
                  <div className="w-full col-span-4">
                    <Select
                      isMulti={false}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      menuPortalTarget={document.body}
                      menuPosition="absolute"
                      defaultValue={selectedBox?.structures.map((s) => {
                        const structureSelected = structures.find(
                          (st) => st._id === s.structure_id
                        );
                        const roomSelected = structureSelected.rooms.find(
                          (r) => r._id === s.room_id
                        );
                        return {
                          value: roomSelected._id,
                          label: roomSelected.name,
                        };
                      })}
                      id={`room-${index}`}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: "2px solid rgb(229 231 235)",
                          width: "100%",
                        }),
                        multiValue: (provided) => ({
                          ...provided,
                          backgroundColor: "#246155",
                          color: "white",
                        }),
                        multiValueLabel: (provided) => ({
                          ...provided,
                          color: "white",
                        }),
                        multiValueRemove: (provided, state) => ({
                          ...provided,
                          color: "white",
                          ":hover": {
                            backgroundColor: "#328575",
                          },
                        }),
                      }}
                      options={structures
                        .find((s) => s._id === currentStructure)
                        .rooms.map((r) => ({
                          value: r._id,
                          label: r.name,
                        }))}
                      onChange={(value) => {
                        setSelectedRooms((prev) => {
                          const newRooms = [...prev]; // crea una copia dell'array
                          newRooms[index] = value.value;
                          return newRooms;
                        });
                      }}
                    />
                  </div>
                  <p className="text-sm text-red-400">
                    {errors.find((e) => e.id === "rooms")?.error}
                  </p>
                </div>
              ))}
            <p className="text-sm text-red-400">
              {errors.find((e) => e.id === "rooms")?.error}
            </p>
            <div className="grid w-full grid-cols-5 mb-2 gap-x-4">
              <p className="mt-1 font-semibold">Esperienze</p>
              <div className="w-full col-span-4">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  menuPortalTarget={document.body}
                  menuPosition="absolute"
                  defaultValue={selectedBox?.experiences.map((e) => {
                    const experienceSelected = experiences.find(
                      (ex) => ex._id === e
                    );
                    return {
                      value: experienceSelected._id,
                      label: experienceSelected.name,
                    };
                  })}
                  id="experiences"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid rgb(229 231 235)",
                      width: "100%",
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: "#246155",
                      color: "white",
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: "white",
                    }),
                    multiValueRemove: (provided, state) => ({
                      ...provided,
                      color: "white",
                      ":hover": {
                        backgroundColor: "#328575",
                      },
                    }),
                  }}
                  options={experiencesFiltered.map((e) => ({
                    value: e._id,
                    label: e.name,
                  }))}
                  onChange={(value) => {
                    setSelectedExperiences(value.map((v) => v.value));
                  }}
                />
                {experiencesFiltered.length === 0 && (
                  <p className="ml-1 text-sm text-gray-400">
                    Seleziona almeno una categoria per visualizzare le
                    esperienze
                  </p>
                )}
                <p className="ml-1 text-sm text-red-400">
                  {errors.find((e) => e.id === "experiences")?.error}
                </p>
              </div>
            </div>
            <div className="grid w-full grid-cols-5 gap-x-4">
              <p className="mt-1 font-semibold">Range prezzo</p>
              <div className="relative flex flex-col w-full col-span-4 gap-y-4">
                <div
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="priceRange"
                >
                  {Math.ceil(minPrice).toFixed(0) + ",00"} -{" "}
                  {Math.ceil(maxPrice).toFixed(0) + ",00"} €
                </div>
                <p className="text-sm text-red-400">
                  {errors.find((e) => e.id === "price")?.error}
                </p>
              </div>
            </div>
            <div className="grid items-center w-full grid-cols-5 gap-x-4">
              <p className="font-semibold">Prezzo sul sito</p>
              <div className="relative flex flex-col w-full col-span-4 gap-y-4">
                <CurrencyInput
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                  placeholder="€300"
                  id="price"
                  min={0}
                  prefix="€"
                  decimalsLimit={2}
                  decimalScale={2}
                  allowNegativeValue={false}
                  defaultValue={
                    selectedBox?.price ? selectedBox?.price / 100 : ""
                  }
                />
              </div>
            </div>
            <p className="font-semibold">Cover</p>
            <div className="h-72">
              <div className="h-full">
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={20}
                  maxFileSize={10 * 1024 * 1024}
                  acceptType={["jpg", "jpeg", "png"]}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    errors,
                  }) => (
                    // write your building UI
                    <div className="relative w-full h-full upload__image-wrapper">
                      <button
                        className="border-4 border-dashed border-gray-400 rounded-[40px] text-gray-700 p-4 w-full text-center h-full flex flex-col justify-center items-center bg-white"
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        <img
                          src={plus}
                          alt="plus"
                          className={`w-12 h-12 p-2 mb-4 md:w-16 md:h-16 ${
                            isUploading || uploadedImages.length > 0
                              ? "-mt-20"
                              : ""
                          }`}
                        />
                        {!errors && (
                          <>
                            Clicca o trascina la foto che vuoi impostare come
                            copertina
                          </>
                        )}
                        {errors && (
                          <>
                            {errors.maxNumber && (
                              <span className="text-red-500">
                                Hai raggiunto il numero massimo di immagini
                              </span>
                            )}
                            {errors.acceptType && (
                              <span className="text-red-500">
                                Il formato dell'immagine non è valido
                              </span>
                            )}
                            {errors.maxFileSize && (
                              <span className="text-red-500">
                                Immagine troppo grande, la dimensione massima è
                                10MB
                              </span>
                            )}
                          </>
                        )}
                      </button>
                      {isUploading && (
                        <div className="relative px-6 py-2 -mt-28 bg-white border-4 border-gray-200 rounded-b-[40px] w-full overflow-x-scroll flex gap-x-4">
                          {uploadedImages.length > 0 &&
                            uploadedImages.map((image, index) => (
                              <div
                                key={index}
                                className="relative min-w-36 w-36 image-item"
                              >
                                <img
                                  src={image}
                                  alt=""
                                  className="object-cover h-24 w-36 min-w-36"
                                />
                                <div className="absolute -right-2 -top-2 image-item__btn-wrapper">
                                  <button
                                    onClick={() => {
                                      setUploadedImages(
                                        uploadedImages.filter(
                                          (_, i) => i !== index
                                        )
                                      );
                                    }}
                                    className="flex items-center justify-center w-5 h-5 bg-white border-2 border-gray-200 rounded-full"
                                  >
                                    <img
                                      src={xmark}
                                      alt="plus"
                                      className="w-5 h-5"
                                    />
                                  </button>
                                </div>
                              </div>
                            ))}
                          {imageList.map((image, index) => (
                            <div
                              key={index}
                              className="relative flex items-center justify-center h-24 bg-gray-200 rounded-md min-w-36 w-36 image-item"
                            >
                              <UiduSpinner
                                size="large"
                                className="text-gray-500"
                              />
                            </div>
                          ))}
                        </div>
                      )}
                      {uploadedImages.length > 0 && !isUploading && (
                        <div className="relative px-6 py-2 -mt-28 bg-white border-4 border-gray-200 rounded-b-[40px] w-full overflow-x-scroll flex gap-x-4">
                          {uploadedImages.map((image, index) => (
                            <div
                              key={index}
                              className="relative min-w-36 w-36 image-item"
                            >
                              <img
                                src={image}
                                alt=""
                                className="object-cover h-24 w-36 min-w-36"
                              />
                              <div className="absolute -right-2 -top-2 image-item__btn-wrapper">
                                <button
                                  onClick={() => {
                                    setUploadedImages(
                                      uploadedImages.filter(
                                        (_, i) => i !== index
                                      )
                                    );
                                  }}
                                  className="flex items-center justify-center w-5 h-5 bg-white border-2 border-gray-200 rounded-full"
                                >
                                  <img
                                    src={xmark}
                                    alt="plus"
                                    className="w-5 h-5"
                                  />
                                </button>
                              </div>
                            </div>
                          ))}
                          {/* {imageList.map((image, index) => (
                        <div
                          key={index}
                          className="relative min-w-36 w-36 image-item"
                        >
                          <img
                            src={image["data_url"]}
                            alt=""
                            className="object-cover h-24 w-36 min-w-36"
                          />
                          <div className="absolute -right-2 -top-2 image-item__btn-wrapper">
                            <button
                              onClick={() => onImageRemove(index)}
                              className="flex items-center justify-center w-5 h-5 bg-white border-2 border-gray-200 rounded-full"
                            >
                              <img src={xmark} alt="plus" className="w-5 h-5" />
                            </button>
                          </div>
                        </div>
                      ))} */}
                        </div>
                      )}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
            <p className="text-sm text-red-400">
              {errors.find((e) => e.id === "cover")?.error}
            </p>
            <p className="font-semibold">Dettagli box</p>
            <div className="flex flex-col gap-y-1">
              <textarea
                className=" p-6 border-2 border-gray-200 resize-none rounded-[40px] "
                placeholder="Corpo box"
                rows={8}
                id="body"
                defaultValue={selectedBox?.description}
                // onChange={() => updateExperience()}
              ></textarea>
              <p className="text-sm text-red-400">
                {errors.find((e) => e.id === "body")?.error}
              </p>
            </div>
          </div>
        ) : (
          <div className="bg-gray-100 flex flex-col gap-y-4 rounded-md min-h-[512px] overflow-x-auto overflow-y-auto py-4 px-10 my-4">
            {boxes && boxes.length > 0 && !loading ? (
              <div className="flow-root h-[512px] ">
                <div className="py-4 -mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3 pl-4 pr-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase sm:pl-0"
                          >
                            Category
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                          >
                            Cover
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                          >
                            Title
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                          >
                            Tags
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                          >
                            Creato il
                          </th>
                          <th
                            scope="col"
                            className="relative py-3 pl-3 pr-4 sm:pr-0"
                          >
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 ">
                        {boxes.map((box, index) => (
                          <tr key={box._id}>
                            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">
                              <div className="flex items-center gap-x-2">
                                {box.category
                                  .filter(
                                    (c, index, self) =>
                                      self.indexOf(c) === index
                                  )
                                  .map((c, index) => (
                                    <div
                                      className="flex items-center justify-center w-8 h-8 rounded-full "
                                      key={`${c}-${index}`}
                                      style={
                                        c === "wine"
                                          ? { backgroundColor: "#612430" }
                                          : c === "art"
                                          ? { backgroundColor: "#C8531C" }
                                          : c === "outdoor"
                                          ? { backgroundColor: "#4C8AFB" }
                                          : c === "authentic"
                                          ? { backgroundColor: "#CA9526" }
                                          : null
                                      }
                                    >
                                      <ExperienceIcon kind={c} dimension={4} />
                                    </div>
                                  ))}
                              </div>
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="flex items-center justify-center w-12 h-8 rounded-full ">
                                  <img
                                    src={box.cover}
                                    alt=""
                                    className="object-cover w-12 h-8 rounded-md"
                                  />
                                </div>
                              </div>
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <div className="text-sm font-medium text-gray-900">
                                {box.name}
                              </div>
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <div className="text-sm text-gray-500">
                                {box.tags.join(", ")}
                              </div>
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <div className="text-sm text-gray-500">
                                {dayjs(box.createdAt).format("DD/MM/YYYY")}
                              </div>
                            </td>
                            <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-0">
                              <div className="flex items-center justify-end gap-x-6">
                                <button
                                  onClick={() => {
                                    setSelectedBox(box);
                                    setTags(box.tags);
                                    setUploadedImages([box.cover]);
                                    setCreateNew(true);
                                    setSelectedStructures(
                                      box.structures.map((s) => s.structure_id)
                                    );
                                    setSelectedRooms(
                                      box.structures.map((s) => s.room_id)
                                    );
                                    setSelectedExperiences(box.experiences);
                                  }}
                                  className="text-xl font-semibold text-primary"
                                >
                                  <FontAwesomeIcon icon={faPencilSquare} />
                                </button>
                                <button
                                  onClick={() => {
                                    const token = localStorage.getItem("token");
                                    const id = localStorage.getItem("user");
                                    const data = {
                                      id: box._id,
                                      user_id: id,
                                    };
                                    axios
                                      .post(
                                        `${process.env.REACT_APP_SERVER_URL}/api/escoBoxDelete`,
                                        data,
                                        {
                                          headers: {
                                            Authorization: `Bearer ${token}`,
                                          },
                                        }
                                      )
                                      .then((res) => {
                                        boxesDetail();
                                      })
                                      .catch((err) => {
                                        console.log(err, "err");
                                      });
                                  }}
                                  className="text-lg font-semibold text-red-400"
                                >
                                  <FontAwesomeIcon icon={faTrashCan} />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : loading ? (
              <div className="flex items-center justify-center w-full min-h-[480px]">
                <UiduSpinner size="medium" className="text-primary" />
              </div>
            ) : (
              <div className="flex w-full h-full text-gray-400">Nessun box</div>
            )}
          </div>
        )}
      </BaseComponent>
      {createNew && (
        <div className="fixed bottom-0 w-full bg-white border-t max-w-screen-2xl 2xl:mx-auto">
          <div className="w-[71%]">
            <div className="flex justify-end w-full py-2">
              <div className="flex gap-x-4">
                <button
                  onClick={() => {
                    setCreateNew(false);
                    setSelectedBox(null);
                    setSelectedStructures(null);
                    setSelectedExperiences(null);
                    setUploadedImages([]);
                    setTags([]);
                    setSelectedRooms([]);
                    setSelectedCategory(null);
                    setSelectedCity(null);
                  }}
                  className="flex items-center justify-between px-4 py-1 text-white rounded-md bg-primary"
                >
                  Annulla
                </button>
                <button
                  onClick={() => (selectedBox ? updateBox() : createBox())}
                  className="flex items-center justify-between px-4 py-1 text-white rounded-md bg-primary"
                >
                  {selectedBox ? "Modifica" : "Crea"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
