import {
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faInfoCircle,
  faMinus,
  faPlus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import Calendar from "react-calendar";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { FeeContext, LanguageContext } from "../App";

function Riepilogo({
  availableCombinationsIfPresent,
  availableOptions,
  selectedRoomOption,
  selectedMeals,
  meals,
  value,
  adults,
  children,
  isPresent,
  nights,
  structureFee,
}) {
  const { fees } = useContext(FeeContext);
  const intl = useIntl();

  if (selectedRoomOption === null) {
    return (
      <p className="flex w-full font-bold text-gray-400 h-60">
        {intl.formatMessage({
          defaultMessage:
            "Seleziona una camera per poter procedere con la prenotazione",
          id: "qVXjVx",
        })}
      </p>
    );
  }

  return (
    <div className="flex flex-col flex-1 w-full mb-16 gap-y-4">
      {adults > 0 && (
        <div className="flex items-center justify-between gap-x-4">
          <div className="flex items-center font-thin gap-x-4">
            <FontAwesomeIcon icon={faCircleCheck} className="text-green-500" />
            <div className="flex items-center gap-x-1">
              <p>{adults}</p>{" "}
              <FontAwesomeIcon icon={faXmark} className="text-[8px]" />{" "}
              <div>
                {intl.formatMessage({
                  defaultMessage: "Adulti",
                  id: "UKsP/o",
                })}
              </div>
              {children > 0 && (
                <>
                  <p>, {children}</p>{" "}
                  <FontAwesomeIcon icon={faXmark} className="text-[8px]" />{" "}
                  <div>
                    {intl.formatMessage({
                      defaultMessage: "Bambini",
                      id: "wERdVO",
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {value && !isPresent && (
        <div className="flex items-center justify-between gap-x-4">
          <div className="flex items-center font-thin gap-x-4">
            <FontAwesomeIcon icon={faCircleCheck} className="text-green-500" />
            <div className="font-thin">
              {dayjs(value[0]).format("DD MMM YYYY")} -{" "}
              {dayjs(value[1]).format("DD MMM YYYY")}
            </div>
          </div>
        </div>
      )}
      {isPresent && (
        <div className="flex items-center justify-between gap-x-4">
          <div className="flex items-center font-thin gap-x-4">
            <FontAwesomeIcon icon={faCircleCheck} className="text-green-500" />
            <div className="font-thin">
              {nights}{" "}
              {nights > 1
                ? intl.formatMessage({
                    defaultMessage: "notti",
                    id: "3+yThn",
                  })
                : intl.formatMessage({
                    defaultMessage: "notte",
                    id: "/+ZZUB",
                  })}
            </div>
          </div>
        </div>
      )}
      {selectedRoomOption !== null && !isPresent && (
        <>
          {availableOptions[selectedRoomOption].rooms.map((room) => (
            <div className="flex items-center justify-between gap-x-4">
              <div className="flex items-center w-2/4 gap-x-4">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-green-500"
                />
                <div className="font-thin">{room.room_name}</div>
              </div>
              <div className="flex items-center gap-x-2">
                <p>
                  {Math.ceil(
                    availableOptions[selectedRoomOption].total_price / 100 +
                      (structureFee *
                        availableOptions[selectedRoomOption].total_price) /
                        10000
                  ).toFixed(0) + ",00"}{" "}
                  €
                </p>
              </div>
            </div>
          ))}
        </>
      )}
      {selectedRoomOption !== null && isPresent && (
        <>
          {availableCombinationsIfPresent[selectedRoomOption].rooms.map(
            (room) => (
              <div className="flex items-center justify-between gap-x-4">
                <div className="flex items-center w-2/4 gap-x-4">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="text-green-500"
                  />
                  <div className="font-thin">{room.room_name}</div>
                </div>
                <div className="flex items-center gap-x-2">
                  <p>
                    {Math.ceil(
                      availableCombinationsIfPresent[selectedRoomOption]
                        .total_price /
                        100 +
                        (structureFee *
                          availableCombinationsIfPresent[selectedRoomOption]
                            .total_price) /
                          10000
                    ).toFixed(0) + ",00"}{" "}
                    €
                  </p>
                </div>
              </div>
            )
          )}
        </>
      )}
      {selectedMeals.length > 0 && (
        <>
          {selectedMeals.map((meal) => {
            const selectedMeal = meals.find((m) => m._id === meal);
            return (
              <div className="flex items-center justify-between gap-x-4">
                <div className="flex items-center w-2/4 gap-x-4">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="text-green-500"
                  />
                  <div className="font-thin">{selectedMeal.kind}</div>
                </div>
                <div className="flex items-center gap-x-2">
                  <p>{children + adults} </p>{" "}
                  <FontAwesomeIcon icon={faXmark} className="text-xs" />{" "}
                  <p>{selectedMeal.price / 100} €</p>
                </div>
              </div>
            );
          })}
        </>
      )}
      <div className="w-full mt-2 border-b border-primary" />
      <div className="flex flex-row justify-between mt-2">
        <div className="font-semibold">
          {intl.formatMessage({
            defaultMessage: "Totale a notte",
            id: "l46P/i",
          })}
        </div>
        <div className="flex items-center font-semibold gap-x-2">
          {!isPresent ? (
            <div>
              {Math.ceil(
                availableOptions[selectedRoomOption].total_price / 100 +
                  (availableOptions[selectedRoomOption].total_price *
                    structureFee) /
                    10000 +
                  selectedMeals.reduce((acc, meal) => {
                    const selectedMeal = meals.find((m) => m._id === meal);
                    return (
                      acc + (selectedMeal.price / 100) * (children + adults)
                    );
                  }, 0)
              ).toFixed(0) + ",00"}
              €
            </div>
          ) : (
            <div>
              {Math.ceil(
                availableCombinationsIfPresent[selectedRoomOption].total_price /
                  100 +
                  (availableCombinationsIfPresent[selectedRoomOption]
                    .total_price *
                    structureFee) /
                    10000 +
                  selectedMeals.reduce((acc, meal) => {
                    const selectedMeal = meals.find((m) => m._id === meal);
                    return (
                      acc + (selectedMeal.price / 100) * (children + adults)
                    );
                  }, 0)
              ).toFixed(0) + ",00"}{" "}
              €
            </div>
          )}
          <FontAwesomeIcon icon={faXmark} className="text-xs" />{" "}
          {!isPresent ? (
            <p>
              {dayjs(value[1]).diff(dayjs(value[0]), "day")}{" "}
              {dayjs(value[1]).diff(dayjs(value[0]), "day") > 1
                ? intl.formatMessage({
                    defaultMessage: "notti",
                    id: "3+yThn",
                  })
                : intl.formatMessage({
                    defaultMessage: "notte",
                    id: "/+ZZUB",
                  })}
            </p>
          ) : (
            <p>
              {nights}{" "}
              {nights > 1
                ? intl.formatMessage({
                    defaultMessage: "notti",
                    id: "3+yThn",
                  })
                : intl.formatMessage({
                    defaultMessage: "notte",
                    id: "/+ZZUB",
                  })}
            </p>
          )}
        </div>
      </div>
      <div className="w-full mt-2 border-b border-primary" />
      <div className="flex flex-row justify-between mt-2">
        <div className="font-bold">
          {intl.formatMessage({
            defaultMessage: "Prezzo finale",
            id: "9Md0tq",
          })}
        </div>
        <div className="flex items-center font-bold gap-x-2">
          {!isPresent ? (
            <div>
              {Math.ceil(
                (availableOptions[selectedRoomOption].total_price / 100 +
                  (availableOptions[selectedRoomOption].total_price *
                    structureFee) /
                    10000 +
                  selectedMeals.reduce((acc, meal) => {
                    const selectedMeal = meals.find((m) => m._id === meal);
                    return (
                      acc + (selectedMeal.price / 100) * (children + adults)
                    );
                  }, 0)) *
                  dayjs(value[1]).diff(dayjs(value[0]), "day")
              ).toFixed(0) + ",00"}{" "}
              €
            </div>
          ) : (
            <div>
              {Math.ceil(
                (availableCombinationsIfPresent[selectedRoomOption]
                  .total_price /
                  100 +
                  (availableCombinationsIfPresent[selectedRoomOption]
                    .total_price *
                    structureFee) /
                    10000 +
                  selectedMeals.reduce((acc, meal) => {
                    const selectedMeal = meals.find((m) => m._id === meal);
                    return (
                      acc + (selectedMeal.price / 100) * (children + adults)
                    );
                  }, 0)) *
                  nights
              ).toFixed(0) + ",00"}{" "}
              €
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function SelectMeal({ selectedMeals, setSelectedMeals, structure }) {
  const intl = useIntl();
  return (
    <>
      <div className="-mt-4 text-sm text-gray-400">
        {intl.formatMessage({
          defaultMessage:
            "Il prezzo indicato è a persona, seleziona i pasti che preferisci!",
          id: "JbyY9Y",
        })}
      </div>
      <div className="flex flex-col pb-16 gap-y-2">
        {structure.meals.map((meal) => (
          <div
            className={
              `relative flex flex-col border border-primary rounded-[25px] text-primary cursor-pointer` +
              (selectedMeals.includes(meal._id) ? " ring" : "")
            }
            key={meal._id}
            onClick={() =>
              setSelectedMeals(
                selectedMeals.includes(meal._id)
                  ? selectedMeals.filter((m) => m !== meal._id)
                  : [...selectedMeals, meal._id]
              )
            }
          >
            <div className="flex flex-row w-full">
              <div className="w-1/3 border-r border-primary">
                <div className="p-4 font-bold">{meal.kind}</div>
              </div>
              <div className="flex flex-col w-2/3 p-4 text-sm gap-y-2">
                <div className="flex flex-row gap-x-2">
                  <div className="font-semibold">
                    {intl.formatMessage({
                      defaultMessage: "Opzioni:",
                      id: "JZcvN5",
                    })}
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {meal.mealsOptions.map((option) => (
                      <div
                        key={option}
                        className="flex items-center justify-center px-2 border rounded-md shadow-md text-primary border-primary"
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-row gap-x-2">
                  <div className="font-semibold">
                    {intl.formatMessage({
                      defaultMessage: "Prezzo",
                      id: "5YBwNa",
                    })}
                  </div>
                  <div>{meal.price / 100}€</div>
                </div>
              </div>
            </div>
            {selectedMeals.includes(meal._id) && (
              <div className="absolute flex items-center justify-center w-4 h-4 text-sm text-white rounded-full top-2 right-2 bg-primary">
                <FontAwesomeIcon icon={faCheckCircle} />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default function AddToCartStructure({
  structure,
  rooms,
  setOpenCartMobile = null,
  value,
  onChange,
}) {
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [isPresent, setIsPresent] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [cart, setCart] = useState(null);
  const intl = useIntl();
  const [message, setMessage] = useState("");
  const [selectedMeals, setSelectedMeals] = useState([]);
  const [selectedRoomOption, setselectedRoomOption] = useState(null);
  const [nights, setNights] = useState(1);
  const [availableOptions, setAvailableOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [availableCombinationsIfPresent, setAvailableCombinationsIfPresent] =
    useState([]);

  const steps = [
    {
      name: intl.formatMessage({
        defaultMessage: "Partecipanti",
        id: "j5Uxzu",
      }),
    },
    ...(isPresent
      ? [
          {
            name: intl.formatMessage({
              defaultMessage: "Messaggio",
              id: "If7xOp",
            }),
          },
        ]
      : [
          {
            name: intl.formatMessage({
              defaultMessage: "Data",
              id: "XHHR08",
            }),
          },
        ]),
    {
      name: intl.formatMessage({
        defaultMessage: "Camera",
        id: "J6ywQ3",
      }),
    },
    ...(structure.meals.length > 0
      ? [
          {
            name: intl.formatMessage({
              defaultMessage: "Pasti",
              id: "ek/aP2",
            }),
          },
        ]
      : []),
    {
      name: intl.formatMessage({
        defaultMessage: "Riepilogo",
        id: "qh5X8Q",
      }),
    },
  ];

  async function getCart() {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/cart/${localStorage.getItem(
          "user"
        )}`
      )
      .then((res) => {
        console.log(res.data.cart, "cart");
        if (!res.data.message) {
          setCart(res.data.cart);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getBookingOptions() {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/bookingOptions`, {
        rooms: rooms.map((room) => room._id),
        date_start: new Date(dayjs(value[0]).format("YYYY-MM-DD")),
        date_end: new Date(dayjs(value[1]).format("YYYY-MM-DD")),
        guests_number: adults + children,
      })
      .then((res) => {
        console.log(res.data, "booking options");
        setAvailableOptions(res.data.possible_combinations);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const createCart = (elementToAdd) => {
    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("user");
    if (!token) {
      navigate("/login");
      return;
    }
    const data = {
      user_id: user_id,
      elements: [elementToAdd],
    };

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/cart`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        navigate("/cart");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCart = async (id, data) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .put(`${process.env.REACT_APP_SERVER_URL}/api/cart/edit/${id}`, data)
      .then((res) => {
        console.log(res);
        navigate("/cart");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBook = () => {
    const mealsSelected = structure.meals.filter((meal) =>
      selectedMeals.includes(meal._id)
    );
    const elementToAdd = {
      structure_id: structure._id,
      rooms: isPresent
        ? availableCombinationsIfPresent[selectedRoomOption].rooms.map(
            (room) => ({
              room_id: room.room_id,
            })
          )
        : availableOptions[selectedRoomOption].rooms.map((room) => ({
            room_id: room.room_id,
          })),
      guests_number: {
        adults: adults,
        children: children,
      },
      ...(!isPresent && { start_date: value[0], end_date: value[1] }),
      ...(isPresent && { message: message }),
      meals: mealsSelected.map((meal) => meal.kind),
      total_price: isPresent
        ? (availableCombinationsIfPresent[selectedRoomOption].total_price /
            100 +
            (availableCombinationsIfPresent[selectedRoomOption].total_price *
              structureFee) /
              10000 +
            selectedMeals.reduce((acc, meal) => {
              const selectedMeal = structure.meals.find((m) => m._id === meal);
              return acc + (selectedMeal.price / 100) * (children + adults);
            }, 0)) *
          nights
        : (availableOptions[selectedRoomOption].total_price / 100 +
            (availableOptions[selectedRoomOption].total_price * structureFee) /
              10000 +
            selectedMeals.reduce((acc, meal) => {
              const selectedMeal = structure.meals.find((m) => m._id === meal);
              return acc + (selectedMeal.price / 100) * (children + adults);
            }, 0)) *
          dayjs(value[1]).diff(dayjs(value[0]), "day"),
    };
    if (cart) {
      const data = {
        elements: [...cart.elements, elementToAdd],
      };
      updateCart(cart._id, data);
    } else {
      createCart(elementToAdd);
    }
  };

  function getCombinations(rooms, adults, children) {
    const combinations = [];
    const helper = (start, combination, quantities) => {
      const totalCapacity = combination.reduce(
        (sum, room) => sum + room.single_bed + room.double_bed * 2,
        0
      );
      if (totalCapacity >= adults + children) {
        combinations.push([...combination]);
        return;
      }
      for (let i = start; i < rooms.length; i++) {
        if (quantities[rooms[i].room_id] > 0) {
          quantities[rooms[i].room_id]--;
          combination.push(rooms[i]);
          helper(i, combination, quantities);
          combination.pop();
          quantities[rooms[i].room_id]++;
        }
      }
    };
    const quantities = rooms.reduce(
      (obj, room) => ({ ...obj, [room.room_id]: room.room_quantity }),
      {}
    );
    helper(0, [], quantities);
    return combinations;
  }

  useEffect(() => {
    if (isPresent) {
      const possible_combinations = [];
      const available_rooms = rooms.map((room) => ({
        room_id: room._id,
        room_name: room.name,
        single_bed: room.single_bed,
        double_bed: room.double_bed,
        room_quantity: room.quantity,
        price: room.price,
      }));
      const combinations = getCombinations(available_rooms, adults, children);
      combinations.forEach((combination) => {
        const rooms = [];
        let total_price = 0;
        combination.forEach((room) => {
          rooms.push(room);
          total_price += room.price;
        });
        possible_combinations.push({ rooms, total_price });
      });

      setAvailableCombinationsIfPresent(
        possible_combinations
          .sort((a, b) => a.total_price - b.total_price)
          .slice(0, 3)
      );
    }
  }, [adults, children, rooms, isPresent]);

  useEffect(() => {
    setCurrentStep(0);
    onChange([]);
    setAdults(1);
    setChildren(0);
    setMessage("");
    getCart();
    setselectedRoomOption(null);
    setSelectedMeals([]);
    setNights(1);
  }, [structure, isPresent]);

  useEffect(() => {
    setCurrentStep(0);
    onChange([]);
    setAdults(1);
    setChildren(0);
    setIsPresent(false);
    setMessage("");
    getCart();
    setselectedRoomOption(null);
    setSelectedMeals([]);
    setNights(1);
  }, [structure]);

  useEffect(() => {
    if (currentStep === 2 && value.length > 1 && adults + children > 0) {
      setLoading(true);
      getBookingOptions();
    }
  }, [currentStep]);

  const { fees } = useContext(FeeContext);
  const structureFee =
    structure.fee_id === "associations"
      ? fees.associations.fee
      : structure.fee_id
      ? fees.custom.find((f) => f._id === structure.fee_id).fee
      : fees.structures.fee;

  return (
    <div className="relative md:sticky flex flex-col bg-white border rounded-[30px] shadow-lg md:top-5 gap-y-2 min-h-[500px] h-full md:h-fit">
      {structure.user_id.stripe_status !== "active" && (
        <div className="absolute z-50 top-0 left-0 w-full h-full bg-[rgba(255,255,255,0.8)] rounded-[20px]">
          <div className="flex flex-col items-center justify-center h-full p-6 gap-y-4">
            <div className="p-4 rounded-[20px] bg-gray-100 flex flex-col gap-y-4 justify-center ">
              <p className="text-lg font-semibold">
                {intl.formatMessage({
                  defaultMessage:
                    "La struttura al momento non è disponibile :(",
                  id: "aRofiQ",
                })}
              </p>
              {localStorage.getItem("user") === structure.user_id._id && (
                <button
                  className="px-4 py-2 text-white rounded-md bg-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(
                      `/creatorZone/${localStorage.getItem("user")}/accounting`
                    );
                  }}
                >
                  {intl.formatMessage({
                    defaultMessage: "completa il tuo profilo",
                    id: "8RwP27",
                  })}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="relative flex flex-row justify-between overflow-hidden bg-gray-200 rounded-full h-[60px]">
        {steps.map((step, index) => (
          <div className="relative flex">
            <div
              className={` flex h-[3.35rem] md:h-[3.25rem] items-center gap-x-4  relative z-10 pl-12 ${
                currentStep === index &&
                index !== 0 &&
                index !== steps.length - 1
                  ? "bg-secondary -ml-6 xl:-ml-20 rounded-r-[30px]"
                  : currentStep === index && index === steps.length - 1
                  ? "bg-secondary absolute -ml-8 xl:-ml-28 rounded-r-[30px]"
                  : currentStep === index && index === 0
                  ? "bg-secondary  rounded-[30px]"
                  : currentStep > index &&
                    index !== 0 &&
                    structure.meals.length > 0
                  ? "bg-secondary absolute w-20 md:w-24 -ml-8 rounded-[0px]"
                  : currentStep > index &&
                    index === 0 &&
                    structure.meals.length > 0
                  ? "absolute bg-secondary left-2 w-24 rounded-[0px]"
                  : currentStep > index &&
                    index !== 0 &&
                    structure.meals.length === 0
                  ? "bg-secondary absolute w-20 md:w-[118px] -ml-8 rounded-[0px]"
                  : currentStep > index &&
                    index === 0 &&
                    structure.meals.length === 0
                  ? "absolute bg-secondary left-2 w-[118px] rounded-[0px]"
                  : "text-gray-400"
              }`}
            >
              {currentStep === index && (
                <div className="pr-6 font-bold text-white">{step.name}</div>
              )}
            </div>
            <div
              className={`absolute left-0 w-[3rem] md:w-[3.25rem] h-[3.35rem] md:h-[3.25rem] flex items-center justify-center  text-white font-bold ${
                currentStep === index &&
                index !== 0 &&
                index !== steps.length - 1
                  ? "bg-secondary -ml-8 md:-ml-20 w-36 md:w-32 z-20 xl:-ml-24"
                  : currentStep === index && index === steps.length - 1
                  ? "bg-secondary -ml-12 md:-ml-28 w-32 z-20 xl:-ml-32"
                  : currentStep === index && index === 0
                  ? "bg-secondary rounded-full z-20 xl:-ml-1"
                  : currentStep > index && index !== 0
                  ? "bg-primary cursor-pointer rounded-full -ml-10 xl:-ml-24 z-30"
                  : currentStep > index && index === 0
                  ? "bg-primary cursor-pointer rounded-full z-30"
                  : "bg-gray-400 cursor-pointer rounded-full z-20 md:-ml-0"
              }`}
              onClick={() => setCurrentStep(index)}
            >
              {index + 1}
            </div>
          </div>
        ))}
      </div>
      <div className="relative h-full m-6">
        {currentStep === 0 && (
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col py-4 gap-y-2">
              <div className="flex items-center justify-between">
                <div className="text-lg font-bold">
                  {intl.formatMessage({
                    defaultMessage: "E` un regalo?",
                    id: "hTPeFx",
                  })}
                </div>
                <button
                  className={`rounded-md px-2 border border-primary ${
                    isPresent ? "bg-primary text-white" : ""
                  }`}
                  onClick={() => setIsPresent(!isPresent)}
                >
                  {intl.formatMessage({
                    defaultMessage: "Seleziona",
                    id: "QYj+42",
                  })}
                </button>
              </div>
              <p className="w-1/2 font-thin">
                {intl.formatMessage({
                  defaultMessage:
                    "Chi riceve il regalo potrà scegliere la data che preferisce",
                  id: "D8XNro",
                })}
              </p>
              {isPresent && (
                <div className="flex flex-row items-center justify-between mt-2">
                  <div className="text-lg font-bold">
                    {intl.formatMessage({
                      defaultMessage: "Numero notti:",
                      id: "+k9XYO",
                    })}
                  </div>
                  <div className="flex items-center gap-x-2 ">
                    <button
                      className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
                      onClick={() => {
                        if (nights > 1) setNights(nights - 1);
                      }}
                    >
                      <FontAwesomeIcon icon={faMinus} className="text-xs" />
                    </button>
                    <div className="w-12 font-semibold text-center">
                      {nights}
                    </div>
                    <button
                      className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
                      onClick={() => setNights(nights + 1)}
                    >
                      <FontAwesomeIcon icon={faPlus} className="text-xs" />
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="text-lg font-bold">
              {intl.formatMessage({
                defaultMessage: "Tipologia ospiti",
                id: "Kfe6XP",
              })}
            </div>
            <div className="flex flex-row justify-between">
              <div className="font-thin">
                {intl.formatMessage({
                  defaultMessage: "Adulti:",
                  id: "LVDPTp",
                })}
              </div>
              <div className="flex items-center gap-x-2 ">
                <button
                  className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
                  onClick={() => {
                    if (adults > 0) setAdults(adults - 1);
                  }}
                >
                  <FontAwesomeIcon icon={faMinus} className="text-xs" />
                </button>
                <div className="w-12 font-semibold text-center">{adults}</div>
                <button
                  className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
                  onClick={() => setAdults(adults + 1)}
                >
                  <FontAwesomeIcon icon={faPlus} className="text-xs" />
                </button>
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <div className="font-thin">
                {intl.formatMessage({
                  defaultMessage: "Bambini:",
                  id: "QUY+AH",
                })}
              </div>
              <div className="flex items-center gap-x-2 ">
                <button
                  className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
                  onClick={() => {
                    if (children > 0) setChildren(children - 1);
                  }}
                >
                  <FontAwesomeIcon icon={faMinus} className="text-xs" />
                </button>
                <div className="w-12 font-semibold text-center">{children}</div>
                <button
                  className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
                  onClick={() => setChildren(children + 1)}
                >
                  <FontAwesomeIcon icon={faPlus} className="text-xs" />
                </button>
              </div>
            </div>
          </div>
        )}
        {currentStep === 1 && !isPresent && (
          <div className="flex flex-col mb-16 gap-y-4">
            <div className="font-bold">
              {intl.formatMessage({
                defaultMessage: "Scegli il range di date",
                id: "teqyg7",
              })}
            </div>
            {console.log("value", value)}
            <Calendar
              onChange={(date) => {
                onChange(date);
              }}
              locale={language}
              value={value}
              minDate={new Date()}
              prev2Label={null}
              next2Label={null}
              selectRange={true}
            />
          </div>
        )}
        {currentStep === 1 && isPresent && (
          <div className="flex flex-col flex-1 w-full gap-y-4">
            <div className="font-bold">
              {intl.formatMessage({
                defaultMessage: "Messaggio",
                id: "If7xOp",
              })}
            </div>
            <div className="w-full h-56">
              <textarea
                className="h-full w-full p-6 mb-6 border-2 border-gray-200 resize-none rounded-[40px]"
                defaultValue={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={intl.formatMessage({
                  defaultMessage: "Aggiungi un messaggio",
                  id: "iCRZWy",
                })}
              ></textarea>
            </div>
          </div>
        )}
        {currentStep === 2 && isPresent && (
          <div className="flex flex-col gap-y-4">
            <div className="mb-2 font-bold">
              {intl.formatMessage({
                defaultMessage: "Opzioni disponibili",
                id: "Efd7Qa",
              })}
            </div>
            {adults + children > 0 ? (
              <div className="flex flex-col mb-14 gap-y-2">
                {availableCombinationsIfPresent.length > 0 ? (
                  <>
                    {availableCombinationsIfPresent.map((option, index) => (
                      <div
                        className={
                          `relative flex flex-col border border-primary rounded-[25px] text-primary cursor-pointer` +
                          (selectedRoomOption === index ? " ring" : "")
                        }
                        key={option.rooms[0].room_id}
                        onClick={() => setselectedRoomOption(index)}
                      >
                        {option.rooms.map((room) => (
                          <div className="flex flex-row w-full">
                            <div className="w-1/3 border-r border-primary">
                              <div className="p-4 font-bold">
                                {room.room_name}
                              </div>
                            </div>
                            <div className="flex flex-col w-2/3 p-4 text-sm gap-y-2">
                              <div className="flex flex-row gap-x-2">
                                <div className="font-semibold">
                                  {intl.formatMessage({
                                    defaultMessage: "Letti:",
                                    id: "r2OGhC",
                                  })}
                                </div>
                                <div className="flex flex-col">
                                  {room.double_bed > 0 && (
                                    <div>
                                      {`${room.double_bed} `}{" "}
                                      {intl.formatMessage({
                                        defaultMessage: "matrimoniali",
                                        id: "c7YzvK",
                                      })}
                                    </div>
                                  )}
                                  {room.single_bed > 0 && (
                                    <div>
                                      {`${room.single_bed} `}{" "}
                                      {intl.formatMessage({
                                        defaultMessage: "singoli",
                                        id: "/nLt3I",
                                      })}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="flex flex-row gap-x-2">
                                <div className="font-semibold">
                                  {intl.formatMessage({
                                    defaultMessage: "Superficie:",
                                    id: "RolgT3",
                                  })}
                                </div>
                                <div>
                                  {room.size} m<sup>2</sup>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="flex flex-row justify-between px-4 py-2 border-t border-primary">
                          <div className="font-semibold">
                            {intl.formatMessage({
                              defaultMessage: "Prezzo a notte",
                              id: "GnhBay",
                            })}
                          </div>
                          <div>
                            {Math.ceil(
                              option.total_price / 100 +
                                (option.total_price * structureFee) / 10000
                            ).toFixed(0) + ",00"}{" "}
                            €
                          </div>
                        </div>
                        {selectedRoomOption === index && (
                          <div className="absolute flex items-center justify-center w-4 h-4 text-sm text-white rounded-full top-2 right-2 bg-primary">
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="flex items-center justify-center w-full text-lg font-bold text-gray-400 h-60">
                    {intl.formatMessage({
                      defaultMessage: "Nessuna opzione disponibile",
                      id: "o9fHbd",
                    })}
                  </div>
                )}
                <div className="flex p-2 mt-2 text-xs text-gray-400 border rounded-lg gap-x-2">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="mt-0.5 text-gray-400"
                  />
                  <div>
                    {intl.formatMessage({
                      defaultMessage: "Cancellazione",
                      id: "6Mrr/R",
                    })}{" "}
                    {structure.cancellation_policy === "flessibile"
                      ? intl.formatMessage({
                          defaultMessage: "gratuita fino a 1 settimana prima",
                          id: "JJSwPI",
                        })
                      : structure.cancellation_policy === "moderata"
                      ? intl.formatMessage({
                          defaultMessage: "gratuita fino a 15 giorni prima",
                          id: "Ypc1P9",
                        })
                      : intl.formatMessage({
                          defaultMessage: "gratuita fino a 1 mese prima",
                          id: "rUDGoN",
                        })}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex w-full font-bold text-gray-400 h-60">
                {intl.formatMessage({
                  defaultMessage:
                    "Il numero di ospiti deve essere maggiore di 0",
                  id: "deoVEb",
                })}
              </div>
            )}
          </div>
        )}
        {currentStep === 2 && !isPresent && (
          <div className="flex flex-col gap-y-4">
            <div className="mb-2 font-bold">
              {intl.formatMessage({
                defaultMessage: "Opzioni disponibili",
                id: "Efd7Qa",
              })}
            </div>
            {value.length > 1 && adults + children > 0 ? (
              <div className="flex flex-col mb-14 gap-y-2">
                {availableOptions.length > 0 && !loading ? (
                  <>
                    {availableOptions.map((option, index) => (
                      <div
                        className={
                          `relative flex flex-col border border-primary rounded-[25px] text-primary cursor-pointer` +
                          (selectedRoomOption === index ? " ring" : "")
                        }
                        key={option.rooms[0].room_id}
                        onClick={() => setselectedRoomOption(index)}
                      >
                        {option.rooms.map((room) => (
                          <div className="flex flex-row w-full">
                            <div className="w-1/3 border-r border-primary">
                              <div className="p-4 font-bold">
                                {room.room_name}
                              </div>
                            </div>
                            <div className="flex flex-col w-2/3 p-4 text-sm gap-y-2">
                              <div className="flex flex-row gap-x-2">
                                <div className="font-semibold">
                                  {intl.formatMessage({
                                    defaultMessage: "Letti:",
                                    id: "r2OGhC",
                                  })}
                                </div>
                                <div className="flex flex-col">
                                  {room.double_bed > 0 && (
                                    <div>
                                      {`${room.double_bed} `}{" "}
                                      {intl.formatMessage({
                                        defaultMessage: "matrimoniali",
                                        id: "c7YzvK",
                                      })}
                                    </div>
                                  )}
                                  {room.single_bed > 0 && (
                                    <div>
                                      {`${room.single_bed} `}{" "}
                                      {intl.formatMessage({
                                        defaultMessage: "singoli",
                                        id: "/nLt3I",
                                      })}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="flex flex-row gap-x-2">
                                <div className="font-semibold">
                                  {intl.formatMessage({
                                    defaultMessage: "Superficie:",
                                    id: "RolgT3",
                                  })}
                                </div>
                                <div>
                                  {room.size} m<sup>2</sup>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="flex flex-row justify-between px-4 py-2 border-t border-primary">
                          <div className="font-semibold">
                            {intl.formatMessage({
                              defaultMessage: "Prezzo a notte",
                              id: "GnhBay",
                            })}
                          </div>
                          <div>
                            {Math.ceil(
                              option.total_price / 100 +
                                (option.total_price * structureFee) / 10000
                            ).toFixed(0) + ",00"}{" "}
                            €
                          </div>
                        </div>
                        {selectedRoomOption === index && (
                          <div className="absolute flex items-center justify-center w-4 h-4 text-sm text-white rounded-full top-2 right-2 bg-primary">
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {loading ? (
                      <div className="flex items-center justify-center w-full text-lg font-bold text-gray-400 h-60">
                        <UiduSpinner size="medium" className="text-primary" />
                      </div>
                    ) : (
                      <div className="flex items-center justify-center w-full text-lg font-bold text-gray-400 h-60">
                        {intl.formatMessage({
                          defaultMessage: "Nessuna opzione disponibile",
                          id: "o9fHbd",
                        })}
                      </div>
                    )}
                  </>
                )}
                <div className="flex p-2 mt-2 text-xs text-gray-400 border rounded-lg gap-x-2">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="mt-0.5 text-gray-400"
                  />
                  <div>
                    {intl.formatMessage({
                      defaultMessage: "Cancellazione",
                      id: "6Mrr/R",
                    })}{" "}
                    {structure.cancellation_policy === "flessibile"
                      ? intl.formatMessage({
                          defaultMessage: "gratuita fino a 1 settimana prima",
                          id: "JJSwPI",
                        })
                      : structure.cancellation_policy === "moderata"
                      ? intl.formatMessage({
                          defaultMessage: "gratuita fino a 15 giorni prima",
                          id: "Ypc1P9",
                        })
                      : intl.formatMessage({
                          defaultMessage: "gratuita fino a 1 mese prima",
                          id: "rUDGoN",
                        })}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex w-full font-bold text-gray-400 h-60">
                {intl.formatMessage({
                  defaultMessage:
                    "Seleziona un range di date prima di procedere",
                  id: "gWgIx3",
                })}
              </div>
            )}
          </div>
        )}
        {currentStep === 3 && structure.meals.length > 0 && (
          <div className="flex flex-col gap-y-4">
            <div className="mb-2 font-bold">
              {intl.formatMessage({
                defaultMessage: "Opzioni disponibili",
                id: "Efd7Qa",
              })}
            </div>
            {value.length > 1 &&
            adults + children > 0 &&
            selectedRoomOption !== null &&
            !isPresent ? (
              <SelectMeal
                structure={structure}
                selectedMeals={selectedMeals}
                setSelectedMeals={setSelectedMeals}
              />
            ) : isPresent &&
              selectedRoomOption !== null &&
              adults + children > 0 ? (
              <SelectMeal
                structure={structure}
                selectedMeals={selectedMeals}
                setSelectedMeals={setSelectedMeals}
              />
            ) : (
              <div className="flex w-full font-bold text-gray-400 h-60">
                {intl.formatMessage({
                  defaultMessage: "Seleziona una camera prima di procedere",
                  id: "imZT/2",
                })}
              </div>
            )}
          </div>
        )}
        {currentStep === 3 && structure.meals.length === 0 && (
          <Riepilogo
            availableOptions={availableOptions}
            element={structure}
            selectedRoomOption={selectedRoomOption}
            selectedMeals={selectedMeals}
            meals={structure.meals}
            value={value}
            adults={adults}
            children={children}
            isPresent={isPresent}
            nights={nights}
            structureFee={structureFee}
          />
        )}
        {currentStep === 4 && (
          <Riepilogo
            availableCombinationsIfPresent={availableCombinationsIfPresent}
            availableOptions={availableOptions}
            element={structure}
            selectedRoomOption={selectedRoomOption}
            selectedMeals={selectedMeals}
            meals={structure.meals}
            value={value}
            adults={adults}
            children={children}
            isPresent={isPresent}
            nights={nights}
            structureFee={structureFee}
          />
        )}
        <div className="absolute bottom-0 flex w-full gap-x-2">
          <button
            className="flex items-center justify-center px-5 py-3 border rounded-full w-fit text-primary border-primary gap-x-2 md:hidden"
            onClick={() => {
              if (currentStep === 0) {
                setOpenCartMobile(false);
              } else {
                setCurrentStep(currentStep - 1);
              }
            }}
          >
            <FontAwesomeIcon
              icon={currentStep === 0 ? faXmark : faChevronLeft}
            />
          </button>
          <button
            className="flex items-center justify-center w-full py-3 border rounded-full text-primary border-primary"
            onClick={() => {
              if (currentStep === steps.length - 1) {
                handleBook();
              } else {
                setCurrentStep(currentStep + 1);
              }
            }}
            style={
              currentStep === steps.length - 1 && selectedRoomOption === null
                ? {
                    border: "1px solid rgb(229 231 235)",
                    color: "rgb(229 231 235)",
                    cursor: "not-allowed",
                  }
                : {}
            }
          >
            <p className="font-semibold">
              {currentStep === steps.length - 1
                ? intl.formatMessage({
                    defaultMessage: "Prenota",
                    id: "dsKjB5",
                  })
                : intl.formatMessage({
                    defaultMessage: "Continua",
                    id: "kiTH9a",
                  })}
            </p>
            {currentStep !== steps.length - 1 && (
              <FontAwesomeIcon icon={faChevronRight} className="mt-1 ml-4" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
