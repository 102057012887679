import Modal, {
  ModalBody,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { SwiperSlide } from "swiper/react";
import Carousel from "./Carousel";
import SingleExperience from "./SingleExperience";
import SingleLoad from "./SingleLoad";
import SingleStructure from "./SingleStructure";

export default function CardUser({ user }) {
  const [dataExperiences, setDataExperiences] = useState([]);
  const [dataStructures, setDataStructures] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [slidesPerView, setSlidesPerView] = useState(4);
  const [showActive, setShowActive] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const intl = useIntl();
  console.log("user", user);

  const userStructuresAndExperiences = async () => {
    if (!user._id) return;
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/user/${user._id}/experiencesAndStructures`
      )
      .then((res) => {
        console.log(res.data, "res exp and str");
        setDataExperiences(res.data.experiences);
        setDataStructures(res.data.structures);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const groupedData = dataExperiences
    .filter((experience) => {
      const afterToday =
        experience.is_recurrent ||
        new Date(experience.data_info.start_date) > new Date();
      return showActive ? afterToday : !afterToday;
    })
    .reduce((acc, curr, i) => {
      const group = Math.floor(i / slidesPerView);
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(curr);
      return acc;
    }, []);

  const groupedDataStructure = dataStructures.reduce((acc, curr, i) => {
    const group = Math.floor(i / slidesPerView);
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(curr);
    return acc;
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSlidesPerView(1); // Per dispositivi mobili
      } else if (width >= 768 && width < 1024) {
        setSlidesPerView(2); // Per tablet
      } else {
        setSlidesPerView(4); // Per desktop
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Chiama immediatamente per impostare il valore iniziale

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    userStructuresAndExperiences();
  }, []);

  return (
    <>
      <div
        className="flex flex-col p-4 bg-gray-200 border rounded-lg shadow-lg cursor-pointer"
        onClick={() => setOpenModal(true)}
      >
        <div className="flex flex-row items-center">
          <img
            src={
              user.profile_pic
                ? user.profile_pic
                : "https://www.gravatar.com/avatar/?d=mp&s=200"
            }
            alt="user"
            className="object-cover w-12 h-12 rounded-full"
          />
          <div className="flex flex-col ml-4">
            <div className="font-semibold">
              {user?.profile_name ? user?.profile_name : user?.name}
            </div>
            <div className="flex items-center gap-x-2">
              {user?.title && (
                <div className="text-xs text-gray-500">{user.title}</div>
              )}
              <div className="text-xs underline text-primary">
                {intl.formatMessage({
                  defaultMessage: "Scopri di più",
                  id: "onwiq0",
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-4 line-clamp-3">{user.bio}</div>
      </div>
      {openModal && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
          <ModalTransition>
            <Modal
              onClose={() => setOpenModal(false)}
              width="calc(85vw - 2rem)"
              height="calc(100vh - 2rem)"
              className="[--modal-dialog-gutter:1rem] [--modal-dialog-translate-y:0] md:mt-4 shadow-lg"
            >
              <ModalHeader className="border-b border-gray-100 !px-4 md:!px-8">
                <div className="flex flex-col md:items-center gap-x-6 md:flex-row">
                  <h3 className="text-lg font-bold md:text-2xl text-primary">
                    {intl.formatMessage({
                      defaultMessage: "Dettagli partner",
                      id: "oO5ym9",
                    })}
                  </h3>
                </div>
                <div className="flex items-center gap-x-3 md:gap-x-6">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenModal(false);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </ModalHeader>
              <ModalBody className="!px-4 md:!px-8 my-4 md:my-8 flex flex-col gap-y-8">
                <div className="flex flex-col items-center gap-12 md:flex-row md:items-start">
                  <img
                    src={
                      user.profile_pic
                        ? user.profile_pic
                        : "https://www.gravatar.com/avatar/?d=mp&s=200"
                    }
                    alt="user"
                    className="object-cover w-64 h-64 rounded-full"
                  />
                  <div className="flex flex-col items-center w-full h-full gap-y-4 md:items-start">
                    <div className="text-2xl font-semibold text-primary">
                      {user?.profile_name ? user?.profile_name : user?.name}
                    </div>
                    <div className="flex flex-col w-full h-full p-4 text-lg text-white rounded-lg gap-y-1 bg-primary">
                      <div className="font-semibold">
                        {intl.formatMessage({
                          defaultMessage: "Bio",
                          id: "2W0f9h",
                        })}
                      </div>
                      <div className="line-clamp-3">{user.bio}</div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-y-4">
                  {user.has_experiences && (
                    <>
                      <div className="flex flex-col gap-4 md:flex-row md:items-center">
                        <p className="text-2xl font-semibold text-primary">
                          {intl.formatMessage({
                            defaultMessage: "Le sue esperienze",
                            id: "QglBzX",
                          })}
                        </p>
                        <div className="flex items-center gap-x-1">
                          <button
                            onClick={() => setShowActive(true)}
                            className={`${
                              showActive
                                ? "bg-primary text-white"
                                : "bg-gray-200 text-gray-500"
                            } px-4 py-2 rounded-t-[15px]`}
                          >
                            {intl.formatMessage({
                              defaultMessage: "Attive",
                              id: "nBWJll",
                            })}
                          </button>
                          <button
                            onClick={() => setShowActive(false)}
                            className={`${
                              !showActive
                                ? "bg-primary text-white"
                                : "bg-gray-200 text-gray-500"
                            } px-4 py-2 rounded-t-[15px]`}
                          >
                            {intl.formatMessage({
                              defaultMessage: "Passate",
                              id: "PvqFWD",
                            })}
                          </button>
                        </div>
                      </div>
                      <Carousel slidesPerView={slidesPerView}>
                        {!isLoading ? (
                          groupedData.map((group, i) => (
                            <SwiperSlide key={i}>
                              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4">
                                <>
                                  {group.map((item) => (
                                    <SingleExperience
                                      item={item}
                                      key={item.id}
                                      onClick={() => setOpenModal(false)}
                                    />
                                  ))}
                                </>
                              </div>
                            </SwiperSlide>
                          ))
                        ) : (
                          <SwiperSlide>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4">
                              <SingleLoad length={slidesPerView} />
                            </div>
                          </SwiperSlide>
                        )}
                      </Carousel>
                    </>
                  )}
                  {user.has_structures && (
                    <>
                      <p className="text-2xl font-semibold text-primary">
                        {intl.formatMessage({
                          defaultMessage: "Le sue strutture",
                          id: "KKK5Ux",
                        })}
                      </p>
                      <Carousel slidesPerView={slidesPerView}>
                        {!isLoading ? (
                          groupedDataStructure.map((group, i) => (
                            <SwiperSlide key={i}>
                              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4">
                                <>
                                  {group.map((item) => (
                                    <SingleStructure
                                      key={item._id}
                                      item={item}
                                      onClick={() => setOpenModal(false)}
                                    />
                                  ))}
                                </>
                              </div>
                            </SwiperSlide>
                          ))
                        ) : (
                          <SwiperSlide>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4">
                              <SingleLoad length={slidesPerView} />
                            </div>
                          </SwiperSlide>
                        )}
                      </Carousel>
                    </>
                  )}
                </div>
              </ModalBody>
            </Modal>
          </ModalTransition>
        </div>
      )}
    </>
  );
}
