import axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Bounce, ToastContainer } from "react-toastify";
import { SwiperSlide } from "swiper/react";
import Carousel from "./Carousel";
import SingleExperience from "./SingleExperience";
import SingleLoad from "./SingleLoad";

export default function CarouselExperiences({ selectedTab }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [slidesPerView, setSlidesPerView] = useState(5);
  const [showCarousel, setShowCarousel] = useState(true);
  const intl = useIntl();

  const experienceOfCategory = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/experiencesApprovedOfCategoryLimited`,
        {
          category: selectedTab,
          limit: 20,
        }
      )
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
        setShowCarousel(res.data.length > 0);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const groupedData = data.reduce((acc, curr, i) => {
    const group = Math.floor(i / slidesPerView);
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(curr);
    return acc;
  }, []);

  useEffect(() => {
    setIsLoading(true);
    experienceOfCategory();
  }, [selectedTab]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSlidesPerView(1); // Per dispositivi mobili
      } else if (width >= 768 && width < 1024) {
        setSlidesPerView(2); // Per tablet
      } else if (width >= 1024 && width < 1280) {
        setSlidesPerView(4); // Per desktop
      } else {
        setSlidesPerView(5); // Per desktop
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Chiama immediatamente per impostare il valore iniziale

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        {showCarousel ? (
          <Carousel slidesPerView={slidesPerView}>
            {!isLoading ? (
              groupedData.map((group, i) => (
                <SwiperSlide key={i}>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-x-4">
                    <>
                      {group.map((item) => (
                        <SingleExperience item={item} key={item.id} />
                      ))}
                    </>
                  </div>
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-x-4">
                  <SingleLoad length={slidesPerView} />
                </div>
              </SwiperSlide>
            )}
          </Carousel>
        ) : (
          <div className="mx-4 px-4 md:px-0 md:mx-0 text-2xl text-center text-gray-500 h-80 bg-gray-100 rounded-[30px] flex items-center justify-center">
            {intl.formatMessage({
              defaultMessage: "Nessun Esperienza disponibile al momento",
              id: "W7NuVY",
            })}
          </div>
        )}
      </Suspense>
      <div className="fixed z-50">
        <ToastContainer
          containerId={`SingleExperienceContainer`}
          position="bottom-left"
          autoClose={2000}
          hideProgressBar
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="light"
          transition={Bounce}
          stacked
        />
      </div>
    </>
  );
}
