import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import elimina from "../assets/icons/ELIMINA.svg";
import geotag from "../assets/icons/GEOTAG.svg";
import info from "../assets/icons/INFO.svg";
import messaggi from "../assets/icons/MESSAGGI.svg";
import stella from "../assets/icons/STELLA.svg";
import voucher from "../assets/icons/VOUCHER.svg";
import ModalBookingDetail from "./ModalBookingDetail";
import PopUp from "./PopUp";
import StructureIcon from "./StructureIcon";

export default function StructureBookingSummaryCard({ booking, isMobile }) {
  const structure = booking.structure_id;
  const [openDetailBooking, setOpenDetailBooking] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const intl = useIntl();

  const room = booking.room_id;
  const averageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  const PopUpBooking = () => {
    return (
      <PopUp
        icon={
          isMobile ? (
            <div className="flex items-center justify-center w-full h-full rounded-full bg-white/90">
              <FontAwesomeIcon
                icon={faEllipsisH}
                className="text-xs text-primary"
              />
            </div>
          ) : (
            <FontAwesomeIcon
              icon={faEllipsisH}
              className="text-xs text-white"
            />
          )
        }
        iconStyle={isMobile ? "bordered" : "default"}
        orientation={isMobile ? "vertical" : "horizontal"}
        length={3}
        items={
          <>
            <button
              className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-t-[30px] cursor-pointer text-primary hover:bg-gray-50"
              onClick={() => setOpenDetailBooking(true)}
            >
              <img src={info} alt="dettagli" className="w-6 h-6 pr-2" />
              <div>
                {intl.formatMessage({
                  defaultMessage: "Vedi dettagli",
                  id: "F7EclG",
                })}
              </div>
            </button>
            <div className="mx-4 border-b" />
            <Link
              className="flex items-center w-full h-8 px-6 py-5 text-sm cursor-pointer text-primary hover:bg-gray-50"
              to={`#`}
            >
              <img src={messaggi} alt="messaggio" className="w-6 h-6 pr-2" />
              <div>
                {intl.formatMessage({
                  defaultMessage: "Scrivi messaggio all'host",
                  id: "qyan0n",
                })}
              </div>
            </Link>
            <div className="mx-4 border-b" />
            <button
              onClick={() => setOpenDelete(true)}
              type="button"
              className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-b-[30px] cursor-pointer text-primary hover:bg-gray-50"
            >
              <img src={elimina} alt="elimina" className="w-[22px] pr-2" />
              <div>
                {intl.formatMessage({
                  defaultMessage: "Annulla prenotazione",
                  id: "6ZhQKV",
                })}
              </div>
            </button>
          </>
        }
      />
    );
  };

  return (
    <div className="relative flex flex-col w-full border rounded-[30px] shadow-lg md:flex-row md:h-72">
      <div className="relative w-full -mt-px overflow-hidden rounded-[30px] md:w-5/12 h-60 md:h-72">
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
          loop={true}
        >
          {structure.images.length > 0 &&
            structure.images.map((image) => (
              <SwiperSlide key={image}>
                <img
                  src={image}
                  alt="room"
                  className="object-cover w-full rounded-lg h-60 md:h-72"
                />
              </SwiperSlide>
            ))}
        </Swiper>
        <div className="absolute z-10 md:hidden top-2 right-2">
          <PopUpBooking />
        </div>
      </div>
      <div className="flex flex-col justify-between w-full p-4 text-sm md:ml-4 md:w-7/12 gap-y-2">
        <div className="flex items-center justify-between w-full">
          <div className="flex flex-col gap-y-2">
            <div className="flex items-center justify-between w-full">
              <Link
                className="flex items-center w-full gap-x-4"
                to={`/structures/${structure._id}`}
              >
                <div className="flex items-center justify-center w-6 h-6 rounded-full ">
                  {booking.start_date ? (
                    <StructureIcon kind={structure.kind} color="black" />
                  ) : (
                    <img src={voucher} alt="is a present" className="w-6 h-6" />
                  )}
                </div>
                <div className="font-bold">{structure.name}</div>
              </Link>
            </div>
            <div className="flex items-center w-full mt-1 gap-x-4">
              <div className="flex items-center mr-12 gap-x-2">
                <img src={geotag} alt="geotag" className="w-4" />
                <div className="text-center">
                  {structure.address
                    ? structure.address.municipality
                      ? structure.address.municipality
                      : structure.address.village
                    : "Pertica Bassa"}
                </div>
              </div>
              {structure.approved_at && (
                <div className="flex items-center gap-x-1.5">
                  <img src={stella} alt="stella" className="w-4" />
                  <div>
                    {structure.reviews
                      ? averageReviews(structure.reviews)
                      : "0"}
                    /5
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="hidden md:block">
            <PopUpBooking />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-2 md:gap-4 md:grid-cols-2">
          {booking.start_date ? (
            <div className="flex w-full md:h-8 ">
              <div className="font-semibold">
                {intl.formatMessage({
                  defaultMessage: "Periodo:",
                  id: "/ZeWkR",
                })}
              </div>
              <div className="flex ml-4 md:flex-col text-primary">
                <div>
                  {intl.formatMessage({
                    defaultMessage: "Dal",
                    id: "1dw/jT",
                  })}{" "}
                  &nbsp;
                  {dayjs(booking.start_date).format("DD/MM/YY")}
                  {"  "} &nbsp;
                </div>
                <div>
                  {intl.formatMessage({
                    defaultMessage: "al",
                    id: "sQ/NTw",
                  })}
                  {"  "}&nbsp;
                  {dayjs(booking.end_date).format("DD/MM/YY")}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex items-start w-full col-span-2 md:h-8 gap-x-6 ">
              <div className="font-semibold">
                {intl.formatMessage({
                  defaultMessage: "Il tuo messaggio:",
                  id: "tc5D15",
                })}
              </div>
              <div className="ml-4 line-clamp-2 text-primary">
                {booking.message}
              </div>
            </div>
          )}
          {booking.start_date && (
            <div className="flex w-full md:h-8 ">
              <div className="font-semibold">
                {intl.formatMessage({
                  defaultMessage: "N° di persone:",
                  id: "iR/Jy5",
                })}
              </div>
              <div className="flex ml-4 md:flex-col text-primary">
                {booking.guests_number.adults ? (
                  <div>
                    {booking.guests_number.adults}{" "}
                    {intl.formatMessage({
                      defaultMessage: "adulti",
                      id: "HR89WG",
                    })}
                  </div>
                ) : null}

                {booking.guests_number.children ? (
                  <>
                    <span className="md:hidden">&nbsp; - </span>
                    <div>
                      {booking.guests_number.children}{" "}
                      {intl.formatMessage({
                        defaultMessage: "bambini",
                        id: "ImjG1h",
                      })}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 gap-2 md:gap-4 md:grid-cols-2">
          <div className="flex w-full md:h-8 ">
            <div className="font-semibold">
              {intl.formatMessage({
                defaultMessage: "Camera :",
                id: "j1gFrd",
              })}
            </div>
            <div className="flex flex-col ml-4 text-primary">
              <div>1 x {room.name}</div>
            </div>
          </div>
          {booking.meals.length > 0 && (
            <div className="flex w-full md:h-8 ">
              <div className="font-semibold">
                {intl.formatMessage({
                  defaultMessage: "Pasti:",
                  id: "SD9Wub",
                })}
              </div>
              <div className="flex flex-col ml-4 text-primary">
                {booking.meals.map((meal) => (
                  <div>{meal}</div>
                ))}
              </div>
            </div>
          )}
          {!booking.start_date && booking.message && (
            <div className="flex w-full h-8 ">
              <div className="font-semibold">
                {intl.formatMessage({
                  defaultMessage: "N° di persone:",
                  id: "iR/Jy5",
                })}
              </div>
              <div className="flex flex-col ml-4 text-primary">
                {booking.guests_number.adults ? (
                  <div>
                    {booking.guests_number.adults}{" "}
                    {intl.formatMessage({
                      defaultMessage: "adulti",
                      id: "HR89WG",
                    })}
                  </div>
                ) : null}
                {booking.guests_number.children ? (
                  <div>
                    {booking.guests_number.children}{" "}
                    {intl.formatMessage({
                      defaultMessage: "bambini",
                      id: "ImjG1h",
                    })}
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="mb-1 text-lg font-bold text-gray-600">
            {intl.formatMessage({
              defaultMessage: "Stato:",
              id: "ds3/7Z",
            })}
          </div>
        </div>
        <div className="flex items-center justify-between w-full -mt-4">
          <div className="flex items-center gap-x-2">
            {booking.status === "pending" ? (
              <div className="w-2 h-2 bg-yellow-500 rounded-full md:w-4 md:h-4"></div>
            ) : (
              <div className="w-2 h-2 bg-green-500 rounded-full md:w-4 md:h-4"></div>
            )}
            <div
              className={`text-lg md:text-2xl font-bold ${
                booking.status === "pending"
                  ? "text-yellow-500"
                  : "text-green-500"
              }`}
            >
              {booking.status === "pending"
                ? intl.formatMessage({
                    defaultMessage: "In attesa",
                    id: "ZrWE4k",
                  })
                : intl.formatMessage({
                    defaultMessage: "Confermato",
                    id: "QCqewK",
                  })}
            </div>
          </div>
          <button
            className="flex items-center px-6 py-2 font-bold text-white rounded-lg bg-primary hover:bg-secondary"
            onClick={() => setOpenDetailBooking(true)}
          >
            {intl.formatMessage({
              defaultMessage: "Vedi dettagli",
              id: "F7EclG",
            })}
          </button>
        </div>
      </div>
      {openDetailBooking && (
        <ModalBookingDetail
          openModal={openDetailBooking}
          setOpenModal={setOpenDetailBooking}
          booking={booking}
          entity={structure}
          kind={"structure"}
        />
      )}
    </div>
  );
}
