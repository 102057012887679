import { useIntl } from "react-intl";

export const useGeneralServices = () => {
  const intl = useIntl();

  const generalServices = [
    {
      id: 1,
      name: intl.formatMessage({
        defaultMessage: "Wi-Fi",
        id: "c8uKl6",
      }),
    },
    {
      id: 2,
      name: intl.formatMessage({
        defaultMessage: "Parcheggio",
        id: "zk3YzL",
      }),
    },
    {
      id: 3,
      name: intl.formatMessage({
        defaultMessage: "Piscina",
        id: "p7WBYT",
      }),
    },
    {
      id: 4,
      name: intl.formatMessage({
        defaultMessage: "Aria condizionata",
        id: "iFvGbD",
      }),
    },
    {
      id: 5,
      name: intl.formatMessage({
        defaultMessage: "Riscaldamento",
        id: "yLVWwc",
      }),
    },
    {
      id: 6,
      name: intl.formatMessage({
        defaultMessage: "Animali ammessi",
        id: "n/vUig",
      }),
    },
    {
      id: 7,
      name: intl.formatMessage({
        defaultMessage: "Servizio in camera",
        id: "LEKLdP",
      }),
    },
    {
      id: 8,
      name: intl.formatMessage({
        defaultMessage: "Servizio lavanderia",
        id: "Z/mCsH",
      }),
    },
    {
      id: 9,
      name: intl.formatMessage({
        defaultMessage: "Servizio stireria",
        id: "MwWDOA",
      }),
    },
    {
      id: 10,
      name: intl.formatMessage({
        defaultMessage: "Servizio navetta",
        id: "+zZor8",
      }),
    },
    {
      id: 11,
      name: intl.formatMessage({
        defaultMessage: "Servizio baby-sitter",
        id: "U75Fvt",
      }),
    },
    {
      id: 12,
      name: intl.formatMessage({
        defaultMessage: "Servizio lavaggio a secco",
        id: "qIgxxQ",
      }),
    },
    {
      id: 13,
      name: intl.formatMessage({
        defaultMessage: "Area ristorante",
        id: "ExMQJb",
      }),
    },
    {
      id: 14,
      name: intl.formatMessage({
        defaultMessage: "Palestra",
        id: "eLOdqK",
      }),
    },
    {
      id: 15,
      name: intl.formatMessage({
        defaultMessage: "Spa e centro benessere",
        id: "EOtZqw",
      }),
    },
    {
      id: 16,
      name: intl.formatMessage({
        defaultMessage: "Sale riunioni/banchetti",
        id: "m3iACo",
      }),
    },
    {
      id: 17,
      name: intl.formatMessage({
        defaultMessage: "Bar",
        id: "SHryPO",
      }),
    },
    {
      id: 17,
      name: intl.formatMessage({
        defaultMessage: "Servizio di check-in/check-out express",
        id: "f9GeUn",
      }),
    },
    {
      id: 19,
      name: intl.formatMessage({
        defaultMessage: "Servizio concierge",
        id: "Cy47jV",
      }),
    },
    {
      id: 20,
      name: intl.formatMessage({
        defaultMessage: "Servizio di pulizia giornaliero",
        id: "4JlZhm",
      }),
    },
    {
      id: 21,
      name: intl.formatMessage({
        defaultMessage: "Servizio sveglia",
        id: "HZKeA/",
      }),
    },
    {
      id: 22,
      name: intl.formatMessage({
        defaultMessage: "Deposito bagagli",
        id: "Pl06kd",
      }),
    },
    {
      id: 23,
      name: intl.formatMessage({
        defaultMessage: "Reception 24 ore su 24",
        id: "E/BYDc",
      }),
    },
    {
      id: 24,
      name: intl.formatMessage({
        defaultMessage: "Servizio di portineria",
        id: "i+rwdO",
      }),
    },
    {
      id: 25,
      name: intl.formatMessage({
        defaultMessage: "Servizio di noleggio auto",
        id: "kLMHEz",
      }),
    },
    {
      id: 26,
      name: intl.formatMessage({
        defaultMessage: "Servizio di trasferimento aeroportuale",
        id: "Hpo+2q",
      }),
    },
  ];

  const securityServices = [
    {
      id: 1,
      name: intl.formatMessage({
        defaultMessage: "Sorveglianza 24 ore su 24",
        id: "D1xO8+",
      }),
    },
    {
      id: 2,
      name: intl.formatMessage({
        defaultMessage: "Cassaforte",
        id: "gybQv4",
      }),
    },
    {
      id: 3,
      name: intl.formatMessage({
        defaultMessage: "Allarme antincendio",
        id: "mCA8FO",
      }),
    },
    {
      id: 4,
      name: intl.formatMessage({
        defaultMessage: "Allarme antifurto",
        id: "U5kqw6",
      }),
    },
    {
      id: 5,
      name: intl.formatMessage({
        defaultMessage: "Videosorveglianza nelle aree comuni",
        id: "PzGM9m",
      }),
    },
    {
      id: 6,
      name: intl.formatMessage({
        defaultMessage: "Accesso con chiave elettronica",
        id: "b9vGoN",
      }),
    },
    {
      id: 7,
      name: intl.formatMessage({
        defaultMessage: "Accesso con codice di sicurezza",
        id: "r2ksiz",
      }),
    },
    {
      id: 8,
      name: intl.formatMessage({
        defaultMessage: "Guardia di sicurezza",
        id: "X3Ib54",
      }),
    },
    {
      id: 9,
      name: intl.formatMessage({
        defaultMessage: "Porte di sicurezza",
        id: "XeXicK",
      }),
    },
    {
      id: 10,
      name: intl.formatMessage({
        defaultMessage: "Estintori",
        id: "cbcxGd",
      }),
    },
    {
      id: 11,
      name: intl.formatMessage({
        defaultMessage: "Rilevatori di fumo",
        id: "+2tlKK",
      }),
    },
    {
      id: 12,
      name: intl.formatMessage({
        defaultMessage: "Rilevatori di monossido di carbonio",
        id: "ECA4MS",
      }),
    },
    {
      id: 13,
      name: intl.formatMessage({
        defaultMessage: "Casseforti nelle camere",
        id: "7u+Bi+",
      }),
    },
    {
      id: 14,
      name: intl.formatMessage({
        defaultMessage: "Sicurezza alimentare",
        id: "7YOtIX",
      }),
    },
    {
      id: 15,
      name: intl.formatMessage({
        defaultMessage: "Primo soccorso disponibile",
        id: "q+ee9a",
      }),
    },
    {
      id: 16,
      name: intl.formatMessage({
        defaultMessage: "Sistema di allarme collegato alla reception",
        id: "4VA4mk",
      }),
    },
    {
      id: 17,
      name: intl.formatMessage({
        defaultMessage: "Formazione del personale sulla sicurezza",
        id: "ClPNYA",
      }),
    },
    {
      id: 18,
      name: intl.formatMessage({
        defaultMessage: "Parcheggio custodito",
        id: "xKTpp/",
      }),
    },
    {
      id: 19,
      name: intl.formatMessage({
        defaultMessage: "CCTV nelle aree comuni",
        id: "Lv3loB",
      }),
    },
    {
      id: 20,
      name: intl.formatMessage({
        defaultMessage:
          "Sistema di sicurezza con telecamere nelle aree esterne",
        id: "19FayE",
      }),
    },
  ];

  const comfortServices = [
    {
      id: 1,
      name: intl.formatMessage({
        defaultMessage: "Riscaldamento",
        id: "yLVWwc",
      }),
    },
    {
      id: 2,
      name: intl.formatMessage({
        defaultMessage: "Aria condizionata",
        id: "iFvGbD",
      }),
    },
    {
      id: 3,
      name: intl.formatMessage({
        defaultMessage: "Camino",
        id: "mCQvKk",
      }),
    },
    {
      id: 4,
      name: intl.formatMessage({
        defaultMessage: "Vasca idromassaggio",
        id: "/ghqP6",
      }),
    },
    {
      id: 5,
      name: intl.formatMessage({
        defaultMessage: "Sauna",
        id: "9VMkLk",
      }),
    },
    {
      id: 6,
      name: intl.formatMessage({
        defaultMessage: "Piscina interna",
        id: "VzkT6k",
      }),
    },
    {
      id: 7,
      name: intl.formatMessage({
        defaultMessage: "Piscina esterna",
        id: "Jiw+nG",
      }),
    },
    {
      id: 8,
      name: intl.formatMessage({
        defaultMessage: "Spa",
        id: "kUDcH0",
      }),
    },
    {
      id: 9,
      name: intl.formatMessage({
        defaultMessage: "Palestra",
        id: "eLOdqK",
      }),
    },
    {
      id: 10,
      name: intl.formatMessage({
        defaultMessage: "Servizio in camera",
        id: "LEKLdP",
      }),
    },
    {
      id: 11,
      name: intl.formatMessage({
        defaultMessage: "Mini bar",
        id: "qOgbOv",
      }),
    },
    {
      id: 12,
      name: intl.formatMessage({
        defaultMessage: "TV a schermo piatto",
        id: "eJmDgB",
      }),
    },
    {
      id: 13,
      name: intl.formatMessage({
        defaultMessage: "WiFi gratuito",
        id: "w4PeJa",
      }),
    },
    {
      id: 14,
      name: intl.formatMessage({
        defaultMessage: "Bagno privato",
        id: "m4NpPO",
      }),
    },
    {
      id: 15,
      name: intl.formatMessage({
        defaultMessage: "Balcone",
        id: "Zo2D97",
      }),
    },
    {
      id: 16,
      name: intl.formatMessage({
        defaultMessage: "Vista panoramica",
        id: "7pD1Lk",
      }),
    },
    {
      id: 17,
      name: intl.formatMessage({
        defaultMessage: "Terrazza",
        id: "VMh3Ep",
      }),
    },
    {
      id: 18,
      name: intl.formatMessage({
        defaultMessage: "Giardino",
        id: "rI3BgW",
      }),
    },
    {
      id: 19,
      name: intl.formatMessage({
        defaultMessage: "Cucina",
        id: "O+DLp7",
      }),
    },
    {
      id: 20,
      name: intl.formatMessage({
        defaultMessage: "Lavatrice",
        id: "yJpNBP",
      }),
    },
  ];

  const hospitalityServices = [
    {
      id: 1,
      name: intl.formatMessage({
        defaultMessage: "Bar",
        id: "SHryPO",
      }),
    },
    {
      id: 2,
      name: intl.formatMessage({
        defaultMessage: "Ristorante",
        id: "21gBZ1",
      }),
    },
    {
      id: 3,
      name: intl.formatMessage({
        defaultMessage: "Servizio in camera",
        id: "LEKLdP",
      }),
    },
    {
      id: 4,
      name: intl.formatMessage({
        defaultMessage: "Servizio lavanderia",
        id: "Z/mCsH",
      }),
    },
    {
      id: 5,
      name: intl.formatMessage({
        defaultMessage: "Servizio stireria",
        id: "MwWDOA",
      }),
    },
    {
      id: 6,
      name: intl.formatMessage({
        defaultMessage: "Servizio navetta",
        id: "+zZor8",
      }),
    },
    {
      id: 7,
      name: intl.formatMessage({
        defaultMessage: "Servizio baby-sitter",
        id: "U75Fvt",
      }),
    },
    {
      id: 8,
      name: intl.formatMessage({
        defaultMessage: "Servizio lavaggio a secco",
        id: "qIgxxQ",
      }),
    },
    {
      id: 9,
      name: intl.formatMessage({
        defaultMessage: "Servizio concierge",
        id: "Cy47jV",
      }),
    },
    {
      id: 10,
      name: intl.formatMessage({
        defaultMessage: "Servizio di parcheggio e riconsegna auto",
        id: "oojBlx",
      }),
    },
    {
      id: 11,
      name: intl.formatMessage({
        defaultMessage: "Servizio di pulizia giornaliero",
        id: "4JlZhm",
      }),
    },
    {
      id: 12,
      name: intl.formatMessage({
        defaultMessage: "Servizio di portineria",
        id: "i+rwdO",
      }),
    },
    {
      id: 13,
      name: intl.formatMessage({
        defaultMessage: "Servizio di prenotazione biglietti",
        id: "sCOsl5",
      }),
    },
    {
      id: 14,
      name: intl.formatMessage({
        defaultMessage: "Servizio di prenotazione tour",
        id: "GNwEbK",
      }),
    },
    {
      id: 15,
      name: intl.formatMessage({
        defaultMessage: "Servizio di risveglio",
        id: "5AhICi",
      }),
    },
    {
      id: 16,
      name: intl.formatMessage({
        defaultMessage: "Servizio di trasporto da e per l'aeroporto",
        id: "cUsIjc",
      }),
    },
    {
      id: 17,
      name: intl.formatMessage({
        defaultMessage: "Servizio di noleggio auto",
        id: "kLMHEz",
      }),
    },
    {
      id: 18,
      name: intl.formatMessage({
        defaultMessage: "Servizio di noleggio biciclette",
        id: "f1nx0g",
      }),
    },
    {
      id: 19,
      name: intl.formatMessage({
        defaultMessage: "Servizio di noleggio attrezzature sportive",
        id: "R54e47",
      }),
    },
    {
      id: 20,
      name: intl.formatMessage({
        defaultMessage: "Servizio di organizzazione eventi",
        id: "SRKTbr",
      }),
    },
  ];

  return {
    generalServices,
    securityServices,
    comfortServices,
    hospitalityServices,
  };
};
