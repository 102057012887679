import {
  faChevronCircleDown,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

export function SingleFaq({ item, index, isRecap }) {
  const [open, setOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const intl = useIntl();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="w-full "
      style={{
        paddingRight:
          windowWidth >= 768 ? (index % 2 === 0 ? "0" : "32px") : "0",
        paddingLeft:
          windowWidth >= 768 ? (index % 2 === 0 ? "32px" : "0") : "0",
      }}
    >
      <div
        className="flex flex-col items-start justify-between w-full px-8 py-3 mb-4 border cursor-pointer border-primary"
        onClick={() => {
          setOpen(!open);
        }}
        style={{
          borderRadius: open ? "30px" : "60px",
        }}
      >
        {isRecap ? (
          <div className="flex items-center justify-between w-full">
            <h1 className="font-bold text-escoBlack">
              {intl.formatMessage({
                defaultMessage: "Per tutto il resto",
                id: "sP5NEK",
              })}
            </h1>
            <FontAwesomeIcon
              icon={open ? faChevronCircleDown : faChevronCircleRight}
              className="text-2xl text-primary"
            />
          </div>
        ) : (
          <div className="flex items-center justify-between w-full">
            <h1 className="font-bold text-escoBlack">{item.title}</h1>
            <FontAwesomeIcon
              icon={open ? faChevronCircleDown : faChevronCircleRight}
              className="text-2xl text-primary"
            />
          </div>
        )}
        {open && (
          <p className="py-2 text-normal text-escoBlack">
            {isRecap ? (
              <FormattedMessage
                id="5POlrh"
                defaultMessage="Consulta le nostre {faqLink} o contattaci direttamente per ricevere assistenza."
                values={{
                  faqLink: (
                    <Link
                      to="/faq"
                      className="underline text-primary hover:text-secondary"
                    >
                      FAQ
                    </Link>
                  ),
                }}
              />
            ) : (
              item.description
            )}
          </p>
        )}
      </div>
    </div>
  );
}

export default function FaqComponent() {
  const intl = useIntl();
  const faqList = [
    {
      title: intl.formatMessage({
        defaultMessage: "Chi può registrarsi come partner su esco.zone?",
        id: "17+J36",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "Possono registrarsi tutte le attività che offrono esperienze autentiche o alloggi unici nelle piccole realtà locali. Siamo alla ricerca di operatori che valorizzano il territorio e offrono esperienze memorabili.",
        id: "9I3Js8",
      }),
    },
    {
      title: intl.formatMessage({
        defaultMessage:
          "Quali vantaggi offre esco.zone rispetto ad altre piattaforme?",
        id: "889Hvz",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "esco.zone è pensato per promuovere realtà locali e originali, senza focalizzarsi sulle attrazioni mainstream. Offriamo visibilità dedicata a chi desidera raggiungere un pubblico interessato a scoprire mete autentiche.",
        id: "93FB0M",
      }),
    },
    {
      title: intl.formatMessage({
        defaultMessage: "Quanto costa registrarsi come partner?",
        id: "bcdh92",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "La registrazione su esco.zone è gratuita. Applichiamo una commissione su ogni prenotazione completata, ma non ci sono costi iniziali o di abbonamento.",
        id: "ZNa7I4",
      }),
    },
    {
      title: intl.formatMessage({
        defaultMessage:
          "Come funziona il processo di approvazione degli annunci?",
        id: "7jaGYB",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "Ogni annuncio viene verificato dal nostro team per garantire che rispetti i nostri standard. La procedura di approvazione richiede fino a 48 ore e vi informeremo appena sarà pubblicato.",
        id: "WYQ0nG",
      }),
    },
    {
      title: intl.formatMessage({
        defaultMessage: "Posso pubblicare sia esperienze che alloggi?",
        id: "uQyljG",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "Sì, puoi pubblicare sia esperienze che alloggi se la tua attività li offre. Ciascuno sarà elencato separatamente per dare la giusta visibilità.",
        id: "K6EFt8",
      }),
    },
  ];

  return (
    <div className="flex md:items-center flex-col md:flex-row w-full bg-gray-100 min-h-96 rounded-[60px]">
      <div className="px-4 pt-8 text-center md:py-12 md:w-1/2 md:px-24 md:text-left">
        <h1 className="pb-4 text-3xl font-bold leading-tight uppercase md:text-7xl text-escoBlack">
          {intl.formatMessage({
            defaultMessage: "Ancora dubbi?",
            id: "y8KuBk",
          })}
        </h1>
        <button
          className="hidden px-24 py-4 mt-8 text-2xl text-white rounded-full md:text-3xl bg-escoBlack hover:bg-black md:block"
          onClick={() => {
            navigate("/contactUs");
          }}
        >
          {intl.formatMessage({
            defaultMessage: "Contattaci",
            id: "i/gk58",
          })}
        </button>
      </div>
      <div className="w-full p-4 md:p-8 md:w-1/2">
        {faqList.map((item, index) => (
          <SingleFaq key={index} item={item} index={index} />
        ))}
        <SingleFaq index={faqList.length} isRecap />
      </div>
      <button
        className="px-24 py-4 mx-4 mt-0 mb-8 text-xl text-white rounded-full md:text-3xl bg-escoBlack hover:bg-black md:hidden"
        onClick={() => {
          navigate("/contactUs");
        }}
      >
        <p className="text-xs">
          {intl.formatMessage({
            defaultMessage: "Per tutto il resto",
            id: "sP5NEK",
          })}
        </p>
        {intl.formatMessage({
          defaultMessage: "Contattaci",
          id: "i/gk58",
        })}
      </button>
    </div>
  );
}
