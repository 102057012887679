import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { FavouriteContext } from "../App";
import heart from "../assets/heart.svg";
import heartFull from "../assets/heartFull.svg";
import StructureBody from "./StructureBody";
import StructureIcon from "./StructureIcon";
import StructureSubtitle from "./structureComponents/StructureSubtitle";

export default function StructureModal({
  openModal,
  setOpenModal,
  structure,
  box,
}) {
  const { favourite, setFavourite } = useContext(FavouriteContext);
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [expandText, setExpandText] = useState(false);
  const intl = useIntl();

  const roomIncluded = box.structures.find(
    (s) => s.structure_id === structure._id
  ).room_id;

  const notify = (msg) =>
    toast.success(msg, {
      toastId: Math.random().toString(),
      position: "bottom-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: (
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="text-2xl text-primary"
        />
      ),
      containerId: `StructureModal${box._id}`,
    });

  const addToFavourites = async () => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    if (!user) {
      navigate("/login");
      return;
    } else {
      if (
        favourite &&
        favourite.length > 0 &&
        favourite[0].structures.find(
          (structureToFind) => structureToFind.structure_id === structure._id
        )
      ) {
        const newStructures = favourite[0].structures.filter(
          (structureToFind) => structureToFind.structure_id !== structure._id
        );
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              structures: newStructures,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            notify(
              intl.formatMessage({
                defaultMessage: "Struttura rimossa dai preferiti con successo",
                id: "Xs/YfX",
              })
            );
          });
      } else {
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              structures: [
                ...(favourite && favourite.length > 0
                  ? favourite[0].structures
                  : []),
                { structure_id: structure._id },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            notify(
              intl.formatMessage({
                defaultMessage: "Struttura aggiunta ai preferiti con successo",
                id: "tnbgWN",
              })
            );
          });
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ModalTransition>
      {openModal && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
          <Modal
            onClose={() => setOpenModal(false)}
            className="mt-0 md:mt-10 "
            width="x-large"
          >
            <ModalHeader className="-mt-2 md:mt-0 !px-4 md:!px-8 !items-start md:!items-center">
              <div className="flex items-start w-10/12 md:items-center gap-x-2 md:gap-x-4">
                <div className="mt-1 md:mt-0">
                  <StructureIcon
                    kind={structure.kind}
                    color="black"
                    dimension={6}
                  />
                </div>
                <h3 className="text-2xl">{structure.name}</h3>
                <button
                  className="items-center justify-center hidden bg-white rounded-full md:flex"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    addToFavourites();
                  }}
                >
                  {/* <img src={heart} alt="heart" className="w-5" /> */}
                  {favourite &&
                  favourite.length > 0 &&
                  favourite[0].structures.find(
                    (structureToFind) =>
                      structureToFind.structure_id === structure._id
                  ) ? (
                    <img src={heartFull} alt="heartFull" className="w-5" />
                  ) : (
                    <img src={heart} alt="heart" className="w-5" />
                  )}
                </button>
              </div>
              <button
                className="mt-1 md:mt-0"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenModal(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </ModalHeader>
            <ModalBody className="h-[calc(100vh-14rem)] !px-4 md:!px-8">
              <Swiper
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
                loop={true}
              >
                {structure.images.map((image) => (
                  <SwiperSlide key={image}>
                    <img
                      src={image}
                      alt=""
                      className="object-cover rounded-lg h-[calc(100vh-400px)] w-full"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="flex flex-col mt-6 gap-y-6">
                <StructureSubtitle structure={structure} />
                <div className={expandText ? "h-auto" : "line-clamp-3"}>
                  {structure.description}
                </div>
                <div className="relative flex justify-end -mt-4">
                  <button
                    className="text-primary"
                    onClick={() => setExpandText(!expandText)}
                  >
                    {" "}
                    {!expandText
                      ? intl.formatMessage({
                          defaultMessage: "Continua a leggere...",
                          id: "1WQaA3",
                        })
                      : intl.formatMessage({
                          defaultMessage: "Mostra meno",
                          id: "oXRP6k",
                        })}
                  </button>
                </div>
                <StructureBody
                  structure={structure}
                  rooms={structure.rooms.filter(
                    (room) => room._id === roomIncluded
                  )}
                  fromModal={true}
                />
              </div>
              <ToastContainer
                containerId={`StructureModal${box._id}`}
                position="bottom-left"
                autoClose={2000}
                hideProgressBar
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="light"
                transition={Bounce}
                stacked
              />
            </ModalBody>
            <ModalFooter />
          </Modal>
        </div>
      )}
    </ModalTransition>
  );
}
