import {
  faCheck,
  faChevronDown,
  faChevronUp,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "@ramonak/react-progress-bar";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../App";
import bio from "../assets/icons/BIO.svg";
import camera from "../assets/icons/CAMERA.svg";
import indietro from "../assets/icons/CHEVRON_LEFT.svg";
import condizioni from "../assets/icons/CONDIZIONI.svg";
import escobox from "../assets/icons/ESCOBOX.svg";
import info from "../assets/icons/INFO.svg";
import pasti from "../assets/icons/PASTI.svg";
import prenotazioni from "../assets/icons/PRENOTAZIONI.svg";
import struttura from "../assets/icons/STRUTTURA.svg";
import tipologia from "../assets/icons/TIPOLOGIA.svg";
import LoadingSpinnerPage from "../components/LoadingSpinnerPage";
import NavbarCreator from "../components/NavbarCreator";
import UserProfileCard from "../components/UserProfileCard";
import AddRoom from "../components/proposeStruscture/AddRoom";
import ApprovalType from "../components/proposeStruscture/ApprovalType";
import Biography from "../components/proposeStruscture/Biography";
import Conditions from "../components/proposeStruscture/Conditions";
import Escobox from "../components/proposeStruscture/Escobox";
import GeneralInfo from "../components/proposeStruscture/GeneralInfo";
import Legal from "../components/proposeStruscture/Legal";
import Meals from "../components/proposeStruscture/Meals";
import Rooms from "../components/proposeStruscture/Rooms";
import Services from "../components/proposeStruscture/Services";
import StructureLocation from "../components/proposeStruscture/StructrureLocation";
import StructureDetail from "../components/proposeStruscture/StructureDetail";
import StructureKind from "../components/proposeStruscture/StructureKind";
import StructureMedia from "../components/proposeStruscture/StructureMedia";

function SingleLink({ item, baseUrl }) {
  const { id } = useParams();
  const navigate = useNavigate();

  const currentPath = window.location.pathname.split("/").pop();
  const isSelected =
    item.title === "Biografia"
      ? currentPath === id
      : `/${id}/${currentPath}` === item.link ||
        (item.children &&
          item.children.some(
            (child) => child.link === `/${id}/${currentPath}`
          ));

  return (
    <>
      <button
        onClick={() => {
          if (item.active || item.children.length > 0) {
            if (item.link === "/bio") {
              navigate("/proposeStructure");
            } else if (item.link === "" || !item.children.length) {
              navigate(`${baseUrl}${item.link}`);
            } else {
              navigate(`${baseUrl}${item.children[0].link}`);
            }
          }
        }}
        className="flex items-center justify-between w-full h-10 px-4 py-2 gap-x-4 hover:bg-gray-200 rounded-[30px] border border-gray-100"
        style={{
          borderColor: isSelected ? "#246155" : "",
          opacity: item.active ? 1 : 0.5,
          cursor: item.active ? "pointer" : "not-allowed",
        }}
      >
        <div className="flex items-center gap-x-4">
          <img src={item.icon} alt={item.title} className="w-5 h-5 mr-4" />
          <p className="font-semibold">{item.title}</p>
        </div>
        {item.completed && item.active && (
          <FontAwesomeIcon icon={faCheck} className="text-green-500" />
        )}
        {item.children.length > 0 &&
          item.children.every((child) => child.completed) && (
            <FontAwesomeIcon icon={faCheck} className="text-green-500" />
          )}
      </button>
      {item.children.length > 0 && isSelected && (
        <div className="flex flex-col gap-y-0.5 w-full">
          {item.children.map((child, index) => (
            <button
              key={index}
              onClick={() => {
                if (child.active) {
                  navigate(`${baseUrl}${child.link}`);
                }
              }}
              className="pl-20 flex items-center justify-between w-full h-10 px-4 py-2 gap-x-4 hover:bg-gray-200 rounded-[30px] border border-gray-100"
              style={{
                backgroundColor:
                  child.link === `/${id}/${currentPath}`
                    ? "rgb(229 231 235) "
                    : "",
                borderColor:
                  child.link === `/${id}/${currentPath}`
                    ? "rgb(229 231 235) "
                    : "",
                opacity: child.active ? 1 : 0.5,
                cursor: child.active ? "pointer" : "not-allowed",
              }}
            >
              <p className="font-semibold">{child.title}</p>
              {child.completed && child.active && (
                <FontAwesomeIcon icon={faCheck} className="text-green-500" />
              )}
            </button>
          ))}
        </div>
      )}
    </>
  );
}

export const ProposeStructureContext = createContext();

export default function ProposeStructure() {
  const { tab, id } = useParams();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [userLogged, setUserLogged] = useState(null);
  const { user } = useContext(UserContext);
  const [userStructure, setUserStructure] = useState({});
  const [rooms, setRooms] = useState([]);
  const [structure, setStructure] = useState(null);
  const [errors, setErrors] = useState([]);
  const [openEditRequested, setOpenEditRequested] = useState(false);
  const [openModalPromotion, setOpenModalPromotion] = useState(false);

  async function Start() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/logged`)
        .then((res) => {
          setUserLogged(true);
        })
        .catch(() => {
          setUserLogged(false);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        });
    } else {
      setUserLogged(false);
    }
  }

  async function GetRooms() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/structure/${id}`)
        .then((res) => {
          setUserStructure(res.data.user);
          setStructure(res.data.structure);
          setRooms(res.data.rooms || []);
          setOpenModalPromotion(
            !res.data.structure.request_of_approval_made_at &&
              new Date() < new Date("2026-01-31")
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  useEffect(() => {
    if (userLogged) {
      GetRooms();
    }
  }, [userLogged]);

  useEffect(() => {
    Start();
  }, []);

  useEffect(() => {
    if (
      userLogged !== null &&
      structure &&
      (userStructure._id === user._id || user.is_admin)
    ) {
      setLoading(false);
    } else if (
      userLogged &&
      userStructure &&
      structure &&
      userStructure._id !== user._id
    ) {
      navigate(`/profile/${user._id}`, { replace: true });
    }
  }, [userLogged, structure]);

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  if (!userLogged) {
    navigate(`/login`, { replace: true });
  }

  const schema = [
    {
      title: "Biografia",
      label: "Parlaci di te",
      icon: bio,
      children: [],
      link: `/${id}`,
      component: <Biography />,
      completed: userStructure?.bio ? true : false,
      active: true,
    },
    {
      title: "Tipologia",
      label: "A quale mondo appartiene la tua struttura?",
      icon: tipologia,
      children: [],
      link: `/${id}/kind`,
      component: <StructureKind />,
      completed: structure.kind ? true : false,
      active: userStructure?.bio ? true : false,
    },
    {
      title: "Struttura",
      icon: struttura,
      children: [
        {
          title: "Nome",
          label: "Informazioni generali",
          link: `/${id}/name`,
          component: <GeneralInfo />,
          completed:
            structure.name && structure.stars && structure.phone_number
              ? true
              : false,
          active: structure.kind ? true : false,
        },
        {
          title: "Luogo",
          label: "Dove si trova la tua struttura? ",
          link: `/${id}/location`,
          component: <StructureLocation />,
          completed: structure.address ? true : false,
          active:
            structure.name && structure.stars && structure.phone_number
              ? true
              : false,
        },
        {
          title: "Descrizione",
          label: "Andiamo nel dettaglio",
          link: `/${id}/description`,
          component: <StructureDetail />,
          completed:
            structure.description && structure.lenguages ? true : false,
          active: structure.address ? true : false,
        },
        {
          title: "Foto/video",
          label: "Aggiungi le tue foto",
          link: `/${id}/media`,
          component: <StructureMedia />,
          completed: structure.images.length > 0 ? true : false,
          active: structure.description && structure.lenguages ? true : false,
        },
        {
          title: "Servizi generali",
          label: "Servizi struttura",
          link: `/${id}/services`,
          component: <Services />,
          completed: true,
          active: structure.images.length > 0 ? true : false,
        },
      ],
      active: structure.kind ? true : false,
    },
    {
      title: "Camera",
      icon: camera,
      children: [
        ...rooms.map((room, index) => ({
          title: `${room.name}`,
          label: `${room.name}`,
          link: `/${id}/room${room._id}`,
          component: (
            <Rooms
              setRooms={setRooms}
              rooms={rooms}
              room={room}
              index={index}
            />
          ),
          completed:
            room.name &&
            room.description &&
            room.quantity &&
            room.size &&
            room.single_bed !== undefined &&
            room.double_bed !== undefined &&
            room.price &&
            room.services
              ? true
              : false,
          active: structure.images.length > 0 ? true : false,
        })),
        {
          title: "Aggiungi camera",
          label: "La tua struttura comprende altre stanze?",
          link: `/${id}/addRoom`,
          component: <AddRoom />,
          completed: true,
          active: structure.images.length > 0 ? true : false,
        },
      ],
      active: structure.images.length > 0 ? true : false,
    },
    {
      title: "Pasti",
      label: "La struttura offre",
      icon: pasti,
      children: [],
      link: `/${id}/meals`,
      component: <Meals />,
      completed: structure.meals ? true : false,
      active:
        rooms[rooms.length - 1].name &&
        rooms[rooms.length - 1].description &&
        rooms[rooms.length - 1].quantity &&
        rooms[rooms.length - 1].size &&
        rooms[rooms.length - 1].single_bed !== undefined &&
        rooms[rooms.length - 1].double_bed !== undefined &&
        rooms[rooms.length - 1].price &&
        rooms[rooms.length - 1].services
          ? true
          : false,
    },
    {
      title: "Condizioni",
      label: "Condizioni",
      icon: condizioni,
      children: [],
      link: `/${id}/conditions`,
      component: <Conditions />,
      completed:
        structure.check_in_time &&
        structure.check_out_time &&
        structure.minimum_stay &&
        structure.cancellation_policy
          ? true
          : false,
      active:
        structure.meals &&
        rooms[rooms.length - 1].name &&
        rooms[rooms.length - 1].description &&
        rooms[rooms.length - 1].quantity &&
        rooms[rooms.length - 1].size &&
        rooms[rooms.length - 1].single_bed !== undefined &&
        rooms[rooms.length - 1].double_bed !== undefined &&
        rooms[rooms.length - 1].price &&
        rooms[rooms.length - 1].services
          ? true
          : false,
    },
    {
      title: "Escobox",
      label: "Aderisci ad Escobox",
      icon: escobox,
      link: `/${id}/escobox`,
      children: [],
      component: <Escobox />,
      completed: true,
      active:
        structure.check_in_time &&
        structure.check_out_time &&
        structure.minimum_stay &&
        structure.cancellation_policy
          ? true
          : false,
    },
    {
      title: "Approvazione",
      label: "Abilita l'approvazione automatica",
      icon: prenotazioni,
      link: `/${id}/approval`,
      children: [],
      component: <ApprovalType />,
      completed: true,
      active:
        structure.check_in_time &&
        structure.check_out_time &&
        structure.minimum_stay &&
        structure.cancellation_policy
          ? true
          : false,
    },
    {
      title: "Info legali",
      label: "Info legali",
      icon: info,
      children: [],
      link: `/${id}/legal`,
      component: <Legal />,
      completed:
        structure.license_number && structure.terms_accepted_at ? true : false,
      active:
        structure.check_in_time &&
        structure.check_out_time &&
        structure.minimum_stay &&
        structure.cancellation_policy
          ? true
          : false,
    },
  ];

  const schemaWithoutChildren = schema.flatMap((item) =>
    item.children.length > 0 ? item.children : item
  );

  const completion = () => {
    let index = 0;
    if (!tab) {
      return index;
    }
    for (let i = 0; i < schema.length; i++) {
      const item = schema[i];
      if (item.link === `/${id}/${tab}`) {
        return index;
      } else if (item.children) {
        if (
          item.children.findIndex((child) => child.link === `/${id}/${tab}`) !==
          -1
        ) {
          index += item.children.findIndex(
            (child) => child.link === `/${id}/${tab}`
          );
          return index;
        }
        if (!item.children.some((child) => child.link === `/${id}/${tab}`)) {
          index += item.children.length;
        }
      }
      if (item.children.length === 0) {
        index++;
      }
    }
    return index;
  };

  const previous = () => {
    const currentIndex = schema.findIndex(
      (item) =>
        item.link === `/${id}/${tab}` ||
        (item.children &&
          item.children.some((child) => child.link === `/${id}/${tab}`))
    );

    let previousLink;

    if (currentIndex > 0) {
      const previousItem = schema[currentIndex];
      if (previousItem.children) {
        previousLink = previousItem.children.findIndex(
          (child) => child.link === `/${id}/${tab}`
        );
        previousLink = previousItem.children[previousLink - 1]?.link;
      }
    }

    if (!previousLink) {
      previousLink = schema[currentIndex - 1]?.link
        ? schema[currentIndex - 1]?.link
        : schema[currentIndex - 1]?.children[
            schema[currentIndex - 1]?.children.length - 1
          ]?.link;
    }

    return previousLink;
  };

  const next = () => {
    const currentIndex = !tab
      ? 0
      : schema.findIndex(
          (item) =>
            item.link === `/${id}/${tab}` ||
            (item.children &&
              item.children.some((child) => child.link === `/${id}/${tab}`))
        );

    let nextLink;

    if (currentIndex < schema.length - 1 || completion() !== 13) {
      const nextItem = schema[currentIndex];
      if (nextItem.children) {
        nextLink = nextItem.children.findIndex(
          (child) => child.link === `/${id}/${tab}`
        );
        nextLink = nextItem.children[nextLink + 1]?.link;
      }
      if (!nextLink) {
        nextLink = schema[currentIndex + 1]?.link
          ? schema[currentIndex + 1]?.link
          : schema[currentIndex + 1]?.children[0]?.link;
      }
    }

    return nextLink;
  };

  const matchingChild = schema
    .flatMap((item) => item.children || [])
    .find((child) => child.link === `/${id}/${tab}`);

  const matchingItem = matchingChild
    ? { component: matchingChild.component }
    : schema.find((item) => item.link === `/${id}/${tab}`);

  const notify = () =>
    toast.error(
      errors
        .map((error) => error.error && error.error)
        .filter(Boolean)
        .join(", "),
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      }
    );

  document.title = "Esco | La tua struttura";

  return (
    <div>
      <header>
        <NavbarCreator />
      </header>
      <main>
        {rooms.length > 0 ? (
          <>
            <div className="mx-0 2xl:mx-8">
              <div className="flex h-full mx-4 mt-24 md:mt-32 md:mx-8 max-w-screen-2xl 2xl:mx-auto">
                <div className="flex-col hidden w-1/4 h-full md:flex gap-y-4 ">
                  {structure.edit_requested ? (
                    <div className="flex flex-col gap-4 w-full bg-gray-100 rounded-[30px] p-4 gap-x-4">
                      <div className="flex items-center justify-between gap-x-4">
                        <div className="flex items-center gap-x-2">
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className="w-5 h-5 text-red-500"
                          />
                          <p className="text-sm font-bold">
                            La struttura richiede modifiche
                          </p>
                        </div>
                        <button
                          onClick={() => setOpenEditRequested((prev) => !prev)}
                          className="text-xs text-gray-400"
                        >
                          {openEditRequested ? (
                            <FontAwesomeIcon icon={faChevronUp} />
                          ) : (
                            <FontAwesomeIcon icon={faChevronDown} />
                          )}
                        </button>
                      </div>
                      {openEditRequested && (
                        <div className="w-full text-sm text-gray-400">
                          {structure.edit_requested}
                        </div>
                      )}
                    </div>
                  ) : (
                    <UserProfileCard
                      user={userStructure}
                      setUser={setUserStructure}
                    />
                  )}
                  <div className="flex flex-col items-center justify-center w-full bg-gray-100 rounded-[20px] gap-y-0.5 ">
                    {schema.map((item, index) => (
                      <SingleLink
                        key={index}
                        item={item}
                        baseUrl={`/proposeStructure`}
                      />
                    ))}
                  </div>
                </div>
                <ProposeStructureContext.Provider
                  value={{
                    userStructure,
                    setUserStructure,
                    structure,
                    setStructure,
                    errors,
                    setErrors,
                    rooms,
                    setRooms,
                    openModalPromotion,
                    setOpenModalPromotion,
                  }}
                >
                  <div className="relative flex flex-col w-full pb-32 md:w-3/4">
                    <div className="flex items-center justify-between w-full py-4 gap-x-4 md:hidden">
                      <button
                        className="px-3 py-2 border-2 rounded-full border-primary h-fit"
                        onClick={() => {
                          setErrors([]);
                          if (previous()) {
                            navigate(`/proposeStructure${previous()}`);
                          } else {
                            navigate(`/proposeStructure${schema[0].link}`);
                          }
                        }}
                      >
                        <img alt="back" src={indietro} className="w-4 h-4" />
                      </button>
                      <div className="w-full font-bold text-center flex items-center justify-center text-primary h-[48px]">
                        {tab
                          ? schema[
                              schema.findIndex(
                                (item) =>
                                  item.link === `/${id}/${tab}` ||
                                  (item.children &&
                                    item.children.some(
                                      (child) => child.link === `/${id}/${tab}`
                                    ))
                              )
                            ].children.length > 0
                            ? schema[
                                schema.findIndex(
                                  (item) =>
                                    item.link === `/${id}/${tab}` ||
                                    (item.children &&
                                      item.children.some(
                                        (child) =>
                                          child.link === `/${id}/${tab}`
                                      ))
                                )
                              ].children.find(
                                (child) => child.link === `/${id}/${tab}`
                              ).label
                            : schema[
                                schema.findIndex(
                                  (item) =>
                                    item.link === `/${id}/${tab}` ||
                                    (item.children &&
                                      item.children.some(
                                        (child) =>
                                          child.link === `/${id}/${tab}`
                                      ))
                                )
                              ].label
                          : schema[0].label}
                      </div>
                      <button
                        className="px-3 py-2 border-2 rounded-full border-primary h-fit"
                        onClick={() => {
                          if (
                            schemaWithoutChildren[!tab ? 0 : completion()]
                              .completed &&
                            !errors.some((e) => e.error !== null)
                          ) {
                            setErrors([]);
                            if (next()) {
                              navigate(`/proposeStructure${next()}`);
                            } else {
                              navigate(`/structure/preview/${id}`);
                            }
                          } else {
                            notify();
                          }
                        }}
                      >
                        <img
                          alt="next"
                          src={indietro}
                          className="w-4 h-4 transform rotate-180"
                        />
                      </button>
                    </div>
                    {matchingItem?.component || <Biography />}
                  </div>
                </ProposeStructureContext.Provider>
                {structure.edit_requested && (
                  <div className="relative md:hidden">
                    <div
                      className={`fixed z-50 ${
                        openEditRequested
                          ? "w-full h-full bottom-0 right-0 bg-white"
                          : "w-16 h-16 bottom-14 right-4 rounded-full bg-primary shadow-md border-2 border-white"
                      } transition-all duration-300 ease-in-out`}
                      onClick={() => setOpenEditRequested((prev) => !prev)}
                    >
                      {openEditRequested ? (
                        <div className="flex flex-col gap-4 p-6">
                          <div className="flex items-center gap-x-4">
                            <FontAwesomeIcon
                              icon={faExclamationTriangle}
                              className="w-5 h-5 text-red-500"
                            />
                            <p className="text-lg font-bold">
                              La struttura richiede modifiche
                            </p>
                          </div>
                          <p className="text-gray-600">
                            {structure.edit_requested}
                          </p>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center w-full h-full">
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className="w-6 h-6 mb-1 text-white"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                  transition={Bounce}
                />
              </div>
            </div>
            <div className="fixed bottom-0 w-full ">
              <div className="h-full pt-4 mx-4 md:mx-8">
                <div className="relative flex max-w-screen-2xl 2xl:mx-auto">
                  <div className="hidden w-1/4 mx-2 md:flex" />
                  <div className="w-full bg-white border-t md:ml-12 md:w-3/4 ">
                    <div className="w-full my-6 md:shadow-inner md:shadow-md flex items-center md:px-12 md:py-4 md:bg-gray-100 gap-x-4 rounded-[40px] ">
                      <div className="hidden md:block">
                        {tab && (
                          <button
                            className="px-2 py-1 border border-black rounded-full "
                            onClick={() => {
                              setErrors([]);
                              if (previous()) {
                                navigate(`/proposeStructure${previous()}`);
                              } else {
                                navigate(`/proposeStructure${schema[0].link}`);
                              }
                            }}
                          >
                            <img
                              alt="back"
                              src={indietro}
                              className="w-4 h-4"
                            />
                          </button>
                        )}
                      </div>
                      <div className="w-full">
                        <ProgressBar
                          bgColor="#246155"
                          customLabel={
                            tab
                              ? `${(
                                  (100 / (13 + (rooms.length - 1))) *
                                  completion()
                                ).toFixed(0)}%`
                              : 0
                          }
                          isLabelVisible={false}
                          labelAlignment="center"
                          labelClassName={`${
                            !tab
                              ? "text-[#e0e0de]"
                              : "text-[#246155] md:text-white"
                          } text-sm font-normal`}
                          maxCompleted={13 + (rooms.length - 1)}
                          completed={!tab ? 0 : completion()}
                        />
                      </div>
                      <div className="hidden md:block">
                        {completion() !== 13 + (rooms.length - 1) && (
                          <button
                            className="px-2 py-1 border border-black rounded-full "
                            onClick={() => {
                              if (
                                schemaWithoutChildren[!tab ? 0 : completion()]
                                  .completed &&
                                !errors.some((e) => e.error !== null)
                              ) {
                                setErrors([]);
                                navigate(`/proposeStructure${next()}`);
                              } else {
                                notify();
                              }
                            }}
                          >
                            <img
                              alt="next"
                              src={indietro}
                              className="w-5 h-5 transform rotate-180"
                            />
                          </button>
                        )}
                        {completion() === 13 + (rooms.length - 1) && (
                          <button
                            className="px-2 py-1 border border-black rounded-full "
                            onClick={() => {
                              if (
                                schemaWithoutChildren[!tab ? 0 : completion()]
                                  .completed &&
                                !errors.some((e) => e.error !== null)
                              ) {
                                setErrors([]);
                                if (
                                  user.is_admin &&
                                  userStructure._id !== user._id
                                ) {
                                  navigate(`/adminStructure/preview/${id}`);
                                } else {
                                  navigate(`/structure/preview/${id}`);
                                }
                              } else {
                                notify();
                              }
                            }}
                          >
                            <p className="font-semibold">Anteprima</p>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </main>
    </div>
  );
}
