import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useState } from "react";
import { useIntl } from "react-intl";

export default function ModalCancelBooking({ setOpenModal, booking, kind }) {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  const handleRefundBooking = () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/bookingRefund/${booking._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setLoading(false);
        setOpenModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
      <ModalTransition>
        <Modal
          onClose={() => setOpenModal(false)}
          width="large"
          className={`[--modal-dialog-gutter:1rem] [--modal-dialog-translate-y:0] md:mt-12`}
        >
          <ModalHeader className="border-b border-gray-100 !px-4 md:!px-8">
            <h3 className="text-lg font-bold md:text-2xl text-primary">
              {intl.formatMessage({
                defaultMessage: "Annulla prenotazione",
                id: "6ZhQKV",
              })}
            </h3>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </ModalHeader>
          <ModalBody className="!px-4 md:!px-8 my-4 md:my-8 flex flex-col gap-y-8">
            <p className="text-xl font-semibold">
              {kind === "experience" ? (
                <>
                  {intl.formatMessage(
                    {
                      defaultMessage:
                        "Sei sicuro di voler annullare la prenotazione dell'esperienza {title} ?",
                      id: "EaM30x",
                    },
                    { title: booking?.experience_id?.name }
                  )}
                </>
              ) : (
                <>
                  {intl.formatMessage(
                    {
                      defaultMessage:
                        "Sei sicuro di voler annullare la prenotazione alla struttura {title} ?",
                      id: "Ck8KkM",
                    },
                    { title: booking?.structure_id?.name }
                  )}
                </>
              )}
            </p>
            <div className="flex p-4 text-white rounded-md md:p-6 bg-primary gap-x-4">
              <div className="flex flex-col justify-between w-full text-sm gap-y-4">
                <div className="flex items-center text-lg gap-x-3 md:gap-x-4">
                  <FontAwesomeIcon icon={faCircleInfo} />
                  <p className="font-semibold">
                    {intl.formatMessage({
                      defaultMessage: "Dettagli rimborso",
                      id: "oxKTXY",
                    })}
                  </p>
                </div>
                <p>
                  {intl.formatMessage({
                    defaultMessage:
                      "Esco se confermi l'annullamento della prenotazione procederà con il rimborso dell'importo pagato inizialmente,trattenendone le spese di transazione. Riceverai il rimborso direttamente sullo stesso metodo di pagamento utilizzato per la prenotazione entro 5 giorni lavorativi.",
                    id: "5kyFDp",
                  })}
                </p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="border-t border-gray-100 !px-4 md:!px-8">
            <div className="flex justify-end gap-x-4">
              <button
                onClick={() => setOpenModal(false)}
                className="px-6 py-2 border rounded-md text-primary border-primary"
              >
                {intl.formatMessage({
                  defaultMessage: "Annulla",
                  id: "tfqu9h",
                })}
              </button>
              <button
                onClick={() => {
                  if (!loading) {
                    handleRefundBooking();
                  }
                  return;
                }}
                className="px-6 py-2 text-white rounded-md bg-primary"
              >
                {!loading ? (
                  intl.formatMessage({
                    defaultMessage: "Conferma",
                    id: "YvGTHj",
                  })
                ) : (
                  <UiduSpinner size="small" invertColor />
                )}
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </ModalTransition>
    </div>
  );
}
