import React from "react";
import { useIntl } from "react-intl";
import accessibility from "../../assets/icons/ACCESSIBILITY.svg";
import incluso from "../../assets/icons/INCLUSO.svg";
import info from "../../assets/icons/INFO.svg";
import requisiti from "../../assets/icons/REQUISITI.svg";

export default function ExperienceInfoCards({ experience }) {
  const intl = useIntl();
  return (
    <div className="grid grid-cols-1 gap-8 my-4 md:grid-cols-2">
      <div className="flex flex-col gap-y-2">
        <div className="flex items-start md:items-center gap-x-6 md:gap-x-2">
          <img src={requisiti} alt="requisiti" className="w-6 mt-0.5 md:mt-0" />
          <div className="text-lg font-semibold">
            {intl.formatMessage({
              defaultMessage: "Requisiti:",
              id: "Q9Xsxg",
            })}
          </div>
        </div>
        <ul className="ml-12 list-disc list-outside md:ml-6">
          <li className="text-base font-normal">
            {intl.formatMessage(
              {
                defaultMessage: "Età minima: {minimum_age} anni",
                id: "3GnRtF",
              },
              {
                minimum_age: experience.minimum_age,
              }
            )}
          </li>
          {experience.requirements.map((requirement) => (
            <li className="text-base font-normal" key={requirement}>
              {requirement}
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="flex items-start md:items-center gap-x-6 md:gap-x-2">
          <img src={incluso} alt="incluso" className="w-6 mt-0.5 md:mt-0" />
          <div className="text-lg font-semibold">
            {intl.formatMessage({
              defaultMessage: "Cos'è incluso:",
              id: "EPeYBW",
            })}
          </div>
        </div>
        <ul className="ml-12 list-disc list-outside md:ml-6">
          {experience.included.map((requirement) => (
            <li className="text-base font-normal" key={requirement}>
              {requirement}
            </li>
          ))}
        </ul>
      </div>
      {experience.partecipants_need_to_bring.length > 0 && (
        <div className="flex flex-col gap-y-2">
          <div className="flex items-start md:items-center gap-x-6 md:gap-x-2">
            <img
              src={requisiti}
              alt="requisiti"
              className="w-6 mt-0.5 md:mt-0"
            />
            <div className="text-lg font-semibold">
              {intl.formatMessage({
                defaultMessage: "L'ospite deve portare:",
                id: "TJ1sVw",
              })}
            </div>
          </div>
          <ul className="ml-12 list-disc list-outside md:ml-6">
            {experience.partecipants_need_to_bring.map((requirement) => (
              <li className="text-base font-normal" key={requirement}>
                {requirement}
              </li>
            ))}
          </ul>
        </div>
      )}
      {experience.accessibility.length > 0 && (
        <div className="flex flex-col gap-y-2">
          <div className="flex items-start md:items-center gap-x-6 md:gap-x-2">
            <img
              src={accessibility}
              alt="accessibilità"
              className="w-6 mt-0.5 md:mt-0"
            />
            <div className="text-lg font-semibold">
              {intl.formatMessage({
                defaultMessage: "Accessibilità:",
                id: "zcF9D+",
              })}
            </div>
          </div>
          <ul className="ml-12 list-disc list-outside md:ml-6">
            {experience.accessibility
              .filter((accessibility) => accessibility !== "altro")
              .map((accessibility) => (
                <li className="text-base font-normal" key={accessibility}>
                  {accessibility === "Lis"
                    ? intl.formatMessage({
                        defaultMessage: "Disponibile in lingua dei segni",
                        id: "rwB2vf",
                      })
                    : accessibility === "sedia"
                    ? intl.formatMessage({
                        defaultMessage: "Accessibile in sedia a rotelle",
                        id: "ROxhlW",
                      })
                    : intl.formatMessage({
                        defaultMessage: "Altro",
                        id: "s0Jlu4",
                      })}
                </li>
              ))}
            {experience.accessibility.includes("altro") && (
              <>
                {experience.extra_accessibility.map((accessibility) => (
                  <li className="text-base font-normal" key={accessibility}>
                    {accessibility}
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>
      )}
      {experience.extra_info && experience.extra_info.length > 0 && (
        <div className="flex flex-col gap-y-2">
          <div className="flex items-start md:items-center gap-x-6 md:gap-x-2">
            <img src={info} alt="info" className="w-6 mt-0.5 md:mt-0" />
            <div className="text-lg font-semibold">
              {intl.formatMessage({
                defaultMessage: "Informazioni utili:",
                id: "gdZY/i",
              })}
            </div>
          </div>
          <ul className="ml-12 list-disc list-outside md:ml-6">
            {experience.extra_info}
          </ul>
        </div>
      )}
    </div>
  );
}
