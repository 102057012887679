import {
  faExclamationTriangle,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import QrScanner from "react-qr-scanner";

export default function ModalQrCodeScan({
  setOpenQrCodeModal,
  kind,
  bookingsForTheDay,
  setBookingsForTheDay,
  rooms = null,
}) {
  const intl = useIntl();
  const [qrScannerLoaded, setQrScannerLoaded] = useState(false);
  const [idBooking, setIdBooking] = useState("");
  const [bookingScanned, setBookingScanned] = useState(null);
  const [bookingNotFound, setBookingNotFound] = useState(false);

  const checkInBooking = async (bookingId) => {
    try {
      await axios
        .put(
          `${process.env.REACT_APP_SERVER_URL}/api/bookingCheckIn/${bookingId}`,
          {
            kind: kind,
            id:
              kind === "experience"
                ? bookingScanned.experience_id
                : bookingScanned.structure_id,
          }
        )
        .then((response) => {
          setBookingsForTheDay(
            bookingsForTheDay.map((booking) =>
              booking._id === bookingId
                ? {
                    ...booking,
                    checked_in_at: response.data.checked_in_at,
                  }
                : booking
            )
          );
          setIdBooking("");
          setBookingScanned(null);
          setOpenQrCodeModal(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (idBooking !== "") {
      if (bookingsForTheDay.find((booking) => booking._id === idBooking)) {
        setBookingScanned(
          bookingsForTheDay.find((booking) => booking._id === idBooking)
        );
        setBookingNotFound(false);
      } else {
        setBookingNotFound(true);
      }
    }
  }, [idBooking]);

  return (
    <ModalTransition>
      <Modal
        onClose={() => {
          setIdBooking("");
          setBookingScanned(null);
          setOpenQrCodeModal(false);
        }}
        width="calc(85vw - 2rem)"
        height="calc(100vh - 2rem)"
        className="[--modal-dialog-gutter:1rem] [--modal-dialog-translate-y:0] md:mt-4"
      >
        <ModalHeader className="border-b border-gray-100 !px-4 md:!px-8">
          <div className="flex flex-col md:items-center gap-x-6 md:flex-row">
            <h3 className="text-lg font-bold md:text-2xl text-primary">
              Scannerizza QR Code
            </h3>
          </div>
          <div className="flex items-center gap-x-3 md:gap-x-6">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIdBooking("");
                setBookingScanned(null);
                setOpenQrCodeModal(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </ModalHeader>
        <ModalBody className="!px-4 md:!px-8 my-4 md:my-8 flex flex-col gap-y-8">
          {bookingScanned ? (
            <div className="grid grid-cols-1 gap-2 md:gap-6 text-[#979797]">
              {bookingScanned.checked_in_at ? (
                <div className="flex items-center mb-2 text-lg font-bold md:-mt-4 md:text-xl">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="text-xl text-yellow-500 md:text-2xl"
                  />
                  <div className="ml-2 text-primary">
                    Check-in già effettuato
                  </div>
                </div>
              ) : (
                <div className="mb-2 text-lg font-bold md:-mt-4 md:text-xl text-primary">
                  Prenotazione trovata!
                </div>
              )}
              <div className="flex flex-col w-full md:items-center md:h-8 md:flex-row gap-y-2 gap-x-4">
                <div className="font-semibold md:w-60">
                  {intl.formatMessage({
                    defaultMessage: "Nome:",
                    id: "JuRwcM",
                  })}
                </div>
                <div className="flex items-center justify-center w-full px-4 py-2 border rounded-lg">
                  {bookingScanned.user_id.name} {bookingScanned.user_id.surname}
                </div>
              </div>
              <div className="flex flex-col w-full md:flex-row md:items-center md:h-8 gap-y-2 gap-x-4">
                <div className="font-semibold md:w-60">
                  {intl.formatMessage({
                    defaultMessage: "Codice prenotazione:",
                    id: "ifSI7L",
                  })}
                </div>
                <div className="flex items-center justify-center w-full px-4 py-2 border rounded-lg">
                  {bookingScanned._id}
                </div>
              </div>
              {kind === "experience" ? (
                <>
                  <div className="flex flex-col w-full md:flex-row md:items-center md:h-8 gap-y-2 gap-x-4">
                    <div className="font-semibold md:w-60">
                      {intl.formatMessage({
                        defaultMessage: "Data:",
                        id: "m19lxP",
                      })}
                    </div>
                    <div className="flex items-center justify-center w-full px-4 py-2 border rounded-lg">
                      {dayjs(bookingScanned.start_date).format("DD/MM/YYYY")}
                    </div>
                  </div>
                  <div className="flex flex-col w-full md:flex-row md:items-center md:h-8 gap-y-2 gap-x-4">
                    <div className="font-semibold md:w-60">
                      {intl.formatMessage({
                        defaultMessage: "Ora:",
                        id: "JOd7sM",
                      })}
                    </div>
                    <div className="flex items-center justify-center w-full px-4 py-2 border rounded-lg">
                      {bookingScanned.start_time}
                    </div>
                  </div>
                  <div className="flex flex-col w-full md:flex-row md:items-center md:h-8 gap-y-2 gap-x-4">
                    {bookingScanned.guests_number.is_group ? (
                      <>
                        <div className="font-semibold md:w-60">
                          {intl.formatMessage({
                            defaultMessage: "Prenotato per un gruppo:",
                            id: "bM0wJ6",
                          })}
                        </div>
                        <div className="flex items-center justify-center w-full px-4 py-2 border rounded-lg">
                          {intl.formatMessage({
                            defaultMessage: "Sì",
                            id: "mt8kJK",
                          })}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="font-semibold md:w-60">
                          {intl.formatMessage({
                            defaultMessage: "N° di persone:",
                            id: "iR/Jy5",
                          })}
                        </div>
                        <div className="flex items-center justify-center w-full px-4 py-2 border rounded-lg">
                          {bookingScanned.guests_number.adults}{" "}
                          {intl.formatMessage({
                            defaultMessage: "adulti",
                            id: "HR89WG",
                          })}{" "}
                          {bookingScanned.guests_number.children > 0 && (
                            <>
                              - {bookingScanned.guests_number.children}{" "}
                              {intl.formatMessage({
                                defaultMessage: "bambini",
                                id: "ImjG1h",
                              })}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {bookingScanned.start_date ? (
                    <div className="flex flex-col w-full md:flex-row md:items-center md:h-8 gap-y-2 gap-x-4">
                      <div className="font-semibold md:w-60">
                        {intl.formatMessage({
                          defaultMessage: "Periodo:",
                          id: "/ZeWkR",
                        })}
                      </div>
                      <div className="flex items-center justify-center w-full px-4 py-2 border rounded-lg">
                        {dayjs(bookingScanned.start_date).format("DD/MM/YY")} -{" "}
                        {dayjs(bookingScanned.end_date).format("DD/MM/YY")}
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-start w-full col-span-2 md:h-8 gap-x-6 ">
                      <div className="font-semibold">
                        {intl.formatMessage({
                          defaultMessage: "Il tuo messaggio:",
                          id: "tc5D15",
                        })}
                      </div>
                      <div className="ml-4 line-clamp-2 text-primary">
                        {bookingScanned.message}
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col w-full md:items-center md:h-8 md:flex-row gap-y-2 gap-x-4">
                    <div className="font-semibold md:w-60">
                      {intl.formatMessage({
                        defaultMessage: "N° di persone:",
                        id: "iR/Jy5",
                      })}
                    </div>
                    <div className="flex items-center justify-center w-full px-4 py-2 border rounded-lg">
                      {bookingScanned.guests_number.adults}{" "}
                      {intl.formatMessage({
                        defaultMessage: "adulti",
                        id: "HR89WG",
                      })}{" "}
                      {bookingScanned.guests_number.children > 0 && (
                        <>
                          - {bookingScanned.guests_number.children}{" "}
                          {intl.formatMessage({
                            defaultMessage: "bambini",
                            id: "ImjG1h",
                          })}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col w-full md:items-center md:h-8 md:flex-row gap-y-2 gap-x-4">
                    <div className="font-semibold md:w-60">
                      {intl.formatMessage({
                        defaultMessage: "Camera:",
                        id: "dk/tmE",
                      })}
                    </div>
                    <div className="flex items-center justify-center w-full px-4 py-2 border rounded-lg">
                      {
                        rooms.find(
                          (room) => room._id === bookingScanned.room_id
                        ).name
                      }
                    </div>
                  </div>
                  {bookingScanned.meals.length > 0 && (
                    <div className="flex flex-col w-full md:items-center md:h-8 md:flex-row gap-y-2 gap-x-4">
                      <div className="font-semibold md:w-60">
                        {intl.formatMessage({
                          defaultMessage: "Pasti:",
                          id: "SD9Wub",
                        })}
                      </div>
                      <div className="flex items-center justify-center w-full px-4 py-2 border rounded-lg">
                        {bookingScanned.meals.map((meal) => (
                          <div>{meal}</div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          ) : idBooking !== "" && bookingNotFound ? (
            <div className="flex flex-col gap-y-4 md:gap-y-8">
              <div className="flex items-center text-lg font-bold text-red-500 gap-x-4 md:text-xl">
                <FontAwesomeIcon icon={faXmarkCircle} />
                <div className="">Prenotazione non trovata</div>
              </div>
              <div className="text-[#979797]">
                La prenotazione con il codice {idBooking} non è stata trovata.
                Verifica di aver scannerizzato il codice corretto.
              </div>
            </div>
          ) : (
            <div className="relative flex-1">
              <QrScanner
                delay={300}
                onError={(err) => console.error(err)}
                onScan={(data) => {
                  if (data) {
                    console.log(data.text, "data");
                    setIdBooking(data.text);
                  }
                }}
                onLoad={() => setQrScannerLoaded(true)}
                style={{ width: "100%", height: "100%" }}
              />
              {qrScannerLoaded && (
                <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                  <div className="w-64 h-64 border-4 border-gray-500 border-dashed md:w-96 md:h-96"></div>
                </div>
              )}
            </div>
          )}
        </ModalBody>
        <ModalFooter className="flex flex-col gap-4 md:flex-row md:justify-between">
          {bookingScanned ? (
            <>
              {bookingScanned.checked_in_at ? (
                <button
                  onClick={() => {
                    setIdBooking("");
                    setBookingScanned(null);
                  }}
                  type="button"
                  className="w-full px-6 py-1 text-xl bg-gray-200 rounded-md"
                >
                  Annulla
                </button>
              ) : (
                <>
                  <button
                    onClick={() => checkInBooking(bookingScanned._id)}
                    type="button"
                    className="w-full px-6 py-1 text-xl text-white rounded-md bg-primary"
                  >
                    Check-in
                  </button>
                  <button
                    onClick={() => {
                      setIdBooking("");
                      setBookingScanned(null);
                    }}
                    type="button"
                    className="w-full px-6 py-1 text-xl bg-gray-200 rounded-md"
                  >
                    Annulla
                  </button>
                </>
              )}
            </>
          ) : idBooking !== "" && bookingNotFound ? (
            <button
              onClick={() => setIdBooking("")}
              type="button"
              className="w-full px-6 py-1 text-xl bg-gray-200 rounded-md"
            >
              Riprova
            </button>
          ) : null}
        </ModalFooter>
      </Modal>
    </ModalTransition>
  );
}
