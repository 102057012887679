import axios from "axios";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Bounce, ToastContainer } from "react-toastify";
import { SwiperSlide } from "swiper/react";
import Carousel from "./Carousel";
import SingleBox from "./SingleBox";
import SingleLoad from "./SingleLoad";

export default function CarouselEscoBox({ selectedTab }) {
  const [data, setData] = useState([]);
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [slidesPerView, setSlidesPerView] = useState(4);

  const getEscoBoxes = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/escoboxesOfCategoryLimited`,
        {
          category: selectedTab,
          limit: 16,
        }
      )
      .then((res) => {
        if (res.data.msg === "No escoBoxes") {
          setData([]);
        } else {
          setData(res.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const groupedData = data.reduce((acc, curr, i) => {
    const group = Math.floor(i / slidesPerView);
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(curr);
    return acc;
  }, []);

  useEffect(() => {
    if (selectedTab) {
      setIsLoading(true);
      getEscoBoxes();
    }
  }, [selectedTab]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSlidesPerView(1); // Per dispositivi mobili
      } else if (width >= 768 && width < 1024) {
        setSlidesPerView(2); // Per tablet
      } else {
        setSlidesPerView(4); // Per desktop
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Chiama immediatamente per impostare il valore iniziale

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Carousel slidesPerView={slidesPerView}>
        {!isLoading ? (
          <>
            {data.length === 0 ? (
              <div className="mx-4 px-4 md:px-0 md:mx-0 text-2xl text-center text-gray-500 h-80 bg-gray-100 rounded-[30px] flex items-center justify-center">
                {intl.formatMessage({
                  defaultMessage: "Nessun EscoBox disponibile al momento",
                  id: "k72OpX",
                })}
              </div>
            ) : (
              groupedData.map((group, i) => (
                <SwiperSlide key={i}>
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-4">
                    <>
                      {group.map((item) => (
                        <SingleBox
                          item={item}
                          key={item.id}
                          selectedTab={selectedTab}
                        />
                      ))}
                    </>
                  </div>
                </SwiperSlide>
              ))
            )}
          </>
        ) : (
          <SwiperSlide>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-4">
              <SingleLoad length={slidesPerView} />
            </div>
          </SwiperSlide>
        )}
      </Carousel>
      <div className="fixed z-50">
        <ToastContainer
          containerId={`SingleBoxContainer`}
          position="bottom-left"
          autoClose={2000}
          hideProgressBar
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="light"
          transition={Bounce}
          stacked
        />
      </div>
    </>
  );
}
