import { faker } from "@faker-js/faker";
import {
  faEllipsisH,
  faExclamationTriangle,
  faEye,
  faGlobe,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import Tooltip from "@uidu/tooltip";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Bounce, ToastContainer } from "react-toastify";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import avanti from "../../assets/icons/AVANTI.svg";
import condividi from "../../assets/icons/CONDIVIDI.svg";
import elimina from "../../assets/icons/ELIMINA.svg";
import geotag from "../../assets/icons/GEOTAG.svg";
import modifica from "../../assets/icons/MODIFICA.svg";
import reviews from "../../assets/icons/REVIEW.svg";
import stella from "../../assets/icons/STELLA.svg";
import BaseComponent from "../BaseComponent";
import ModalShare from "../ModalShare";
import PopUp from "../PopUp";
import StructureIcon from "../StructureIcon";

function SingleStructure({ structure, setStructures }) {
  const [openDelete, setOpenDelete] = useState(false);
  const [openShowcase, setOpenShowcase] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const detailImages = useMemo(() => {
    const images = [
      faker.image.urlLoremFlickr({
        category: "agriturism",
        width: 600,
        height: 400,
      }),
      faker.image.urlLoremFlickr({
        category: "montain",
        width: 600,
        height: 400,
      }),
      faker.image.urlLoremFlickr({
        category: "room",
        width: 600,
        height: 400,
      }),
    ];
    return images;
  }, []);

  const averageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  const deleteStructure = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/structure/edit/${structure._id}`,
        {
          is_suspended: true,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log("response", res); // 'response', 'ok
        setStructures((prev) =>
          prev.filter((item) => item._id !== structure._id)
        );
        setOpenDelete(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateShowcase = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/structure/edit/${structure._id}`,
        {
          showcase_mode: !structure.showcase_mode,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log("response", res); // 'response', 'ok
        setStructures((prev) =>
          prev.map((item) =>
            item._id === structure._id
              ? { ...item, showcase_mode: !item.showcase_mode }
              : item
          )
        );
        setOpenShowcase(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="hidden" id="fakeClickOutside" />
      <div className="relative flex flex-col w-full border rounded-lg shadow-lg md:flex-row md:h-72">
        <div className="relative w-full -mt-px overflow-hidden rounded-lg md:w-5/12 h-72">
          <Swiper
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
            loop={true}
          >
            {structure.images && structure.images.length > 0
              ? structure.images.map((image) => (
                  <SwiperSlide key={image}>
                    <img
                      src={image}
                      alt="room"
                      className="object-cover w-full rounded-lg h-72"
                    />
                  </SwiperSlide>
                ))
              : detailImages.map((image) => (
                  <SwiperSlide key={image}>
                    <img
                      src={image}
                      alt="room"
                      className="object-cover w-full rounded-lg h-72"
                    />
                  </SwiperSlide>
                ))}
          </Swiper>
          <button
            className="absolute z-50 flex items-center justify-center w-8 h-8 text-white bg-white rounded-full shadow-md top-4 right-4 md:hidden"
            onClick={() => setOpenShareModal(true)}
          >
            <img src={condividi} alt="share" className="w-5 h-5" />
          </button>
        </div>
        <div className="flex flex-col w-full p-4 text-sm md:ml-4 md:w-7/12 gap-y-2">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-x-4">
              <div className="flex items-center justify-center w-6 h-6 rounded-full ">
                <StructureIcon kind={structure.kind} color="black" />
              </div>
              <div className="font-bold">{structure.name}</div>
            </div>
            <div className="flex items-center gap-x-4">
              <div
                className={`px-2 py-0.5 rounded text-white font-bold ${
                  !structure.showcase_mode ? "bg-primary" : "bg-yellow-500"
                }`}
              >
                {!structure.showcase_mode ? "Attiva" : "In modalità vetrina"}
              </div>
              <PopUp
                icon={
                  <FontAwesomeIcon
                    icon={faEllipsisH}
                    className="text-xs text-white"
                  />
                }
                orientation="horizontal"
                direction="right"
                items={
                  <>
                    <Link
                      className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-t-[30px] cursor-pointer text-primary hover:bg-gray-50"
                      to={`/proposeStructure/${structure._id}`}
                    >
                      <img
                        src={modifica}
                        alt="modifica"
                        className="w-6 h-6 pr-2"
                      />
                      <div>Modifica</div>
                    </Link>
                    <div className="mx-4 border-b" />
                    <div
                      className="flex items-center w-full h-8 px-6 py-5 text-sm cursor-pointer text-primary hover:bg-gray-50"
                      onClick={() => {
                        setOpenShowcase(true);
                        document.getElementById("fakeClickOutside").click();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEye}
                        className="pr-2 text-primary"
                      />
                      {structure.showcase_mode ? (
                        <div className="flex items-center justify-between w-full">
                          <p>Attiva offerta</p>
                          <Tooltip
                            content="La tua struttura sarà prenotabile sul portale"
                            position="left"
                          >
                            <FontAwesomeIcon
                              icon={faQuestionCircle}
                              className="cursor-pointer text-primary"
                            />
                          </Tooltip>
                        </div>
                      ) : (
                        <div className="flex items-center justify-between w-full">
                          <p>Disattiva offerta</p>
                          <Tooltip
                            content="La tua struttura non sarà prenotabile sul portale, ma rimarrà visibile come vetrina"
                            position="left"
                          >
                            <FontAwesomeIcon
                              icon={faQuestionCircle}
                              className="cursor-pointer text-primary"
                            />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                    <div className="mx-4 border-b" />
                    <button
                      onClick={() => {
                        setOpenDelete(true);
                        document.getElementById("fakeClickOutside").click();
                      }}
                      type="button"
                      className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-b-[30px] cursor-pointer text-primary hover:bg-gray-50"
                    >
                      <img
                        src={elimina}
                        alt="elimina"
                        className="w-[22px] pr-2"
                      />
                      <div>Elimina</div>
                    </button>
                  </>
                }
              />
            </div>
          </div>
          <div className="flex items-center w-full mt-2 gap-x-4">
            <div className="flex items-center mr-12 gap-x-2">
              <img src={geotag} alt="geotag" className="w-4" />
              <div className="text-center">
                {structure.address
                  ? structure.address.municipality
                    ? structure.address.municipality
                    : structure.address.village
                  : "Pertica Bassa"}
              </div>
            </div>
            {structure.approved_at && (
              <div className="flex items-center gap-x-1.5">
                <img src={stella} alt="stella" className="w-4" />
                <div>
                  {structure.reviews ? averageReviews(structure.reviews) : "0"}
                  /5
                </div>
              </div>
            )}
          </div>
          <div
            className="grid h-full mt-4 gap-y-2"
            style={
              structure.approved_at
                ? { gridTemplateRows: "repeat(5, 1fr)" }
                : windowWidth >= 768
                ? { gridTemplateRows: "repeat(4, 1fr)" }
                : { gridTemplateRows: "repeat(1, 1fr)" }
            }
          >
            {structure.approved_at ? (
              <>
                <div className="flex items-center gap-x-4">
                  <div className="font-semibold">Prenotazioni:</div>
                  <div className="text-primary">0</div>
                </div>
                <div className="flex items-center gap-x-4">
                  <div className="font-semibold">Recensioni:</div>
                  <div className="text-primary">
                    {structure.reviews ? structure.reviews.length : "0"}
                  </div>
                </div>
                <div className="flex items-center gap-x-4">
                  <div className="font-semibold">Ultima prenotazione:</div>
                  <div className="text-primary">Mai</div>
                </div>
                <div />
              </>
            ) : structure.request_of_approval_made_at ? (
              <div className="flex flex-col justify-center gap-x-4">
                <div className="text-lg font-semibold">
                  In attesa di approvazione
                </div>
                <div className="text-sm text-gray-400">
                  La tua struttura è in fase di revisione da parte del nostro
                  team, riceverai una mail di conferma non appena sarà
                  approvata.
                </div>
              </div>
            ) : (
              <>
                <div className="flex flex-col justify-center gap-x-4">
                  <div className="text-lg font-semibold">
                    Completa il caricamento
                  </div>
                  <div className="text-sm text-gray-400">
                    Ci risulta che tu abbia iniziato a caricare la tua
                    struttura, ma delle informazioni sono ancora mancanti.
                    Aggiungi le informazioni mancanti per rendere la tua
                    struttura visibile.
                  </div>
                </div>
                <Link
                  to={`/proposeStructure/${structure._id}`}
                  className="flex items-center justify-between w-full h-8 px-6 py-5 text-sm rounded-lg cursor-pointer text-primary hover:bg-gray-50"
                >
                  <div>Completa caricamento</div>
                  <img src={avanti} alt="avanti" className="w-6 h-6 pl-2" />
                </Link>
              </>
            )}
            {structure.approved_at && (
              <div className="grid w-full grid-cols-2 divide-x md:grid-cols-3 gap-x-4">
                <div className="flex items-center justify-center cursor-pointer gap-x-4">
                  <img src={reviews} alt="reviews" className="w-5 h-5" />
                  <div className="text-primary">Recensioni</div>
                </div>
                <div
                  className="flex items-center justify-center cursor-pointer gap-x-4"
                  onClick={() => navigate(`/structures/${structure._id}`)}
                >
                  {/* <img src={escobox} alt="reviews" className="w-5 h-5" /> */}
                  <FontAwesomeIcon icon={faGlobe} className="text-primary" />
                  <div className="text-primary">Vedi sul sito</div>
                </div>
                <button
                  className="items-center justify-center hidden cursor-pointer md:flex gap-x-4"
                  onClick={() => setOpenShareModal(true)}
                  type="button"
                >
                  <img src={condividi} alt="share" className="w-5 h-5" />
                  <div className="text-primary">Condividi</div>
                </button>
                {openShareModal && (
                  <ModalShare
                    kind={"structure"}
                    setOpenShareModal={setOpenShareModal}
                    urlToUse={`/structures/${structure._id}`}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <ModalTransition>
          {openDelete && (
            <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
              <Modal
                onClose={() => setOpenDelete(false)}
                className="mt-0 md:mt-20"
                shouldCloseOnOverlayClick={false}
              >
                <ModalHeader>
                  <div className="flex items-center gap-x-4">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className="w-6 h-6 text-yellow-500"
                    />
                    <h3 className="text-2xl">Attenzione</h3>
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenDelete(false);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </ModalHeader>
                <ModalBody>
                  <div className="text-gray-400 ">
                    Sei sicuro di voler eliminare la struttura {structure.name}?
                    <br /> Questa azione è irreversibile.
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className="flex flex-col items-center justify-end w-full gap-4 md:flex-row">
                    <button
                      className="w-full h-10 text-sm bg-gray-200 rounded-lg md:w-28 "
                      onClick={() => setOpenDelete(false)}
                    >
                      Annulla
                    </button>
                    <button
                      className="w-full h-10 text-sm text-white bg-red-500 rounded-lg md:w-28"
                      onClick={() => deleteStructure()}
                    >
                      Elimina
                    </button>
                  </div>
                </ModalFooter>
              </Modal>
            </div>
          )}
          {openShowcase && (
            <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
              <Modal
                onClose={() => setOpenShowcase(false)}
                className="mt-0 md:mt-20"
                shouldCloseOnOverlayClick={false}
              >
                <ModalHeader>
                  <div className="flex items-center gap-x-4">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className="w-6 h-6 text-yellow-500"
                    />
                    <h3 className="text-2xl">Attenzione</h3>
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenShowcase(false);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </ModalHeader>
                <ModalBody>
                  <div className="text-gray-400 ">
                    {!structure.showcase_mode
                      ? "Disattivando l'offerta, la tua struttura non sarà più prenotabile sul portale, ma rimarrà visibile come vetrina."
                      : "Attivando l'offerta, la tua struttura sarà prenotabile sul portale."}
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className="flex flex-col items-center justify-end w-full gap-4 md:flex-row">
                    <button
                      className="w-full h-10 text-sm bg-gray-200 rounded-lg md:w-28 "
                      onClick={() => setOpenShowcase(false)}
                    >
                      Annulla
                    </button>
                    <button
                      className="w-full h-10 text-sm text-white rounded-lg bg-primary md:w-40"
                      onClick={() => updateShowcase()}
                    >
                      {!structure.showcase_mode
                        ? "Disattiva offerta"
                        : "Attiva offerta"}
                    </button>
                  </div>
                </ModalFooter>
              </Modal>
            </div>
          )}
        </ModalTransition>
      </div>
    </>
  );
}

export default function ManageStructures({ structures, setStructures }) {
  return (
    <BaseComponent title="">
      <h1 className="text-2xl text-center md:text-left text-primary">
        Le tue strutture
      </h1>
      <div className="flex flex-col px-4 pt-4 mt-4 mb-12 overflow-y-auto rounded-md gap-y-6">
        {structures.map((structure) => (
          <SingleStructure
            key={structure._id}
            structure={structure}
            setStructures={setStructures}
          />
        ))}
        <div className="absolute">
          <ToastContainer
            containerId={`CopyLinkModal`}
            position="bottom-left"
            autoClose={2000}
            hideProgressBar
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            theme="light"
            transition={Bounce}
            stacked
          />
        </div>
      </div>
    </BaseComponent>
  );
}
