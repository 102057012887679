import dayjs from "dayjs";
import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import heart from "../assets/heart.svg";

export default function SingleArticle({ item }) {
  console.log("item", item);
  const wordsPerMinute = 200;
  const intl = useIntl();
  const numberOfWords = item.body.split(" ").length;
  const navigate = useNavigate();

  return (
    <div
      key={item.id}
      className="relative flex flex-col mb-10 border rounded-[30px] shadow-lg cursor-pointer hover:shadow-xl"
      onClick={() => navigate(`/article/${item._id}`)}
    >
      <div
        style={{
          backgroundImage: `url(${item.images[0]})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        alt={item.name}
        className="relative w-full h-56 rounded-[30px] shadow-[inset_0px_-60px_40px_0px_rgba(0,0,0,0.6)]"
      />
      <div className="absolute flex flex-row justify-end w-full p-2 top-2">
        <div className="flex items-center justify-center bg-white rounded-full w-9 h-9">
          <img src={heart} alt="heart" className="w-5" />
        </div>
      </div>
      <div className="relative flex flex-row w-full -mt-10">
        {item.tags.map((tag) => (
          <div
            key={tag}
            className="flex items-center justify-center px-2 ml-3 text-white border border-white rounded-md shadow-md"
          >
            {tag}
          </div>
        ))}
      </div>
      <div className="flex flex-col flex-1 p-3 mt-4">
        <div className="pb-2 font-semibold">{item.title}</div>
        <div className="flex flex-row text-xs text-gray-400 gap-x-4">
          <div>{dayjs(item.created_at).format("DD MMM YYYY")}</div>
          &bull;
          <div className="flex items-center gap-x-1.5">
            <div>
              {Math.ceil(numberOfWords / wordsPerMinute)}{" "}
              {intl.formatMessage({
                defaultMessage: "min di lettura",
                id: "gjL2U+",
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 pt-0 overflow-hidden text-sm rounded-b-lg ">
        <div className="line-clamp-3">{item?.text_preview}</div>
        <div className="relative flex justify-between -top-5">
          <div></div>
          <div className="bg-white">
            ...
            <a href="/" className="text-gray-500 w-fit">
              {" "}
              {intl.formatMessage({
                defaultMessage: "Continua a leggere",
                id: "TnRYCQ",
              })}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
