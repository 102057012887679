import Modal, {
  ModalBody,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import modifica from "../assets/icons/MODIFICA.svg";
import EditUser from "./EditUser";

export default function UserProfileCard({ user, setUser }) {
  const [openEditModal, setOpenEditModal] = useState(false);
  const intl = useIntl();

  return (
    <div className="flex w-full bg-gray-100 rounded-[30px] h-32 p-4 gap-x-4">
      <div>
        {user.profile_pic ? (
          <div
            className="w-24 h-24 bg-gray-300 rounded-[30px]"
            style={{
              backgroundImage: `url(${user.profile_pic})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        ) : (
          <div className="w-24 h-24 bg-gray-300 rounded-[30px]" />
        )}
      </div>
      <div className="flex flex-col justify-between w-full">
        <div className="flex items-center justify-between w-full">
          <p className="font-semibold ">{user.name}</p>
          <button
            className="flex items-center justify-center w-6 h-6 rounded-[30px]"
            onClick={() => setOpenEditModal(true)}
          >
            <img src={modifica} alt={"modifica"} className="w-5 h-5 mr-4" />
          </button>
        </div>
        <p className="font-semibold ">{user.surname}</p>
        <div className="flex items-start w-full">
          <div className="flex items-center justify-center gap-x-2">
            <p className="text-xs">{user?.bookingNumber || 0}</p>
            <p className="text-xs">
              {intl.formatMessage({
                defaultMessage: "Prenotazioni",
                id: "GnJKAV",
              })}
            </p>
          </div>
          {/*<div className="h-8 border-r border-gray-400" />
           <div className="flex flex-col items-center justify-center">
            <p className="text-xs">0</p>
            <p className="text-xs">
              {intl.formatMessage({
                defaultMessage: "Escoshare",
                id: "i9Q1LC",
              })}
            </p>
          </div> */}
        </div>
      </div>
      <ModalTransition>
        {openEditModal && (
          <>
            <div className="fixed inset-0 z-50 bg-black bg-opacity-50" />
            <Modal
              onClose={() => setOpenEditModal(false)}
              className="mt-0 md:mt-10 "
              width="large"
            >
              <ModalHeader>
                <div className="flex items-center text-xl font-bold gap-x-4 text-primary">
                  {intl.formatMessage({
                    defaultMessage: "Modifica profilo",
                    id: "5/Pm0b",
                  })}
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenEditModal(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </ModalHeader>
              <ModalBody>
                <EditUser
                  user={user}
                  setOpenEditInfo={setOpenEditModal}
                  setUser={setUser}
                />
              </ModalBody>
            </Modal>
          </>
        )}
      </ModalTransition>
    </div>
  );
}
