import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import {
  faChampagneGlasses,
  faCheck,
  faChevronRight,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import UiduSpinner from "@uidu/spinner";
import UiduTooltip from "@uidu/tooltip";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useParams } from "react-router-dom";
import { FeeContext } from "../../App";
import { ProposeContext } from "../../pages/Propose";
import BaseComponent from "../BaseComponent";

export function ModalPromotion({ entity, openModal, setOpenModal, user }) {
  return (
    <ModalTransition>
      {openModal && (
        <div
          className="fixed inset-0 z-50 bg-black bg-opacity-50"
          key={Date.now()}
        >
          <Modal
            onClose={() => setOpenModal(false)}
            className="mt-0 md:mt-24"
            width="wide"
            shouldCloseOnOverlayClick={false}
            onStackChange={(e) => {
              if (e === 0) {
                setOpenModal(false);
              }
            }}
            stackIndex={1}
          >
            <ModalHeader className="border-b">
              <div className="flex items-center w-full gap-x-4 md:text-base">
                <FontAwesomeIcon
                  icon={faChampagneGlasses}
                  className="text-2xl text-primary"
                />
                <div className="text-2xl font-bold ">
                  Congratulazioni {user.name}!
                </div>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenModal(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </ModalHeader>
            <ModalBody>
              <div className="flex flex-col items-end justify-between w-full h-full gap-8 py-6">
                <div className="flex items-center w-full gap-x-4">
                  <div className="flex flex-col gap-y-2">
                    <p className="w-full text-xl font-semibold text-gray-400">
                      {entity === "structure"
                        ? "Per ringraziarti della fiducia, esco.zone ti offre una tariffa agevolata.✨"
                        : "Per ringraziarti della fiducia, esco.zone ti offre una tariffa agevolata.✨"}
                    </p>
                    <p className="w-full text-xl font-semibold text-gray-400">
                      {entity === "structure"
                        ? "Per i primi 50 partner, la commissione sarà ridotta all'8% anziché al 13% fino al 31/01/2026"
                        : "Per i primi 50 partner, la commissione sarà ridotta al 10% anziché al 16% fino al 31/01/2026."}
                    </p>
                  </div>
                </div>
                <button
                  onClick={() => {
                    setOpenModal(false);
                  }}
                  className="flex items-center justify-between w-full h-12 px-4 text-xl font-bold text-white rounded-md md:w-64 bg-primary"
                >
                  Prosegui
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      )}
    </ModalTransition>
  );
}

export default function Price() {
  const { id } = useParams();
  const { fees } = useContext(FeeContext);

  const {
    experience,
    setExperience,
    errors,
    setErrors,
    openModalPromotion,
    setOpenModalPromotion,
    userExperience,
  } = useContext(ProposeContext);

  const [isRecurrent, setIsRecurrent] = useState(experience.is_recurrent);
  const [isFree, setIsFree] = useState(
    experience?.price && experience?.price?.adult === 0
  );
  const [hasChildrenPrice, setHasChildrenPrice] = useState(
    experience?.price && experience?.price?.child !== -1
  );

  const updateExperience = async () => {
    const token = localStorage.getItem("token");
    const currentError = [
      {
        id: "adultPrice",
        error: null,
      },
      {
        id: "childrenPrice",
        error: null,
      },
      {
        id: "groupPrice",
        error: null,
      },
    ];

    if (
      document.getElementById("adultPrice").value === "" ||
      +document.getElementById("adultPrice").value.split("€")[1] <= 0
    ) {
      currentError[0].error = "Il prezzo deve essere definito e maggiore di 0";
      setErrors(currentError);
      return;
    }

    if (hasChildrenPrice) {
      if (
        document.getElementById("childrenPrice").value === "" ||
        +document.getElementById("childrenPrice").value.split("€")[1] <= 0
      ) {
        currentError[1].error =
          "Il prezzo per bambini deve essere definito e maggiore di 0";
        setErrors(currentError);
        return;
      }
    }

    if (
      isRecurrent &&
      experience?.max_private_group_size &&
      experience?.max_private_group_size !== -1
    ) {
      if (
        document.getElementById("groupPrice").value === "" ||
        +document.getElementById("groupPrice").value.split("€")[1] <= 0
      ) {
        currentError[2].error =
          "Il prezzo per i gruppi deve essere definito e maggiore di 0";
        setErrors(currentError);
        return;
      }
    }

    setErrors(currentError);

    if (currentError.every((el) => el.error === null)) {
      let data = {};

      if (isRecurrent) {
        data = {
          price: {
            adult: !isFree
              ? parseFloat(
                  +document
                    .getElementById("adultPrice")
                    .value.substring(1)
                    .replace(".", "")
                    .replace(",", ".")
                ) * 100
              : 0,
            child:
              hasChildrenPrice && !isFree
                ? parseFloat(
                    +document
                      .getElementById("childrenPrice")
                      .value.substring(1)
                      .replace(".", "")
                      .replace(",", ".")
                  ) * 100
                : -1,
            group_price: isFree
              ? 0
              : (experience?.max_private_group_size &&
                  experience?.max_private_group_size !== -1 &&
                  parseFloat(
                    +document
                      .getElementById("groupPrice")
                      .value.substring(1)
                      .replace(".", "")
                      .replace(",", ".")
                  ) * 100) ||
                -1,
          },
        };
      } else {
        data = {
          price: {
            adult: !isFree
              ? parseFloat(
                  +document
                    .getElementById("adultPrice")
                    .value.substring(1)
                    .replace(".", "")
                    .replace(",", ".")
                ) * 100
              : 0,
            child:
              hasChildrenPrice && !isFree
                ? parseFloat(
                    +document
                      .getElementById("childrenPrice")
                      .value.substring(1)
                      .replace(".", "")
                      .replace(",", ".")
                  ) * 100
                : -1,
          },
        };
      }

      console.log(data, "data");

      await axios
        .put(
          `${process.env.REACT_APP_SERVER_URL}/api/experience/edit/${id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setExperience(res.data.experience);
        })
        .catch(() => {
          alert("Errore durante la modifica dell'esperienza");
        });
    } else {
      setErrors(currentError);
      return;
    }
  };

  useEffect(() => {
    if (experience) {
      updateExperience();
    }
  }, []);

  useEffect(() => {
    updateExperience();
  }, [hasChildrenPrice]);

  useEffect(() => {
    updateExperience();
  }, [isFree]);

  return (
    <BaseComponent title="Tariffa">
      {openModalPromotion && (
        <ModalPromotion
          entity="experience"
          openModal={openModalPromotion}
          setOpenModal={setOpenModalPromotion}
          user={userExperience}
        />
      )}
      <div className="flex flex-col px-4 min-h-[400px] py-4 my-4 overflow-y-auto bg-gray-100 rounded-md gap-y-6">
        {experience ? (
          <>
            <div className="flex flex-col gap-y-6">
              <div className="flex items-center gap-x-4">
                <div className="flex items-center gap-x-2">
                  <input
                    type="checkbox"
                    id="isFree"
                    name="isFree"
                    className="hidden"
                    defaultChecked={isFree}
                  />
                  <label
                    htmlFor="isFree"
                    className="flex items-center cursor-pointer gap-x-2"
                    onClick={() => {
                      setIsFree(!isFree);
                      setHasChildrenPrice(false);
                    }}
                  >
                    <div
                      className={`min-w-5 w-5 h-5 border rounded-md shadow-sm text-white flex items-center justify-center ${
                        isFree
                          ? "bg-primary border-primary"
                          : "bg-white border-gray-300"
                      }`}
                    >
                      {isFree && (
                        <FontAwesomeIcon className="h-3" icon={faCheck} />
                      )}
                    </div>
                    <p className="font-semibold">L'esperienza è gratuita</p>
                  </label>
                </div>
                <UiduTooltip
                  content="Seleziona questa opzione se l'esperienza è gratuita"
                  position="bottom"
                >
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="cursor-pointer text-primary"
                  />
                </UiduTooltip>
              </div>
            </div>
            <div className={`${isFree ? "hidden" : "block"}`}>
              <div className="flex p-6 text-white rounded-md bg-primary">
                <div className="flex flex-col justify-between w-full text-sm">
                  <div className="flex flex-col gap-y-4">
                    <div className="flex items-center text-lg gap-x-4">
                      <FontAwesomeIcon icon={faCircleQuestion} />
                      <p className="font-semibold">
                        Come viene calcolato il prezzo dell'esperienza? &nbsp;
                        🤔
                      </p>
                    </div>
                    <p>
                      Esco applica una commissione del {fees.experiences.fee}%
                      sul prezzo dell'esperienza che viene visualizzato sul
                      portale. Il prezzo che imposti sarà quello che riceverai
                      al netto delle commissioni di stripe equivalente al 1,5% +
                      0,25€ per transazione o del 2,5% per i pagamenti con carta
                      britannica.In modo da ottimmizzare le prenotazioni, ti
                      consigliamo di mantenere un prezzo in linea al proprio
                      sito personale o ai propri canali di vendita.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:px-6 gap-y-6">
                <div className="grid items-center grid-cols-1 gap-6 pt-4 md:grid-cols-4">
                  <div className="grid items-center grid-cols-2 col-span-2 gap-x-4">
                    <div className="flex flex-col gap-y-1">
                      <p className="text-lg font-semibold">Tariffa adulti</p>
                      <p className="text-xs text-primary">
                        Commissioni escluse
                      </p>
                    </div>
                    <div className="flex flex-col gap-y-4">
                      <CurrencyInput
                        // key={experience?.price?.adult}
                        className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                        placeholder="€50"
                        id="adultPrice"
                        min={0}
                        prefix="€"
                        decimalsLimit={2}
                        decimalScale={2}
                        allowNegativeValue={false}
                        onValueChange={() => updateExperience()}
                        defaultValue={
                          experience?.price && experience?.price?.adult !== -1
                            ? experience?.price?.adult / 100
                            : ""
                        }
                      />
                      {/* {error.error && (
                      <p className="-mt-2 text-sm text-gray-400">{error.error}</p>
                    )} */}
                    </div>
                  </div>
                  <div className="flex items-center justify-between col-span-2 gap-x-4">
                    <div className="flex flex-col gap-y-1">
                      <p className="text-lg font-semibold">Prezzo</p>
                      <div className="flex text-xs text-primary gap-x-2">
                        <p>Visibile sul portale</p>
                        <UiduTooltip
                          content="il prezzo comprensivo delle commissioni di Esco arrotondato per eccesso, sarà quello che il cliente vedrà sul portale"
                          position="bottom"
                        >
                          <FontAwesomeIcon
                            icon={faQuestionCircle}
                            className="cursor-pointer text-primary"
                          />
                        </UiduTooltip>
                      </div>
                    </div>
                    {experience?.price && experience?.price?.adult !== -1 ? (
                      <div className="flex flex-col">
                        <div className="flex gap-x-4">
                          <p
                            className="font-bold md:text-xl"
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            {Math.ceil(
                              experience?.price?.adult / 100 +
                                (fees.experiences.fee *
                                  experience?.price?.adult) /
                                  10000
                            ).toFixed(0) + ",00"}
                            <span
                              className="absolute bg-primary"
                              style={{
                                content: "",
                                top: "50%",
                                left: -5,
                                width: "120%",
                                height: "2px", // Spessore della linea
                                transform: "rotate(-15deg)", // Angolo di inclinazione
                                transformOrigin: "center",
                              }}
                            />
                          </p>
                          <p className="font-bold md:text-xl">
                            {Math.ceil(
                              experience?.price?.adult / 100 +
                                (10 * experience?.price?.adult) / 10000
                            ).toFixed(0) + ",00"}{" "}
                            €
                          </p>
                        </div>
                        <p className="text-xs text-primary text-end">
                          Con promo fee al 10%
                        </p>
                      </div>
                    ) : (
                      <p className="font-bold md:text-xl">€ 0</p>
                    )}
                  </div>
                </div>
                <label
                  htmlFor="hasChildrenPrice"
                  className="flex items-center gap-x-3"
                >
                  <input
                    type="checkbox"
                    id="hasChildrenPrice"
                    name="hasChildrenPrice"
                    className="hidden"
                    defaultChecked={false}
                  />
                  <div
                    className={`min-w-5 w-5 h-5 border rounded-md shadow-sm text-white flex items-center justify-center ${
                      hasChildrenPrice
                        ? "bg-primary border-primary"
                        : "bg-white border-gray-300"
                    }`}
                    onClick={() => {
                      setHasChildrenPrice(!hasChildrenPrice);
                    }}
                  >
                    {hasChildrenPrice && (
                      <FontAwesomeIcon className="h-3" icon={faCheck} />
                    )}
                  </div>
                  L'esperienza prevede un prezzo differente per i bambini
                </label>
                {hasChildrenPrice && (
                  <>
                    <div className="border-b border-gray-200 " />
                    <div className="grid items-center grid-cols-1 gap-6 pt-4 mb-2 md:grid-cols-4">
                      <div className="grid items-center grid-cols-2 col-span-2 gap-x-4">
                        <div className="flex flex-col gap-y-1">
                          <p className="text-lg font-semibold">
                            Tariffa bambini
                          </p>
                          <p className="text-xs text-primary">
                            Commissioni escluse
                          </p>
                        </div>
                        <CurrencyInput
                          className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                          placeholder="€20"
                          id="childrenPrice"
                          min={0}
                          prefix="€"
                          decimalsLimit={2}
                          decimalScale={2}
                          allowNegativeValue={false}
                          defaultValue={
                            experience?.price && experience?.price?.child !== -1
                              ? experience?.price?.child / 100
                              : ""
                          }
                          onValueChange={() => updateExperience()}
                        />
                      </div>
                      <div className="flex items-center justify-between col-span-2 gap-x-4">
                        <div className="flex flex-col gap-y-1">
                          <p className="text-lg font-semibold">Prezzo</p>
                          <div className="flex text-xs text-primary gap-x-2">
                            <p>Visibile sul portale</p>
                            <UiduTooltip
                              content="il prezzo comprensivo delle commissioni di Esco arrotondato per eccesso, sarà quello che il cliente vedrà sul portale"
                              position="bottom"
                            >
                              <FontAwesomeIcon
                                icon={faQuestionCircle}
                                className="cursor-pointer text-primary"
                              />
                            </UiduTooltip>
                          </div>
                        </div>
                        {experience?.price &&
                        experience?.price?.child !== -1 ? (
                          // <p className="text-2xl font-bold">
                          //   €{" "}
                          //   {Math.ceil(
                          //     experience?.price?.child / 100 +
                          //       (fees.experiences.fee *
                          //         experience?.price?.child) /
                          //         10000
                          //   ).toFixed(0) + ",00"}
                          // </p>
                          <div className="flex flex-col">
                            <div className="flex gap-x-4">
                              <p
                                className="font-bold md:text-xl"
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                              >
                                {Math.ceil(
                                  experience?.price?.child / 100 +
                                    (fees.experiences.fee *
                                      experience?.price?.child) /
                                      10000
                                ).toFixed(0) + ",00"}
                                <span
                                  className="absolute bg-primary"
                                  style={{
                                    content: "",
                                    top: "50%",
                                    left: -5,
                                    width: "120%",
                                    height: "2px", // Spessore della linea
                                    transform: "rotate(-15deg)", // Angolo di inclinazione
                                    transformOrigin: "center",
                                  }}
                                />
                              </p>
                              <p className="font-bold md:text-xl">
                                {Math.ceil(
                                  experience?.price?.child / 100 +
                                    (10 * experience?.price?.child) / 10000
                                ).toFixed(0) + ",00"}{" "}
                                €
                              </p>
                            </div>
                            <p className="text-xs text-primary text-end">
                              Con promo fee al 10%
                            </p>
                          </div>
                        ) : (
                          <p className="font-bold md:text-xl">€ 0</p>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {isRecurrent &&
                  experience?.max_private_group_size &&
                  experience?.max_private_group_size !== -1 && (
                    <>
                      <div className="border-b border-gray-200 " />
                      <div className="grid items-center grid-cols-1 gap-6 pt-4 mb-6 md:grid-cols-4">
                        <div className="grid items-center grid-cols-2 col-span-2 gap-x-4">
                          <div className="flex flex-col gap-y-1">
                            <p className="text-lg font-semibold">
                              Tariffa gruppo
                            </p>
                            <p className="text-xs text-primary">
                              Commissioni escluse
                            </p>
                          </div>
                          <CurrencyInput
                            className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                            placeholder="€250"
                            id="groupPrice"
                            min={0}
                            prefix="€"
                            decimalsLimit={2}
                            decimalScale={2}
                            allowNegativeValue={false}
                            defaultValue={
                              experience?.price &&
                              experience?.price?.group_price !== -1
                                ? experience?.price?.group_price / 100
                                : ""
                            }
                            onValueChange={() => updateExperience()}
                          />
                        </div>
                        <div className="flex items-center justify-between col-span-2 gap-x-4">
                          <div className="flex flex-col gap-y-1">
                            <p className="text-lg font-semibold">Prezzo</p>
                            <div className="flex text-xs text-primary gap-x-2">
                              <p>Visibile sul portale</p>
                              <UiduTooltip
                                content="il prezzo comprensivo delle commissioni di Esco arrotondato per eccesso, sarà quello che il cliente vedrà sul portale"
                                position="bottom"
                              >
                                <FontAwesomeIcon
                                  icon={faQuestionCircle}
                                  className="cursor-pointer text-primary"
                                />
                              </UiduTooltip>
                            </div>
                          </div>
                          {experience?.price &&
                          experience?.price?.group_price !== -1 ? (
                            // <p className="text-2xl font-bold">
                            //   €{" "}
                            //   {Math.ceil(
                            //     experience?.price?.group_price / 100 +
                            //       (fees.experiences.fee *
                            //         experience?.price?.group_price) /
                            //         10000
                            //   ).toFixed(0) + ",00"}
                            // </p>
                            <div className="flex flex-col">
                              <div className="flex gap-x-4">
                                <p
                                  className="font-bold md:text-xl"
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  {Math.ceil(
                                    experience?.price?.group_price / 100 +
                                      (fees.experiences.fee *
                                        experience?.price?.group_price) /
                                        10000
                                  ).toFixed(0) + ",00"}
                                  <span
                                    className="absolute bg-primary"
                                    style={{
                                      content: "",
                                      top: "50%",
                                      left: -5,
                                      width: "120%",
                                      height: "2px", // Spessore della linea
                                      transform: "rotate(-15deg)", // Angolo di inclinazione
                                      transformOrigin: "center",
                                    }}
                                  />
                                </p>
                                <p className="font-bold md:text-xl">
                                  {Math.ceil(
                                    experience?.price?.group_price / 100 +
                                      (10 * experience?.price?.group_price) /
                                        10000
                                  ).toFixed(0) + ",00"}{" "}
                                  €
                                </p>
                              </div>
                              <p className="text-xs text-primary text-end">
                                Con promo fee al 10%
                              </p>
                            </div>
                          ) : (
                            <p className="font-bold md:text-xl">€ 0</p>
                          )}
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
