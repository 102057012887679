import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import structure from "../assets/icons/ALLOGGI_GENERIC.svg";
import box from "../assets/icons/ESCOBOXWHITE.svg";
import experience from "../assets/icons/EXPERIENCE_GENERIC.svg";
import { default as home } from "../assets/icons/HOME_GENERIC.svg";

export default function SelectPageTabMobile() {
  const { selectedTab, setSelectedTab } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  let lastScrollY = window.scrollY;

  useEffect(() => {
    if (location.pathname === "/") {
      setSelectedTab("home");
    } else if (location.pathname === "/experiences") {
      setSelectedTab("experience");
    } else if (location.pathname === "/structures") {
      setSelectedTab("structure");
    } else if (location.pathname === "/escoboxes") {
      setSelectedTab("box");
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`fixed rounded-t-[30px] bottom-0 z-50 grid w-full h-20 grid-cols-4 p-4 pt-0 md:hidden bg-secondary transition-transform duration-300 ${
        isVisible ? "translate-y-0" : "translate-y-full"
      }`}
    >
      <button
        type="button"
        className="relative z-20 flex flex-col items-center justify-center w-full h-full px-3 py-5 rounded-full gap-x-4 focus:outline-none gap-y-px"
        onClick={() => {
          navigate("/");
          setSelectedTab("home");
        }}
        style={{
          transform: selectedTab === "home" ? "scale(1.15)" : "none",
        }}
      >
        <img src={home} alt="home" className="relative z-20 w-6 h-6" />
        <p
          className="z-20 text-sm text-white"
          style={{
            fontWeight: selectedTab === "home" ? "600" : "400",
            fontSize: selectedTab === "home" ? "0.75rem" : "0.875rem",
          }}
        >
          home
        </p>
        {selectedTab === "home" && (
          <motion.div
            layoutId="selectedTabPage"
            className="absolute z-10 w-full rounded-full h-[56px] bg-primary"
          ></motion.div>
        )}
      </button>
      <button
        type="button"
        className="relative z-20 flex flex-col items-center justify-center w-full h-full px-3 py-5 rounded-full gap-x-4 focus:outline-none gap-y-px "
        onClick={() => {
          navigate("/experiences");
          setSelectedTab("experience");
        }}
        style={{
          transform: selectedTab === "experience" ? "scale(1.15)" : "none",
        }}
      >
        <img
          src={experience}
          alt="experience"
          className="relative z-20 w-6 h-6"
        />
        <p
          className="z-20 text-sm text-white"
          style={{
            fontWeight: selectedTab === "experience" ? "600" : "400",
            fontSize: selectedTab === "experience" ? "0.75rem" : "0.875rem",
          }}
        >
          esperienze
        </p>
        {selectedTab === "experience" && (
          <motion.div
            layoutId="selectedTabPage"
            className="absolute z-10 w-full rounded-full h-[56px] bg-primary"
          ></motion.div>
        )}
      </button>
      <button
        type="button"
        className="relative z-20 flex flex-col items-center justify-center w-full h-full px-3 py-5 rounded-full gap-x-4 focus:outline-none gap-y-px"
        onClick={() => {
          navigate("/structures");
          setSelectedTab("structure");
        }}
        style={{
          transform: selectedTab === "structure" ? "scale(1.15)" : "none",
        }}
      >
        <img
          src={structure}
          alt="structure"
          className="relative z-20 w-6 h-6"
        />
        <p
          className="z-20 text-sm text-white"
          style={{
            fontWeight: selectedTab === "structure" ? "600" : "400",
            fontSize: selectedTab === "structure" ? "0.75rem" : "0.875rem",
          }}
        >
          alloggi
        </p>
        {selectedTab === "structure" && (
          <motion.div
            layoutId="selectedTabPage"
            className="absolute z-10 w-full rounded-full h-[56px] bg-primary"
          ></motion.div>
        )}
      </button>
      <button
        type="button"
        className="relative z-20 flex flex-col items-center justify-center w-full h-full px-3 py-5 rounded-full gap-x-4 focus:outline-none gap-y-px"
        onClick={() => {
          navigate("/escoboxes");
          setSelectedTab("box");
        }}
        style={{
          transform: selectedTab === "box" ? "scale(1.15)" : "none",
        }}
      >
        <img src={box} alt="box" className="relative z-20 w-6 h-6" />
        <p
          className="z-20 text-sm text-white"
          style={{
            fontWeight: selectedTab === "box" ? "600" : "400",
            fontSize: selectedTab === "box" ? "0.75rem" : "0.875rem",
          }}
        >
          box
        </p>
        {selectedTab === "box" && (
          <motion.div
            layoutId="selectedTabPage"
            className="absolute z-10 w-full rounded-full h-[56px] bg-primary"
          ></motion.div>
        )}
      </button>
    </div>
  );
}
