import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Bounce, toast, ToastContainer } from "react-toastify";
import authentic from "../assets/auth.svg";
import box from "../assets/icons/ESCOBOXWHITE.svg";
import filter from "../assets/icons/FILTROWHITE.svg";
import itinerary from "../assets/icons/ITINERARIOWHITE.svg";
import magicGreen from "../assets/icons/MAGICGREEN.svg";
import map from "../assets/icons/MAPPAWHITE.svg";
import people from "../assets/icons/PEOPLEWHITE.svg";
import FaqComponent from "../components/FaqComponent";
import Footer from "../components/Footer";
import NavbarCreator from "../components/NavbarCreator";

export function TableComparison() {
  const intl = useIntl();
  return (
    <div className="relative border-4 border-primary rounded-[40px] md:rounded-[50px] mt-12 md:mt-36 overflow-scroll">
      <div className="grid grid-cols-5 min-w-[450px]">
        <div className="col-span-2" />
        <div className="flex items-center justify-center px-8 py-8 md:py-12 bg-primary rounded-tl-[60px] hidden md:flex">
          <p className="font-bold text-white md:text-xl">esco.zone</p>
        </div>
        <div className="flex items-center justify-center px-8 py-8 md:py-12 bg-primary rounded-tl-[30px] md:hidden">
          <p className="font-bold text-white md:text-xl">esco .zone</p>
        </div>
        <div className="flex items-center justify-center px-8 py-8 md:py-12 bg-primary">
          <p className="font-bold text-white md:text-xl">Online Adv</p>
        </div>
        <div className="flex items-center justify-center px-8 py-8 md:py-12 bg-primary rounded-tr-[30px] md:rounded-tr-[40px]">
          <p className="font-bold text-white md:text-xl">Offline Adv</p>
        </div>
        <div className="col-span-2 px-4 py-8 font-bold border-t-2 border-b border-r md:py-12 md:px-8 md:text-2xl border-primary">
          📈{" "}
          {intl.formatMessage({
            defaultMessage: "Conoscenze tecniche richieste",
            id: "EGMKTn",
          })}
        </div>
        <div className="flex items-center justify-center px-4 py-8 text-sm border md:py-12 md:px-8 md:text-2xl border-primary">
          {intl.formatMessage({
            defaultMessage: "limitate",
            id: "HTs0aj",
          })}
        </div>
        <div className="flex items-center justify-center px-4 py-8 text-sm border md:py-12 md:px-8 md:text-2xl border-primary">
          {intl.formatMessage({
            defaultMessage: "elevate",
            id: "SsgbVT",
          })}
        </div>
        <div className="flex items-center justify-center px-4 py-8 text-sm border border-r-0 md:py-12 md:px-8 md:text-2xl border-primary">
          {intl.formatMessage({
            defaultMessage: "moderate",
            id: "BUGYWB",
          })}
        </div>
        <div className="col-span-2 px-4 py-8 font-bold border-t border-b border-r md:py-12 md:px-8 md:text-2xl border-primary">
          📈{" "}
          {intl.formatMessage({
            defaultMessage: "Struttura dei costi",
            id: "AAwjPm",
          })}
        </div>
        <div className="flex items-center justify-center px-4 py-8 text-sm border md:py-12 md:text-2xl md:px-8 border-primary">
          {intl.formatMessage({
            defaultMessage: "commissione",
            id: "ahSgXh",
          })}
        </div>
        <div className="flex items-center justify-center px-4 py-8 text-sm border md:py-12 md:text-2xl md:px-8 border-primary">
          {intl.formatMessage({
            defaultMessage: "variabili",
            id: "qRohNF",
          })}
        </div>
        <div className="flex items-center justify-center px-4 py-8 border border-r-0 md:px-8 md:py-12 md:text-2xl border-primary">
          {intl.formatMessage({
            defaultMessage: "elevati",
            id: "o8+IND",
          })}
        </div>
        <div className="col-span-2 px-4 py-8 font-bold border-t border-b border-r md:py-12 md:px-8 md:text-2xl border-primary">
          📈{" "}
          {intl.formatMessage({
            defaultMessage: "Tempo per mettere in mostra la tua attività",
            id: "FckqQM",
          })}
        </div>
        <div className="flex items-center justify-center px-4 py-8 text-sm border md:py-12 md:text-2xl md:px-8 border-primary">
          {intl.formatMessage({
            defaultMessage: "ridotto",
            id: "mnbL9M",
          })}
        </div>
        <div className="flex items-center justify-center px-4 py-8 text-sm border md:py-12 md:text-2xl md:px-8 border-primary">
          {intl.formatMessage({
            defaultMessage: "elevato",
            id: "dUj1Gl",
          })}
        </div>
        <div className="flex items-center justify-center px-4 py-8 border border-r-0 md:px-8 md:py-12 md:text-2xl border-primary">
          {intl.formatMessage({
            defaultMessage: "elevato",
            id: "dUj1Gl",
          })}
        </div>
        <div className="col-span-2 px-4 py-12 font-bold border-t border-r md:px-8 md:text-2xl border-primary">
          📈{" "}
          {intl.formatMessage({
            defaultMessage: "Targeting",
            id: "1kses6",
          })}
        </div>
        <div className="flex items-center justify-center px-4 py-12 text-sm border border-b-0 md:text-2xl md:px-8 border-primary">
          {intl.formatMessage({
            defaultMessage: "mirato",
            id: "XFomsC",
          })}
        </div>
        <div className="flex items-center justify-center px-4 py-12 text-sm border border-b-0 md:text-2xl md:px-8 border-primary">
          {intl.formatMessage({
            defaultMessage: "limitato",
            id: "c0yoUl",
          })}
        </div>
        <div className="flex items-center justify-center px-4 py-12 border border-b-0 border-r-0 md:px-8 md:text-2xl border-primary">
          {intl.formatMessage({
            defaultMessage: "generico",
            id: "18i9rF",
          })}
        </div>
      </div>
    </div>
  );
}

export function CentralBecomePartnerMessage() {
  const intl = useIntl();
  return (
    <>
      <div className="hidden grid-cols-1 mt-36 gap-x-6 md:grid-cols-3 md:grid">
        <div className="flex flex-col text-center gap-y-4 border-4 border-primary rounded-[60px] p-6">
          <p className="text-6xl funnyFont text-primary">
            {intl.formatMessage({
              defaultMessage: "Local",
              id: "W4SaxY",
            })}
          </p>
          <p className="text-3xl font-thin md:text-4xl">
            {intl.formatMessage({
              defaultMessage:
                "il luogo perfetto per fare emergere le piccole realtà locali",
              id: "lfwJfc",
            })}
          </p>
        </div>
        <div className="flex flex-col text-center gap-y-4 border-4 border-primary rounded-[60px] p-6">
          <p className="text-6xl funnyFont text-primary">
            {intl.formatMessage({
              defaultMessage: "Su misura",
              id: "6eoJGD",
            })}
          </p>
          <p className="text-3xl font-thin md:text-4xl">
            {intl.formatMessage({
              defaultMessage:
                "grazie alle diverse categorie si adatta a diverse soluzioni",
              id: "6khj0n",
            })}
          </p>
        </div>
        <div className="flex flex-col text-center gap-y-4 border-4 border-primary rounded-[60px] p-6">
          <p className="text-6xl funnyFont text-primary">
            {intl.formatMessage({
              defaultMessage: "Authentic",
              id: "MzJPQ5",
            })}
          </p>
          <p className="text-3xl font-thin md:text-4xl">
            {intl.formatMessage({
              defaultMessage:
                "in linea con le nuove tendenze di viaggio esperienziale",
              id: "tfO67x",
            })}
          </p>
        </div>
        {/* ----- vertical lines ----- */}
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        {/*  ----- dots with icon ------ */}
        <div className="relative flex items-center justify-center w-full h-12">
          <div className="flex items-center justify-center w-20 h-20 rounded-full bg-primary">
            <img src={map} alt="local" className="w-12 h-12 mx-auto" />
          </div>
        </div>
        <div className="relative flex items-center justify-center w-full h-12">
          <div className="flex items-center justify-center w-20 h-20 rounded-full bg-primary">
            <img src={filter} alt="su misura" className="w-12 h-12 mx-auto" />
          </div>
        </div>
        <div className="relative flex items-center justify-center w-full h-12">
          <div className="flex items-center justify-center w-20 h-20 rounded-full bg-primary">
            <img
              src={authentic}
              alt="authentic"
              className="w-12 h-12 mx-auto"
            />
          </div>
        </div>
        {/* ----central message ----- */}
        <div className="-mt-6 flex px-44 py-24 items-center justify-center text-center border-4 border-primary rounded-[80px] p-6 col-span-3">
          <p className="text-8xl">
            <span className="funnyFont">
              {intl.formatMessage({
                defaultMessage: "I vantaggi",
                id: "QR4oH3",
              })}
            </span>
            &nbsp;
            <span className="font-thin">
              {intl.formatMessage({
                defaultMessage: " di essere un partner",
                id: "Q7gZmX",
              })}
            </span>
            <span className="font-bold text-primary"> esco.zone</span>
          </p>
        </div>
        {/*  ----- dots with icon ------ */}
        <div className="flex items-center justify-center w-full h-12 -mt-6">
          <div className="flex items-center justify-center w-20 h-20 rounded-full bg-primary">
            <img src={box} alt="risparmio" className="w-12 h-12 mx-auto" />
          </div>
        </div>
        <div className="flex items-center justify-center w-full h-12 -mt-6">
          <div className="flex items-center justify-center w-20 h-20 rounded-full bg-primary">
            <img src={itinerary} alt="easy" className="w-12 h-12 mx-auto" />
          </div>
        </div>
        <div className="flex items-center justify-center w-full h-12 -mt-6">
          <div className="flex items-center justify-center w-20 h-20 rounded-full bg-primary">
            <img src={people} alt="target" className="w-12 h-12 mx-auto" />
          </div>
        </div>
        {/* ----- vertical lines ----- */}
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="flex flex-col text-center gap-y-4 border-4 border-primary rounded-[60px] p-6">
          <p className="text-6xl funnyFont text-primary">
            {intl.formatMessage({
              defaultMessage: "Risparmio",
              id: "FH6Xrx",
            })}
          </p>
          <p className="text-3xl font-thin md:text-4xl">
            {intl.formatMessage({
              defaultMessage:
                "grazie al modello di commissioni più vantaggioso",
              id: "ZslgGz",
            })}
          </p>
        </div>
        <div className="flex flex-col text-center gap-y-4 border-4 border-primary rounded-[60px] p-6">
          <p className="text-6xl funnyFont text-primary">
            {intl.formatMessage({
              defaultMessage: "Easy",
              id: "6n4LpX",
            })}
          </p>
          <p className="text-3xl font-thin md:text-4xl">
            {intl.formatMessage({
              defaultMessage: "non è necessario avere competenze di marketing",
              id: "nTNJaI",
            })}
          </p>
        </div>
        <div className="flex flex-col text-center gap-y-4 border-4 border-primary rounded-[60px] p-6">
          <p className="text-6xl funnyFont text-primary">
            {intl.formatMessage({
              defaultMessage: "Target",
              id: "rzbYbE",
            })}
          </p>
          <p className="text-3xl font-thin md:text-4xl">
            {intl.formatMessage({
              defaultMessage:
                "raggiungi il pubblico interessato alla tua proposta di viaggio",
              id: "FXP7jH",
            })}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 mt-12 md:mt-36 md:hidden">
        <div className="flex py-12 px-4 items-center justify-center text-center border-4 border-primary rounded-[30px] ">
          <p className="text-3xl">
            <span className="funnyFont">
              {intl.formatMessage({
                defaultMessage: "I vantaggi",
                id: "QR4oH3",
              })}
            </span>
            <span className="font-thin">
              {intl.formatMessage({
                defaultMessage: " di essere un partner",
                id: "Q7gZmX",
              })}
            </span>
            <span className="font-bold text-primary"> esco.zone</span>
          </p>
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="relative z-10 flex items-center justify-center w-full h-10 -mt-4">
          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-primary">
            <img src={map} alt="local" className="w-6 h-6 mx-auto" />
          </div>
        </div>
        <div className="relative -mt-6 flex flex-col text-center gap-y-4 border-4 border-primary rounded-[30px] p-6">
          <p className="text-3xl funnyFont text-primary">
            {intl.formatMessage({
              defaultMessage: "Local",
              id: "W4SaxY",
            })}
          </p>
          <p className="text-2xl font-thin">
            {intl.formatMessage({
              defaultMessage:
                "il luogo perfetto per fare emergere le piccole realtà locali",
              id: "lfwJfc",
            })}
          </p>
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="relative z-10 flex items-center justify-center w-full h-10 -mt-4">
          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-primary">
            <img src={filter} alt="local" className="w-6 h-6 mx-auto" />
          </div>
        </div>
        <div className="relative -mt-6 flex flex-col text-center gap-y-4 border-4 border-primary rounded-[30px] p-6">
          <p className="text-3xl funnyFont text-primary">
            {intl.formatMessage({
              defaultMessage: "Su misura",
              id: "6eoJGD",
            })}
          </p>
          <p className="text-2xl font-thin">
            {intl.formatMessage({
              defaultMessage:
                "grazie alle diverse categorie si adatta a diverse soluzioni",
              id: "6khj0n",
            })}
          </p>
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="relative z-10 flex items-center justify-center w-full h-10 -mt-4">
          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-primary">
            <img src={authentic} alt="local" className="w-6 h-6 mx-auto" />
          </div>
        </div>
        <div className="relative -mt-6 flex flex-col text-center gap-y-4 border-4 border-primary rounded-[30px] p-6">
          <p className="text-3xl funnyFont text-primary">
            {intl.formatMessage({
              defaultMessage: "Authentic",
              id: "MzJPQ5",
            })}
          </p>
          <p className="text-2xl font-thin">
            {intl.formatMessage({
              defaultMessage:
                "in linea con le nuove tendenze di viaggio esperienziale",
              id: "tfO67x",
            })}
          </p>
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="relative z-10 flex items-center justify-center w-full h-10 -mt-4">
          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-primary">
            <img src={box} alt="local" className="w-6 h-6 mx-auto" />
          </div>
        </div>
        <div className="relative -mt-6 flex flex-col text-center gap-y-4 border-4 border-primary rounded-[30px] p-6">
          <p className="text-3xl funnyFont text-primary">
            {intl.formatMessage({
              defaultMessage: "Risparmio",
              id: "FH6Xrx",
            })}
          </p>
          <p className="text-2xl font-thin">
            {intl.formatMessage({
              defaultMessage:
                "grazie al modello di commissioni più vantaggioso",
              id: "ZslgGz",
            })}
          </p>
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="relative z-10 flex items-center justify-center w-full h-10 -mt-4">
          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-primary">
            <img src={itinerary} alt="local" className="w-6 h-6 mx-auto" />
          </div>
        </div>
        <div className="relative -mt-6 flex flex-col text-center gap-y-4 border-4 border-primary rounded-[30px] p-6">
          <p className="text-3xl funnyFont text-primary">
            {intl.formatMessage({
              defaultMessage: "Easy",
              id: "6n4LpX",
            })}
          </p>
          <p className="text-2xl font-thin">
            {intl.formatMessage({
              defaultMessage: "non è necessario avere competenze di marketing",
              id: "nTNJaI",
            })}
          </p>
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="relative z-10 flex items-center justify-center w-full h-10 -mt-4">
          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-primary">
            <img src={people} alt="local" className="w-6 h-6 mx-auto" />
          </div>
        </div>
        <div className="relative -mt-6 flex flex-col text-center gap-y-4 border-4 border-primary rounded-[30px] p-6">
          <p className="text-3xl funnyFont text-primary">
            {intl.formatMessage({
              defaultMessage: "Target",
              id: "rzbYbE",
            })}
          </p>
          <p className="text-2xl font-thin">
            {intl.formatMessage({
              defaultMessage:
                "raggiungi il pubblico interessato alla tua proposta di viaggio",
              id: "FXP7jH",
            })}
          </p>
        </div>
      </div>
    </>
  );
}

export default function BecomePartnerStructure() {
  const [userLogged, setUserLogged] = useState(null);
  const [emailVerified, setEmailVerified] = useState(null);
  const intl = useIntl();
  const navigate = useNavigate();
  const notify = () =>
    toast.error(
      intl.formatMessage({
        defaultMessage:
          "Devi verificare la tua email prima di poter caricare una struttura",
        id: "7KkUfp",
      }),
      {
        toastId: Math.random().toString(),
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        containerId: "BecomePartnerContainer",
      }
    );

  async function Start() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/loggedEmailVerified`)
        .then((res) => {
          setUserLogged(res.data.logged);
          setEmailVerified(res.data.verified);
        })
        .catch(() => {
          setUserLogged(false);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        });
    } else {
      setUserLogged(false);
    }
  }

  const createStructure = async () => {
    if (!userLogged) {
      navigate("/login");
      return;
    }

    if (!emailVerified) {
      notify();
      return;
    }

    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("user");

    const data = {
      user_id,
    };

    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/structure`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        const structure_id = res.data._id;
        const dataUser = {
          has_structures: true,
        };
        await axios
          .put(
            `${process.env.REACT_APP_SERVER_URL}/api/user/edit/${user_id}`,
            dataUser,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(async (res) => {
            await axios
              .post(
                `${process.env.REACT_APP_SERVER_URL}/api/room`,
                {
                  structure_id: structure_id,
                  name: "Camera 1",
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                navigate(`/proposeStructure/${structure_id}`);
              })
              .catch(() => {
                alert(
                  intl.formatMessage({
                    defaultMessage: "Errore durante la creazione della stanza",
                    id: "9nxx8l",
                  })
                );
              });
          })
          .catch(() => {
            alert(
              intl.formatMessage({
                defaultMessage: "Errore durante la modifica dell'utente",
                id: "Nqynhk",
              })
            );
          });
      })
      .catch(() => {
        alert(
          intl.formatMessage({
            defaultMessage: "Errore durante la creazione della struttura",
            id: "WobPRI",
          })
        );
      });
  };

  useEffect(() => {
    Start();
  }, []);

  useEffect(() => {
    document.getElementById("page-top").scrollIntoView({ behavior: "smooth" });
    document.title = "Esco | Diventa partner";
  }, []);

  return (
    <>
      <Helmet>
        <style>{`
          @import url('https://fonts.googleapis.com/css2?family=Caprasimo&display=swap');
          .funnyFont {
            font-family: 'Caprasimo', sans-serif;
          }
        `}</style>
      </Helmet>
      <div id="page-top">
        <header>
          <NavbarCreator fromPropose />
        </header>
        <main>
          <div className="relative">
            <div className="w-full">
              <div className="relative block md:hidden top-[83px]">
                <img
                  src="https://escopublic.s3.eu-north-1.amazonaws.com/cover_become_partner_structure.jpeg"
                  alt="becomePartnerStructureCover"
                  className="w-full  rounded-bl-[400px] rounded-br-[300px]"
                />
              </div>
              <div className="mx-0 2xl:mx-24">
                <div className="flex flex-col pt-24 mx-4 md:mx-24 md:pt-44 max-w-screen-2xl 2xl:mx-auto">
                  <div className="grid items-center w-full grid-cols-1 gap-4 md:grid-cols-2">
                    <div className="flex flex-col gap-y-6 md:gap-y-4">
                      <div className="relative flex flex-col gap-y-4 md:gap-y-8 max-w-[660px]  ">
                        <div className="flex flex-col text-center gap-y-4 md:text-start">
                          <div className="relative">
                            <h1 className="text-5xl font-semibold md:text-7xl text-escoBlack">
                              {intl.formatMessage({
                                defaultMessage: "Svela il fascino",
                                id: "VvWohi",
                              })}
                            </h1>
                            <div className="absolute hidden -right-6 md:-right-12 top-2 md:block">
                              <img
                                src={magicGreen}
                                alt="magicGreen"
                                className="w-12 mr-8 md:w-32"
                              />
                            </div>
                          </div>
                          <h2 className="text-5xl md:text-7xl text-primary funnyFont">
                            {intl.formatMessage({
                              defaultMessage: "del tuo alloggio unico",
                              id: "UChe5d",
                            })}
                          </h2>
                        </div>
                        <div className="font-semibold text-center md:text-3xl text-escoBlack md:text-start">
                          <p className="mb-8">
                            {intl.formatMessage({
                              defaultMessage:
                                "Ti senti trascurato tra le infinite offerte di alloggi generici?",
                              id: "3buNcD",
                            })}{" "}
                          </p>
                          <p className="pt-2 md:pt-4">
                            <span className="font-bold">esco.zone</span>{" "}
                            {intl.formatMessage({
                              defaultMessage:
                                "è qui per dare luce alla tua struttura autentica, aiutandoti a farla scoprire da chi cerca luoghi che raccontano una storia.",
                              id: "gtmNhq",
                            })}
                          </p>
                        </div>
                        <button
                          className="relative flex items-center self-center px-12 py-3 font-bold text-white rounded-full gap-x-4 md:text-xl w-fit bg-primary hover:bg-secondary md:self-start"
                          onClick={() => {
                            if (userLogged) {
                              createStructure();
                            } else {
                              navigate("/login");
                            }
                          }}
                        >
                          <span>
                            {intl.formatMessage({
                              defaultMessage: "Carica il tuo alloggio",
                              id: "fdzsD+",
                            })}
                          </span>
                          <div></div>
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="absolute w-4 h-4 md:w-6 md:h-6 right-4"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="absolute right-0 top-20 -right-24 w-[48%]">
                      <img
                        src="https://escopublic.s3.eu-north-1.amazonaws.com/cover_become_partner_structure.jpeg"
                        alt="becomePartnerStructureCover"
                        className="w-full h-full rounded-bl-[400px] rounded-tl-[250px] rounded-br-[300px] hidden md:block "
                      />
                    </div>
                  </div>
                  {/* <img
                    src="https://escopublic.s3.eu-north-1.amazonaws.com/becomePartnerExperienceCover.png"
                    alt="becomePartnerExperienceCover"
                    className="w-full rounded-[30px] mt-36 hidden md:block"
                  /> */}
                  <CentralBecomePartnerMessage />
                  <div className="grid grid-cols-1 md:grid-cols-2 bg-primary rounded-[30px] mt-12 md:mt-36 p-8 md:p-12 items-center">
                    <div className="flex items-center h-full mt-10">
                      <div className="flex flex-col w-full md:w-3/4 md:flex-row gap-x-12">
                        <div className="flex flex-col w-full">
                          <div className="flex items-center text-white justify-center border-2 border-white rounded-[60px] p-4 font-thin text-3xl md:text-6xl">
                            {intl.formatMessage({
                              defaultMessage: "Scopri se fai",
                              id: "K4F9e1",
                            })}
                          </div>
                          <div className="self-center h-10 border-r-2 border-white md:h-12" />
                          <div className="flex items-center justify-center">
                            <div className="absolute w-6 h-6 mt-1 bg-white rounded-full " />
                          </div>
                          <div className=" text-center flex items-center justify-center border-2 border-white text-white rounded-[60px] px-4 py-4 funnyFont text-3xl md:text-7xl">
                            {intl.formatMessage({
                              defaultMessage: "al caso nostro",
                              id: "kwUqwt",
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="hidden md:block relative w-1/4 h-[35%]">
                        <div className="flex flex-col w-full h-full border-b-2" />
                        <div className="absolute w-6 h-6 bg-white rounded-full -bottom-3 -right-3" />
                      </div>
                    </div>
                    <div className="relative flex items-center justify-center w-full md:hidden">
                      <div className="self-center h-10 border-r-2 border-white md:h-12" />
                      <div className="flex items-center justify-center">
                        <div className="absolute w-6 h-6 mt-10 bg-white rounded-full " />
                      </div>
                    </div>
                    <div className="md:mt-12 border-2 border-white rounded-[30px]">
                      <div className="grid grid-cols-1 text-white">
                        <div className="flex flex-col p-6 gap-y-6">
                          <p className="text-xl font-bold">
                            {intl.formatMessage({
                              defaultMessage: "Strutture ricettive ammesse",
                              id: "1BeOA6",
                            })}
                          </p>
                          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-4">
                            <ul className="px-4 list-disc">
                              <li className="text-lg">
                                {intl.formatMessage({
                                  defaultMessage: "Agriturismi",
                                  id: "lWIgIf",
                                })}
                              </li>
                              <li className="text-lg">
                                {intl.formatMessage({
                                  defaultMessage: "B&B",
                                  id: "vD2RWX",
                                })}
                              </li>
                            </ul>
                            <ul className="px-4 list-disc">
                              <li className="text-lg">
                                {intl.formatMessage({
                                  defaultMessage: "Rifugi",
                                  id: "QRgXYk",
                                })}
                              </li>
                              <li className="text-lg">
                                {intl.formatMessage({
                                  defaultMessage: "Glamping",
                                  id: "PhD2Qn",
                                })}
                              </li>
                              {/* <li className="text-lg">
                                {intl.formatMessage({
                                  defaultMessage: "Camping",
                                  id: "XjJcVS",
                                })}
                              </li> */}
                            </ul>
                            {/* <ul className="px-4 list-disc">
                              <li className="text-lg">
                                {intl.formatMessage({
                                  defaultMessage: "Glamping",
                                  id: "PhD2Qn",
                                })}
                              </li>
                            </ul> */}
                          </div>
                        </div>
                        <div className="flex flex-col p-6 border-t-2 border-white gap-y-6">
                          <p className="text-xl font-bold">
                            {intl.formatMessage({
                              defaultMessage: "Aspetti che ci stanno a cuore",
                              id: "fmbSHy",
                            })}
                          </p>
                          <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-4">
                            <ul className="col-span-2 px-4 list-disc">
                              <li className="text-lg">
                                {intl.formatMessage({
                                  defaultMessage:
                                    "Valorizzazione delle tradizioni locali",
                                  id: "olKfrZ",
                                })}
                              </li>
                              <li className="text-lg">
                                {intl.formatMessage({
                                  defaultMessage:
                                    "Posizione immersa nella natura o contesti rurali",
                                  id: "N8aLMU",
                                })}
                              </li>
                              <li className="text-lg">
                                {intl.formatMessage({
                                  defaultMessage:
                                    "Stile architettonico in armonia con il territorio",
                                  id: "2hDKWb",
                                })}
                              </li>
                              <li className="text-lg">
                                {intl.formatMessage({
                                  defaultMessage:
                                    "Proprietà storiche o tradizionali",
                                  id: "usfthi",
                                })}
                              </li>
                              <li className="text-lg">
                                {intl.formatMessage({
                                  defaultMessage: "Utilizzo di prodotti locali",
                                  id: "nuHqh4",
                                })}
                              </li>
                            </ul>
                            <ul className="px-4 list-disc">
                              <li className="text-lg">
                                {intl.formatMessage({
                                  defaultMessage:
                                    "Gestione familiare o piccole realtà indipendenti",
                                  id: "7rcW5c",
                                })}
                              </li>
                              <li className="text-lg">
                                {intl.formatMessage({
                                  defaultMessage: "Sostenibilità ambientale",
                                  id: "bWQh5Q",
                                })}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-4 border-primary rounded-[30px] mt-12 md:mt-36 p-4 md:p-12">
                    <p className="text-4xl font-thin text-center md:text-7xl text-escoBlack">
                      {intl.formatMessage({
                        defaultMessage: "L'attività di",
                        id: "MeCfh9",
                      })}{" "}
                      <span className="funnyFont">
                        {intl.formatMessage({
                          defaultMessage: "marketing perfetta ",
                          id: "ZZ6fTf",
                        })}
                      </span>
                      &nbsp;
                      {intl.formatMessage({
                        defaultMessage: "per le tue esigenze",
                        id: "FTTjFA",
                      })}
                    </p>
                  </div>
                  <TableComparison />
                  <div className="flex flex-col md:flex-row gap-x-12 bg-primary rounded-[30px] mt-12 md:mt-36 p-8 md:p-12">
                    <div className="w-full pb-8 md:w-3/4 md:pb-0">
                      <p className="text-3xl font-thin text-center text-white md:text-6xl">
                        {intl.formatMessage({
                          defaultMessage: "Bastano solo",
                          id: "S2F2un",
                        })}{" "}
                        <span className="relative">
                          <span className="absolute w-3 h-3 mt-4 -ml-1.5 bg-white rounded-full md:-ml-2 md:w-4 md:h-4 md:mt-6"></span>
                        </span>
                        <span className="px-2 md:px-4 py-0.5 md:py-2 text-white border border-white rounded-[40px] funnyFont">
                          15
                        </span>
                        <span className="relative mr-4">
                          <span className="absolute w-3 h-3 mt-4 -ml-1.5 bg-white rounded-full md:-ml-2 md:w-4 md:h-4 md:mt-6"></span>
                        </span>
                        <span className="text-white funnyFont">
                          {intl.formatMessage({
                            defaultMessage: "minuti ",
                            id: "BiXwvU",
                          })}
                        </span>
                        {intl.formatMessage({
                          defaultMessage: "per caricare la tua struttura",
                          id: "ATCsXz",
                        })}
                      </p>
                    </div>
                    <div className="flex flex-col justify-around w-full gap-y-4 md:w-1/4">
                      <button
                        className="w-full px-8 py-2 text-lg text-white border-2 border-white rounded-full hover:bg-secondary"
                        onClick={() => {
                          // window.location.href =
                          //   "https://escopublic.s3.eu-north-1.amazonaws.com/Esco_Guida_Creator.pdf";
                          console.log("download pdf");
                        }}
                      >
                        {intl.formatMessage({
                          defaultMessage: "Scarica il tutorial",
                          id: "MmACCk",
                        })}
                        -
                      </button>
                      <button
                        className="w-full px-8 py-2 text-lg bg-white border-2 border-white rounded-full text-primary hover:bg-gray-100"
                        onClick={() => {
                          if (userLogged) {
                            createStructure();
                          } else {
                            navigate("/login");
                          }
                        }}
                      >
                        {intl.formatMessage({
                          defaultMessage: "Carica la tua struttura",
                          id: "YVojPd",
                        })}
                      </button>
                    </div>
                  </div>
                  <div className="relative flex items-center justify-center w-full">
                    <div className="absolute z-10 w-10 h-10 -mt-1.5 rounded-full bg-secondary"></div>
                  </div>
                  <div className="flex items-center justify-center w-full">
                    <div className="border-2 h-36 md:h-64 border-left border-secondary"></div>
                  </div>
                  <div className="mb-16 md:mb-32">
                    <FaqComponent />
                    <div className="flex items-center justify-center ">
                      <button
                        className="px-16 py-4 mt-8 text-2xl bg-white border-2 rounded-full md:text-3xl md:px-32 text-primary border-primary hover:bg-primary hover:text-white"
                        onClick={() => {
                          if (userLogged) {
                            createStructure();
                          } else {
                            navigate("/login");
                          }
                        }}
                      >
                        {intl.formatMessage({
                          defaultMessage: "Diventa partner",
                          id: "tVcuIm",
                        })}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            containerId={`BecomePartnerContainer`}
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            theme="light"
            transition={Bounce}
            stacked
          />
        </main>
        <Footer />
      </div>
    </>
  );
}
