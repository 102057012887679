import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function AboutUs() {
  const navigate = useNavigate();
  const intl = useIntl();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    document.getElementById("page-top").scrollIntoView({ behavior: "smooth" });
    document.title = "Esco | Diventa partner";
  }, []);

  return (
    <>
      <Helmet>
        <style>{`
          @import url('https://fonts.googleapis.com/css2?family=Caprasimo&display=swap');
          .funnyFont {
            font-family: 'Caprasimo', sans-serif;
          }
        `}</style>
      </Helmet>
      <div id="page-top">
        <header>
          <Header />
        </header>
        <main>
          <div className="relative">
            <div className="w-full ">
              <div className="flex flex-col">
                <div
                  className="relative w-full"
                  style={{
                    height: windowWidth >= 768 ? "1020px" : "800px",
                    borderRadius:
                      windowWidth >= 768 ? "0 0 0 300px" : "0 0 0 150px",
                    transform: "translateY(-300px)",
                    zIndex: 5,
                    marginBottom: "0",
                  }}
                >
                  <img
                    src="https://escopublic.s3.eu-north-1.amazonaws.com/b567587c0095b1bf59398b09d9ba1466.jpeg"
                    className="object-cover object-center w-full h-full"
                    style={{
                      borderRadius:
                        windowWidth >= 768 ? "0 0 0 300px" : "0 0 0 80px",
                    }}
                    alt="cover"
                  />
                  <div
                    className="absolute top-0 z-10 flex flex-col items-start justify-end w-full h-full bg-black bg-opacity-30 shadow-[0px_20px_50px_0px_rgba(255,255,255,0.25)]"
                    style={{
                      borderRadius:
                        windowWidth >= 768 ? "0 0 0 300px" : "0 0 0 80px",
                      mixBlendMode: "hard-light",
                    }}
                  />
                  <div className="absolute top-0 z-20 w-full h-full">
                    <div className="flex items-center justify-center h-full mx-0 2xl:mx-8 ">
                      <div
                        className="flex flex-col mx-8 gap-y-4 max-w-screen-2xl 2xl:mx-auto lg:px-24 md:px-12 2xl:px-0"
                        style={{
                          alignItems:
                            windowWidth >= 768 ? "flex-start" : "center",
                          paddingTop: windowWidth >= 768 ? "300px" : "350px",
                        }}
                      >
                        <h1
                          className="mb-6 text-4xl text-white md:text-5xl xl:text-7xl funnyFont"
                          style={{
                            width: windowWidth >= 768 ? "50%" : "100%",
                          }}
                        >
                          {intl.formatMessage({
                            defaultMessage: "Una nuova filosofia di viaggio",
                            id: "oFRNUG",
                          })}
                        </h1>
                        <p className="w-full font-semibold text-white md:w-2/3 xl:w-1/2 md:text-xl">
                          {intl.formatMessage({
                            defaultMessage:
                              "esco.zone non è solo una piattaforma di viaggi, ma una porta d'accesso a esperienze autentiche che ti permettono di scoprire il vero cuore di un territorio. ",
                            id: "w0dYGE",
                          })}
                        </p>
                        <button
                          className="relative flex justify-between w-full py-4 pl-8 pr-16 mt-8 text-xl font-bold text-white rounded-full md:w-fit gap-x-12 md:pl-12 md:pr-20 xl:text-3xl bg-primary hover:bg-secondary"
                          onClick={() => {
                            navigate("/");
                          }}
                        >
                          <div>
                            {intl.formatMessage({
                              defaultMessage: "Inizia la tua avventura",
                              id: "RdBh1I",
                            })}
                          </div>
                          <div className="absolute right-6">
                            <FontAwesomeIcon icon={faArrowRight} />
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8 px-4 py-8 mx-0 -mt-72 md:-mt-52 2xl:mx-8 lg:px-20 lg:grid-cols-2 max-w-screen-2xl 2xl:mx-auto lg:gap-32">
            <div className="h-full rounded-[30px] bg-gray-200" />
            <div className="flex flex-col items-center justify-center gap-y-4 md:gap-y-8 lg:items-start lg:justify-start">
              <h1 className="text-3xl font-bold text-primary md:text-5xl xl:text-7xl">
                {intl.formatMessage({
                  defaultMessage: "Il nostro viaggio",
                  id: "taDXLe",
                })}
              </h1>
              <h1 className="mb-2 -mt-4 text-3xl md:mt-0 md:mb-8 funnyFont text-primary md:text-5xl xl:text-7xl">
                {intl.formatMessage({
                  defaultMessage: "inizia qui",
                  id: "8TNjZw",
                })}
              </h1>
              <div className="h-64 w-full rounded-[60px] bg-gray-100 lg:hidden md:max-w-[80%]" />
              <div className="flex flex-col text-center md:text-start md:leading-10 md:text-xl gap-y-2 md:gap-y-4 p-4 md:p-0 md:max-w-[80%]">
                <p>
                  {intl.formatMessage({
                    defaultMessage:
                      "Spesso si dice che per vivere davvero l’anima di un luogo bisogna immergersi nelle comunità locali e scoprire il territorio come un vero abitante. Tuttavia, trovare esperienze autentiche online è sempre più difficile, tra proposte commerciali e turismo di massa.",
                    id: "24G6Yi",
                  })}
                </p>
                <span className="text-lg md:text-2xl funnyFont text-primary">
                  {intl.formatMessage({
                    defaultMessage: "Esco.zone nasce per cambiare le regole",
                    id: "oBrUD0",
                  })}
                </span>
                <p>
                  {intl.formatMessage({
                    defaultMessage:
                      "promuoviamo attività e alloggi che raccontano la vera essenza delle aree rurali, lontano dai circuiti sovraffollati. Vogliamo essere il punto di riferimento per chi cerca un turismo più consapevole, sostenibile e ricco di valore umano.",
                    id: "0BkxDE",
                  })}
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8 px-4 py-8 mx-0 mt-2 md:mt-20 2xl:mx-8 lg:px-20 max-w-screen-2xl 2xl:mx-auto lg:gap-32">
            <div className="flex flex-col items-center justify-center pb-8 text-center gap-y-8">
              <h1 className="text-3xl text-primary md:text-5xl xl:text-6xl funnyFont max-w-[80%] md:max-w-[70%] xl:mb-8">
                {intl.formatMessage({
                  defaultMessage: "Valorizziamo i tesori nascosti",
                  id: "hrGVAJ",
                })}
              </h1>
              <div className="md:w-[80%] px-4 text-black md:text-xl flex flex-col gap-y-4 md:pb-8">
                <p>
                  {intl.formatMessage({
                    defaultMessage:
                      "Le aree rurali sono ricche di storia, natura, tradizioni e prodotti unici, ma spesso restano in ombra.",
                    id: "9acSzZ",
                  })}
                </p>
                <p className="font-bold">
                  {intl.formatMessage({
                    defaultMessage: "Con esco.zone:",
                    id: "q2LVm+",
                  })}
                </p>
              </div>
              <div className="grid grid-cols-1 gap-8 md:gap-16 md:grid-cols-3 md:text-xl">
                <div className="flex flex-col items-center gap-y-8">
                  <div className="w-full h-64 rounded-[60px] bg-gray-200" />
                  <p>
                    {intl.formatMessage({
                      defaultMessage:
                        "Connettiamo viaggiatori curiosi con esperienze autentiche e alloggi unici.",
                      id: "o0yYBY",
                    })}
                  </p>
                </div>
                <div className="flex flex-col items-center gap-y-8">
                  <div className="w-full h-64 rounded-[60px] bg-gray-200" />
                  <p>
                    {intl.formatMessage({
                      defaultMessage:
                        "Sosteniamo le comunità locali aiutandole a digitalizzarsi e promuoversi.",
                      id: "wRcnLp",
                    })}
                  </p>
                </div>
                <div className="flex flex-col items-center gap-y-8">
                  <div className="w-full h-64 rounded-[60px] bg-gray-200" />
                  <p>
                    {intl.formatMessage({
                      defaultMessage:
                        "Educhiamo le realtà del territorio sull’importanza dell’accoglienza, per garantire un futuro sostenibile e prospero.",
                      id: "8ScBnN",
                    })}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#EFEDED]">
            <div className="grid grid-cols-1 gap-8 px-4 py-8 mx-0 mt-2 md:mt-20 2xl:mx-8 lg:px-20 max-w-screen-2xl 2xl:mx-auto lg:gap-32">
              <div className="flex flex-col items-center justify-center py-12 text-center md:py-20 gap-y-8">
                <h1 className="text-3xl text-primary md:text-5xl xl:text-6xl funnyFont max-w-[80%] md:max-w-[60%] xl:mb-8">
                  {intl.formatMessage({
                    defaultMessage: "Una rivoluzione silenziosa nel turismo",
                    id: "dNHRPz",
                  })}
                </h1>
                <div className="md:w-[80%] px-4 text-black md:text-xl flex flex-col gap-y-4 md:pb-8">
                  <p>
                    {intl.formatMessage({
                      defaultMessage:
                        "Il nostro obiettivo non è solo offrire esperienze uniche, ma contribuire a preservare il patrimonio culturale e naturale delle piccole realtà.",
                      id: "PXpr1w",
                    })}
                  </p>
                </div>
                <div className="w-[90%] md:w-[80%] px-4 text-black md:text-xl flex flex-col gap-y-8 md:pb-8">
                  <div className="flex flex-col items-center gap-y-4 md:flex-row md:gap-x-16 md:gap-y-0">
                    <div className="w-48 h-48 rounded-[30px] bg-gray-200" />
                    <p className="w-full md:text-left md:w-5/6">
                      {intl.formatMessage({
                        defaultMessage:
                          "Promuoviamo solo attività locali autentiche.",
                        id: "QLX0BM",
                      })}
                    </p>
                  </div>
                  <div className="flex flex-col items-center gap-y-4 md:flex-row md:gap-x-16 md:gap-y-0">
                    <div className="w-48 h-48 rounded-[30px] bg-gray-200" />
                    <p className="w-full md:text-left md:w-5/6">
                      {intl.formatMessage({
                        defaultMessage:
                          "Valorizziamo la sostenibilità e il rispetto per il territorio.",
                        id: "XeslGu",
                      })}
                    </p>
                  </div>
                  <div className="flex flex-col items-center gap-y-4 md:flex-row md:gap-x-16 md:gap-y-0">
                    <div className="w-48 h-48 rounded-[30px] bg-gray-200" />
                    <p className="w-full md:text-left md:w-5/6">
                      {intl.formatMessage({
                        defaultMessage:
                          "Crediamo nel turismo come strumento per connettere le persone alle tradizioni, alla natura e alle storie che rendono ogni luogo speciale.",
                        id: "Mut9pm",
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8 px-4 py-8 mx-0 mt-2 md:mt-20 2xl:mx-8 lg:px-20 max-w-screen-2xl 2xl:mx-auto lg:gap-32">
            <div className="flex flex-col items-center text-center justify-center pt-4 pb-8 md:py-8 bg-primary rounded-[73px] gap-y-8">
              <div className="w-full px-4 md:px-8">
                <div
                  className="w-full h-56 md:h-96 rounded-[62px]"
                  style={{
                    backgroundImage:
                      "url('https://escopublic.s3.eu-north-1.amazonaws.com/blacca.jpeg')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </div>
              <h1 className="text-3xl text-white md:text-5xl xl:text-6xl funnyFont max-w-[90%] xl:mb-8">
                {intl.formatMessage({
                  defaultMessage: "Le nostre radici, la nostra ispirazione",
                  id: "oI/Pbj",
                })}
              </h1>
              <div className="w-[90%] md:w-[80%] px-4 text-white md:text-xl flex flex-col gap-y-4 md:pb-8">
                <p>
                  {intl.formatMessage({
                    defaultMessage:
                      "Siamo nati in un piccolo comune di 600 abitanti, e abbiamo vissuto in prima persona la bellezza e le sfide delle piccole comunità.",
                    id: "AoSRrK",
                  })}
                </p>
                <p>
                  {intl.formatMessage({
                    defaultMessage:
                      "Questo legame ci ha spinto a creare esco.zone, per dare voce ai territori meno conosciuti e alle persone che li rendono unici.",
                    id: "M0TlxY",
                  })}
                </p>
                <p>
                  {intl.formatMessage({
                    defaultMessage:
                      "Crediamo che il futuro del turismo passi da scelte responsabili, esperienze autentiche e ospiti che sappiano rispettare e valorizzare i luoghi che visitano.",
                    id: "Y3TnTW",
                  })}
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8 px-4 py-8 mx-0 mt-12 md:mt-20 2xl:mx-8 lg:px-20 max-w-screen-2xl 2xl:mx-auto lg:gap-32">
            <div
              style={{
                backgroundImage:
                  "url('https://escopublic.s3.eu-north-1.amazonaws.com/7a2535600c12238e7df0f167f56de784.jpeg')",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="relative flex flex-col items-center text-center justify-center py-16 md:py-20 bg-primary rounded-[73px] gap-y-8"
            >
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-65 rounded-[73px] z-10" />
              <h1 className="text-3xl text-white md:text-5xl xl:text-6xl funnyFont max-w-[80%] md:max-w-[60%] xl:mb-8 relative z-20">
                {intl.formatMessage({
                  defaultMessage: "Unisciti alla nostra missione.",
                  id: "a73D6E",
                })}
              </h1>
              <div className="w-[80%] md:w-[50%] px-4 text-white md:text-xl flex flex-col gap-y-4 md:gap-y-8 pb-8 relative z-20">
                <p>
                  {intl.formatMessage({
                    defaultMessage:
                      "Diamo spazio a esperienze autentiche e alloggi unici per chi vuole vivere il territorio in modo vero.",
                    id: "22m0Bc",
                  })}
                </p>
                <p>
                  {intl.formatMessage({
                    defaultMessage:
                      " Se anche tu credi in un turismo più consapevole, esco.zone è la vetrina giusta per te.",
                    id: "FuHXee",
                  })}
                </p>
              </div>
              <button
                className="relative z-20 flex justify-between py-4 pl-8 pr-16 mt-8 font-bold bg-white rounded-full text-primary w-fit gap-x-12 md:pl-12 md:pr-20 xl:text-xl hover:bg-gray-100"
                onClick={() => {
                  navigate("/becomePartner");
                }}
              >
                <div>
                  {intl.formatMessage({
                    defaultMessage: "Scopri di più",
                    id: "onwiq0",
                  })}
                </div>
                <div className="absolute right-6">
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </button>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}
