import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";

export default function PopUp({
  icon,
  items,
  iconStyle,
  setIsOpen,
  goUnderneath = false,
  orientation = "vertical",
  direction = null,
  length = 0,
  withoutEvent = false,
  isOpen = false,
}) {
  const popUpRef = useRef(null);
  const modalRef = useRef(null);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpWidth, setPopUpWidth] = useState(0);
  const [moalWidth, setModalWidth] = useState(0);
  const [isCalculating, setIsCalculating] = useState(true);

  const popUpStyle = {
    marginLeft: `-${moalWidth - popUpWidth}px`,
    visibility: isCalculating ? "hidden" : "visible",
  };

  useEffect(() => {
    if (popUpRef.current) {
      setPopUpWidth(popUpRef.current.offsetWidth);
    }
    if (modalRef.current) {
      setModalWidth(modalRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        setPopUpOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (setIsOpen) {
      setIsOpen(popUpOpen);
    }
  }, [popUpOpen]);

  useEffect(() => {
    setPopUpOpen(isOpen);
    if (modalRef.current && isOpen) {
      setModalWidth(modalRef.current.offsetWidth);
      setIsCalculating(false);
    }
  }, [isOpen]);

  return (
    <div>
      <div className="relative" ref={popUpRef}>
        {iconStyle === "bordered" ? (
          <div
            onClick={() => {
              if (!withoutEvent) {
                setPopUpOpen((prev) => !prev);
              }
            }}
            className="flex items-center justify-center w-6 h-6 border rounded-full cursor-pointer border-primary"
          >
            {icon}
          </div>
        ) : (
          <>
            {iconStyle === "noBackground" ? (
              <div
                onClick={() => {
                  if (!withoutEvent) {
                    setPopUpOpen((prev) => !prev);
                  }
                }}
                className="flex items-center justify-center h-6 rounded-full cursor-pointer"
              >
                {icon}
              </div>
            ) : (
              <div
                onClick={() => {
                  if (!withoutEvent) {
                    setPopUpOpen((prev) => !prev);
                  }
                }}
                className="flex items-center justify-center w-6 h-6 rounded-full cursor-pointer bg-primary"
              >
                {icon}
              </div>
            )}
          </>
        )}
      </div>
      <div className="absolute z-[9999999999]">
        <div className="relative z-[9999999999]">
          {orientation === "horizontal" && (popUpOpen || isOpen) && (
            <div
              className={`absolute z-[9999999999] w-52 bg-white border rounded-[30px] shadow-md -left-[220px] ${
                length > 2
                  ? "-top-[49px]"
                  : length === 2
                  ? "-top-[29px]"
                  : "-top-[9px]"
              }`}
            >
              {items}
            </div>
          )}
          {(popUpOpen || isOpen) &&
            !goUnderneath &&
            orientation === "vertical" && (
              <div
                className="absolute z-[9999999999] flex flex-col w-52 mt-0.5 bg-white border rounded-[30px] shadow-md"
                style={popUpStyle}
                ref={modalRef}
              >
                {items}
              </div>
            )}
          {(popUpOpen || isOpen) &&
            goUnderneath &&
            orientation === "vertical" &&
            !direction && (
              <div
                ref={modalRef}
                className="absolute z-[9999999999] flex flex-col w-52 mt-4 bg-white border rounded-[30px] shadow-md"
                style={popUpStyle}
              >
                {items}
              </div>
            )}
          {(popUpOpen || isOpen) &&
            goUnderneath &&
            orientation === "vertical" &&
            direction === "right" && (
              <div
                className="absolute z-[9999999999] flex flex-col w-52 mt-4 bg-white border rounded-[30px] shadow-md"
                style={popUpStyle}
                ref={modalRef}
              >
                {items}
              </div>
            )}
          {(popUpOpen || isOpen) &&
            goUnderneath &&
            orientation === "vertical" &&
            direction === "left" && (
              <div
                ref={modalRef}
                className="absolute -left-16 z-[9999999999] flex flex-col w-52 mt-4 bg-white border rounded-[30px] shadow-md"
              >
                {items}
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
