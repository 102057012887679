import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import React from "react";
import agriturismWhite from "../assets/agriturism.png";
import art from "../assets/art.png";
import authentic from "../assets/auth.png";
import BeBWhite from "../assets/b&b.png";
import campingWhite from "../assets/camping.png";
import glampingWhite from "../assets/glamping.png";
import logo from "../assets/logoMail.png";
import hostelWhite from "../assets/ostello.png";
import outdoor from "../assets/out.png";
import rifugioWhite from "../assets/rifugio.png";
import food from "../assets/wine.png";

const ExperienceIcon = ({ kind, dimension = 10 }) => {
  const getIconSrc = () => {
    switch (kind) {
      case "wine":
        return food;
      case "art":
        return art;
      case "outdoor":
        return outdoor;
      case "authentic":
        return authentic;
      default:
        return null;
    }
  };

  const iconSrc = getIconSrc();
  return iconSrc ? (
    <Image src={iconSrc} style={{ width: dimension, height: dimension }} />
  ) : null;
};

const StructureIcon = ({ kind, dimension = 10 }) => {
  console.log("structure icon kind", kind);
  const getIconSrc = () => {
    switch (kind) {
      case "ostello":
        return hostelWhite;
      case "B&B":
        return BeBWhite;
      case "camping":
        return campingWhite;
      case "glamping":
        return glampingWhite;
      case "rifugio":
        return rifugioWhite;
      case "agriturismo":
        return agriturismWhite;
      default:
        return null;
    }
  };

  const iconSrc = getIconSrc();

  console.log("structure icon src", iconSrc);
  return iconSrc ? (
    <Image src={iconSrc} style={{ width: dimension, height: dimension }} />
  ) : null;
};

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  section: {
    marginBottom: 10,
    padding: 20,
    borderRadius: 15,
    backgroundColor: "#246155",
  },
  sectionInfo: {
    padding: 20,
    borderRadius: 15,
    backgroundColor: "#ffffff",
  },
  title: {
    fontSize: 18,
    fontWeight: "semibold",
    marginBottom: 10,
    color: "#ffffff",
    display: "flex",
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
    color: "#979797",
  },
  image: {
    width: "100%",
    height: 200,
    marginBottom: 10,
    borderRadius: 15,
    objectFit: "cover",
    objectPosition: "center",
  },
  qrCode: {
    width: 100,
    height: 100,
    alignSelf: "center",
    marginTop: 20,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  column: {
    flexDirection: "column",
    justifyContent: "center",
  },
  label: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#231F1D",
  },
  value: {
    fontSize: 12,
    color: "#231F1D",
  },
  logo: {
    height: 50,
    width: 120,
    marginBottom: 20,
  },
});

const BookingDetailPdf = ({ booking, entity, kind, user, messages }) => {
  return (
    <Document>
      <Page style={styles.page}>
        <View>
          <Image src={logo} style={styles.logo} />
        </View>
        <View style={styles.section}>
          <View style={styles.title}>
            {kind === "experience" ? (
              <ExperienceIcon kind={entity.category} dimension={18} />
            ) : (
              <StructureIcon kind={entity.kind} dimension={18} />
            )}
            <Text>{entity.name}</Text>
          </View>
          <Image style={styles.image} src={entity.images[0]} />
          <View style={styles.sectionInfo}>
            <View style={styles.row}>
              <Text style={styles.label}>{messages.bookingCode}</Text>
              <Text style={styles.value}>{booking._id}</Text>
            </View>
            {kind === "experience" ? (
              <>
                <View style={styles.row}>
                  <Text style={styles.label}>{messages.date}</Text>
                  <Text style={styles.value}>
                    {dayjs(booking.start_date).format("DD/MM/YYYY")}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.label}>{messages.time}</Text>
                  <Text style={styles.value}>{booking.start_time}</Text>
                </View>
              </>
            ) : (
              <View style={styles.row}>
                <Text style={styles.label}>{messages.period}</Text>
                <Text style={styles.value}>
                  {dayjs(booking.start_date).format("DD/MM/YY")} -{" "}
                  {dayjs(booking.end_date).format("DD/MM/YY")}
                </Text>
              </View>
            )}
            <View style={styles.row}>
              <Text style={styles.label}>{messages.numberOfPeople}</Text>
              <Text style={styles.value}>
                {booking.guests_number.adults} {messages.adults}{" "}
                {booking.guests_number.children > 0 &&
                  `- ${booking.guests_number.children} ${messages.children}`}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>{messages.organizedBy}</Text>
              <Text style={styles.value}>
                {entity.user_id.name} {entity.user_id.surname}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>{messages.email}</Text>
              <Text style={styles.value}>{entity.user_id.email}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>{messages.address}</Text>
              <Text style={styles.value}>
                {entity.address.formatted_address}
              </Text>
            </View>
            <Image
              style={styles.qrCode}
              src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${booking._id}`}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default BookingDetailPdf;
