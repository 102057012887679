import axios from "axios";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import "tinymce/skins/content/default/content.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import commento from "../assets/icons/COMMENTO.svg";
import geotag from "../assets/icons/GEOTAG.svg";
import lettura from "../assets/icons/LETTURA.svg";
import like from "../assets/icons/LIKE.svg";
import CardUser from "../components/CardUser";
import CarouselArticles from "../components/CarouselArticles";
import Cover from "../components/Cover";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LoadingSpinnerPage from "../components/LoadingSpinnerPage";
import SingleReview from "../components/SingleReview";

export default function Article() {
  const { id } = useParams();
  const intl = useIntl();
  const [article, setArticle] = useState(null);
  const wordsPerMinute = 200;
  const [numberOfWords, setNumberOfWords] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const articleDetail = async () =>
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/article/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        alert("Errore durante il caricamento dell'articolo");
      });

  useEffect(() => {
    document.getElementById("page-top").scrollIntoView({ behavior: "smooth" });
    articleDetail().then((res) => {
      setArticle(res);
      setNumberOfWords(res.body.split(" ").length);
    });
  }, [id]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  document.title = "Esco | " + (article ? article.title : "Articolo");

  const options = {
    replace: (domNode) => {
      if (domNode.attribs) {
        switch (domNode.name) {
          case "h1":
            domNode.attribs.class = "text-4xl font-bold my-6";
            break;
          case "h2":
            domNode.attribs.class = "text-3xl font-bold my-6";
            break;
          case "p":
            domNode.attribs.class = "mb-4";
            break;
          case "ul":
            domNode.attribs.class = "list-disc list-outside ml-4 my-4";
            break;
          case "li":
            domNode.attribs.class = "my-2";
            break;
          default:
            break;
        }
      }
    },
  };

  return (
    <div id="page-top">
      <header>
        <Header />
      </header>
      <main>
        {article ? (
          <>
            <div>
              <Cover
                url={
                  article.images && article.images.length > 1
                    ? article.images[0]
                    : "https://images.unsplash.com/photo-1519681393784-d120267933ba?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
                isVideo={false}
              />
            </div>
            <div className="relative">
              <div className="relative w-full -mt-60 md:-mt-52 bg-white rounded-[60px] md:rounded-[210px] 2xl:-mt-60 lg:px-10 ">
                <div className="mx-4 md:mx-8 max-w-screen-2xl 2xl:mx-auto">
                  <div className="py-1 md:p-8">
                    <div className="flex justify-between w-full px-4 mt-8 mb-4 md:mb-8 md:mb-16 gap-x-2 md:gap-x-8">
                      <h1 className="text-xl font-bold text-start md:text-3xl md:text-4xl text-secondary">
                        {article.title}
                      </h1>
                    </div>
                    <div className="grid grid-cols-1">
                      <div className="flex flex-col px-4 gap-y-4 md:gap-y-6">
                        <div className="flex flex-wrap gap-6 text-lg md:flex-row md:justify-between">
                          {article.city ? (
                            <div>
                              <div className="flex items-center gap-x-2 md:gap-x-4 md:mr-12">
                                <img
                                  src={geotag}
                                  alt="geotag"
                                  className="w-[18px] md:w-6"
                                />
                                <div className="text-base font-semibold md:text-lg text-escoBlack">
                                  {article?.city}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="flex flex-row flex-wrap w-full gap-3 text-sm md:text-base">
                                {article.tags.map((tag) => (
                                  <div
                                    key={tag}
                                    className="flex items-center justify-center px-2 border rounded-md shadow-md text-primary border-primary"
                                  >
                                    {tag}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                          <div className="flex items-center gap-6">
                            <div className="flex items-center md:mr-12 gap-x-2 md:gap-x-4">
                              <img
                                src={like}
                                alt="like"
                                className="w-[18px] md:w-6"
                              />
                              <div className="text-base font-semibold md:text-lg text-escoBlack">
                                {windowWidth > 768
                                  ? intl.formatMessage(
                                      {
                                        defaultMessage: "{likes} like",
                                        id: "ApsDZG",
                                      },
                                      {
                                        likes: article.likes
                                          ? article.likes.length
                                          : 0,
                                      }
                                    )
                                  : article.likes
                                  ? article.likes.length
                                  : 0}
                              </div>
                            </div>
                            <div className="flex items-center md:mr-12 gap-x-2 md:gap-x-4">
                              <img
                                src={commento}
                                alt="commento"
                                className="w-[18px] md:w-6"
                              />
                              <div className="text-base font-semibold md:text-lg text-escoBlack">
                                {windowWidth > 768
                                  ? intl.formatMessage(
                                      {
                                        defaultMessage: "{comments} commenti",
                                        id: "UKlKKE",
                                      },
                                      {
                                        comments: article.comments
                                          ? article.comments.length
                                          : 0,
                                      }
                                    )
                                  : article.comments
                                  ? article.comments.length
                                  : 0}
                              </div>
                            </div>
                            <div className="flex items-center md:mr-12 gap-x-2 md:gap-x-4">
                              <img
                                src={lettura}
                                alt="lettura"
                                className="w-[18px] md:w-6"
                              />
                              <div className="text-base font-semibold md:text-lg text-escoBlack">
                                {windowWidth > 768
                                  ? `${Math.ceil(
                                      numberOfWords / wordsPerMinute
                                    )} min di
                              lettura`
                                  : `${Math.ceil(
                                      numberOfWords / wordsPerMinute
                                    )} min`}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="h-auto font-semibold md:text-xl text-escoBlack">
                          {article.sub_title}
                        </div> */}
                        {article.city && (
                          <div className="flex flex-row flex-wrap w-full gap-3 text-sm md:text-base">
                            {article.tags.map((tag) => (
                              <div
                                key={tag}
                                className="flex items-center justify-center px-2 border rounded-md shadow-md text-primary border-primary"
                              >
                                {tag}
                              </div>
                            ))}
                          </div>
                        )}
                        {/* <ImageGroup
                          images={article.images}
                          h={windowWidth > 768 ? "[500px]" : "[400px]"}
                        /> */}
                        <div className="h-auto">
                          {parse(article.body, options)}
                        </div>
                        <div className="w-full border-b border-primary" />
                        <div className="flex justify-between my-4">
                          <div className="flex items-start md:items-center gap-x-6 md:gap-x-2">
                            <img
                              src={commento}
                              alt="commento"
                              className="w-6 mt-0.5 md:mt-0"
                            />
                            <div className="text-lg font-semibold">
                              {intl.formatMessage({
                                defaultMessage: "Commenti:",
                                id: "vT2fTE",
                              })}
                            </div>
                          </div>
                          <div className="flex gap-x-4">
                            <div className="flex items-center gap-x-1.5 text-base text-primary">
                              <div>
                                {article.comments ? article.comments.length : 0}{" "}
                                {intl.formatMessage({
                                  defaultMessage: "commenti",
                                  id: "6Ltqf3",
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                          {article.comments ? (
                            <>
                              {article.reviews.map((review) => (
                                <SingleReview key={review.id} review={review} />
                              ))}
                            </>
                          ) : (
                            <div className="flex text-gray-400">
                              {intl.formatMessage({
                                defaultMessage:
                                  "Nessun commento disponibile per questo articolo",
                                id: "hG9JqS",
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col gap-y-6">
                        {article.user_id && <CardUser user={article.user_id} />}
                      </div>
                    </div>
                    <div className="hidden md:block">
                      <h1 className="m-12 text-3xl text-center md:text-4xl text-secondary">
                        {intl.formatMessage({
                          defaultMessage: "Articoli correlati",
                          id: "mTV+TS",
                        })}
                      </h1>
                      <div className="-mb-6">
                        <CarouselArticles />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:hidden">
                  <h1 className="m-12 text-xl font-bold text-center md:text-4xl text-secondary">
                    {intl.formatMessage({
                      defaultMessage: "Articoli correlati",
                      id: "mTV+TS",
                    })}
                  </h1>
                  <div className="-mb-6">
                    <CarouselArticles />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <LoadingSpinnerPage />
        )}
      </main>
      <Footer />
    </div>
  );
}
