import { faCheck, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { faHandSpock } from "@fortawesome/free-regular-svg-icons";
import BaseComponent from "../BaseComponent";

export default function CheckIn({ structures }) {
  const [selectedStructure, setSelectedStructure] = useState(structures[0]);
  const animatedComponents = makeAnimated();
  const [isFocused, setIsFocused] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [value, setValue] = useState(() => {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    return today;
  });
  const [loading, setLoading] = useState(false);

  const [rooms, setRooms] = useState([]);
  const [bookingsForTheDay, setBookingsForTheDay] = useState([]);

  const getRooms = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/roomsOfStructure/${selectedStructure._id}`
        )
        .then((res) => {
          setRooms(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getBookingsForTheDay = async (date) => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .post(`${process.env.REACT_APP_SERVER_URL}/api/roomsBookingsOnDate`, {
          structure_id: selectedStructure._id,
          date: dayjs(date).endOf("day").toDate(),
        })
        .then((res) => {
          setBookingsForTheDay(
            res.data.filter((booking) =>
              dayjs(booking.start_date).isSame(dayjs(date), "day")
            )
          );
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (selectedStructure) {
      setLoading(true);
      getRooms();
    }
  }, [selectedStructure]);

  useEffect(() => {
    if (rooms.length > 0) {
      getBookingsForTheDay(value);
    }
  }, [rooms]);

  return (
    <BaseComponent title="">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl text-center md:text-left text-primary">
          Check-in
        </h1>
        <div className="flex items-center pt-1 pr-4 gap-x-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Cerca prenotazioni"
              value={searchValue}
              className={` py-1.5 border-2 border-gray-300 rounded-md pl-10 transition-all duration-300 ${
                isFocused || searchValue ? "w-72 px-4" : "w-8"
              }`}
              onChange={(e) => setSearchValue(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
            <FontAwesomeIcon
              icon={faSearch}
              className="absolute text-gray-400 transform -translate-y-1/2 left-3 top-1/2"
            />
          </div>
          <button className="px-4 py-[7px] text-white rounded-md bg-primary">
            Scan QR Code
          </button>
        </div>
      </div>
      <div className="flex flex-col px-4 pt-4 mt-4 mb-12 overflow-y-auto rounded-md gap-y-6">
        {structures.length > 1 ? (
          <div className="flex items-center justify-between">
            <p className="text-lg font-semibold">Struttura</p>
            <div className="w-2/3">
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                menuPortalTarget={document.body}
                menuPosition="absolute"
                defaultValue={
                  selectedStructure && {
                    value: selectedStructure?._id,
                    label: selectedStructure?.name,
                  }
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "2px solid rgb(229 231 235)",
                    height: "35px",
                    width: "100%",
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: "#246155",
                    color: "white",
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: "white",
                  }),
                  multiValueRemove: (provided, state) => ({
                    ...provided,
                    color: "white",
                    ":hover": {
                      backgroundColor: "#328575",
                    },
                  }),
                }}
                options={structures.map((structure) => ({
                  value: structure._id,
                  label: structure.name,
                }))}
                onChange={(value) => {
                  setSelectedStructure(
                    structures.find(
                      (structure) => structure._id === value.value
                    )
                  );
                }}
              />
            </div>
          </div>
        ) : (
          <p className="text-gray-600">
            Fai il check-in delle prenotazioni di oggi
          </p>
        )}
        <div className="flex flex-col w-full mt-6 gap-y-6">
          {loading ? (
            <div className="flex items-center justify-center w-full h-32">
              <UiduSpinner size="medium" className="text-primary" />
            </div>
          ) : (
            <>
              {bookingsForTheDay.length > 0 ? (
                <div className="flex flex-col w-full gap-y-2">
                  <div className="flow-root h-[512px] ">
                    <div className="-my-2">
                      <div className="inline-block min-w-full py-2 align-middle">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="py-3 pl-4 pr-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase sm:pl-0"
                              >
                                Numero Prenotazione
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                              >
                                Nome Ospite
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                              >
                                Email Ospite
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                              >
                                Camera
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase text-end"
                              >
                                Numero Ospiti
                              </th>
                              <th
                                scope="col"
                                className="relative py-3 pl-3 pr-4 sm:pr-0"
                              >
                                <span className="sr-only">Check-in</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 ">
                            {bookingsForTheDay.map((booking, index) => (
                              <tr key={index}>
                                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">
                                  <div className="flex items-center">
                                    {booking._id}
                                  </div>
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                  <div className="text-sm font-medium text-gray-900">
                                    {booking.user_id.name}{" "}
                                    {booking.user_id.surname}
                                  </div>
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                  <div className="text-sm text-gray-500">
                                    {booking.user_id.email}
                                  </div>
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                  <div className="text-sm text-gray-500 text-start">
                                    {
                                      rooms.find(
                                        (room) => room._id === booking.room_id
                                      ).name
                                    }
                                  </div>
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                  <div className="text-sm text-gray-500 text-end">
                                    {booking.guests_number.adults} adulti{" "}
                                    {booking.guests_number.children > 0
                                      ? `-${booking.guests_number.children} bambini`
                                      : ""}
                                  </div>
                                </td>

                                {/* for the check-in we do a button that do it if it's not done yet or a check if it's done */}
                                <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-0">
                                  {booking.checked_in_at ? (
                                    <div className="flex items-center justify-end gap-x-6">
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        className="text-green-500"
                                      />
                                    </div>
                                  ) : (
                                    <div className="flex items-center justify-end gap-x-6">
                                      <button
                                        onClick={() => {}}
                                        type="button"
                                        className="px-6 py-1 text-white rounded-md bg-primary"
                                      >
                                        Check-in
                                      </button>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center py-10 gap-y-8">
                  <FontAwesomeIcon
                    icon={faHandSpock}
                    className="text-6xl text-gray-600"
                  />
                  <p className="text-lg font-bold text-gray-600">
                    Nessuna prenotazione per la data di oggi
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </BaseComponent>
  );
}
