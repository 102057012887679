import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoadScript } from "@react-google-maps/api";
import Tooltip from "@uidu/tooltip";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { SwiperSlide } from "swiper/react";
import { FavouriteContext } from "../App";
import heart from "../assets/heart.svg";
import heartFull from "../assets/heartFull.svg";
import camera from "../assets/icons/CAMERA.svg";
import geotag from "../assets/icons/GEOTAG.svg";
import mappa from "../assets/icons/MAPPA.svg";
import persone from "../assets/icons/PERSONE.svg";
import recensioni from "../assets/icons/REVIEW.svg";
import stella from "../assets/icons/STELLA.svg";
import logo from "../assets/logo.jpg";
import AddToCartEscoBox from "../components/AddToCartEscoBox";
import CardUser from "../components/CardUser";
import Carousel from "../components/Carousel";
import CarouselEscoBox from "../components/CarouselEscoBox";
import Cover from "../components/Cover";
import ExperienceIcon from "../components/ExperienceIcon";
import ExperienceModal from "../components/ExperienceModal";
import ExtendedSingleExperience from "../components/ExtendedSingleExperience";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ImageGroup from "../components/ImageGroup";
import LoadingSpinnerPage from "../components/LoadingSpinnerPage";
import Map from "../components/Map";
import SingleReview from "../components/SingleReview";
import StructureCard from "../components/StructureCard";
import StructureModal from "../components/StructureModal";

export default function EscoBox() {
  const { id } = useParams();
  const intl = useIntl();
  const [box, setBox] = useState(null);
  const [experiencesBox, setExperiencesBox] = useState([]);
  const [structuresBox, setStructuresBox] = useState([]);
  const [expandText, setExpandText] = useState(false);
  const [boxImages, setBoxImages] = useState([]);
  const [experienceModal, setExperienceModal] = useState(null);
  const [openExperienceModal, setOpenExperienceModal] = useState(false);
  const [structureModal, setStructureModal] = useState(null);
  const [openStructureModal, setOpenStructureModal] = useState(false);
  const { favourite, setFavourite } = useContext(FavouriteContext);
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  });
  const notify = (msg) =>
    toast.success(msg, {
      toastId: Math.random().toString(),
      position: "bottom-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: (
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="text-2xl text-primary"
        />
      ),
      containerId: `EscoBoxPageContainer`,
    });

  const boxDetail = async () =>
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/escoBox/${id}`)
      .then((res) => {
        console.log("boxessss", res.data);
        setBox(res.data.escoBox);
        setExperiencesBox(res.data.experiences);
        setStructuresBox(res.data.structuresData);
      })
      .catch(() => {
        alert(
          intl.formatMessage({
            defaultMessage: "Errore durante il caricamento dell'EscoBox",
            id: "TJhSCy",
          })
        );
      });

  useEffect(() => {
    document.getElementById("page-top").scrollIntoView({ behavior: "smooth" });
    boxDetail();
  }, [id]);

  useEffect(() => {
    if (box && experiencesBox.length > 0 && structuresBox.length > 0) {
      setBoxImages([
        box.cover,
        ...experiencesBox.map((exp) => exp.images).flat(),
        ...structuresBox.map((str) => str.images).flat(),
      ]);
    }
  }, [box, experiencesBox, structuresBox]);

  const averageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  const addToFavourites = async () => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    if (!user) {
      navigate("/login");
      return;
    } else {
      if (
        favourite &&
        favourite.length > 0 &&
        favourite[0]?.escoBoxes.find(
          (escoBox) => escoBox.escoBox_id === box._id
        )
      ) {
        const newEscoBoxes = favourite[0].escoBoxes.filter(
          (escoBox) => escoBox.escoBox_id !== box._id
        );
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              escoBoxes: newEscoBoxes,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            notify(
              intl.formatMessage({
                defaultMessage: "Box rimosso dai preferiti con successo.",
                id: "sXG1z6",
              })
            );
          });
      } else {
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              escoBoxes: [
                ...(favourite && favourite.length > 0
                  ? favourite[0].escoBoxes
                  : []),
                { escoBox_id: box._id },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            notify(
              intl.formatMessage({
                defaultMessage: "Box aggiunto ai preferiti con successo.",
                id: "fLehyd",
              })
            );
          });
      }
    }
  };

  document.title = "Esco | " + (box ? box.name : "EscoBox");

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div id="page-top">
      <header>
        <Header />
      </header>
      <main>
        {box ? (
          <>
            <div>
              <Cover url={box.cover} isVideo={false} />
            </div>
            <div className="relative">
              <div className="relative w-full -mt-60 md:-mt-52 bg-white rounded-[60px] md:rounded-[210px] 2xl:-mt-60 lg:px-10 ">
                <div className="absolute flex items-center justify-center w-full -mt-5">
                  <div className="p-1.5 bg-white rounded-full w-fit">
                    <div className="flex gap-2">
                      {box.category
                        .filter((c, index, self) => self.indexOf(c) === index)
                        .map((c, index) => (
                          <div
                            className="flex items-center justify-center w-8 h-8 rounded-full md:w-10 md:h-10 min-w-8"
                            key={`${c}-${index}`}
                            style={
                              c === "wine"
                                ? { backgroundColor: "#612430" }
                                : c === "art"
                                ? { backgroundColor: "#C8531C" }
                                : c === "outdoor"
                                ? { backgroundColor: "#4C8AFB" }
                                : c === "authentic"
                                ? { backgroundColor: "#CA9526" }
                                : null
                            }
                          >
                            <ExperienceIcon
                              kind={c}
                              dimension={windowWidth >= 768 ? 6 : 5}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="mx-4 md:mx-8 max-w-screen-2xl 2xl:mx-auto">
                  <div className="py-1 md:p-8">
                    <div className="flex justify-between w-full px-4 mt-8 mb-4 md:mb-8 md:mb-16 gap-x-2 md:gap-x-8">
                      {/* <div className="hidden md:block">
                        <div className="flex flex-col gap-2 mt-1 md:flex-row md:mt-0">
                          {box.category
                            .filter(
                              (c, index, self) => self.indexOf(c) === index
                            )
                            .map((c, index) => (
                              <div
                                className="flex items-center justify-center w-8 h-8 rounded-full md:w-10 md:h-10 min-w-8"
                                key={`${c}-${index}`}
                                style={
                                  c === "wine"
                                    ? { backgroundColor: "#612430" }
                                    : c === "art"
                                    ? { backgroundColor: "#C8531C" }
                                    : c === "outdoor"
                                    ? { backgroundColor: "#4C8AFB" }
                                    : c === "authentic"
                                    ? { backgroundColor: "#CA9526" }
                                    : null
                                }
                              >
                                <ExperienceIcon
                                  kind={c}
                                  dimension={windowWidth >= 768 ? 6 : 5}
                                />
                              </div>
                            ))}
                        </div>
                      </div> */}
                      <h1 className="text-xl font-bold text-start md:text-center md:text-3xl md:text-4xl text-secondary">
                        {box.name}
                      </h1>
                      <div className="mt-1 md:mt-0">
                        <button
                          className="flex items-center justify-center w-6 h-6 bg-white rounded-full md:w-10 md:h-10 min-w-8 md:min-w-10"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            addToFavourites();
                          }}
                        >
                          {/* <img src={heart} alt="heart" className="w-5" /> */}
                          {favourite &&
                          favourite.length > 0 &&
                          favourite[0].escoBoxes.find(
                            (escoBox) => escoBox.escoBox_id === box._id
                          ) ? (
                            <img
                              src={heartFull}
                              alt="heartFull"
                              className="h-6 md:h-10"
                            />
                          ) : (
                            <img
                              src={heart}
                              alt="heart"
                              className="h-6 md:h-10"
                            />
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
                      <div className="flex flex-col col-span-2 px-4 gap-y-4 md:gap-y-6">
                        <div className="flex flex-wrap gap-4 text-lg">
                          <div className="flex items-center mr-6 md:mr-12 gap-x-2 md:gap-x-4">
                            <img
                              src={geotag}
                              alt="geotag"
                              className="w-[18px] md:w-6"
                            />
                            <div className="text-base font-semibold md:text-lg text-escoBlack">
                              {box.city}
                            </div>
                          </div>
                          {box.guest_number && (
                            <div className="flex items-center mr-6 md:mr-12 gap-x-2 md:gap-x-4">
                              <img
                                src={persone}
                                alt="numero persone"
                                className="w-[18px] md:w-6"
                              />
                              <div className="text-base font-semibold md:text-lg text-escoBlack">
                                {box.guest_number} &nbsp;
                                {box.guest_number > 1
                                  ? intl.formatMessage({
                                      defaultMessage: "persone",
                                      id: "Pk3yp0",
                                    })
                                  : intl.formatMessage({
                                      defaultMessage: "persona",
                                      id: "s6OwHN",
                                    })}
                              </div>
                            </div>
                          )}
                          {box.duration && (
                            <div className="flex items-center mr-6 md:mr-12 gap-x-2 md:gap-x-4">
                              <img
                                src={camera}
                                alt="numero notti"
                                className="w-[18px] md:w-6"
                              />
                              <div className="text-base font-semibold md:text-lg text-escoBlack">
                                {box.duration} &nbsp;
                                {box.duration > 1
                                  ? intl.formatMessage({
                                      defaultMessage: "notti",
                                      id: "3+yThn",
                                    })
                                  : intl.formatMessage({
                                      defaultMessage: "notte",
                                      id: "/+ZZUB",
                                    })}
                              </div>
                            </div>
                          )}
                          <div className="flex items-center md:mr-12 gap-x-2 md:gap-x-4">
                            <img
                              src={stella}
                              alt="stella"
                              className="w-[18px] md:w-6"
                            />
                            <div className="text-base md:text-lg text-escoBlack">
                              <span className="font-semibold">
                                {box.reviews ? averageReviews(box.reviews) : 0}
                              </span>
                              /5{" "}
                              {windowWidth < 768 &&
                                `(${box.reviews ? box.reviews.length : 0})`}
                            </div>
                          </div>
                          <div className="items-center hidden text-base md:flex gap-x-4 text-primary">
                            <div>
                              {box.reviews ? box.reviews.length : 0}{" "}
                              {intl.formatMessage({
                                defaultMessage: "recensioni",
                                id: "zAyIZ1",
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row flex-wrap w-full gap-3 text-sm md:text-base">
                          {box.tags.map((tag) => (
                            <div
                              key={tag}
                              className="flex items-center justify-center px-2 border rounded-md shadow-md text-primary border-primary"
                            >
                              {tag}
                            </div>
                          ))}
                        </div>
                        <div className={expandText ? "h-auto" : "line-clamp-3"}>
                          {box.description}
                        </div>
                        <div className="relative flex justify-end -mt-4">
                          <button
                            className="text-primary"
                            onClick={() => setExpandText(!expandText)}
                          >
                            {" "}
                            {intl.formatMessage({
                              defaultMessage: "Continua a leggere...",
                              id: "1WQaA3",
                            })}
                          </button>
                        </div>
                        <div className="mb-6">
                          <ImageGroup images={boxImages || []} />
                        </div>
                        <div className="w-full border-b border-primary" />
                        <div className="flex flex-col my-4 gap-y-6">
                          <div className="text-lg font-semibold">
                            {intl.formatMessage({
                              defaultMessage: "Le esperienze incluse:",
                              id: "MMuqho",
                            })}
                          </div>
                          <div className="md:hidden">
                            <Carousel
                              slidesPerView={1}
                              withAutoplay={false}
                              withLoop={false}
                            >
                              {experiencesBox.map((experience, i) => (
                                <SwiperSlide key={i}>
                                  <div className="grid grid-cols-1 mb-6">
                                    <div
                                      className="cursor-pointer"
                                      onClick={() => {
                                        setOpenExperienceModal(true);
                                        setExperienceModal(experience);
                                      }}
                                    >
                                      <ExtendedSingleExperience
                                        item={experience}
                                      />
                                    </div>
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Carousel>
                          </div>
                          <div className="hidden gap-4 md:grid md:grid-cols-2">
                            {experiencesBox.map((experience) => (
                              <div
                                className="cursor-pointer"
                                key={experience.id}
                                onClick={() => {
                                  setOpenExperienceModal(true);
                                  setExperienceModal(experience);
                                }}
                              >
                                <ExtendedSingleExperience item={experience} />
                              </div>
                            ))}
                            {openExperienceModal && experienceModal && (
                              <ExperienceModal
                                openModal={openExperienceModal}
                                setOpenModal={setOpenExperienceModal}
                                experience={experienceModal}
                              />
                            )}
                          </div>
                        </div>
                        <div className="w-full border-b border-primary" />
                        <div className="flex flex-col my-4 gap-y-6">
                          <div className="text-lg font-semibold">
                            {intl.formatMessage({
                              defaultMessage:
                                "Scegli la struttura tra le opzioni disponibili:",
                              id: "xEVS4F",
                            })}
                          </div>
                          {structuresBox.map((structure) => (
                            <div key={structure.id} onClick={() => {}}>
                              <StructureCard
                                key={structure.id}
                                structure={structure}
                                box={box}
                                setOpenStructureModal={setOpenStructureModal}
                                setStructureModal={setStructureModal}
                              />
                            </div>
                          ))}
                          {openStructureModal && structureModal && (
                            <StructureModal
                              openModal={openStructureModal}
                              setOpenModal={setOpenStructureModal}
                              structure={structureModal}
                              box={box}
                            />
                          )}
                        </div>
                        <div className="w-full border-b border-primary" />
                        <div className="flex flex-col pb-4 my-4 md:my-0 gap-y-6">
                          <div className="flex flex-col text-lg font-semibold md:gap-2 md:items-end md:flex-row">
                            <div className="flex items-start md:items-center gap-x-6 md:gap-x-2">
                              <img
                                src={mappa}
                                alt="luogo"
                                className="w-6 mt-0.5 md:mt-0"
                              />
                              <div className="text-lg font-semibold">
                                {intl.formatMessage({
                                  defaultMessage: "Luogo:",
                                  id: "Ec9APS",
                                })}
                              </div>
                            </div>
                            <Tooltip
                              className="w-full md:w-3/4"
                              content={box.city}
                              position="bottom"
                            >
                              <div className="mt-1 ml-12 text-base font-normal md:truncate md:ml-4">
                                {box.city}
                              </div>
                            </Tooltip>
                          </div>
                          <div className="w-full rounded-md h-80">
                            <Map
                              address={{
                                latitude: 45.7658776,
                                longitude: 10.3767045,
                              }}
                              isLoaded={isLoaded}
                              loadError={loadError}
                            />
                          </div>
                        </div>
                        <div className="w-full border-b border-primary" />
                        <div className="flex justify-between my-4">
                          <div className="flex items-start md:items-center gap-x-6 md:gap-x-2">
                            <img
                              src={recensioni}
                              alt="recensioni"
                              className="w-6 mt-0.5 md:mt-0"
                            />
                            <div className="text-lg font-semibold">
                              {intl.formatMessage({
                                defaultMessage: "Recensioni:",
                                id: "yKJH0S",
                              })}
                            </div>
                          </div>
                          <div className="flex gap-x-4">
                            <div className="flex items-center gap-x-2">
                              <img src={stella} alt="stella" className="w-5" />
                              <div>
                                {box.reviews ? averageReviews(box.reviews) : 0}
                                /5{" "}
                                {windowWidth < 768 &&
                                  `(${box.reviews ? box.reviews.length : 0})`}
                              </div>
                            </div>
                            <div className="hidden md:flex items-center gap-x-1.5 text-base text-primary">
                              <div>
                                {box.reviews ? box.reviews.length : 0}{" "}
                                {intl.formatMessage({
                                  defaultMessage: "recensioni",
                                  id: "zAyIZ1",
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                          {box.reviews ? (
                            box.reviews.map((review) => (
                              <SingleReview key={review.id} review={review} />
                            ))
                          ) : (
                            <div className="flex text-gray-400">
                              {intl.formatMessage({
                                defaultMessage:
                                  "Nessuna recensione disponibile per questo esco box",
                                id: "GwaODi",
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex-col hidden md:flex gap-y-6">
                        {box.user ? (
                          <CardUser user={box.user} />
                        ) : (
                          <CardUser
                            user={{
                              image: logo,
                              name: "The esco",
                              surname: "team",
                              title: "Your travel buddy",
                              bio: "Dopo anni di esperienza nel settore del turismo, abbiamo deciso di mettere a disposizione la nostra passione e la nostra esperienza per creare un'esperienza unica e indimenticabile per te. Siamo pronti a guidarti in un viaggio alla scoperta di luoghi e culture, per farti vivere un'esperienza indimenticabile.",
                            }}
                          />
                        )}
                        <AddToCartEscoBox
                          element={box}
                          experiences={experiencesBox}
                          structures={structuresBox}
                        />
                      </div>
                    </div>
                    <div className="hidden md:block">
                      <h1 className="m-12 text-xl font-bold text-center md:text-4xl text-secondary">
                        {intl.formatMessage({
                          defaultMessage: "Scopri altre esco box",
                          id: "fR8cKz",
                        })}
                      </h1>
                      <div className="-mb-6">
                        <CarouselEscoBox selectedTab={box.category[0]} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:hidden">
                  <h1 className="m-12 text-xl font-bold text-center md:text-4xl text-secondary">
                    {intl.formatMessage({
                      defaultMessage: "Scopri altre esco box",
                      id: "fR8cKz",
                    })}
                  </h1>
                  <div className="-mb-6">
                    <CarouselEscoBox selectedTab={box.category[0]} />
                  </div>
                </div>
              </div>
              <div className="fixed z-50">
                <ToastContainer
                  containerId={`EscoBoxPageContainer`}
                  position="bottom-left"
                  autoClose={2000}
                  hideProgressBar
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover={false}
                  theme="light"
                  transition={Bounce}
                  stacked
                />
                <ToastContainer
                  containerId={`SingleBoxContainer`}
                  position="bottom-left"
                  autoClose={2000}
                  hideProgressBar
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover={false}
                  theme="light"
                  transition={Bounce}
                  stacked
                />
              </div>
            </div>
          </>
        ) : (
          <LoadingSpinnerPage />
        )}
      </main>
      <Footer />
    </div>
  );
}
