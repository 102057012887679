import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { motion, useAnimation } from "framer-motion";
import parse from "html-react-parser";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { FavouriteContext, FeeContext } from "../App";
import heart from "../assets/heart.svg";
import heartFull from "../assets/heartFull.svg";
import AddToCartExperience from "../components/AddToCartExperience";
import CardUser from "../components/CardUser";
import CarouselExperiences from "../components/CarouselExperiences";
import Cover from "../components/Cover";
import ExperienceBody from "../components/ExperienceBody";
import ExperienceIcon from "../components/ExperienceIcon";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ImageGroup from "../components/ImageGroup";
import LoadingSpinnerPage from "../components/LoadingSpinnerPage";
import ExperienceSubtitle from "../components/experienceComponents/ExperienceSubtitle";
import { options } from "./ExperiencePreview";

export default function Experience() {
  const { id } = useParams();
  const controls = useAnimation();
  const intl = useIntl();
  const [experience, setExperience] = useState(null);
  const [expandText, setExpandText] = useState(false);
  const [openCartMobile, setOpenCartMobile] = useState(false);
  const [changeBackArrowColor, setChangeBackArrowColor] = useState(false);
  const { favourite, setFavourite } = useContext(FavouriteContext);
  const ref = useRef(null);
  const { fees } = useContext(FeeContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const notify = (msg) =>
    toast.success(msg, {
      toastId: Math.random().toString(),
      position: "bottom-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: (
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="text-2xl text-primary"
        />
      ),
      containerId: `ExperiencePageContainer`,
    });

  const experienceDetail = async () =>
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/experiencePublic/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        alert(
          intl.formatMessage({
            defaultMessage: "Errore durante il caricamento dell'esperienza",
            id: "j0GVJj",
          })
        );
      });

  useEffect(() => {
    document.getElementById("page-top").scrollIntoView({ behavior: "smooth" });
    experienceDetail().then((res) => {
      console.log(res, "res");
      setExperience(res.experience);
    });
  }, [id]);

  const addToFavourites = async () => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    if (!user) {
      navigate("/login");
      return;
    } else {
      if (
        favourite &&
        favourite.length > 0 &&
        favourite[0].experiences.find(
          (experienceToFind) =>
            experienceToFind.experience_id === experience._id
        )
      ) {
        const newExperiences = favourite[0].experiences.filter(
          (experienceToFind) =>
            experienceToFind.experience_id !== experience._id
        );
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              experiences: newExperiences,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            notify(
              intl.formatMessage({
                defaultMessage:
                  "Esperienza rimossa dai preferiti con successo.",
                id: "u6pkrj",
              })
            );
          });
      } else {
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              experiences: [
                ...(favourite && favourite.length > 0
                  ? favourite[0].experiences
                  : []),
                { experience_id: experience._id },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            notify(
              intl.formatMessage({
                defaultMessage:
                  "Esperienza aggiunta ai preferiti con successo.",
                id: "dmjaR8",
              })
            );
          });
      }
    }
  };

  document.title = "Esco | " + (experience ? experience.name : "Esperienza");

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const referenceElement = ref.current;
      if (!referenceElement) return;

      const referencePosition = referenceElement.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (referencePosition <= windowHeight) {
        // Nascondi la sezione fissa quando l'elemento di riferimento è visibile
        controls.start({ y: 100, opacity: 0 });
        setChangeBackArrowColor(true);
      } else {
        // Mostra la sezione fissa quando l'elemento di riferimento non è visibile
        controls.start({ y: 0, opacity: 1 });
        setChangeBackArrowColor(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [controls, ref]);

  const experienceFee = experience
    ? experience.fee_id === "associations"
      ? fees.associations.fee
      : experience.fee_id
      ? fees.custom.find((f) => f._id === experience.fee_id).fee
      : fees.experiences.fee
    : fees.experiences.fee;

  const isAfterToday = experience
    ? experience.is_recurrent
      ? true
      : new Date(experience.data_info.start_date) > new Date()
    : false;

  return (
    <div id="page-top">
      <header>
        <Header />
      </header>
      <main>
        {experience ? (
          <>
            <div>
              <Cover
                url={
                  experience.images && experience.images.length > 0
                    ? experience.images[0]
                    : "https://images.unsplash.com/photo-1519681393784-d120267933ba?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
                isVideo={false}
              />
            </div>
            <div className="md:hidden">
              <button
                className={`fixed z-50 flex items-center justify-center w-10 h-10 text-white rounded-full top-24 left-4 ${
                  changeBackArrowColor ? "bg-secondary" : "bg-primary"
                }`}
                onClick={() => navigate(-1)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10 19l-7-7m0 0l7-7m-7 7h18"
                  />
                </svg>
              </button>
            </div>
            <div className="relative">
              <div className="relative w-full -mt-60 md:-md-52 bg-white rounded-[60px] md:rounded-[210px] 2xl:-mt-60 lg:px-10 ">
                <div className="absolute flex items-center justify-center w-full -mt-5 md:hidden">
                  <div className="p-1.5 bg-white rounded-full w-fit">
                    <div
                      className="flex items-center justify-center w-8 h-8 rounded-full md:w-10 md:h-10 min-w-8"
                      style={
                        experience.category === "wine"
                          ? { backgroundColor: "#612430" }
                          : experience.category === "art"
                          ? { backgroundColor: "#C8531C" }
                          : experience.category === "outdoor"
                          ? { backgroundColor: "#4C8AFB" }
                          : experience.category === "authentic"
                          ? { backgroundColor: "#CA9526" }
                          : null
                      }
                    >
                      <ExperienceIcon
                        kind={experience.category}
                        dimension={windowWidth >= 768 ? 6 : 5}
                      />
                    </div>
                  </div>
                </div>
                <div className="mx-4 md:mx-8 max-w-screen-2xl 2xl:mx-auto">
                  <div className="py-1 md:p-8">
                    <div className="flex justify-between w-full px-4 mt-8 mb-4 md:mb-8 md:justify-center md:mb-16 gap-x-2 md:gap-x-8">
                      <div className="hidden md:block">
                        <div
                          className="flex items-center justify-center w-8 h-8 rounded-full md:w-10 md:h-10 min-w-8"
                          style={
                            experience.category === "wine"
                              ? { backgroundColor: "#612430" }
                              : experience.category === "art"
                              ? { backgroundColor: "#C8531C" }
                              : experience.category === "outdoor"
                              ? { backgroundColor: "#4C8AFB" }
                              : experience.category === "authentic"
                              ? { backgroundColor: "#CA9526" }
                              : null
                          }
                        >
                          <ExperienceIcon
                            kind={experience.category}
                            dimension={windowWidth >= 768 ? 6 : 5}
                          />
                        </div>
                      </div>
                      <h1 className="text-xl font-bold text-start md:text-center md:text-3xl md:text-4xl text-secondary">
                        {experience.name}
                      </h1>
                      <div className="mt-1 md:mt-0">
                        <button
                          className="flex items-center justify-center w-6 h-6 bg-white rounded-full md:w-10 md:h-10 min-w-8 md:min-w-10"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            addToFavourites();
                          }}
                        >
                          {/* <img src={heart} alt="heart" className="w-5" /> */}
                          {favourite &&
                          favourite.length > 0 &&
                          favourite[0].experiences.find(
                            (experienceToFind) =>
                              experienceToFind.experience_id === experience._id
                          ) ? (
                            <img
                              src={heartFull}
                              alt="heartFull"
                              className="h-6 md:h-10"
                            />
                          ) : (
                            <img
                              src={heart}
                              alt="heart"
                              className="h-6 md:h-10"
                            />
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
                      <div className="flex flex-col col-span-2 px-4 gap-y-4 md:gap-y-6">
                        <ExperienceSubtitle experience={experience} />
                        <div className="flex flex-row w-full">
                          {experience.tags.map((tag) => (
                            <div
                              key={tag}
                              className="flex items-center justify-center px-2 ml-3 border rounded-md shadow-md text-primary border-primary"
                            >
                              {tag}
                            </div>
                          ))}
                        </div>
                        <div className={expandText ? "h-auto" : "line-clamp-3"}>
                          {parse(experience.description, options)}
                        </div>
                        <div className="relative flex justify-end -mt-4">
                          <button
                            className="text-primary"
                            onClick={() => setExpandText(!expandText)}
                          >
                            {" "}
                            {!expandText
                              ? intl.formatMessage({
                                  defaultMessage: "Continua a leggere...",
                                  id: "1WQaA3",
                                })
                              : intl.formatMessage({
                                  defaultMessage: "Mostra meno",
                                  id: "oXRP6k",
                                })}
                          </button>
                        </div>
                        <div className="mb-6">
                          <ImageGroup images={experience.images} />
                        </div>
                        <div className="w-full border-b border-primary" />
                        <ExperienceBody experience={experience} />
                      </div>
                      <div className="flex-col hidden md:flex gap-y-6">
                        <CardUser user={experience.user_id} />
                        {!experience.showcase_mode && isAfterToday && (
                          <AddToCartExperience element={experience} />
                        )}
                      </div>
                    </div>
                    <div className="hidden md:block">
                      <h1
                        ref={ref}
                        className="m-12 text-3xl text-center md:text-3xl md:text-4xl text-secondary"
                      >
                        {intl.formatMessage({
                          defaultMessage: "Esperienze correlate",
                          id: "o3yKYJ",
                        })}
                      </h1>
                      <div className="-mb-6">
                        <CarouselExperiences
                          selectedTab={experience.category}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:hidden">
                  <h1
                    ref={ref}
                    className="m-12 text-xl font-bold text-center md:text-4xl text-secondary"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Esperienze correlate",
                      id: "o3yKYJ",
                    })}
                  </h1>
                  <div className="-mb-6">
                    <CarouselExperiences selectedTab={experience.category} />
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer
              containerId={`ExperiencePageContainer`}
              position="bottom-left"
              autoClose={2000}
              hideProgressBar
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover={false}
              theme="light"
              transition={Bounce}
              stacked
            />
            {openCartMobile && (
              <div className="fixed top-0 bottom-0 left-0 right-0 z-50 bg-white md:hidden">
                <AddToCartExperience
                  element={experience}
                  setOpenCartMobile={setOpenCartMobile}
                />
              </div>
            )}
            {!experience.showcase_mode && isAfterToday && (
              <motion.div
                id="fixed-section"
                className="fixed bottom-0 right-0 z-40 w-full p-4 pb-5 bg-white shadow-inner md:hidden"
                initial={{ y: 100, opacity: 0 }}
                animate={controls}
                transition={{ duration: 0.2 }}
              >
                <p className="font-bold text-primary">
                  {intl.formatMessage({
                    defaultMessage: "Da",
                    id: "vvU2PG",
                  })}{" "}
                  {Math.ceil(
                    experience.price.adult / 100 +
                      (experienceFee * experience.price.adult) / 10000
                  ).toFixed(0) + ",00"}{" "}
                  €
                </p>
                <div className="flex w-full mt-2 gap-x-4">
                  <button
                    className="w-full py-2 text-white rounded-full bg-primary"
                    onClick={() => setOpenCartMobile((prev) => !prev)}
                  >
                    {intl.formatMessage({
                      defaultMessage: "Prenota",
                      id: "dsKjB5",
                    })}
                  </button>
                  <button
                    className="w-full py-2 text-white rounded-full bg-escoBlack"
                    onClick={() => setOpenCartMobile((prev) => !prev)}
                  >
                    {intl.formatMessage({
                      defaultMessage: "Regala",
                      id: "TjWSJm",
                    })}
                  </button>
                </div>
              </motion.div>
            )}
          </>
        ) : (
          <LoadingSpinnerPage />
        )}
      </main>
      <Footer />
    </div>
  );
}
