import {
  faExclamationTriangle,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

export default function ModalMultipleDayAvailability({
  openModal,
  setOpenModal,
  rooms,
  structure,
}) {
  const [selectedRoom, setSelectedRoom] = useState(null);
  const animatedComponents = makeAnimated();
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [maxQuantity, setMaxQuantity] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedRoom) {
      setSelectedQuantity(
        rooms.find((r) => r._id === selectedRoom.value).quantity
      );
      setMaxQuantity(rooms.find((r) => r._id === selectedRoom.value).quantity);
    }
  }, [selectedRoom]);

  const handleSave = async () => {
    const room = selectedRoom
      ? rooms.find((r) => r._id === selectedRoom.value)
      : null;
    const start_date = document.getElementById("start_date").value
      ? dayjs(document.getElementById("start_date").value).format("YYYY-MM-DD")
      : null;
    const end_date = document.getElementById("end_date").value
      ? dayjs(document.getElementById("end_date").value).format("YYYY-MM-DD")
      : null;

    if (!selectedRoom) {
      setError("Seleziona una stanza");
      return;
    }
    if (!start_date || !end_date) {
      setError("Inserisci le date");
      return;
    }

    if (end_date < start_date) {
      setError("La data di fine non può essere minore di quella di inizio");
      return;
    }

    const quantityToBook = room.quantity - selectedQuantity;
    const data = {
      room_id: selectedRoom.value,
      start_date,
      end_date,
      rooms_number_reserved: quantityToBook,
      structure_id: structure._id,
    };

    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/api/bookingRoomReservationMultipleDays`,
          data
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.message === "Rooms reserved successfully") {
            setOpenModal(false);
            setSelectedRoom(null);
            setError(null);
          } else {
            setError(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          setError("Errore durante la prenotazione");
        });
    } else {
      console.log("Token not found");
    }
  };

  return (
    <ModalTransition>
      {openModal && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
          <Modal
            onClose={() => {
              setOpenModal(false);
              setSelectedRoom(null);
              setError(null);
            }}
            className="mt-0 md:mt-5"
            width="x-large"
          >
            <ModalHeader className="!px-4 md:!px-8 border-b">
              <h3 className="text-2xl text-primary">
                Modifica disponibilità per più giorni
              </h3>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenModal(false);
                  setSelectedRoom(null);
                  setError(null);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </ModalHeader>
            <ModalBody className="!p-4 md:!p-8">
              <div className="flex flex-col gap-y-8">
                <div className="flex flex-col gap-y-2">
                  <p className="font-semibold">Stanza:</p>
                  <Select
                    isMulti={false}
                    closeMenuOnSelect
                    components={animatedComponents}
                    menuPortalTarget={document.body}
                    menuPlacement="bottom"
                    menuPosition="fixed"
                    id="room"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: "2px solid rgb(229 231 235)",
                        minHeight: "35px",
                      }),
                      multiValue: (provided) => ({
                        ...provided,
                        backgroundColor: "#246155",
                        color: "white",
                      }),
                      multiValueLabel: (provided) => ({
                        ...provided,
                        color: "white",
                      }),
                      multiValueRemove: (provided, state) => ({
                        ...provided,
                        color: "white",
                        ":hover": {
                          backgroundColor: "#328575",
                        },
                      }),
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    options={rooms.map((room) => ({
                      value: room._id,
                      label: room.name,
                    }))}
                    placeholder="Seleziona la stanza"
                    onChange={(value) => {
                      setSelectedRoom(value);
                    }}
                  />
                </div>
                <div className="flex flex-col w-full gap-y-2 md:flex-row md:gap-x-4">
                  <div className="flex flex-col w-full gap-y-2 md:w-1/2">
                    <p className="font-semibold">Data inizio:</p>
                    <input
                      type="date"
                      id="start_date"
                      min={dayjs().format("YYYY-MM-DD")}
                      className="px-2 py-2 border-2 border-gray-200 rounded-md"
                    />
                  </div>
                  <div className="flex flex-col w-full gap-y-2 md:w-1/2">
                    <p className="font-semibold">Data fine:</p>
                    <input
                      type="date"
                      id="end_date"
                      min={dayjs().format("YYYY-MM-DD")}
                      className="p-2 border-2 border-gray-200 rounded-md"
                    />
                  </div>
                </div>
                {selectedRoom && (
                  <div className="flex flex-col gap-4 my-2 md:flex-row md:items-center">
                    <p className="w-full font-semibold md:w-1/3">
                      Stanze disponibili :
                    </p>
                    <div className="flex items-center justify-between w-full px-4 py-2 border-2 rounded-md md:w-2/3 gap-x-4 boder-gray-200">
                      <button
                        className="flex items-center justify-center w-6 h-6 text-xs text-[#585858] border-2 border-gray-200 rounded-md"
                        name="minus"
                        onClick={(e) => {
                          if (selectedQuantity > 0) {
                            setSelectedQuantity(selectedQuantity - 1);
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </button>
                      <div className="text-center ">
                        {selectedQuantity}
                        {" su "}
                        {maxQuantity}
                      </div>
                      <button
                        className="flex items-center justify-center w-6 h-6 text-xs text-[#585858] border-2 border-gray-200 rounded-md"
                        name="plus"
                        onClick={(e) => {
                          if (selectedQuantity < maxQuantity) {
                            setSelectedQuantity(selectedQuantity + 1);
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </ModalBody>
            <ModalFooter className="!px-4 md:!px-8 border-t">
              <div className="flex items-center justify-between w-full">
                <div>
                  {error && (
                    <div className="p-4 bg-red-100 rounded-md">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className="w-5 h-5 text-red-700"
                          />
                        </div>
                        <div className="ml-3">
                          <div className="text-sm text-red-700 ">
                            <p>{error}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => {
                      setOpenModal(false);
                      setSelectedRoom(null);
                      setError(null);
                    }}
                    className="px-4 py-2 bg-gray-200 rounded-md "
                  >
                    Annulla
                  </button>
                  <button
                    onClick={() => {
                      handleSave();
                    }}
                    className="px-4 py-2 text-white rounded-md bg-primary"
                  >
                    Salva
                  </button>
                </div>
              </div>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </ModalTransition>
  );
}
