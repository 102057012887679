import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import calendar from "../assets/icons/CALENDARIO.svg";
import datiPersonali from "../assets/icons/DATI_PERSONALI.svg";
import durata from "../assets/icons/DURATA.svg";
import manage from "../assets/icons/MANAGE.svg";
import persone from "../assets/icons/PERSONE.svg";
import propose from "../assets/icons/PROPOSE.svg";
import ExperienceBody from "./ExperienceBody";
import ExperienceIcon from "./ExperienceIcon";

export default function ModalBookingDetail({
  openModal,
  setOpenModal,
  booking,
  entity,
  kind,
}) {
  const intl = useIntl();
  const [expandText, setExpandText] = useState(false);
  const [manageBooking, setManageBooking] = useState(false);

  console.log("this is the entity", entity);

  return (
    <ModalTransition>
      <Modal
        onClose={() => setOpenModal(false)}
        width="calc(85vw - 2rem)"
        height="calc(100vh - 2rem)"
        className="[--modal-dialog-gutter:1rem] [--modal-dialog-translate-y:0] md:mt-4 shadow-lg"
      >
        <ModalHeader className="border-b border-gray-100">
          <div className="flex flex-col md:items-center gap-x-6 md:flex-row">
            <h3 className="text-xl font-bold md:text-2xl text-primary">
              {intl.formatMessage({
                defaultMessage: "Dettagli prenotazione",
                id: "ethKtj",
              })}
            </h3>
            <div className="flex items-center gap-x-2">
              {booking.status === "pending" ? (
                <div className="w-2 h-2 bg-yellow-500 rounded-full md:w-3 md:h-3"></div>
              ) : (
                <div className="w-2 h-2 bg-green-500 rounded-full md:w-3 md:h-3"></div>
              )}
              <div
                className={`text-lg md:text-xl font-bold ${
                  booking.status === "pending"
                    ? "text-yellow-500"
                    : "text-green-500"
                }`}
              >
                {booking.status === "pending"
                  ? intl.formatMessage({
                      defaultMessage: "In attesa",
                      id: "ZrWE4k",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Confermato",
                      id: "QCqewK",
                    })}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-x-4 md:gap-x-6">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setManageBooking((prev) => !prev);
              }}
              className="flex items-center px-6 py-4 font-bold bg-gray-200 rounded-lg md:py-2 hover:bg-gray-300 gap-x-3"
            >
              <img src={manage} alt="manage" className="w-4 h-4" />
              <span className="hidden md:block">
                {intl.formatMessage({
                  defaultMessage: "Gestisci prenotazione",
                  id: "ZmuQzj",
                })}
              </span>
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/
                2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="md:py-4">
            {kind === "experience" && (
              <div className="flex flex-col mt-4 gap-y-6">
                <div className="flex items-center justify-between w-full gap-x-4">
                  <div className="flex items-center w-full pb-4 gap-x-4">
                    <div className="flex items-center justify-center w-6 h-6 rounded-full ">
                      {booking.start_time ? (
                        <ExperienceIcon kind={entity.category} color="black" />
                      ) : (
                        <img
                          src={voucher}
                          alt="is a present"
                          className="w-6 h-6"
                        />
                      )}
                    </div>
                    <div className="font-bold md:text-2xl">{entity.name}</div>
                  </div>
                  <div className="justify-end hidden w-56 md:flex">
                    <button
                      onClick={() => console.log("vedi qr")}
                      className="px-6 py-2 font-bold text-white rounded-lg bg-primary hover:bg-secondary"
                    >
                      {intl.formatMessage({
                        defaultMessage: "Vedi qr code",
                        id: "Br68ic",
                      })}
                    </button>
                  </div>
                </div>
                <div className="grid w-full grid-cols-1 gap-8 mb-4 md:grid-cols-2">
                  <div className="relative w-full -mt-px overflow-hidden rounded-[30px] h-60 md:h-96">
                    <Swiper
                      navigation={true}
                      modules={[Navigation]}
                      className="mySwiper"
                      loop={true}
                    >
                      {entity.images.length > 0 &&
                        entity.images.map((image) => (
                          <SwiperSlide key={image}>
                            <img
                              src={image}
                              alt="experience image"
                              className="object-cover w-full rounded-lg h-60 md:h-96"
                            />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                  <div className="flex flex-col justify-around gap-y-6">
                    <div className="flex items-center mt-4 gap-x-4">
                      <img
                        src={propose}
                        alt="propose"
                        className="w-[18px] md:w-6"
                      />
                      <div className="font-semibold">
                        {intl.formatMessage({
                          defaultMessage: "Codice prenotazione:",
                          id: "ifSI7L",
                        })}
                      </div>
                      <div className="text-primary">{booking._id}</div>
                    </div>
                    <div className="w-full border-t border-primary" />
                    <div className="flex flex-col w-full py-4 gap-y-6 ">
                      <div className="flex items-center gap-x-4">
                        <img
                          src={datiPersonali}
                          alt="organizzatore"
                          className="w-[18px] md:w-6"
                        />
                        <div className="font-semibold">
                          {intl.formatMessage({
                            defaultMessage: "Organizzato da:",
                            id: "zOkaQe",
                          })}
                        </div>
                      </div>
                      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                        <div className="flex items-center gap-x-4">
                          <img
                            src={
                              entity.user_id.profile_pic
                                ? entity.user_id.profile_pic
                                : "https://www.gravatar.com/avatar/?d=mp&s=200"
                            }
                            alt="user"
                            className="object-cover w-8 h-8 rounded-full"
                          />
                          <div className="ml-4 text-primary">
                            {entity.user_id.name} {entity.user_id.surname}
                          </div>
                        </div>
                        <div className="flex items-center gap-x-4">
                          <div className="font-semibold">
                            {intl.formatMessage({
                              defaultMessage: "N° di  telefono:",
                              id: "NN/D1x",
                            })}
                          </div>
                          <div className="ml-4 text-primary">
                            {entity.user_id.phone_number}
                          </div>
                        </div>
                        <div className="flex items-center gap-x-4">
                          <div className="font-semibold">
                            {intl.formatMessage({
                              defaultMessage: "Email:",
                              id: "xpTPb3",
                            })}
                          </div>
                          <div className="ml-4 text-primary">
                            {entity.user_id.email}
                          </div>
                        </div>
                        <div className="flex items-center gap-x-4">
                          <div className="font-semibold">
                            {intl.formatMessage({
                              defaultMessage: "Indirizzo:",
                              id: "n+31up",
                            })}
                          </div>
                          <div className="ml-4 text-primary">
                            {entity.address.formatted_address}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full border-t border-primary" />
                    <div className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2">
                      <div className="flex items-center w-full gap-x-4">
                        <img
                          src={calendar}
                          alt="calendar"
                          className="w-[18px] md:w-6"
                        />
                        <div className="font-semibold">
                          {intl.formatMessage({
                            defaultMessage: "Data:",
                            id: "m19lxP",
                          })}
                        </div>
                        <div className="ml-4 text-primary">
                          {dayjs(booking.start_date).format("DD/MM/YYYY")}
                        </div>
                      </div>
                      <div className="flex items-center w-full gap-x-4">
                        <img
                          src={durata}
                          alt="ora inizio"
                          className="w-[18px] md:w-6"
                        />
                        <div className="font-semibold">
                          {intl.formatMessage({
                            defaultMessage: "Ora:",
                            id: "JOd7sM",
                          })}
                        </div>
                        <div className="ml-4 text-primary">
                          {booking.start_time}
                        </div>
                      </div>
                      <div className="flex items-center w-full gap-x-4">
                        <img
                          src={persone}
                          alt="persone"
                          className="w-[18px] md:w-6"
                        />
                        <div className="font-semibold">
                          {intl.formatMessage({
                            defaultMessage: "N° di persone:",
                            id: "iR/Jy5",
                          })}
                        </div>
                        <div className="flex flex-col gap-2 ml-4 text-primary md:flex-row">
                          {booking.guests_number.adults ? (
                            <div>
                              {booking.guests_number.adults}{" "}
                              {intl.formatMessage({
                                defaultMessage: "adulti",
                                id: "HR89WG",
                              })}
                            </div>
                          ) : null}
                          {booking.guests_number.children ? (
                            <div>
                              {booking.guests_number.children}{" "}
                              {intl.formatMessage({
                                defaultMessage: "bambini",
                                id: "ImjG1h",
                              })}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <ExperienceSubtitle experience={entity} /> */}
                <div className="w-full border-b border-primary" />
                <div className={expandText ? "h-auto" : "line-clamp-3"}>
                  {entity.description}
                </div>
                <div className="relative flex justify-end -mt-4">
                  <button
                    className="text-primary"
                    onClick={() => setExpandText(!expandText)}
                  >
                    {" "}
                    {!expandText ? "Continua a leggere..." : "Mostra meno"}
                  </button>
                </div>
                <div className="w-full border-b border-primary" />
                <ExperienceBody experience={entity} hideReviews />
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter className="border-t border-gray-100">
          <div className="flex items-center gap-x-4">
            <button
              onClick={() => setOpenModal(false)}
              className="px-6 py-2 font-bold text-gray-600 bg-gray-200 rounded-lg hover:bg-gray-300"
            >
              {intl.formatMessage({
                defaultMessage: "Chiudi",
                id: "v1MlfW",
              })}
            </button>
            <button
              onClick={() => console.log("Scarica Voucher")}
              className="px-6 py-2 font-bold text-white rounded-lg bg-primary hover:bg-secondary"
            >
              {intl.formatMessage({
                defaultMessage: "Scarica Voucher",
                id: "i0BYWp",
              })}
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </ModalTransition>
  );
}
