import { faArrowRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { LocationValleys } from "./utils/LocationValleys";

export default function Cover({
  isVideo,
  url,
  description,
  selectedTab = null,
  selectedLocation = null,
  setSelectedLocation = null,
  isHomePage = false,
  withSearch = false,
  buttonTitle = null,
  buttonLink = null,
}) {
  const [searchLocation, setSearchLocation] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [coverHeight, setCoverHeight] = useState(0);
  const searchRef = useRef(null);
  const coverRef = useRef(null);
  const navigate = useNavigate();
  const filteredLocations = LocationValleys.filter(
    (valley) =>
      valley.villages.some((village) =>
        village.toLowerCase().includes(searchLocation.toLowerCase())
      ) || valley.name.toLowerCase().includes(searchLocation.toLowerCase())
  );

  useEffect(() => {
    if (selectedLocation === "" || !selectedLocation) {
      setSearchLocation("");
    }
    if (selectedLocation && selectedLocation !== searchLocation) {
      setSearchLocation(selectedLocation);
    }
  }, [selectedLocation]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setOpenPopup(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (coverRef.current) {
      const height = coverRef.current.offsetHeight;
      setCoverHeight(height);
      console.log("Height of the element:", height);
    }
  }, [selectedTab]);

  return (
    <div
      className={`relative w-screen md:h-screen md:min-h-[800px]  ${
        coverHeight < 560 ? "h-[100vh]" : "h-[90vh]"
      }`}
    >
      <Helmet>
        <style>{`
          @import url('https://fonts.googleapis.com/css2?family=Caprasimo&display=swap');
          .funnyFont {
            font-family: 'Caprasimo', sans-serif;
          }
        `}</style>
      </Helmet>
      <div
        className="absolute top-0 w-full h-[80vh] md:h-[120vh] min-h-[80vh] md:min-h-[1000px]"
        ref={coverRef}
      >
        {isVideo ? (
          <video
            controls={false}
            autoPlay={true}
            muted={true}
            playsInline={true}
            loop={true}
            disablePictureInPicture={true}
            className="object-cover object-center w-full h-full pointer-events-none"
          >
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            src={url}
            className="object-cover object-center w-full h-full"
            alt="cover"
          />
        )}
      </div>
      <div className="mx-auto max-w-screen-2xl lg:px-10 2xl:px-0">
        {withSearch && (
          <div className="absolute w-full z-20 top-[104px] md:hidden">
            <div className="relative mr-8 ml-7" ref={searchRef}>
              <div className="absolute z-40 flex items-center justify-center h-full left-8">
                <FontAwesomeIcon className="text-gray-500" icon={faSearch} />
              </div>
              <input
                type="text"
                placeholder="Dove andiamo?"
                className="relative z-30 flex items-center w-full h-full py-3 pl-16 text-gray-500 truncate bg-white rounded-full shadow-md gap-x-4 focus:outline-none hover:text-gray-700"
                value={searchLocation}
                onFocus={() => setOpenPopup(true)}
                onChange={(e) => {
                  setSearchLocation(e.target.value);
                  setOpenPopup(true);
                }}
              />
              {selectedLocation && (
                <div className="absolute top-0 z-40 flex items-center justify-center h-full right-8">
                  <button
                    type="button"
                    className="flex items-center justify-center w-4 h-4 text-gray-500 "
                    onClick={() => {
                      if (isHomePage) {
                        navigate("/");
                      } else {
                        setSelectedLocation("");
                        setSearchLocation("");
                      }
                    }}
                  >
                    <span className="sr-only">Clear</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-4 h-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              )}
              {openPopup && (
                <div className="absolute z-50 w-full flex flex-col p-4 bg-white border rounded-[20px] shadow-md top-12 divide-y">
                  {filteredLocations.map((valley, index) => (
                    <div
                      key={valley.id}
                      className={`flex items-center justify-between p-2 text-gray-500 cursor-pointer hover:text-primary`}
                    >
                      <button
                        type="button"
                        onClick={() => {
                          if (isHomePage) {
                            navigate(`/discover/${valley.id}`);
                          } else {
                            setSelectedLocation(valley.name);
                            setSearchLocation(valley.name);
                          }
                          setOpenPopup(false);
                        }}
                      >
                        {valley.name}
                      </button>
                    </div>
                  ))}
                  {filteredLocations.length === 0 && (
                    <div className="p-2 text-gray-500">Nessun risultato</div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        <div
          className={`absolute z-10 w-full p-8 md:w-3/5 md:top-48 ${
            coverHeight > 640
              ? "top-48"
              : coverHeight > 610
              ? "top-36"
              : "top-32"
          }`}
        >
          <div
            className={`text-white md:text-5xl funnyFont 2xl:text-7xl lg:text-[55px] ${
              coverHeight > 700 ? "text-4xl" : "text-3xl"
            }`}
          >
            {description}
          </div>
          {buttonTitle && buttonLink && (
            <button
              type="button"
              className={`flex items-center justify-between w-full py-2 pl-8 pr-3  text-xl font-bold text-white rounded-full md:w-fit md:mt-12 md:text-2xl gap-x-4 bg-primary hover:bg-primary-700 ${
                coverHeight > 650 ? "mt-8" : "mt-4"
              }`}
              onClick={() => navigate(buttonLink)}
            >
              <p>{buttonTitle}</p>
              <div className="flex items-center justify-center w-8 text-lg bg-white rounded-full h-7 text-primary">
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
