import { faFacebookF, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faCircleCheck,
  faCopy,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import Tooltip from "@uidu/tooltip";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { Bounce, toast } from "react-toastify";

function ModalContent({ setOpenShareModal, kind, urlToUse }) {
  const intl = useIntl();
  const location = useLocation();
  const shareUrl = `${window.location.origin}${
    urlToUse ? urlToUse : location.pathname
  }`;

  const handleShare = (platform) => {
    let url = "";
    switch (platform) {
      case "whatsapp":
        url = `https://wa.me/?text=${encodeURIComponent(shareUrl)}`;
        break;
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "email":
        url = `mailto:?subject=${encodeURIComponent(
          intl.formatMessage(
            {
              defaultMessage: "Guarda questa {kind}",
              id: "bZr6gq",
            },
            {
              kind:
                kind === "experience"
                  ? intl.formatMessage({
                      defaultMessage: "esperienza",
                      id: "G+TF8E",
                    })
                  : kind === "escoBox"
                  ? intl.formatMessage({
                      defaultMessage: "EscoBox",
                      id: "0yPph0",
                    })
                  : kind === "article"
                  ? intl.formatMessage({
                      defaultMessage: "storia",
                      id: "RNdw9B",
                    })
                  : intl.formatMessage({
                      defaultMessage: "struttura",
                      id: "CK+HHK",
                    }),
            }
          )
        )}&body=${encodeURIComponent(shareUrl)}`;
        break;
      default:
        break;
    }
    window.open(url, "_blank");
    setOpenShareModal(false);
  };

  const notify = (msg) =>
    toast.success(msg, {
      toastId: Math.random().toString(),
      position: "bottom-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: (
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="text-2xl text-primary"
        />
      ),
      containerId: `CopyLinkModal`,
    });

  return (
    <>
      <div className="flex items-center justify-center w-full gap-x-8">
        <Tooltip
          content={intl.formatMessage({
            defaultMessage: "Condividi su Whatsapp",
            id: "TmkPi7",
          })}
          position="bottom"
        >
          <button
            type="button"
            className="flex items-center justify-center bg-gray-100 rounded-full cursor-pointer w-11 h-11"
            onClick={() => handleShare("whatsapp")}
          >
            <FontAwesomeIcon
              icon={faWhatsapp}
              className="text-2xl text-escoBlack"
            />
          </button>
        </Tooltip>
        <Tooltip
          content={intl.formatMessage({
            defaultMessage: "Condividi su Facebook",
            id: "DMGt41",
          })}
          position="bottom"
        >
          <button
            type="button"
            className="flex items-center justify-center bg-gray-100 rounded-full cursor-pointer w-11 h-11"
            onClick={() => handleShare("facebook")}
          >
            <FontAwesomeIcon
              icon={faFacebookF}
              className="text-2xl text-escoBlack"
            />
          </button>
        </Tooltip>
        <Tooltip
          content={intl.formatMessage({
            defaultMessage: "Condividi via email",
            id: "Y3Uph6",
          })}
          position="bottom"
        >
          <button
            type="button"
            className="flex items-center justify-center bg-gray-100 rounded-full cursor-pointer w-11 h-11"
            onClick={() => handleShare("email")}
          >
            <FontAwesomeIcon
              icon={faEnvelope}
              className="text-2xl text-escoBlack"
            />
          </button>
        </Tooltip>
      </div>
      <div className="flex justify-between w-full cursor-pointer">
        <div
          onClick={() => {
            navigator.clipboard.writeText(shareUrl);
            notify(
              intl.formatMessage({
                defaultMessage: "Link copiato negli appunti",
                id: "fhVhfV",
              })
            );
            setOpenShareModal(false);
          }}
          className="flex items-center w-[calc(100%-40px)] h-10 px-4 text-sm text-gray-400 border border-gray-200 rounded-l-md"
        >
          <p className="truncate">{shareUrl}</p>
        </div>
        <Tooltip
          content={intl.formatMessage({
            defaultMessage: "Copia link",
            id: "vuOtJs",
          })}
          position="bottom"
        >
          <button
            type="button"
            className="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-r-md text-escoBlack"
            onClick={() => {
              navigator.clipboard.writeText(shareUrl);
              notify(
                intl.formatMessage({
                  defaultMessage: "Link copiato negli appunti",
                  id: "fhVhfV",
                })
              );
              setOpenShareModal(false);
            }}
          >
            <FontAwesomeIcon icon={faCopy} className="text-xl" />
          </button>
        </Tooltip>
      </div>
    </>
  );
}

function BottomSheet({ setOpenShareModal, kind, urlToUse }) {
  const intl = useIntl();
  const location = useLocation();
  const shareUrl = `${window.location.origin}${location.pathname}`;

  return (
    <motion.div
      initial={{ y: "100%" }}
      animate={{ y: 0 }}
      exit={{ y: "100%" }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
      className="fixed bottom-0 left-0 right-0 z-50 p-4 bg-white h-[200px] shadow-inner"
    >
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-bold text-primary">
          {kind === "experience"
            ? intl.formatMessage({
                defaultMessage: "Condividi l'esperienza",
                id: "fJ8ekR",
              })
            : kind === "escoBox"
            ? intl.formatMessage({
                defaultMessage: "Condividi EscoBox",
                id: "kWfZeG",
              })
            : kind === "article"
            ? intl.formatMessage({
                defaultMessage: "Condividi Articolo",
                id: "th5XZJ",
              })
            : intl.formatMessage({
                defaultMessage: "Condividi la struttura",
                id: "xB4i/i",
              })}
        </h3>
        <button
          onClick={() => setOpenShareModal(false)}
          className="text-gray-500"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="!px-4 md:!px-8 my-4 md:my-8 flex flex-col gap-y-6">
        <ModalContent
          setOpenShareModal={setOpenShareModal}
          kind={kind}
          urlToUse={urlToUse}
        />
      </div>
    </motion.div>
  );
}

export default function ModalShare({
  setOpenShareModal,
  kind,
  urlToUse = null,
}) {
  const intl = useIntl();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth >= 768 ? (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
      <ModalTransition>
        <Modal
          onClose={() => setOpenShareModal(false)}
          height="300px"
          className={`[--modal-dialog-gutter:1rem] [--modal-dialog-translate-y:0] md:mt-12`}
        >
          <ModalHeader className="border-b border-gray-100 !px-4 md:!px-8">
            <div className="flex flex-col md:items-center gap-x-6 md:flex-row">
              <h3 className="text-lg font-bold md:text-2xl text-primary">
                {kind === "experience"
                  ? intl.formatMessage({
                      defaultMessage: "Condividi l'esperienza",
                      id: "fJ8ekR",
                    })
                  : kind === "escoBox"
                  ? intl.formatMessage({
                      defaultMessage: "Condividi EscoBox",
                      id: "kWfZeG",
                    })
                  : kind === "article"
                  ? intl.formatMessage({
                      defaultMessage: "Condividi Articolo",
                      id: "th5XZJ",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Condividi la struttura",
                      id: "xB4i/i",
                    })}
              </h3>
            </div>
            <div className="flex items-center gap-x-3 md:gap-x-6">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenShareModal(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </ModalHeader>
          <ModalBody className="!px-4 md:!px-8 my-4 md:my-8 flex flex-col gap-y-8">
            <ModalContent
              setOpenShareModal={setOpenShareModal}
              kind={kind}
              urlToUse={urlToUse}
            />
          </ModalBody>
        </Modal>
      </ModalTransition>
    </div>
  ) : (
    <BottomSheet
      setOpenShareModal={setOpenShareModal}
      kind={kind}
      urlToUse={urlToUse}
    />
  );
}
