import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import magicGreen from "../assets/icons/MAGICGREEN.svg";
import FaqComponent from "../components/FaqComponent";
import Footer from "../components/Footer";
import NavbarCreator from "../components/NavbarCreator";
import {
  CentralBecomePartnerMessage,
  TableComparison,
} from "./BecomePartnerStructure";

export default function BecomePartnerExperience() {
  const [userLogged, setUserLogged] = useState(null);
  const navigate = useNavigate();
  const intl = useIntl();

  async function Start() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/logged`)
        .then((res) => {
          setUserLogged(true);
        })
        .catch(() => {
          setUserLogged(false);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        });
    } else {
      setUserLogged(false);
    }
  }

  useEffect(() => {
    Start();
  }, []);

  useEffect(() => {
    document.getElementById("page-top").scrollIntoView({ behavior: "smooth" });
    document.title = "Esco | Diventa partner";
  }, []);

  return (
    <>
      <Helmet>
        <style>{`
          @import url('https://fonts.googleapis.com/css2?family=Caprasimo&display=swap');
          .funnyFont {
            font-family: 'Caprasimo', sans-serif;
          }
        `}</style>
      </Helmet>
      <div id="page-top">
        <header>
          <NavbarCreator fromPropose />
        </header>
        <main>
          <div className="relative">
            <div className="w-full">
              <div className="relative block md:hidden top-[83px]">
                <img
                  src="https://escopublic.s3.eu-north-1.amazonaws.com/propose_exp_cover.jpeg"
                  alt="becomePartnerExperienceCover"
                  className="w-full  rounded-bl-[400px] rounded-br-[300px]"
                />
              </div>
              <div className="mx-0 2xl:mx-24">
                <div className="flex flex-col pt-24 mx-4 md:mx-24 md:pt-44 max-w-screen-2xl 2xl:mx-auto">
                  <div className="grid items-center w-full grid-cols-1 gap-4 md:grid-cols-2">
                    <div className="flex flex-col gap-y-6 md:gap-y-4">
                      <div className="relative flex flex-col gap-y-4 md:gap-y-8 max-w-[660px]  ">
                        <div className="flex flex-col text-center gap-y-4 md:text-start">
                          <div className="relative">
                            <h1 className="text-5xl font-semibold md:text-7xl text-escoBlack">
                              {intl.formatMessage({
                                defaultMessage: "Risplendi tra",
                                id: "GApHtr",
                              })}
                            </h1>
                            <div className="absolute hidden -right-6 md:-right-12 top-2 md:block">
                              <img
                                src={magicGreen}
                                alt="magicGreen"
                                className="w-12 mr-8 md:w-32"
                              />
                            </div>
                          </div>
                          <h2 className="text-5xl md:text-7xl text-primary funnyFont">
                            {intl.formatMessage({
                              defaultMessage: "le eccellenze locali",
                              id: "6EVmw/",
                            })}
                          </h2>
                        </div>
                        <div className="font-semibold text-center md:text-3xl text-escoBlack md:text-start">
                          <p className="mb-8">
                            {intl.formatMessage({
                              defaultMessage:
                                "Hai un’esperienza unica da offrire ma ti senti oscurato dalle proposte convenz-ionali?",
                              id: "Zjw23s",
                            })}{" "}
                          </p>
                          <p className="pt-2 md:pt-4">
                            {intl.formatMessage({
                              defaultMessage: "Unisciti a",
                              id: "tdunxr",
                            })}{" "}
                            <span className="font-bold">esco.zone</span>{" "}
                            {intl.formatMessage({
                              defaultMessage:
                                "e fai risplendere la tua offerta autentica, dandole la visibilità che merita in un mercato sempre più affollato.",
                              id: "BChEy9",
                            })}
                          </p>
                        </div>
                        <button
                          className="relative flex items-center self-center px-12 py-3 font-bold text-white rounded-full gap-x-4 md:text-xl w-fit bg-primary hover:bg-secondary md:self-start"
                          onClick={() => {
                            if (userLogged) {
                              navigate("/eventKind");
                            } else {
                              navigate("/login");
                            }
                          }}
                        >
                          <span>
                            {intl.formatMessage({
                              defaultMessage: "Carica la tua esperienza",
                              id: "HKDNmd",
                            })}
                          </span>
                          <div></div>
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="absolute w-4 h-4 md:w-6 md:h-6 right-4"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="absolute right-0 top-20 -right-24 w-[48%]">
                      <img
                        src="https://escopublic.s3.eu-north-1.amazonaws.com/propose_exp_cover.jpeg"
                        alt="becomePartnerExperienceCover"
                        className="w-full h-full rounded-bl-[400px] rounded-tl-[250px] rounded-br-[300px] hidden md:block "
                      />
                    </div>
                  </div>
                  {/* <img
                    src="https://escopublic.s3.eu-north-1.amazonaws.com/becomePartnerExperienceCover.png"
                    alt="becomePartnerExperienceCover"
                    className="w-full rounded-[30px] mt-36 hidden md:block"
                  /> */}
                  <CentralBecomePartnerMessage />
                  <div className="grid grid-cols-1 md:grid-cols-2 bg-primary rounded-[30px] mt-12 md:mt-36 p-8 md:p-12 items-center">
                    <div className="flex items-center h-full mt-10">
                      <div className="flex flex-col w-full md:w-3/4 md:flex-row gap-x-12">
                        <div className="flex flex-col w-full">
                          <div className="flex items-center text-white justify-center border-2 border-white rounded-[60px] p-4 font-thin text-3xl md:text-6xl">
                            {intl.formatMessage({
                              defaultMessage: "Scopri se fai",
                              id: "K4F9e1",
                            })}
                          </div>
                          <div className="self-center h-10 border-r-2 border-white md:h-12" />
                          <div className="flex items-center justify-center">
                            <div className="absolute w-6 h-6 mt-1 bg-white rounded-full " />
                          </div>
                          <div className=" text-center flex items-center justify-center border-2 border-white text-white rounded-[60px] px-4 py-4 funnyFont text-3xl md:text-7xl">
                            {intl.formatMessage({
                              defaultMessage: "al caso nostro",
                              id: "kwUqwt",
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="hidden md:block relative w-1/4 h-[35%]">
                        <div className="flex flex-col w-full h-full border-b-2" />
                        <div className="absolute w-6 h-6 bg-white rounded-full -bottom-3 -right-3" />
                      </div>
                    </div>
                    <div className="relative flex items-center justify-center w-full md:hidden">
                      <div className="self-center h-10 border-r-2 border-white md:h-12" />
                      <div className="flex items-center justify-center">
                        <div className="absolute w-6 h-6 mt-10 bg-white rounded-full " />
                      </div>
                    </div>
                    <div className="md:mt-12 border-2 border-white rounded-[30px]">
                      <div className="grid grid-cols-1 text-white md:grid-cols-2">
                        <div className="flex flex-col p-6 gap-y-6">
                          <p className="text-xl font-bold">
                            {intl.formatMessage({
                              defaultMessage: "Esperienze",
                              id: "ipp3co",
                            })}
                          </p>
                          <ul className="px-4 list-disc">
                            <li className="text-lg">
                              {intl.formatMessage({
                                defaultMessage: "Enogastronomiche",
                                id: "2cqxH9",
                              })}
                            </li>
                            <li className="text-lg">
                              {intl.formatMessage({
                                defaultMessage: "Attività di laboratorio",
                                id: "etVFfo",
                              })}
                            </li>
                            <li className="text-lg md:hidden">
                              {intl.formatMessage({
                                defaultMessage: "Attività sportive",
                                id: "9a5H2+",
                              })}
                            </li>
                            <li className="text-lg md:hidden">
                              {intl.formatMessage({
                                defaultMessage: "Corsi e workshop",
                                id: "6Txtkq",
                              })}
                            </li>
                          </ul>
                        </div>
                        <div className="flex flex-col hidden p-6 gap-y-6 md:block">
                          <p className="text-xl font-bold text-primary">
                            {intl.formatMessage({
                              defaultMessage: "Esperienze",
                              id: "ipp3co",
                            })}
                          </p>
                          <ul className="px-4 list-disc">
                            <li className="text-lg">
                              {intl.formatMessage({
                                defaultMessage: "Attività sportive",
                                id: "9a5H2+",
                              })}
                            </li>
                            <li className="text-lg">
                              {intl.formatMessage({
                                defaultMessage: "Corsi e workshop",
                                id: "6Txtkq",
                              })}
                            </li>
                          </ul>
                        </div>
                        <div className="flex flex-col p-6 border-t-2 border-white md:border-r-2 gap-y-6">
                          <p className="text-xl font-bold">
                            {intl.formatMessage({
                              defaultMessage: "Tour guidati",
                              id: "QIcg7b",
                            })}
                          </p>
                          <ul className="px-4 list-disc">
                            <li className="text-lg">
                              {intl.formatMessage({
                                defaultMessage: "Tour culturali",
                                id: "a1ySi3",
                              })}
                            </li>
                            <li className="text-lg">
                              {intl.formatMessage({
                                defaultMessage: "Esperienze artistiche",
                                id: "/HVGfr",
                              })}
                            </li>
                            <li className="text-lg">
                              {intl.formatMessage({
                                defaultMessage: "Gite sportive con guida",
                                id: "3K6xmn",
                              })}
                            </li>
                            <li className="text-lg">
                              {intl.formatMessage({
                                defaultMessage: "Tour enogastronomici",
                                id: "4X9Hrm",
                              })}
                            </li>
                          </ul>
                        </div>
                        <div className="flex flex-col p-6 border-t-2 border-white gap-y-6">
                          <p className="text-xl font-bold">
                            {intl.formatMessage({
                              defaultMessage: "Eventi",
                              id: "cDQxgq",
                            })}
                          </p>
                          <ul className="px-4 list-disc">
                            <li className="text-lg">
                              {intl.formatMessage({
                                defaultMessage: "Eventi culturali",
                                id: "kTBLDZ",
                              })}
                            </li>
                            <li className="text-lg">
                              {intl.formatMessage({
                                defaultMessage: "Eventi sportivi",
                                id: "QKYLC2",
                              })}
                            </li>
                            <li className="text-lg">
                              {intl.formatMessage({
                                defaultMessage: "Eventi musicali",
                                id: "9x71r/",
                              })}
                            </li>
                            <li className="text-lg">
                              {intl.formatMessage({
                                defaultMessage: "Eventi enogastronomici",
                                id: "/Gwqb+",
                              })}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-4 border-primary rounded-[30px] mt-12 md:mt-36 p-4 md:p-12">
                    <p className="text-4xl font-thin text-center md:text-7xl text-escoBlack">
                      {intl.formatMessage({
                        defaultMessage: "L'attività di",
                        id: "MeCfh9",
                      })}{" "}
                      <span className="funnyFont">
                        {intl.formatMessage({
                          defaultMessage: "marketing perfetta ",
                          id: "ZZ6fTf",
                        })}
                      </span>
                      &nbsp;
                      {intl.formatMessage({
                        defaultMessage: "per le tue esigenze",
                        id: "FTTjFA",
                      })}
                    </p>
                  </div>
                  <TableComparison />
                  <div className="flex flex-col md:flex-row gap-x-12 bg-primary rounded-[30px] mt-12 md:mt-36 p-8 md:p-12">
                    <div className="w-full pb-8 md:w-3/4 md:pb-0">
                      <p className="text-3xl font-thin text-center text-white md:text-6xl">
                        {intl.formatMessage({
                          defaultMessage: "Bastano solo",
                          id: "S2F2un",
                        })}{" "}
                        <span className="relative">
                          <span className="absolute w-3 h-3 mt-4 -ml-1.5 bg-white rounded-full md:-ml-2 md:w-4 md:h-4 md:mt-6"></span>
                        </span>
                        <span className="px-2 md:px-4 py-0.5 md:py-2 text-white border border-white rounded-[40px] funnyFont">
                          15
                        </span>
                        <span className="relative mr-4">
                          <span className="absolute w-3 h-3 mt-4 -ml-1.5 bg-white rounded-full md:-ml-2 md:w-4 md:h-4 md:mt-6"></span>
                        </span>
                        <span className="text-white funnyFont">
                          {intl.formatMessage({
                            defaultMessage: "minuti ",
                            id: "BiXwvU",
                          })}
                        </span>
                        {intl.formatMessage({
                          defaultMessage: "per caricare la tua esperienza",
                          id: "znlRce",
                        })}
                      </p>
                    </div>
                    <div className="flex flex-col justify-around w-full gap-y-4 md:w-1/4">
                      <button
                        className="w-full px-8 py-2 text-lg text-white border-2 border-white rounded-full hover:bg-secondary"
                        onClick={() => {
                          // window.location.href =
                          //   "https://escopublic.s3.eu-north-1.amazonaws.com/Esco_Guida_Creator.pdf";
                          console.log("download pdf");
                        }}
                      >
                        {intl.formatMessage({
                          defaultMessage: "Scarica il tutorial",
                          id: "MmACCk",
                        })}
                      </button>
                      <button
                        className="w-full px-8 py-2 text-lg bg-white border-2 border-white rounded-full text-primary hover:bg-gray-100"
                        onClick={() => {
                          if (userLogged) {
                            navigate("/eventKind");
                          } else {
                            navigate("/login");
                          }
                        }}
                      >
                        {intl.formatMessage({
                          defaultMessage: "Carica la tua esperienza",
                          id: "HKDNmd",
                        })}
                      </button>
                    </div>
                  </div>
                  <div className="relative flex items-center justify-center w-full">
                    <div className="absolute w-10 h-10 -mt-1.5 rounded-full bg-secondary"></div>
                  </div>
                  <div className="flex items-center justify-center w-full">
                    <div className="border-2 h-36 md:h-64 border-left border-secondary"></div>
                  </div>
                  <div className="mb-16 md:mb-32">
                    <FaqComponent />
                    <div className="flex items-center justify-center ">
                      <button
                        className="px-16 py-4 mt-8 text-2xl bg-white border-2 rounded-full md:text-3xl md:px-32 text-primary border-primary hover:bg-primary hover:text-white"
                        onClick={() => {
                          if (userLogged) {
                            navigate("/eventKind");
                          } else {
                            navigate("/login");
                          }
                        }}
                      >
                        {intl.formatMessage({
                          defaultMessage: "Diventa creator",
                          id: "3gv9es",
                        })}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}
