import { faHandSpock } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import BaseComponent from "../BaseComponent";

export default function CheckIn({ experiences }) {
  const experiencesApproved = experiences.filter(
    (experience) => experience.approved_at
  );
  const [experiencesOfTheDay, setExperiencesOfTheDay] = useState([]);
  const [bookingsForTheDay, setBookingsForTheDay] = useState(null);
  const [bookingsFiltered, setBookingsFiltered] = useState(null);
  const [value, setValue] = useState(() => {
    const today = new Date();
    // today.setDate(today.getDate() + 1);
    return today;
  });
  const [selectedExperience, setSelectedexperience] = useState(experiences[0]);
  const animatedComponents = makeAnimated();
  const [loading, setLoading] = useState(true);
  const [action, setAction] = useState(null);
  const [bookingToEdit, setBookingToEdit] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const getBookings = async () => {
    const token = localStorage.getItem("token");
    const data = {
      date: value,
      experiences: [selectedExperience._id],
    };
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/experiencesBookingsOnDate`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (res) => {
        setBookingsForTheDay(res.data.filter((booking) => booking.user_id));
        setTimeout(() => {
          setLoading(false);
        }, 200);
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  function getExperiencesOfTheDay(date) {
    const experiences = experiencesApproved.filter((experience) => {
      if (experience.is_recurrent) {
        if (experience.data_info.recurrency_type === "week") {
          const dayOfWeek = date.getDay();
          const experienceDayOfWeek = experience.data_info.week_days;
          const experienceDayOfWeekByNumber = experienceDayOfWeek.map((day) => {
            switch (day) {
              case "lunedi":
                return 1;
              case "martedi":
                return 2;
              case "mercoledi":
                return 3;
              case "giovedi":
                return 4;
              case "venerdi":
                return 5;
              case "sabato":
                return 6;
              default:
                return 0;
            }
          });
          return experienceDayOfWeekByNumber.includes(dayOfWeek);
        } else {
          return experience;
        }
      } else {
        const dateFormatted =
          date.getFullYear() +
          "-" +
          String(date.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(date.getDate()).padStart(2, "0");

        const currentDate = new Date(experience.data_info.start_date);
        const inputDate = new Date(dateFormatted);

        return inputDate.getTime() === currentDate.getTime();
      }
    });
    setExperiencesOfTheDay(experiences);
  }

  const updateBooking = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      if (action === "confirm" || action === "delete") {
        await axios
          .put(
            `${process.env.REACT_APP_SERVER_URL}/api/bookings/edit/${bookingToEdit._id}`,
            {
              status: action === "confirm" ? "confirmed" : "rejected",
            }
          )
          .then((res) => {
            console.log(res.data);
            if (res.data.status === "confirmed") {
              setBookingsForTheDay(
                bookingsForTheDay.map((booking) =>
                  booking._id === bookingToEdit._id
                    ? { ...booking, status: "confirmed" }
                    : booking
                )
              );
            } else {
              setBookingsForTheDay(
                bookingsForTheDay.filter(
                  (booking) => booking._id !== bookingToEdit._id
                )
              );
            }
            setAction(null);
            setBookingToEdit(null);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (action === "propose") {
        setAction(null);
        setBookingToEdit(null);
      }
    }
  };

  useEffect(() => {
    if (bookingToEdit) {
      updateBooking();
    }
  }, [bookingToEdit]);

  useEffect(() => {
    if (selectedExperience) {
      getBookings();
    }
  }, [selectedExperience, value]);

  useEffect(() => {
    getExperiencesOfTheDay(value);
  }, []);

  const renderExperience = (experience, value, bookingsForTheDay) => (
    <div className="flex flex-col w-full gap-y-2">
      <div className="flow-root h-[512px] ">
        <div className="-my-2">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3 pl-4 pr-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase sm:pl-0"
                  >
                    Numero Prenotazione
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                  >
                    Nome Partecipante
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                  >
                    Email Partecipante
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase text-end"
                  >
                    Numero Ospiti
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase text-end"
                  >
                    Orario
                  </th>
                  <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Check-in</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 ">
                {bookingsForTheDay
                  .filter((booking) => booking.experience_id === experience._id)
                  .map((booking, index) => (
                    <tr key={index}>
                      <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">
                        <div className="flex items-center">{booking._id}</div>
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {booking.user_id.name} {booking.user_id.surname}
                        </div>
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        <div className="text-sm text-gray-500">
                          {booking.user_id.email}
                        </div>
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        <div className="text-sm text-gray-500 text-end">
                          {booking.guests_number.is_group
                            ? "Gruppo privato"
                            : `${booking.guests_number.adults} adulti ${
                                booking.guests_number.children > 0
                                  ? `-${booking.guests_number.children} bambini`
                                  : ""
                              }`}
                        </div>
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        <div className="text-sm text-gray-500 text-end">
                          {booking.start_time}
                        </div>
                      </td>
                      {/* for the check-in we do a button that do it if it's not done yet or a check if it's done */}
                      <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-0">
                        {booking.checked_in_at ? (
                          <div className="flex items-center justify-end gap-x-6">
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="text-green-500"
                            />
                          </div>
                        ) : (
                          <div className="flex items-center justify-end gap-x-6">
                            <button
                              onClick={() => {}}
                              type="button"
                              className="px-6 py-1 text-white rounded-md bg-primary"
                            >
                              Check-in
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (searchValue && bookingsForTheDay && searchValue.length > 0) {
      setBookingsFiltered(
        bookingsForTheDay.filter(
          (booking) =>
            booking._id.includes(searchValue) ||
            booking.user_id.name.includes(searchValue) ||
            booking.user_id.surname.includes(searchValue) ||
            booking.user_id.email.includes(searchValue)
        )
      );
    } else if (bookingsForTheDay) {
      setBookingsFiltered(bookingsForTheDay);
    } else {
      setBookingsFiltered(null);
    }
  }, [searchValue, bookingsForTheDay]);

  return (
    <BaseComponent title="">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl text-center md:text-left text-primary">
          Check-in
        </h1>
        <div className="flex items-center pt-1 pr-4 gap-x-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Cerca prenotazioni"
              value={searchValue}
              className={` py-1.5 border-2 border-gray-300 rounded-md pl-10 transition-all duration-300 ${
                isFocused || searchValue ? "w-72 px-4" : "w-8"
              }`}
              onChange={(e) => setSearchValue(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
            <FontAwesomeIcon
              icon={faSearch}
              className="absolute text-gray-400 transform -translate-y-1/2 left-3 top-1/2"
            />
          </div>
          <button className="px-4 py-[7px] text-white rounded-md bg-primary">
            Scan QR Code
          </button>
        </div>
      </div>
      <div className="flex flex-col px-4 pt-4 mt-4 mb-12 overflow-y-auto rounded-md gap-y-6">
        {experiences.length > 1 ? (
          <div className="flex items-center justify-between">
            <p className="text-lg font-semibold">Esperienza</p>
            <div className="w-2/3">
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                menuPortalTarget={document.body}
                menuPosition="absolute"
                defaultValue={
                  selectedExperience && {
                    value: selectedExperience?._id,
                    label: selectedExperience?.name,
                  }
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "2px solid rgb(229 231 235)",
                    height: "35px",
                    width: "100%",
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: "#246155",
                    color: "white",
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: "white",
                  }),
                  multiValueRemove: (provided, state) => ({
                    ...provided,
                    color: "white",
                    ":hover": {
                      backgroundColor: "#328575",
                    },
                  }),
                }}
                options={experiences.map((experience) => ({
                  value: experience._id,
                  label: experience.name,
                }))}
                onChange={(value) => {
                  setSelectedexperience(
                    experiences.find(
                      (experience) => experience._id === value.value
                    )
                  );
                }}
              />
            </div>
          </div>
        ) : (
          <p className="text-gray-600">
            Fai il check-in delle prenotazioni di oggi
          </p>
        )}
        <div className="flex flex-col w-full mt-6 gap-y-4">
          {loading ? (
            <div className="flex items-center justify-center w-full h-32">
              <UiduSpinner size="medium" className="text-primary" />
            </div>
          ) : (
            <>
              {experiencesOfTheDay.length > 0 && bookingsForTheDay ? (
                <>
                  {experiencesOfTheDay.some(
                    (experience) => selectedExperience._id === experience._id
                  ) ? (
                    experiencesOfTheDay
                      .filter(
                        (experience) =>
                          selectedExperience._id === experience._id
                      )
                      .map((experience) =>
                        renderExperience(experience, value, bookingsFiltered)
                      )
                  ) : (
                    <div className="flex flex-col items-center justify-center py-10 gap-y-8">
                      <FontAwesomeIcon
                        icon={faHandSpock}
                        className="text-6xl text-gray-600"
                      />
                      <p className="text-lg font-bold text-gray-600">
                        L'esperienza non è disponibile nella data di oggi
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <p className="py-2 text-lg font-bold text-gray-600">
                  L'esperienza non è disponibile nella data di oggi
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </BaseComponent>
  );
}
