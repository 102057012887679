import useHover from "@react-hook/hover";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import agriturismo from "../../assets/agriturism.svg";
import agriturismoBlack from "../../assets/agriturismoBlack.svg";
import beb from "../../assets/b&b.svg";
import bebBlack from "../../assets/b&bBlack.svg";
import glamping from "../../assets/glamping.svg";
import glampingBlack from "../../assets/glampingBlack.svg";
import rifugio from "../../assets/rifugio.svg";
import rifugioBlack from "../../assets/rifugioBlack.svg";
import { ProposeStructureContext } from "../../pages/ProposeStructure";
import BaseComponent from "../BaseComponent";

function SingleButton({ item, setSelectedKind, selectedKind }) {
  const target = useRef(null);
  const isHovering = useHover(target, { enterDelay: 0, leaveDelay: 0 });

  return (
    <button
      ref={target}
      className="w-full h-full text-black border-2 rounded-lg hover:text-white border-primary hover:bg-primary"
      onClick={() => setSelectedKind(item.text)}
      style={{
        backgroundColor: selectedKind === item.text ? "#246155" : "",
        color: selectedKind === item.text ? "white" : "",
      }}
    >
      <div className="flex flex-col items-center justify-center w-full h-full gap-4 md:flex-row">
        {isHovering || selectedKind === item.text ? (
          <img className="w-6 h-6" src={item.img} alt={item.text} />
        ) : (
          <img className="w-6 h-6" src={item.imgBlack} alt={item.text} />
        )}
        <p className="text-center">{item.text}</p>
      </div>
    </button>
  );
}

export default function StructureKind() {
  const { id } = useParams();

  const { structure, setStructure, setErrors } = useContext(
    ProposeStructureContext
  );
  const [selectedKind, setSelectedKind] = useState(structure?.kind || null);

  const updateStructure = async () => {
    setErrors([]);
    if (!selectedKind) {
      setErrors([{ error: "Seleziona un tipo di struttura" }]);
    } else {
      await axios
        .put(`${process.env.REACT_APP_SERVER_URL}/api/structure/edit/${id}`, {
          kind: selectedKind,
        })
        .then((res) => {
          setStructure(res.data.structure);
        })
        .catch(() => {
          alert("Errore durante l'aggiornamento della struttura");
        });
    }
  };

  useEffect(() => {
    updateStructure();
  }, []);

  useEffect(() => {
    if (structure && selectedKind !== structure.kind) updateStructure();
  }, [selectedKind, structure]);

  const schema = [
    {
      text: "rifugio",
      img: rifugio,
      imgBlack: rifugioBlack,
    },
    {
      text: "B&B",
      img: beb,
      imgBlack: bebBlack,
    },
    {
      text: "agriturismo",
      img: agriturismo,
      imgBlack: agriturismoBlack,
    },
    // {
    //   text: "ostello",
    //   img: ostello,
    //   imgBlack: ostelloBlack,
    // },
    // {
    //   text: "camping",
    //   img: camping,
    //   imgBlack: campingBlack,
    // },
    {
      text: "glamping",
      img: glamping,
      imgBlack: glampingBlack,
    },
  ];

  return (
    <BaseComponent title="A quale mondo appartiene la tua struttura?">
      <div className="bg-gray-100 grid grid-cols-2 gap-6 rounded-md h-[500px] overflow-y-auto p-10 my-4">
        {structure ? (
          <>
            {schema.map((item, index) => (
              <div key={index}>
                <SingleButton
                  key={index}
                  item={item}
                  selectedKind={selectedKind}
                  setSelectedKind={setSelectedKind}
                />
              </div>
            ))}
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
