import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { ProposeContext } from "../../pages/Propose";
import BaseComponent from "../BaseComponent";

export default function Biography() {
  const { setErrors, userExperience, setUserExperience } =
    useContext(ProposeContext);
  const [charCount, setCharCount] = useState(userExperience?.bio?.length || 0);

  const updateBio = async () => {
    const token = localStorage.getItem("token");
    const currentErrors = [
      { id: "bio", error: null },
      { id: "profile_name", error: null },
    ];

    const bio = document.getElementById("biography").value;
    const profile_name = document.getElementById("profile_name").value;

    if (bio.length > 250 || bio === "") {
      currentErrors[0].error =
        "La biografia deve essere definita e lunga al massimo 250 caratteri";
    }

    if (
      profile_name.length > 50 ||
      profile_name === "" ||
      profile_name.length < 3
    ) {
      currentErrors[1].error =
        "Il nome deve essere definito e lungo al massimo 50 caratteri";
    }

    setErrors(currentErrors);

    if (currentErrors[0].error === null && currentErrors[1].error === null) {
      const data = {
        bio: bio,
        profile_name: profile_name,
      };

      await axios
        .put(
          `${process.env.REACT_APP_SERVER_URL}/api/user/edit/${userExperience._id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setUserExperience(res.data);
        })
        .catch(() => {
          alert("Errore durante la modifica dell'esperienza");
        });
    }
  };

  useEffect(() => {
    if (userExperience) {
      updateBio();
    }
  }, []);

  return (
    <BaseComponent title="Parlaci di te">
      <div className="bg-gray-100 flex flex-col gap-y-4 rounded-md h-[400px] overflow-y-auto py-4 px-10 my-4">
        {userExperience ? (
          <>
            <p className="font-semibold">Nome visibile sul portale</p>
            <input
              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="profile_name"
              defaultValue={
                userExperience?.profile_name
                  ? userExperience?.profile_name
                  : `${userExperience?.name}`
              }
              onChange={(e) => {
                e.preventDefault();
                updateBio();
              }}
              placeholder="Nome visibile sul portale"
            ></input>
            <div className="flex items-center justify-between w-full">
              <p className="font-semibold">Raccontaci chi sei</p>
              <p
                className={`text-sm ${
                  charCount > 250 ? "text-red-500" : "text-gray-400"
                }`}
              >
                {charCount}/250
              </p>
            </div>
            <textarea
              className="h-full max-h-[200px] p-4 md:p-6 mb-6 border-2 border-gray-200 resize-none rounded-[20px] rounded-[40px]"
              id="biography"
              defaultValue={userExperience?.bio}
              onChange={(e) => {
                e.preventDefault();
                setCharCount(e.target.value.length);
                updateBio();
              }}
            ></textarea>
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
