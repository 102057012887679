import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import UiduTooltip from "@uidu/tooltip";
import AWS from "aws-sdk";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import ImageUploading from "react-images-uploading";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FeeContext } from "../../App";
import plus from "../../assets/icons/PLUS.svg";
import xmark from "../../assets/icons/XMARK.svg";
import { ProposeStructureContext } from "../../pages/ProposeStructure";
import BaseComponent from "../BaseComponent";
import { useGeneralServices } from "../structureComponents/StructureServices";

export default function Rooms({ index }) {
  const { setErrors, rooms, setRooms } = useContext(ProposeStructureContext);
  const [images, setImages] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const animatedComponents = makeAnimated();
  const navigate = useNavigate();
  const { fees } = useContext(FeeContext);
  const { generalServices } = useGeneralServices();
  const { id } = useParams();
  const [uploadedImages, setUploadedImages] = useState(
    rooms[index].images || []
  );
  const [isUploading, setIsUploading] = useState(false);
  useEffect(() => {
    setErrors([
      { id: "name", error: null },
      { id: "description", error: null },
      { id: "quantity", error: null },
      { id: "surface", error: null },
      { id: "singleBed", error: null },
      { id: "doubleBed", error: null },
      { id: "price", error: null },
      { id: "services", error: null },
      { id: "images", error: null },
    ]);
    setUploadedImages(rooms[index].images);
  }, [index]);
  const uploadFile = async () => {
    const S3_BUCKET = "escopublic";
    const REGION = "eu-north-1";

    AWS.config.update({
      accessKeyId: `${process.env.REACT_APP_AWS_ACCES_KEY_ID}`,
      secretAccessKey: `${process.env.REACT_APP_AWS_sECRET_ACCESS_KEY}`,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const tempImages = [];
    setIsUploading(true);

    images.forEach((image, index) => {
      if (
        !uploadedImages.includes(
          `https://escopublic.s3.eu-north-1.amazonaws.com/${image.file.name}`
        )
      ) {
        const params = {
          Bucket: S3_BUCKET,
          Key: image.file.name,
          Body: image.file,
        };

        var upload = s3
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            console.log(
              "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
            );
          })
          .promise();

        upload.then((err, data) => {
          tempImages.push(
            `https://escopublic.s3.eu-north-1.amazonaws.com/${image.file.name}`
          );
          if (index === images.length - 1) {
            setImages([]);
            setIsUploading(false);
            setUploadedImages((prev) => {
              return [...prev, ...tempImages];
            });
          }
        });
      } else {
        if (index === images.length - 1) {
          setImages([]);
          setIsUploading(false);
          setUploadedImages((prev) => {
            return [...prev, ...tempImages];
          });
        }
      }
    });
  };

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };

  const deleteRoom = async () => {
    const token = localStorage.getItem("token");
    await axios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}/api/room/delete/${rooms[index]._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        const newRooms = [...rooms];
        const roomBeforeCurrent = newRooms[index - 1];
        newRooms.splice(index, 1);
        navigate(`/proposeStructure/${id}/room${roomBeforeCurrent._id}`);
        setRooms(newRooms);
      })
      .catch(() => {
        alert("Errore durante l'eliminazione della camera");
      });
  };

  const updateRoom = async () => {
    const token = localStorage.getItem("token");
    const currentErrors = [
      { id: "name", error: null },
      { id: "description", error: null },
      { id: "quantity", error: null },
      { id: "surface", error: null },
      { id: "singleBed", error: null },
      { id: "doubleBed", error: null },
      { id: "price", error: null },
      { id: "services", error: null },
      { id: "images", error: null },
    ];

    if (!rooms[index].name || rooms[index].name.length < 3) {
      currentErrors[0].error = "Il nome deve essere lungo almeno 3 caratteri";
    }

    if (!rooms[index].description || rooms[index].description.length < 100) {
      currentErrors[1].error =
        "La descrizione deve essere lunga almeno 100 caratteri";
    }

    if (!rooms[index].quantity || rooms[index].quantity < 1) {
      currentErrors[2].error = "La quantità deve essere maggiore di 0";
    }

    if (!rooms[index].size || rooms[index].size < 1) {
      currentErrors[3].error = "La superficie deve essere maggiore di 0";
    }

    if (rooms[index].single_bed === undefined || rooms[index].single_bed < 0) {
      currentErrors[4].error =
        "I posti letto singoli devono essere valorizzati";
    }

    if (rooms[index].double_bed === undefined || rooms[index].double_bed < 0) {
      currentErrors[5].error =
        "I posti letto matrimoniali devono essere valorizzati";
    }

    if (!rooms[index].price || rooms[index].price < 1) {
      currentErrors[6].error = "Il prezzo deve essere maggiore di 0";
    }

    if (!rooms[index].services || rooms[index].services.length < 1) {
      currentErrors[7].error = "Seleziona almeno un servizio";
    }

    if (uploadedImages.length === 0) {
      currentErrors[8].error = "Aggiungi almeno un'immagine";
    }

    setErrors(currentErrors);

    if (currentErrors.some((error) => error.error)) {
      return;
    }

    const data = {
      name: rooms[index].name,
      description: rooms[index].description,
      quantity: rooms[index].quantity,
      size: rooms[index].size,
      single_bed: rooms[index].single_bed,
      double_bed: rooms[index].double_bed,
      price: rooms[index].price,
      services: rooms[index].services,
      images: uploadedImages,
    };

    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/room/edit/${rooms[index]._id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        console.log("Camera aggiornata");
      })
      .catch(() => {
        alert("Errore durante l'aggiornamento della camera");
      });
  };

  useEffect(() => {
    if (images.length > 0) {
      uploadFile();
    }
  }, [images]);

  useEffect(() => {
    if (rooms) {
      updateRoom();
    }
    if (
      JSON.stringify(uploadedImages) !== JSON.stringify(rooms[index].images)
    ) {
      setRooms((prev) => {
        const newRooms = [...prev];
        newRooms[index].images = uploadedImages;
        return newRooms;
      });
      updateRoom();
    }
  }, [rooms, uploadedImages]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <BaseComponent
      title="Camera"
      key={`room${index}`}
      sidebar={
        <>
          {rooms.length > 1 && (
            <button
              className="px-4 py-1 text-white rounded-md bg-primary"
              onClick={() => deleteRoom()}
            >
              Elimina camera
            </button>
          )}
        </>
      }
    >
      <div className="flex flex-col px-4 py-4 my-4 overflow-y-auto bg-gray-100 rounded-md md:px-10 gap-y-4">
        {rooms[index] ? (
          <>
            <div className="relative grid grid-cols-1 gap-4 md:grid-cols-4">
              <p className="font-semibold">Nome camera</p>
              <div className="flex flex-col col-span-3 gap-y-4">
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Nome camera"
                  name="license"
                  defaultValue={rooms[index].name}
                  onChange={(e) => {
                    const newRooms = [...rooms];
                    newRooms[index].name = e.target.value;
                    setRooms(newRooms);
                  }}
                />
                {/* <p className="-mt-2 text-sm text-gray-400">
                  {errors[0].error && errors[0].error}
                </p> */}
              </div>
              {rooms.length > 1 && (
                <button
                  className="absolute top-0 right-0 px-4 py-1 text-white rounded-md bg-primary md:hidden"
                  onClick={() => deleteRoom()}
                >
                  Elimina camera
                </button>
              )}
            </div>
            <p className="font-semibold">Descrizione camera</p>
            <textarea
              className="h-[140px] p-4 md:p-6 border-2 border-gray-200 resize-none rounded-[20px] md:rounded-[40px]"
              id="description"
              defaultValue={rooms[index].description}
              onChange={(e) => {
                const newRooms = [...rooms];
                newRooms[index].description = e.target.value;
                setRooms(newRooms);
              }}
            ></textarea>
            {/* <p className="mb-2 text-sm text-gray-400">
              {errors[1].error && errors[1].error}
            </p> */}
            <div className="grid items-center grid-cols-1 gap-4 md:grid-cols-2">
              <div className="grid items-center grid-cols-4 gap-x-4">
                <p className="font-semibold">Quantità</p>
                <input
                  className="w-full col-span-3 px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="number"
                  placeholder="1"
                  id="quantity"
                  min={0}
                  defaultValue={rooms[index].quantity}
                  onChange={(e) => {
                    const newRooms = [...rooms];
                    newRooms[index].quantity = e.target.value;
                    setRooms(newRooms);
                  }}
                />
              </div>
              <div className="grid items-center grid-cols-4 gap-x-4">
                <p className="font-semibold">Superficie</p>
                <CurrencyInput
                  className="w-full col-span-3 px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                  placeholder="mq 45"
                  id="surface"
                  min={0}
                  prefix="mq "
                  decimalsLimit={0}
                  decimalScale={0}
                  allowNegativeValue={false}
                  defaultValue={rooms[index].size}
                  onValueChange={(value, name) => {
                    const newRooms = [...rooms];
                    newRooms[index].size = value ? value.replace("mq ", "") : 0;
                    setRooms(newRooms);
                  }}
                />
              </div>
            </div>
            {/* <div className="grid items-center grid-cols-2 gap-x-4">
              <p className="mt-2 text-sm text-gray-400">
                {errors[2].error && errors[2].error}
              </p>
              <p className="mt-2 text-sm text-gray-400">
                {errors[3].error && errors[3].error}
              </p>
            </div> */}
            <p className="font-semibold">Posti letto</p>
            <div className="grid gap-4 md:grid-cols-2">
              <div className="relative flex items-center gap-x-4">
                <p className="absolute top-0 z-10 w-[106px] pr-8 mt-2 ml-4 font-semibold border-r">
                  Singoli
                </p>
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none pl-36 focus:outline-none focus:shadow-outline"
                  type="number"
                  placeholder="1"
                  id="singleBed"
                  min={0}
                  defaultValue={rooms[index].single_bed}
                  onChange={(e) => {
                    const newRooms = [...rooms];
                    newRooms[index].single_bed = e.target.value;
                    setRooms(newRooms);
                  }}
                />
              </div>
              <div className="relative flex items-center gap-x-4">
                <p className="absolute top-0 z-10 pr-4 mt-2 ml-4 font-semibold border-r w-[106px]">
                  Matrimoniali
                </p>
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none pl-36 focus:outline-none focus:shadow-outline"
                  type="number"
                  placeholder="1"
                  id="doubleBed"
                  min={0}
                  defaultValue={rooms[index].double_bed}
                  onChange={(e) => {
                    const newRooms = [...rooms];
                    newRooms[index].double_bed = e.target.value;
                    setRooms(newRooms);
                  }}
                />
              </div>
            </div>
            {/* <div className="grid items-center grid-cols-2 mb-2 gap-x-4">
              <p className="mt-2 text-sm text-gray-400">
                {errors[4].error && errors[4].error}
              </p>
              <p className="mt-2 text-sm text-gray-400">
                {errors[5].error && errors[5].error}
              </p>
            </div> */}
            <div className="flex p-6 my-4 text-white rounded-md bg-primary">
              <div className="flex flex-col justify-between w-full text-sm">
                <div className="flex flex-col gap-y-4">
                  <div className="flex items-center text-lg gap-x-4">
                    <FontAwesomeIcon icon={faCircleQuestion} />
                    <p className="font-semibold">
                      Come scegliere le foto per la tua camera? &nbsp; 📸
                    </p>
                  </div>
                  <p>
                    Esco ti consiglia di caricare almeno 3 foto per la tua
                    camera, in modo da mostrare ai visitatori cosa gli aspetta.
                    Consigliamo foto in 16:9, orizzontali e di alta qualità, per
                    una migliore visualizzazione. Il formato consigliato è .jpg
                    o .png. e la dimensione massima per ogni foto è di 10MB.
                  </p>
                </div>
              </div>
            </div>
            <div className="h-64 mb-6">
              <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={20}
                maxFileSize={10 * 1024 * 1024}
                acceptType={["jpg", "jpeg", "png"]}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                  errors,
                }) => (
                  // write your building UI
                  <div className="relative w-full h-64 upload__image-wrapper">
                    <button
                      className="border-2 md:border-4 border-dashed border-gray-400 rounded-[25px] md:rounded-[40px] text-gray-700 p-4 w-full text-center h-full flex flex-col justify-center items-center bg-white"
                      style={isDragging ? { color: "red" } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      <img
                        src={plus}
                        alt="plus"
                        className={`w-12 h-12 p-2 mb-2 md:w-16 md:h-16 
                    ${isUploading || uploadedImages.length > 0 ? "-mt-20" : ""}
                    `}
                      />
                      {!errors && (
                        <>
                          {windowWidth >= 768
                            ? "Clicca o trascina le tue foto qui"
                            : "Clicca per aggiungere le tue foto"}
                        </>
                      )}
                      {errors && (
                        <>
                          {errors.maxNumber && (
                            <span className="text-red-500">
                              Hai raggiunto il numero massimo di immagini
                            </span>
                          )}
                          {errors.acceptType && (
                            <span className="text-red-500">
                              Il formato dell'immagine non è valido
                            </span>
                          )}
                          {errors.maxFileSize && (
                            <span className="text-red-500">
                              Immagine troppo grande, la dimensione massima è
                              10MB
                            </span>
                          )}
                        </>
                      )}
                    </button>
                    {isUploading && (
                      <div
                        className="relative px-2 md:px-6 py-2 -mt-28 bg-white border-4 border-gray-200 rounded-b-[30px] md:rounded-b-[40px] w-full overflow-x-scroll flex gap-x-4"
                        style={{
                          // Firefox
                          scrollbarWidth: "none",
                          // IE and Edge
                          msOverflowStyle: "none",
                        }}
                      >
                        {uploadedImages.length > 0 &&
                          uploadedImages.map((image, index) => (
                            <div
                              key={index}
                              className="relative min-w-36 w-36 image-item"
                            >
                              <img
                                src={image}
                                alt=""
                                className="object-cover h-24 rounded w-36 min-w-36"
                              />
                              <div className="absolute -right-2 -top-2 image-item__btn-wrapper">
                                <button
                                  onClick={() => {
                                    setUploadedImages(
                                      uploadedImages.filter(
                                        (_, i) => i !== index
                                      )
                                    );
                                  }}
                                  className="flex items-center justify-center w-6 h-6 bg-white border-2 border-gray-200 rounded-full"
                                >
                                  <img
                                    src={xmark}
                                    alt="plus"
                                    className="w-6 h-6"
                                  />
                                </button>
                              </div>
                            </div>
                          ))}
                        {imageList.map((image, index) => (
                          <div
                            key={index}
                            className="relative flex items-center justify-center h-24 bg-gray-200 rounded-md min-w-36 w-36 image-item"
                          >
                            <UiduSpinner
                              size="large"
                              className="text-gray-500"
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    {uploadedImages.length > 0 && !isUploading && (
                      <div
                        className="relative px-2 md:px-6 py-2 -mt-28 bg-white border-4 border-gray-200 rounded-b-[30px] md:rounded-b-[40px] w-full overflow-x-scroll flex gap-x-4"
                        style={{
                          // Firefox
                          scrollbarWidth: "none",
                          // IE and Edge
                          msOverflowStyle: "none",
                        }}
                      >
                        {uploadedImages.map((image, index) => (
                          <div
                            key={index}
                            className="relative min-w-36 w-36 image-item"
                          >
                            <img
                              src={image}
                              alt=""
                              className="object-cover h-24 rounded w-36 min-w-36"
                            />
                            <div className="absolute -right-2 -top-2 image-item__btn-wrapper">
                              <button
                                onClick={() => {
                                  setUploadedImages(
                                    uploadedImages.filter((_, i) => i !== index)
                                  );
                                }}
                                className="flex items-center justify-center w-6 h-6 bg-white border-2 border-gray-200 rounded-full"
                              >
                                <img
                                  src={xmark}
                                  alt="plus"
                                  className="w-6 h-6"
                                />
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </ImageUploading>
            </div>
            <div className="grid gap-4 md:grid-cols-4">
              <p className="font-semibold">Servizi generali</p>
              <div className="flex flex-col col-span-3 mb-2 gap-y-2">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  menuPortalTarget={document.body}
                  menuPosition="absolute"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid rgb(229 231 235)",
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: "#246155",
                      color: "white",
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: "white",
                    }),
                    multiValueRemove: (provided, state) => ({
                      ...provided,
                      color: "white",
                      ":hover": {
                        backgroundColor: "#328575",
                      },
                    }),
                  }}
                  defaultValue={
                    rooms[index].services &&
                    rooms[index].services.map((service) => {
                      return {
                        value: service,
                        label: generalServices.find((s) => s.id === service)
                          .name,
                      };
                    })
                  }
                  options={generalServices.map((service) => {
                    return {
                      value: service.id,
                      label: service.name,
                    };
                  })}
                  onChange={(value) => {
                    const newRooms = [...rooms];
                    newRooms[index].services = value.map(
                      (service) => service.value
                    );
                    setRooms(newRooms);
                  }}
                />
                {/* <p className="text-sm text-gray-400">
                  {errors[7].error && errors[7].error}
                </p> */}
              </div>
            </div>
            <div className="flex p-6 mt-2 mb-4 text-white rounded-md bg-primary">
              <div className="flex flex-col justify-between w-full text-sm">
                <div className="flex flex-col gap-y-4">
                  <div className="flex items-center text-lg gap-x-4">
                    <FontAwesomeIcon icon={faCircleQuestion} />
                    <p className="font-semibold">
                      Come viene calcolato il prezzo della camera? &nbsp; 🤔
                    </p>
                  </div>
                  <p>
                    Esco applica una commissione del {fees.structures.fee}% sul
                    prezzo della stanza che viene visualizzata sul portale. Il
                    prezzo che imposti sarà quello che riceverai al netto delle
                    commissioni di stripe equivalente al 1,5% + 0,25€ per
                    transazione o del 2,5% per i pagamenti con carta britannica.
                    In modo da ottimmizzare le prenotazioni, ti consigliamo di
                    mantenere un prezzo in linea al proprio sito personale o ad
                    altri portali di prenotazione.
                  </p>
                </div>
              </div>
              {/* <div className="flex justify-end w-1/3 overflow-hidden rounded-md">
                <img
                  src="https://escopublic.s3.eu-north-1.amazonaws.com/escoBoxExample.png"
                  alt="itinerary"
                  className="rounded-md h-72"
                />
              </div> */}
            </div>
            <div className="grid items-center gap-4 md:grid-cols-2">
              <div className="grid grid-cols-2 gap-x-4">
                <div>
                  <p className="font-semibold">Prezzo a notte</p>
                  <p className="text-xs text-primary">Commissioni escluse</p>
                </div>
                <CurrencyInput
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                  placeholder="€"
                  id="price"
                  min={0}
                  prefix="€ "
                  decimalsLimit={2}
                  decimalScale={2}
                  allowNegativeValue={false}
                  defaultValue={
                    rooms[index].price ? rooms[index].price / 100 : 0
                  }
                  onValueChange={(value, name) => {
                    const newRooms = [...rooms];
                    newRooms[index].price =
                      parseFloat(value.replace(",", ".")) * 100;
                    setRooms(newRooms);
                  }}
                />
              </div>
              <div className="flex items-center justify-between gap-x-4">
                <div>
                  <p className="font-semibold">Prezzo a notte</p>
                  <div className="flex text-xs text-primary gap-x-2">
                    <p>Visibile sul portale</p>
                    <UiduTooltip
                      content="il prezzo comprensivo delle commissioni di Esco arrotondato per eccesso, sarà quello che il cliente vedrà sul portale"
                      position="bottom"
                    >
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        className="cursor-pointer text-primary"
                      />
                    </UiduTooltip>
                  </div>
                </div>
                {rooms[index].price && rooms[index].price > 0 ? (
                  <p className="text-2xl font-bold">
                    €{" "}
                    {Math.ceil(
                      rooms[index].price / 100 +
                        (fees.structures.fee * rooms[index].price) / 10000
                    ).toFixed(0) + ",00"}
                  </p>
                ) : (
                  <p className="text-2xl font-bold">€ 0</p>
                )}
              </div>
            </div>
            <div className="grid items-center grid-cols-2 gap-x-4">
              {/* <p className="mt-2 text-sm text-gray-400">
                {errors[6].error && errors[6].error}
              </p> */}
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
