import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Slider from "@radix-ui/react-slider";
import React, { useContext, useState } from "react";
import Calendar from "react-calendar";
import { useIntl } from "react-intl";
import { LanguageContext } from "../App";

export default function Filters({
  minPrice,
  maxPrice,
  sliderValue,
  setSliderValue,
  setHaveMaxPrice,
}) {
  const [value, onChange] = useState(new Date());
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const intl = useIntl();
  const { language } = useContext(LanguageContext);

  return (
    <div className="sticky flex flex-col p-6 mt-6 border rounded-lg shadow-lg gap-y-4 top-5">
      <div className="font-bold">
        {intl.formatMessage({
          defaultMessage: "Data",
          id: "XHHR08",
        })}
      </div>
      <Calendar
        onChange={onChange}
        value={value}
        minDate={new Date()}
        prev2Label={null}
        next2Label={null}
        locale={language}
      />
      <div className="font-bold">
        {intl.formatMessage({
          defaultMessage: "Ospiti",
          id: "GE0lzj",
        })}
      </div>
      <div className="flex flex-row justify-between">
        <div className="font-thin">
          {intl.formatMessage({
            defaultMessage: "Adulti",
            id: "UKsP/o",
          })}
        </div>
        <div className="flex items-center gap-x-2 ">
          <button
            className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
            onClick={() => {
              if (adults > 0) setAdults(adults - 1);
            }}
          >
            <FontAwesomeIcon icon={faMinus} className="text-xs" />
          </button>
          <div className="w-12 font-semibold text-center">{adults}</div>
          <button
            className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
            onClick={() => setAdults(adults + 1)}
          >
            <FontAwesomeIcon icon={faPlus} className="text-xs" />
          </button>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="font-thin">
          {intl.formatMessage({
            defaultMessage: "Bambini",
            id: "wERdVO",
          })}
        </div>
        <div className="flex items-center gap-x-2 ">
          <button
            className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
            onClick={() => {
              if (children > 0) setChildren(children - 1);
            }}
          >
            <FontAwesomeIcon icon={faMinus} className="text-xs" />
          </button>
          <div className="w-12 font-semibold text-center">{children}</div>
          <button
            className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
            onClick={() => setChildren(children + 1)}
          >
            <FontAwesomeIcon icon={faPlus} className="text-xs" />
          </button>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="font-bold">
          {intl.formatMessage({
            defaultMessage: "Prezzo massimo",
            id: "NcWhRj",
          })}
        </div>
        <div className="font-semibold">€{sliderValue / 100}</div>
      </div>
      <form>
        <Slider.Root
          className="flex items-center w-full h-6 position-relative"
          value={[sliderValue]}
          max={maxPrice}
          min={minPrice}
          step={1}
          onValueChange={(value) => {
            setSliderValue(value);
            setHaveMaxPrice(true);
          }}
        >
          <Slider.Track className="relative h-2 bg-gray-200 rounded-full grow">
            <Slider.Range className="absolute h-2 rounded-full bg-primary" />
          </Slider.Track>
          <Slider.Thumb className="block w-6 h-6 mx-4 rounded-full shadow-md cursor-pointer bg-primary" />
        </Slider.Root>
      </form>
    </div>
  );
}
