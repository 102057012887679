import { faChevronDown, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useHover from "@react-hook/hover";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import {
  AreaContext,
  ElementsCartContext,
  FavouriteContext,
  LanguageContext,
  UserContext,
  UserLoggedContext,
} from "../App";
import logo from "../assets/LOGO.svg";
import structure from "../assets/icons/ALLOGGI_GENERIC.svg";
import carrello_white from "../assets/icons/CARRELLOWHITE.svg";
import box from "../assets/icons/ESCOBOXWHITE.svg";
import experience from "../assets/icons/EXPERIENCE_GENERIC.svg";
import logout from "../assets/icons/LOGOUT.svg";
import { NavbarSwitch } from "./NavbarCreator";
import PopUp from "./PopUp";

export default function Navbar() {
  const currentPath = window.location.pathname.split("/").pop();
  const intl = useIntl();
  const { userLogged, setUserLogged } = useContext(UserLoggedContext);
  const { language, setLanguage } = useContext(LanguageContext);
  const target = useRef(null);
  const isHovering = useHover(target, { enterDelay: 0, leaveDelay: 0 });
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenLanguage, setIsOpenLanguage] = useState(false);
  const navigate = useNavigate();
  const { setArea } = useContext(AreaContext);
  useEffect(() => {
    setArea("explorer");
  }, []);

  const { elements, setElements } = useContext(ElementsCartContext);
  const { setFavourite } = useContext(FavouriteContext);
  const { user, setUser } = useContext(UserContext);
  const user_id = localStorage.getItem("user");

  const logOutUser = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate(`/`, { replace: true });
    setUserLogged(false);
    setFavourite(null);
    setElements([]);
    setUser({});
  };

  return (
    <div className="relative">
      <nav className="absolute left-0 right-0 z-10 flex items-center justify-between w-full text-white rounded-full bg-secondary top-5">
        <div className="h-full py-6 pl-12 pr-20 rounded-full bg-primary min-w-[280px]">
          <Link to="/" className="flex items-center gap-x-2">
            <img src={logo} alt="logo" className="w-36" />
          </Link>
        </div>
        <div className="flex items-center py-5 pl-4 pr-8 font-semibold xl:px-8 gap-x-2">
          <Link
            className={`py-2 xl:py-3 px-2 xl:px-4 rounded-full border border-secondary flex items-center gap-x-3 ${
              currentPath === "experiences"
                ? "bg-primary"
                : "hover:border-primary"
            }`}
            to="/experiences"
          >
            <img
              src={experience}
              alt="experience"
              className="hidden w-6 h-6 xl:block"
            />
            <p>
              {intl.formatMessage({
                defaultMessage: "Esperienze",
                id: "ipp3co",
              })}
            </p>
          </Link>
          <Link
            className={`py-2 px-2 xl:px-4 rounded-full border border-secondary items-center flex gap-x-3 ${
              currentPath === "structures"
                ? "bg-primary"
                : "hover:border-primary"
            }`}
            to="/structures"
          >
            <img
              src={structure}
              alt="structure"
              className="hidden w-6 h-6 mb-2 xl:block"
            />
            <p>
              {intl.formatMessage({
                defaultMessage: "Alloggi",
                id: "TAoh5a",
              })}
            </p>
          </Link>
          <Link
            className={`py-2 px-2 xl:px-4 rounded-full border border-secondary items-center flex gap-x-3 ${
              currentPath === "escoboxes"
                ? "bg-primary"
                : "hover:border-primary"
            }`}
            to="/escoboxes"
          >
            <img src={box} alt="box" className="hidden w-6 h-6 mb-2 xl:block" />
            <p>
              {intl.formatMessage({
                defaultMessage: "Esco box",
                id: "1kkz+W",
              })}
            </p>
          </Link>
          {/* <Link
            className={`py-2 px-1 xl:px-4 rounded-full border border-secondary ${
              currentPath === "community"
                ? "bg-primary"
                : "hover:border-primary"
            }`}
            to="/community"
          >
            {intl.formatMessage({
              defaultMessage: "Community",
              id: "4CrCbD",
            })}
          </Link> */}
          <Link
            className={`relative py-2 xl:py-3 px-1 pr-3 xl:px-4 xl:pr-6 rounded-full border border-secondary ${
              currentPath === "cart" ? "bg-primary" : "hover:border-primary"
            }`}
            to="/cart"
          >
            <img src={carrello_white} alt="carrello" className="w-6 h-6" />
            {elements.length === 0 ? null : (
              <span className="absolute px-2 text-xs text-white rounded-full left-6 xl:left-10 -top-2 bg-primary">
                {elements.length}
              </span>
            )}
          </Link>
          {userLogged ? (
            <>
              {user.has_structures || user.has_experiences ? (
                <PopUp
                  setIsOpen={setIsOpen}
                  iconStyle="noBackground"
                  goUnderneath={true}
                  orientation="vertical"
                  direction="right"
                  withoutEvent={true}
                  isOpen={isOpen}
                  icon={
                    <div
                      className="flex items-center border rounded-full"
                      ref={target}
                      style={{
                        borderColor: isHovering || isOpen ? "#246155" : "white",
                      }}
                    >
                      <div
                        className="flex items-center rounded-full"
                        onClick={() => navigate(`/profile/${user_id}`)}
                        style={{
                          backgroundColor:
                            isHovering || isOpen ? "#246155" : "white",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faUser}
                          className={`py-2.5 px-2 xl:px-4`}
                          style={{
                            color: isHovering || isOpen ? "white" : "#246155",
                          }}
                        />
                      </div>
                      <div
                        onClick={() => setIsOpen((prev) => !prev)}
                        className="flex items-center h-full"
                      >
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={`py-2 px-2 text-white text-sm`}
                        />
                      </div>
                    </div>
                  }
                  items={
                    <>
                      <NavbarSwitch />
                      {/* <Link
                        className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-t-[30px] cursor-pointer text-black hover:bg-gray-50"
                        to={`/profile/${user_id}`}
                      >
                        <img
                          src={backToUser}
                          alt="torna al profilo"
                          className="w-6 h-6 pr-2"
                        />
                        <div>Profilo</div>
                      </Link>
                      {user.has_structures && user.has_experiences && (
                        <>
                          <div className="mx-4 border-b" />
                          <Link
                            className="flex items-center w-full h-8 px-6 py-5 text-sm text-black cursor-pointer hover:bg-gray-50"
                            to={`/partnerZone/${user_id}`}
                            target="blank"
                          >
                            <img
                              src={backToUser}
                              alt="torna al profilo"
                              className="w-6 h-6 pr-2"
                            />
                            <div>Partner zone</div>
                          </Link>
                        </>
                      )}
                      {user.has_structures && !user.has_experiences && (
                        <>
                          <div className="mx-4 border-b" />
                          <Link
                            className="flex items-center w-full h-8 px-6 py-5 text-sm text-black cursor-pointer hover:bg-gray-50"
                            to={`/partner/${user_id}`}
                            target="blank"
                          >
                            <img
                              src={backToUser}
                              alt="torna al profilo"
                              className="w-6 h-6 pr-2"
                            />
                            <div>Partner zone</div>
                          </Link>
                        </>
                      )}
                      {user.has_experiences && !user.has_structures && (
                        <>
                          <div className="mx-4 border-b" />
                          <Link
                            className="flex items-center w-full h-8 px-6 rounded-b-[30px] py-5 text-sm text-black cursor-pointer hover:bg-gray-50"
                            to={`/creatorZone/${user_id}`}
                            target="blank"
                          >
                            <img
                              src={backToUser}
                              alt="torna al profilo"
                              className="w-6 h-6 pr-2"
                            />
                            <div>Partner zone</div>
                          </Link>
                        </>
                      )} */}
                      <div className="mx-4 border-b" />
                      <button
                        className="flex items-center w-full h-8 px-4 py-5 text-sm rounded-b-[30px] cursor-pointer text-black hover:bg-gray-50"
                        type="button"
                        onClick={logOutUser}
                      >
                        <img
                          src={logout}
                          alt="torna al profilo"
                          className="w-6 h-6 pr-2 mx-4"
                        />
                        <div>
                          {intl.formatMessage({
                            defaultMessage: "Logout",
                            id: "C81/uG",
                          })}
                        </div>
                      </button>
                    </>
                  }
                />
              ) : (
                <Link
                  className={`py-2 px-2 xl:px-4 rounded-full border border-secondary ${
                    currentPath === "login"
                      ? "bg-primary"
                      : "hover:border-primary"
                  }`}
                  to={`/profile/${localStorage.getItem("user")}`}
                >
                  <FontAwesomeIcon icon={faUser} />
                </Link>
              )}
            </>
          ) : (
            <Link
              className={`py-2 px-2 xl:px-4 rounded-full border border-secondary ${
                currentPath === "login" ? "bg-primary" : "hover:border-primary"
              }`}
              to="/login"
            >
              <FontAwesomeIcon icon={faUser} />
            </Link>
          )}
          <Link
            className={`py-2 xl:py-3 px-1 xl:px-4 rounded-full border border-secondary ${
              currentPath === "becomePartner"
                ? "bg-primary"
                : "hover:border-primary"
            }`}
            to="/becomePartner"
          >
            {intl.formatMessage({
              defaultMessage: "Diventa partner",
              id: "tVcuIm",
            })}
          </Link>
          {/* <button
            className={`py-2 px-1 xl:px-4 rounded-full border border-secondary hover:border-primary`}
          >
            <FontAwesomeIcon icon={faFlag} />
          </button> */}
          {/* this need to become a popup to set the language */}
          <PopUp
            setIsOpen={setIsOpenLanguage}
            iconStyle="noBackground"
            goUnderneath={true}
            withoutEvent={true}
            isOpen={isOpenLanguage}
            orientation="vertical"
            icon={
              <div
                className="flex items-center px-1 py-2 border rounded-full xl:py-3 xl:px-4 border-secondary hover:border-primary"
                onClick={() => setIsOpenLanguage((prev) => !prev)}
              >
                {language === "it" ? "IT" : "EN"}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={` pl-2 text-white text-sm`}
                />
              </div>
            }
            items={
              <>
                <button
                  className="flex items-center w-32 h-8 px-6 py-5 text-sm rounded-t-[30px] cursor-pointer text-black hover:bg-gray-50"
                  onClick={() => setLanguage("it")}
                >
                  <div className="px-2 mr-4 border rounded-md">It</div>
                  <div>Italiano</div>
                </button>
                <div className="mx-4 border-b" />
                <button
                  className="flex items-center w-32 h-8 px-6 rounded-b-[30px] py-5 text-sm text-black cursor-pointer hover:bg-gray-50"
                  onClick={() => setLanguage("en")}
                >
                  <div className="px-2 mr-2 border rounded-md">En</div>
                  <div>English</div>
                </button>
              </>
            }
          />
        </div>
      </nav>
    </div>
  );
}
