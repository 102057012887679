import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import geotag from "../../assets/icons/GEOTAG.svg";
import stella from "../../assets/icons/STELLA.svg";

export default function StructureSubtitle({ structure }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const intl = useIntl();
  const averageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="flex flex-wrap gap-4 text-lg">
      <div className="flex items-center mr-6 md:mr-12 gap-x-2 md:gap-x-4">
        <img src={geotag} alt="geotag" className="w-[18px] md:w-6" />
        <div className="text-base font-semibold md:text-lg text-escoBlack">
          {structure.address
            ? structure.address?.municipality
            : "Pertica Bassa"}
        </div>
      </div>
      <div className="flex items-center md:mr-12 gap-x-2 md:gap-x-4">
        <img src={stella} alt="stella" className="w-[18px] md:w-6" />
        <div className="text-base md:text-lg text-escoBlack">
          <span className="font-semibold">
            {structure.reviews ? averageReviews(structure.reviews) : 0}
          </span>
          /5{" "}
          {windowWidth < 768 &&
            `(${structure.reviews ? structure.reviews.length : 0})`}
        </div>
      </div>
      <div className="hidden md:flex items-center gap-x-1.5 text-base text-primary">
        <div>
          {structure.reviews ? structure.reviews.length : 0}{" "}
          {intl.formatMessage({
            defaultMessage: "recensioni",
            id: "zAyIZ1",
          })}
        </div>
      </div>
    </div>
  );
}
