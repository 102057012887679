import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AreaContext } from "../App";
import backToUser from "../assets/icons/BACKTOUSER.svg";
import disponibilita from "../assets/icons/DISPONIBILITA.svg";
import escobot from "../assets/icons/ESCOBOT.svg";
import esperienza from "../assets/icons/ESPERIENZA.svg";
import manage from "../assets/icons/MANAGE.svg";
import messaggi from "../assets/icons/MESSAGGI.svg";
import prenotazioni from "../assets/icons/PRENOTAZIONI.svg";
import propose from "../assets/icons/PROPOSE.svg";
import requisiti from "../assets/icons/REQUISITI.svg";
import review from "../assets/icons/REVIEW.svg";
import voucher from "../assets/icons/VOUCHER.svg";
import BaseComponent from "../components/BaseComponent";
import Contability from "../components/Contability";
import LoadingSpinnerPage from "../components/LoadingSpinnerPage";
import NavbarCreator from "../components/NavbarCreator";
import UserProfileCard from "../components/UserProfileCard";
import Availability from "../components/creatorZonePages/Availability";
import Bookings from "../components/creatorZonePages/Bookings";
import Home from "../components/creatorZonePages/Home";
import ManageExperiences from "../components/creatorZonePages/ManageExperiences";
import Faq from "./Faq";

function SingleLink({ item, baseUrl, windowWidth, setSelectedTab }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setArea } = useContext(AreaContext);
  const isSelected =
    windowWidth < 768
      ? false
      : item.label === "Home"
      ? window.location.pathname.split("/").pop() === id
      : `/${window.location.pathname.split("/").pop()}` === item.link;

  return (
    <>
      {item.isActive ? (
        <button
          onClick={() => {
            setSelectedTab(item.link);
            if (item.link === "/profile") {
              setArea("explorer");
            }
            let path =
              item.link === "/propose"
                ? "/eventKind"
                : item.link === "/profile"
                ? `/profile/${id}`
                : `${baseUrl}${item.link}`;
            navigate(path);
          }}
          className="flex items-center w-full h-10 px-4 py-2 gap-x-4 hover:bg-gray-200 rounded-[30px] border border-gray-100"
          style={{
            borderColor: isSelected ? "#246155" : "",
          }}
        >
          <img src={item.iconDark} alt={item.label} className="w-5 h-5 mr-4" />
          <p className="font-semibold">{item.label}</p>
        </button>
      ) : (
        <div
          className="flex items-center w-full h-10 px-4 py-2 gap-x-4 hover:bg-gray-200 rounded-[30px] border border-gray-100 cursor-not-allowed"
          style={{
            borderColor: isSelected ? "#246155" : "",
          }}
        >
          <img
            src={item.iconDark}
            alt={item.label}
            className="w-5 h-5 mr-4 opacity-40"
          />
          <p className="font-semibold text-gray-400">{item.label}</p>
        </div>
      )}
    </>
  );
}

export default function CreatorZone() {
  const { id, tab } = useParams();
  console.log(id, tab);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [userLogged, setUserLogged] = useState(null);
  const [user, setUser] = useState({});
  const [experiences, setExperiences] = useState([]);
  const [approvedExperiences, setApprovedExperiences] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  async function Start() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/logged`)
        .then((res) => {
          setUserLogged(true);
        })
        .catch(() => {
          setUserLogged(false);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        });
    } else {
      setUserLogged(false);
    }
  }

  async function GetUser() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/user/${id}/experiences`)
        .then((res) => {
          if (res.data.message === "Unauthorized") {
            navigate(`/`, { replace: true });
          }
          if (res.data.user.has_experiences) {
            setExperiences(res.data.experiences);
            setApprovedExperiences(
              res.data.experiences.filter(
                (experience) => experience.approved_at
              )
            );
            setUser(res.data.user);
          } else {
            navigate(`/proposeExperience`, { replace: true });
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  useEffect(() => {
    if (userLogged) {
      GetUser();
    }
  }, [userLogged]);

  useEffect(() => {
    Start();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  if (!userLogged) {
    navigate(`/login`, { replace: true });
  }

  const schema = [
    {
      label: "Home",
      iconDark: esperienza,
      link: "",
      isActive: true,
      component: <Home experiences={experiences} user={user} />,
    },
    {
      label: "Prenotazioni",
      iconDark: prenotazioni,
      link: "/bookings",
      component: <Bookings experiences={experiences} />,
      isActive: approvedExperiences.length > 0,
    },
    {
      label: "Disponibilità",
      iconDark: disponibilita,
      link: "/availability",
      component: <Availability experiences={experiences} />,
      isActive: approvedExperiences.length > 0,
    },
    {
      label: "Check-in",
      iconDark: requisiti,
      link: "/checkin",
      isActive: approvedExperiences.length > 0,
    },
    {
      label: "Messaggi",
      iconDark: messaggi,
      link: "/messages",
      isActive: approvedExperiences.length > 0,
    },
    {
      label: "Gestione esperienze",
      iconDark: manage,
      link: "/experiences",
      component: (
        <ManageExperiences
          experiences={experiences}
          setExperiences={setExperiences}
        />
      ),
      isActive: true,
    },
    {
      label: "Gestione account",
      iconDark: manage,
      link: "/account",
      isActive: true,
    },
    {
      label: "Proponi un esperienza",
      iconDark: propose,
      link: "/propose",
      isActive: true,
    },
    {
      label: "Recensioni",
      iconDark: review,
      link: "/reviews",
      isActive: approvedExperiences.length > 0,
    },
    {
      label: "Contabilità",
      iconDark: voucher,
      link: "/accounting",
      isActive: approvedExperiences.length > 0,
      component: <Contability user={user} isExperience={true} />,
    },
    {
      label: "Assistenza",
      iconDark: escobot,
      link: "/help",
      isActive: true,
      component: <Faq withoutHeader />,
    },
    {
      label: "Torna a viaggiare",
      iconDark: backToUser,
      link: "/profile",
      isActive: true,
    },
  ];

  if (!loading && userLogged) {
    document.title = "Esco | Area creator";

    return (
      <div>
        <header>
          <NavbarCreator />
        </header>
        <main>
          <div className="mx-0 2xl:mx-8">
            <div className="flex flex-col mx-8 mt-32 md:flex-row max-w-screen-2xl 2xl:mx-auto">
              <div className="flex flex-col w-full md:w-1/4 gap-y-4 ">
                <UserProfileCard user={user} setUser={setUser} />
                <div className="flex flex-col items-center justify-center w-full bg-gray-100 rounded-[20px] gap-y-0.5 ">
                  {schema.map((item, index) => (
                    <SingleLink
                      key={index}
                      item={item}
                      baseUrl={`/creatorZone/${id}`}
                      windowWidth={windowWidth}
                      setSelectedTab={setSelectedTab}
                    />
                  ))}
                </div>
              </div>
              <div className="flex-col hidden w-3/4 h-screen md:flex ">
                {tab ? (
                  <>
                    {schema.find((item) => item.link === `/${tab}`)
                      .component ? (
                      schema.find((item) => item.link === `/${tab}`).component
                    ) : (
                      <BaseComponent
                        title={
                          schema.filter((item) => item.link === `/${tab}`)[0]
                            .label
                        }
                      />
                    )}
                  </>
                ) : (
                  <Home experiences={experiences} user={user} />
                )}
              </div>
              <div className="flex flex-col w-full md:hidden">
                <AnimatePresence mode="wait">
                  {selectedTab !== null && (
                    <div className="fixed left-0 z-50 w-full top-[84px] h-[calc(100%-84px)]">
                      <motion.div
                        initial={{ x: 300 }}
                        animate={{ x: 0 }}
                        exit={{ x: 300 }}
                        transition={{ duration: 0.2 }}
                        className="absolute top-0 left-0 w-screen h-full px-4 bg-white"
                      >
                        {/* we need to create an arrow at the top and than show the component as above with the same logic as tab but using the selectedTab */}
                        <div className="relative flex w-full h-10 my-6 bg-gray-100 rounded-full">
                          <button
                            onClick={() => setSelectedTab(null)}
                            className="absolute border border-primary rounded-[50%] w-10 h-10 flex items-center justify-center"
                          >
                            <FontAwesomeIcon
                              icon={faChevronLeft}
                              className="w-6 h-6"
                            />
                          </button>
                          <h1 className="w-full pt-2 text-xl font-semibold text-center">
                            {
                              schema.find((item) => item.link === selectedTab)
                                .label
                            }
                          </h1>
                        </div>
                        <div className="bg-gray-100 h-[calc(90%-4rem)] rounded-[20px] overflow-y-auto py-4 gap-y-4">
                          {schema.find((item) => item.link === selectedTab)
                            .component ? (
                            schema.find((item) => item.link === selectedTab)
                              .component
                          ) : (
                            <BaseComponent
                              title={
                                schema.filter(
                                  (item) => item.link === selectedTab
                                )[0].label
                              }
                            />
                          )}
                        </div>
                      </motion.div>
                    </div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
