import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Bounce, ToastContainer } from "react-toastify";
import { SwiperSlide } from "swiper/react";
import { FavouriteContext } from "../../App";
import BaseComponent from "../BaseComponent";
import Carousel from "../Carousel";
import SingleBox from "../SingleBox";
import SingleExperience from "../SingleExperience";
import SingleStructure from "../SingleStructure";

function RenderGroupedExperiences({ experience, slidesPerView }) {
  console.log(experience, "experience");
  const intl = useIntl();
  const groupedData = experience.reduce((acc, curr, i) => {
    const group = Math.floor(i / slidesPerView);
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(curr);
    return acc;
  }, []);

  return (
    <div>
      <h1 className="text-2xl text-center text-primary md:text-left">
        {intl.formatMessage({
          defaultMessage: "Le tue esperienze preferite",
          id: "vxjjgY",
        })}
      </h1>
      {experience.length > 0 ? (
        <div className="flex flex-col pt-4 mt-4 overflow-y-auto rounded-md md:pr-8 gap-y-6">
          {experience.length > 3 || slidesPerView === 1 ? (
            <Carousel slidesPerView={slidesPerView} withLoop={false} centered>
              {groupedData.map((group, i) => (
                <SwiperSlide key={i}>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4">
                    <>
                      {group.map((item) => (
                        <SingleExperience item={item} key={item.id} />
                      ))}
                    </>
                  </div>
                </SwiperSlide>
              ))}
            </Carousel>
          ) : (
            <>
              {groupedData.map((group, i) => (
                <SwiperSlide key={i}>
                  <div className="grid grid-cols-3 gap-x-4">
                    <>
                      {group.map((item) => (
                        <SingleExperience item={item} key={item.id} />
                      ))}
                    </>
                  </div>
                </SwiperSlide>
              ))}
            </>
          )}
        </div>
      ) : (
        <h1 className="my-8 text-center text-gray-500 md:text-left">
          {intl.formatMessage({
            defaultMessage: "Non hai esperienze preferite",
            id: "++eUNu",
          })}
        </h1>
      )}
      <div className="fixed z-50">
        <ToastContainer
          containerId={`SingleExperienceContainer`}
          position="bottom-left"
          autoClose={2000}
          hideProgressBar
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="light"
          transition={Bounce}
          stacked
        />
      </div>
    </div>
  );
}

function RenderGroupedStructures({ structure, slidesPerView }) {
  const intl = useIntl();
  const groupedData = structure.reduce((acc, curr, i) => {
    const group = Math.floor(i / slidesPerView);
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(curr);
    return acc;
  }, []);

  return (
    <div>
      <h1 className="text-2xl text-center text-primary md:text-left">
        {intl.formatMessage({
          defaultMessage: "I tuoi alloggi preferiti",
          id: "tCRiKz",
        })}
      </h1>
      {structure.length > 0 ? (
        <div className="flex flex-col pt-4 mt-4 overflow-y-auto rounded-md md:pr-8 gap-y-6">
          {structure.length > 3 || slidesPerView === 1 ? (
            <Carousel slidesPerView={slidesPerView} withLoop={false} centered>
              {groupedData.map((group, i) => (
                <SwiperSlide key={i}>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4">
                    <>
                      {group.map((item) => (
                        <SingleStructure item={item} key={item.id} />
                      ))}
                    </>
                  </div>
                </SwiperSlide>
              ))}
            </Carousel>
          ) : (
            <>
              {groupedData.map((group, i) => (
                <SwiperSlide key={i}>
                  <div className="grid grid-cols-3 gap-x-4">
                    <>
                      {group.map((item) => (
                        <SingleStructure item={item} key={item.id} />
                      ))}
                    </>
                  </div>
                </SwiperSlide>
              ))}
            </>
          )}
        </div>
      ) : (
        <h1 className="my-8 text-center text-gray-500 md:text-left">
          {intl.formatMessage({
            defaultMessage: "Non hai alloggi preferiti",
            id: "B1gFI8",
          })}
        </h1>
      )}
      <div className="fixed z-50">
        <ToastContainer
          containerId={`SingleStructureContainer`}
          position="bottom-left"
          autoClose={2000}
          hideProgressBar
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="light"
          transition={Bounce}
          stacked
        />
      </div>
    </div>
  );
}

function RenderGroupedEscoBoxes({ escoBox, slidesPerView }) {
  const intl = useIntl();
  const groupedData = escoBox.reduce((acc, curr, i) => {
    const group = Math.floor(i / slidesPerView);
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(curr);
    return acc;
  }, []);

  return (
    <div>
      <h1 className="text-2xl text-center text-primary md:text-left">
        {intl.formatMessage({
          defaultMessage: "I tuoi escoBox preferiti",
          id: "oxq82E",
        })}
      </h1>
      {escoBox.length > 0 ? (
        <div className="flex flex-col pt-4 mt-4 overflow-y-auto rounded-md md:pr-8 gap-y-6">
          {escoBox.length >= 3 || slidesPerView === 1 ? (
            <Carousel slidesPerView={slidesPerView} withLoop={false} centered>
              {groupedData.map((group, i) => (
                <SwiperSlide key={i}>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4">
                    <>
                      {group.map((item) => (
                        <SingleBox
                          item={item}
                          key={item.id}
                          selectedTab={item.category[0]}
                        />
                      ))}
                    </>
                  </div>
                </SwiperSlide>
              ))}
            </Carousel>
          ) : (
            <>
              {groupedData.map((group, i) => (
                <SwiperSlide key={i}>
                  <div className="grid grid-cols-3 gap-x-4">
                    <>
                      {group.map((item) => (
                        <SingleBox
                          item={item}
                          key={item.id}
                          selectedTab={item.category[0]}
                        />
                      ))}
                    </>
                  </div>
                </SwiperSlide>
              ))}
            </>
          )}
        </div>
      ) : (
        <h1 className="my-8 text-center text-gray-500 md:text-left">
          {intl.formatMessage({
            defaultMessage: "Non hai escoBox preferiti",
            id: "ri51Cf",
          })}
        </h1>
      )}
      <div className="fixed z-50">
        <ToastContainer
          containerId={`SingleBoxContainer`}
          position="bottom-left"
          autoClose={2000}
          hideProgressBar
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="light"
          transition={Bounce}
          stacked
        />
      </div>
    </div>
  );
}

export default function Favourites() {
  const { favourite, setFavourite } = useContext(FavouriteContext);
  const [slidesPerView, setSlidesPerView] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSlidesPerView(1); // Per dispositivi mobili
      } else if (width >= 768 && width < 1024) {
        setSlidesPerView(2); // Per tablet
      } else {
        setSlidesPerView(3); // Per desktop
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Chiama immediatamente per impostare il valore iniziale

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <BaseComponent title="">
      <RenderGroupedExperiences
        experience={
          favourite && favourite.length > 0 ? favourite[0].experience : []
        }
        slidesPerView={slidesPerView}
      />
      <RenderGroupedStructures
        structure={
          favourite && favourite.length > 0 ? favourite[0].structure : []
        }
        slidesPerView={slidesPerView}
      />
      <RenderGroupedEscoBoxes
        escoBox={favourite && favourite.length > 0 ? favourite[0].escoBox : []}
        slidesPerView={slidesPerView}
      />
    </BaseComponent>
  );
}
