import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import BaseComponent from "../BaseComponent";
import EditPassword from "../EditPassword";
import EditUser from "../EditUser";

export default function Account() {
  const [user, setUser] = useState(null);
  const [editInfo, setEditInfo] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const { id } = useParams();
  const intl = useIntl();

  async function GetUser() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/user/${id}`)
        .then((res) => {
          console.log(res.data);
          setUser(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  useEffect(() => {
    GetUser();
  }, []);

  return (
    <BaseComponent
      title="Gestione account"
      sidebar={
        editInfo ? (
          <button
            onClick={() => setEditPassword(true)}
            className="flex items-center justify-between px-4 text-white rounded-md bg-primary"
          >
            {intl.formatMessage({
              defaultMessage: "Modifica password",
              id: "uFAk6V",
            })}
          </button>
        ) : (
          <button
            onClick={() => setEditInfo(true)}
            className="flex items-center justify-between px-4 text-white rounded-md bg-primary"
          >
            {intl.formatMessage({
              defaultMessage: "Modifica informazioni",
              id: "qtHWsh",
            })}
          </button>
        )
      }
    >
      <div className="flex flex-col md:flex-row px-4 md:px-10 md:py-4 my-4 overflow-y-auto bg-gray-100 rounded-md gap-y-4 min-h-[400px]">
        {user ? (
          <>
            {editPassword ? (
              <EditPassword setOpenEditPassword={setEditPassword} />
            ) : editInfo ? (
              <EditUser
                user={user}
                setOpenEditInfo={setEditInfo}
                setUser={setUser}
              />
            ) : (
              <>
                <div className="flex justify-center w-full py-2 md:w-1/4">
                  {user?.profile_pic ? (
                    <img
                      src={user?.profile_pic}
                      alt="profile"
                      className="w-32 h-32 rounded-[30px] xl:w-44 xl:h-44 object-cover"
                    />
                  ) : (
                    <div className="w-32 h-32 bg-gray-300 rounded-[30px] xl:w-44 xl:h-44"></div>
                  )}
                </div>
                <div className="flex flex-col w-full divide-y md:w-3/4 divide-primary">
                  <div className="flex flex-col h-20 py-2 gap-y-2">
                    <p className="text-lg font-semibold">
                      {intl.formatMessage({
                        defaultMessage: "Nome",
                        id: "CH/6DN",
                      })}
                    </p>
                    <p>
                      {user?.name} {user?.surname}
                    </p>
                  </div>
                  <div className="flex flex-col h-20 py-2 gap-y-2">
                    <p className="text-lg font-semibold">
                      {intl.formatMessage({
                        defaultMessage: "Email",
                        id: "sy+pv5",
                      })}
                    </p>
                    <p>{user?.email}</p>
                  </div>
                  <div className="flex flex-col h-20 py-2 gap-y-2">
                    <p className="text-lg font-semibold">
                      {intl.formatMessage({
                        defaultMessage: "Telefono",
                        id: "W0+B4S",
                      })}
                    </p>
                    <p>
                      {user.phone_number && user?.phone_number !== "-1"
                        ? user?.phone_number.substring(2)
                        : ""}
                    </p>
                  </div>
                  <div className="flex flex-col h-20 py-2 gap-y-2">
                    <p className="text-lg font-semibold">
                      {intl.formatMessage({
                        defaultMessage: "Indirizzo",
                        id: "4InXNE",
                      })}
                    </p>
                    <p>{user?.address?.formatted_address}</p>
                  </div>
                  <button
                    onClick={() => setEditInfo(true)}
                    className="flex items-center justify-center px-4 py-2 mt-8 text-white rounded-full md:hidden bg-primary"
                  >
                    <div>
                      {intl.formatMessage({
                        defaultMessage: "Modifica informazioni",
                        id: "qtHWsh",
                      })}
                    </div>
                  </button>
                </div>
              </>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full min-h-[368px]">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
