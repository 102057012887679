import { useLoadScript } from "@react-google-maps/api";
import Tooltip from "@uidu/tooltip";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import accessibility from "../assets/icons/ACCESSIBILITY.svg";
import cancellazione from "../assets/icons/CANCELLAZIONE.svg";
import lingue from "../assets/icons/LINGUE.svg";
import mappa from "../assets/icons/MAPPA.svg";
import recensioni from "../assets/icons/REVIEW.svg";
import stella from "../assets/icons/STELLA.svg";
import Map from "../components/Map";
import RoomCard from "../components/RoomCard";
import SingleReview from "../components/SingleReview";

export default function StructureBody({ structure, rooms, fromModal = false }) {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const intl = useIntl();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  });
  const averageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="w-full border-b border-primary" />
      <div className="grid grid-cols-1 gap-8 py-4 md:grid-cols-2">
        <div className="flex flex-col gap-y-4">
          <div className="flex items-start gap-x-6 md:gap-x-2">
            <img
              src={cancellazione}
              alt="cancellazione"
              className="w-6 mt-0.5 md:mt-0"
            />
            <div className="flex flex-col text-lg font-semibold md:gap-2 md:items-end md:flex-row">
              {intl.formatMessage({
                defaultMessage: "Cancellazione gratuita", id: 'rq1Dwe',
              })}{" "}
              <span className="text-base font-normal">
                {structure.cancellation_policy === "flessibile"
                  ? intl.formatMessage({
                      defaultMessage: "fino a 7 giorni prima",
                      id: "qD3jTm",
                    })
                  : structure.cancellation_policy === "moderata"
                  ? intl.formatMessage({
                      defaultMessage: "fino a 15 giorni prima",
                      id: "h+iuse",
                    })
                  : intl.formatMessage({
                      defaultMessage: "fino a 30 giorni prima",
                      id: "bELkKY",
                    })}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <div className="flex items-start gap-x-6 md:gap-x-2">
            <img src={lingue} alt="lingue" className="w-6 mt-0.5 md:mt-0" />
            <div className="flex flex-col text-lg font-semibold md:gap-2 md:items-end md:flex-row">
              {intl.formatMessage({
                defaultMessage: "Lingua",
                id: "6k9RO+",
              })}{" "}
              <div>
                {structure.lenguages.map((language, index) => (
                  <span className="text-base font-normal" key={language}>
                    {language}
                    {index < structure.lenguages.length - 1 ? ", " : ""}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
        {structure.accessibility.length > 0 && (
          <div className="flex flex-col items-start gap-2 md:flex-row md:items-center md:flex-wrap md:col-span-2">
            <div className="flex items-center gap-x-6 md:gap-x-2">
              <img src={accessibility} alt="accessibilità" className="w-6" />
              <div className="text-lg font-semibold">
                {intl.formatMessage({
                  defaultMessage: "Accessibilità:",
                  id: "zcF9D+",
                })}
              </div>
            </div>
            <div className="flex flex-wrap gap-2 ml-12 md:ml-0">
              {structure.accessibility
                .filter((accessibility) => accessibility !== "altro")
                .map((accessibility) => (
                  <span
                    className="px-2 text-base font-normal border rounded text-primary border-primary"
                    key={accessibility}
                  >
                    {accessibility === "Lis"
                      ? intl.formatMessage({
                          defaultMessage: "Ausili visivi: segnaletica tattile",
                          id: "rfCmkm",
                        })
                      : accessibility === "sedia"
                      ? intl.formatMessage({
                          defaultMessage: "Accessibile in sedia a rotelle",
                          id: "ROxhlW",
                        })
                      : intl.formatMessage({
                          defaultMessage: "Altro",
                          id: "s0Jlu4",
                        })}
                  </span>
                ))}
              {structure.accessibility.includes("altro") && (
                <>
                  {structure.extra_accessibility.map((accessibility) => (
                    <span
                      className="px-2 text-base font-normal border rounded text-primary border-primary"
                      key={accessibility}
                    >
                      {accessibility}
                    </span>
                  ))}
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="w-full border-b border-primary" />
      <div className="flex flex-col py-6 gap-y-6">
        <div className="text-xl font-semibold">
          {fromModal
            ? intl.formatMessage({
                defaultMessage: "Camera inclusa nel box:",
                id: "ZHaPvn",
              })
            : intl.formatMessage({
                defaultMessage: "Le nostre camere:",
                id: "cEarg3",
              })}
        </div>
        {rooms.map((room) => (
          <RoomCard
            key={room.id}
            room={room}
            kind={structure.kind}
            structure={structure}
          />
        ))}
      </div>
      <div className="w-full border-b border-primary" />
      <div className="flex flex-col pb-4 my-4 md:my-0 gap-y-6">
        <div className="flex flex-col text-lg font-semibold md:gap-2 md:items-end md:flex-row">
          <div className="flex items-start md:items-center gap-x-6 md:gap-x-2">
            <img src={mappa} alt="luogo" className="w-6 mt-0.5 md:mt-0" />
            <div className="text-lg font-semibold">
              {intl.formatMessage({
                defaultMessage: "Luogo:",
                id: "Ec9APS",
              })}
            </div>
          </div>
          <Tooltip
            className="w-full md:w-3/4"
            content={
              structure.address && structure.address?.formatted_address
                ? structure.address?.formatted_address
                : "via Roma 1, Pertica Bassa"
            }
            position="bottom"
          >
            <div className="mt-1 ml-12 text-base font-normal md:truncate md:ml-4">
              {structure.address && structure.address?.formatted_address
                ? structure.address?.formatted_address
                : "via Roma 1, Pertica Bassa"}
            </div>
          </Tooltip>
        </div>
        <div className="w-full rounded-md h-80">
          <Map
            address={{
              latitude: structure?.address?.latitude || 45.7658776,
              longitude: structure?.address?.longitude || 10.3767045,
            }}
            isLoaded={isLoaded}
            loadError={loadError}
          />
        </div>
      </div>
      <div className="w-full border-b border-primary" />
      <div className="flex justify-between my-4">
        <div className="flex items-start md:items-center gap-x-6 md:gap-x-2">
          <img
            src={recensioni}
            alt="recensioni"
            className="w-6 mt-0.5 md:mt-0"
          />
          <div className="text-lg font-semibold">
            {intl.formatMessage({
              defaultMessage: "Recensioni:",
              id: "yKJH0S",
            })}
          </div>
        </div>
        <div className="flex gap-x-4">
          <div className="flex items-center gap-x-2">
            <img src={stella} alt="stella" className="w-5" />
            <div>
              {structure.reviews ? averageReviews(structure.reviews) : 0}
              /5{" "}
              {windowWidth < 768 &&
                `(${structure.reviews ? structure.reviews.length : 0})`}
            </div>
          </div>
          <div className="hidden md:flex items-center gap-x-1.5 text-base text-primary">
            <div>
              {structure.reviews ? structure.reviews.length : 0}{" "}
              {intl.formatMessage({
                defaultMessage: "recensioni",
                id: "zAyIZ1",
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {structure.reviews ? (
          structure.reviews.map((review) => (
            <SingleReview key={review.id} review={review} />
          ))
        ) : (
          <div className="flex text-gray-400">
            {intl.formatMessage({
              defaultMessage: "Nessuna recensione disponibile al momento",
              id: "LrNHC+",
            })}
          </div>
        )}
      </div>
    </>
  );
}
